import { Component, OnInit } from '@angular/core';
import { StateService } from '../services/state/state.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(public stateService: StateService) { }

  ngOnInit() {
    this.stateService.raiseApplicationReady();
  }

}
