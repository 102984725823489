export class StationPeriod {
  public FromUTC: string;
  public ToUTC: string;
  public Year: number;
  public Month: number;
  public Day: number;
  public Hour: number;
  public Minute: number;

  // Placeholders ready for grid work around 
  // TODO: Work on grid exports
  //private ToLocalDateTime: string;
  //private FromLocalDateTime: string;
  //private ToLocalDateMonth: string;
  //private FromLocalDateMonth: string;
  //private ToLocalDate: string;
  //private FromLocalDate: string;

  constructor(fromUTC: string, toUTC: string, year: number, month: number, day: number, hour: number, minute: number) {
    this.FromUTC = fromUTC;
    this.ToUTC = toUTC;
    this.Year = year;
    this.Month = month;
    this.Day = day;
    this.Hour = hour;
    this.Minute = minute;
  }

  //getFromDate(formatMask) {
  //  return moment(this.FromUTC).format(formatMask);
  //}

  //getToDate(formatMask) {
  //  return moment(this.ToUTC).format(formatMask).toString();
  //}

}