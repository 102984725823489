export class RadarModel {

  private Code: string;
  private RadarName: string;
  private Location: string;
  private State: string;
  private Bounds: any;
  private isEnabled: boolean;
  private isOnline: boolean;
  private OfflineMessage: string;

  constructor(response: any) {
    if (response !== undefined && response !== null) {
     this.Code = response.code;
     this.Location = response.location;
     this.State = response.state;
     this.Bounds = response.bounds;
     this.isOnline = response.online;
     this.OfflineMessage = response.offline_reason;

    

    }
  }
}