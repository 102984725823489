<div class="outer-wrapper">
  <div class="container top">
    <div class="row">
      <div class="content-page">
        <h2>Features and Updates</h2>

        <h4>Weather stations overview</h4>

        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/Rm_Vel3Y1zw"></iframe>


        </div>
        <h4>v2.0.1</h4>
        <ul>
          <li>Forecast tab added into station latest.</li>
          <li>Spraying condition indicator updated.</li>
          <li>Wind spraying condition added.</li>
          <li>Minor bug fixes.</li>
        </ul>
        <h4>v2.0.0</h4>
        <ul>
          <li>Angular Updated.</li>
          <li>iOS 12,13 Bug not rendering dashboards.</li>
          <li>Link external tools via station code.</li>
          <li>Minor bug fixes.</li>
        </ul>
        <h4>v1.6.5</h4>
        <ul>
          <li>Rainfall chart bug fix.</li>
          <li>Dashboard station status bug fix.</li>
          <li>Minor bug fixes.</li>
        </ul>
        <h4>v1.6.4</h4>
        <ul>
          <li>Dynamic grouping of stations from API.</li>
          <li>Marker for Rainfall MTD grey when data availability less than 90%.</li>
          <li>Radar year to date updated.</li>
          <li>Radar Tab improved performance from Map click</li>
          <li>Minor bug fixes.</li>
        </ul>

        <h4>v1.6.3</h4>
        <ul>
          <li>Minor bug fixes.</li>
        </ul>

        <h4>v1.6.2</h4>
        <ul>
          <li>Esperance Radar location ready.</li>
          <li>Radar notifications updated.</li>
          <li>Filter option ranges updated.</li>
          <li>Datepicker for tabular fixed.</li>
          <li>Additional video tips created.</li>
          <li>Minor bug fixes.</li>
        </ul>

        <h4>v1.6.1</h4>
        <ul>
          <li>Minor bug fixes.</li>
        </ul>

        <h4>v1.6.0</h4>
        <ul>
          <li><strong>New: </strong>A redesigned User Navigation Interface intended to be more intuitive and easier to
            navigate.</li>
          <li><strong>New: </strong>The selection menu’s have been moved from the right side of the screen to the
            bottom, to make more space
            available to the map.</li>
          <li><strong>New: </strong>Maximised the map screen in Landscape and Portrait views</li>
          <li><strong>New: </strong>Fully rebuilt the Weather Station Search feature to provide more options and ability
            to move directly to the Station Dashboard or Map.</li>
          <li><strong>New: </strong>Removed the settings options as the latest users settings are now saved
            automatically</li>

          <li><strong>New: </strong>BoM Stations updated to show certain metrics on the map screen.</li>
          <li><strong>Coming soon: </strong>FAQ Videos to help you get the most from the Weather Website.</li>
        </ul>

        <h4>v1.5.1</h4>
        <ul>
          <li><strong>New: </strong>Addition of 16 new Weather Stations around Geraldton, Albany, and Esperance.</li>
          <li><strong>New: </strong>Addition of 3 new Weather Stations from Water Corporation</li>
          <li><strong>New: </strong>Upgrade of Radar data files to the new RF3 format, supplied by BoM.</li>
          <li><strong>Change: </strong>RADAR API Version 2 now being used.</li>
          <li><strong>New: </strong>Identification of Radar used to provide rainfall estimates</li>
          <li><strong>Change: </strong>Device Settings persist after the browser is closed and reopened</li>
          <li><strong>New:</strong>Map position saved by default when closing the application</li>
          <li>Minor bug fixes and performance enhancements</li>
        </ul>

        <h4>v1.5.0</h4>

        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/yTNrvLW9f5A"></iframe>
        </div>

        <ul>
          <li><strong>New: </strong>Added latest data from 91 BoM Weather Stations to the map</li>
          <li><strong>New: </strong>Added min temperature in the last 24 hours to the map</li>
          <li><strong>New: </strong>Weather legend - Updated with new colours to improve readability.</li>
          <li><strong>New: </strong>Weather legend filter - Clicking any colour will only show that value on the map.
          </li>
          <li><strong>New: </strong>Summaries – Add Drill Down capability to single station summaries</li>
          <li><strong>New: </strong>Map Feature – Station Outages; After 90 minutes map shows a light grey (NA) station
            marker</li>
          <li><strong>New: </strong>Map Feature – Accumulative Weather variables - Stations with missing data show grey
            instead of colour</li>
          <li><strong>New: </strong>All Stations List – Added 'Latest 10 days' tab (10 days data of a weather variable)
          </li>
          <li><strong>New: </strong>Dashboard Summaries – added the availability columns to the Hourly, Daily, Monthly
            and Yearly summary tables</li>
          <li><strong>New: </strong>Summaries – Added Barometric Pressure columns on the Hourly, Daily, Monthly and
            Yearly summary table pages</li>
          <li><strong>New: </strong>Map Views – Added New Temperature options; Max Temp since 9:00am, Max Temp previous
            24hrs to 9:00am and Minimum temperature in the past 24 hours</li>
          <li><strong>New: </strong>Warning & Alert notifications added to rain radars</li>
          <li><strong>Change: </strong>Dashboard Tables – Changed default sort back to descending order</li>
          <li><strong>Change: </strong>Charts – Solar Exposure Charts show MJ instead of kJ (for all intervals)</li>
          <li><strong>Change: </strong>Monthly & Yearly summaries – Reorder minAvg, maxAvg and RainDays columns next to
            their related columns</li>
          <li><strong>Change: </strong>Device Settings persist after the browser is closed and reopened</li>
          <li>Minor updates to chart headers and axis labels</li>
          <li>Minor bug fixes and performance enhancements</li>
        </ul>



        <h4>v1.4.2</h4>
        <ul>
          <li><strong>New: </strong>Charts display a default reporting period when selecting Hourly, Daily, Monthly or
            Yearly intervals.</li>
          <li><strong>New: </strong>Data Exporting feature has been corrected and allows for a maximum of 1000 records.
          </li>
          <li><strong>New: </strong>When the radar images are not available, the weather station map indicates the radar
            being unavailable by a red square and cross.</li>
          <li><strong>New: </strong>When a weather station has an outage that goes past midnight, the dashboard now
            displays a message "Data is not currently available for display due to an outage at this station.</li>
          <li>Optimised performance and other small bug fixes.</li>
        </ul>

        <h4>v1.4.1</h4>
        <ul>
          <li>Small bug fixes.</li>
        </ul>

        <h4>v1.4.0</h4>


        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/jymm7eXsuDw"></iframe>
        </div>


        <ul>
          <li> <strong>New:</strong> The map and dashboard automatically refreshes data every 5 minutes. Hovering over
            the refresh timer enables refresh now, pause and play buttons for finer control.
          </li>

          <li> <strong>New:</strong> Users can now customise default settings for their device. These options include
            setting the default weather variable and region, enable dark mode to reduce eye strain or
            collapse the header to increase screen space, labels can also be hidden by default.
          </li>


          <li><strong>New:</strong> Enabling the radar control activates a time slider with pause, play and slider
            functionality.
          </li>

          <li><strong>New:</strong> Buttons have been added to the dashboard chart page to easily switch between tabular
            data sets.
          </li>
          <li>Optimised performance to reduce page load times.</li>


          <li>Other small improvements.</li>
        </ul>


        <h4>v1.3.0</h4>
        <ul>
          <li><strong>New:</strong> Welcome screen has weather station overview video included.</li>
          <li>Other small improvements.</li>
        </ul>

        <h4>v1.2.1</h4>
        <ul>
          <li><strong>New:</strong> Welcome screen inviting the user to switch to dark mode and access to
            video showcasing the app and new features.
          </li>
          <li><strong>New:</strong> Weather station meta data including probe heights is now available from
            the dashboard via the 'i' button, next to the station name.
          </li>
          <li><strong>New:</strong> Map colour legends are now shown on small mobile devices.</li>
          <li><strong>New:</strong> Dark mode for night use or to reduce eye strain. The 'Theme' switch is
            located at the top-right of the webpage to enable it.
          </li>
          <li><strong>New:</strong> Better compatibility with other browsers.</li>
          <li><strong>New:</strong> Cookies used to store theme settings.</li>
          <li>Other small improvements.</li>
        </ul>

        <h4>v1.2.0</h4>

        <ul>
          <li><strong>New:</strong> The map weather variables now all have colour codes and filterable
            legends.
          </li>
          <li><strong>New:</strong> The map weather variable labels are now hidden by default and can be
            displayed using <span class="ShowValues">Show values</span> button.
          </li>
          <li><strong>New:</strong> The map’s weather variables panel is now shown by default.</li>
          <li><strong>New:</strong> The map can be set to <img class="FullScreenIcon"
              src="../../assets/images/full-screen-icon.png" alt="Full screen icon"> full-screen mode to
            provide more viewing space.
          </li>
          <li><strong>New:</strong> The site header can be hidden using <img class="RemoveHeader"
              src="../../assets/images/RemoveHeaderIcon.png" alt="Remove Header icon"> to
            provide more viewing space.
          </li>
          <li><strong>New:</strong> The dashboard now shows an ‘actual’ rainfall graph and other current data
            is more visible.
          </li>
          <li><strong>New:</strong> The dashboard layout has been updated to include additional station
            rainfall and wind rose charts.
          </li>
          <li><strong>New:</strong> The map wind direction display has been improved and now includes wind
            speed colour ranges.
          </li>
          <li>Other small improvements.</li>
        </ul>

        <h4>v1.1.1</h4>

        <ul>
          <li><strong>New</strong>: The map's 'Nearby' stations list now enables clicking the station's name
            to select it on the map and show its 'Summary' data.
          </li>
          <li><strong>New</strong>: The dashboard 'Rainfall' box now shows rainfall for the previous 24hrs to
            9am and for the last 14 days.
          </li>
          <li><strong>Improvement</strong>: Updated data for the map view of 'Rainfall previous 24 hours to
            9am mm'.
          </li>
          <li><strong>Improvement</strong>: The 'Export All' option in Minute, Hourly, Daily, Monthly and
            Yearly - summaries now include all pages.
          </li>
          <li>Other small improvements.</li>
        </ul>

      </div>
    </div>
  </div>
</div>
<app-mobile-filter-menu></app-mobile-filter-menu>
<app-station-table></app-station-table>