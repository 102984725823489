import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';

import { WeatherChartService } from '../../../services/weather/weather.chart.service';
import { StateService } from '../../../services/state/state.service';

import * as moment from 'moment'; // Include the library have to include reference in angular CLI to js file

// Add additional modules required
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);


const HOUR_INTERVAL = 3600000;
const DAY_INTERVAL = 86400000;       // 24 * 3600 * 1000;
const MONTHLY_INTERVAL = 2628000000;
const YEARLY_INTERVAL = 31536000000; // 365 Days

const MARGINAL_COLOUR = '#FFFFDF';
const PREFERRED_COLOUR = '#DCF8DB';
const UNSUITABLE_COLOUR = '#F9DCDB';

const DARK_MARGINAL_COLOUR = '#a96b03';
const DARK_PREFERRED_COLOUR = '#006500';
const DARK_UNSUITABLE_COLOUR = '#840000';

const DEWPOINT_SERIES_COLOUR = '#199AD2';
const DELTAT_SERIES_COLOUR = '#F65102';



@Component({
  selector: 'app-delta-tchart',
  templateUrl: './delta-tchart.component.html',
  styleUrls: ['./delta-tchart.component.scss']
})
export class DeltaTchartComponent implements OnInit {

  public currentTheme: String;
  station = { code: '', name: '' };       

  Highcharts = Highcharts;
  isDataLoaded: boolean = false;

  tickInterval = DAY_INTERVAL;

  chartOptions: any = {
    credits: {
      enabled: false
    },
    yAxis: {
      title: {
        text: '&#8451;',
        useHTML: true
      },
      plotBands: [
        {
          color: MARGINAL_COLOUR, // Color value
          from: 0, // Start of the plot band
          to: 2, // End of the plot band
          label: {
            text: 'Marginal', // Content of the label. 
            align: 'center' // Positioning of the label. 
          }
        },
        {
          color: PREFERRED_COLOUR, // Color value
          from: 2, // Start of the plot band
          to: 8, // End of the plot band
          label: {
            text: 'Preferred', // Content of the label. 
            align: 'center' // Positioning of the label. 
          }
        },
        {
          color: MARGINAL_COLOUR, // Color value
          from: 8, // Start of the plot band
          to: 10, // End of the plot band
          label: {
            text: 'Marginal', // Content of the label. 
            align: 'center' // Positioning of the label. 
          }
        },
        {
          color: UNSUITABLE_COLOUR,
          from: 10, // Start of the plot band
          to: 9999, // End of the plot band
          label: {
            text: 'Unsuitable', // Content of the label. 
            align: 'center' // Positioning of the label. 
          }
        }
      ],
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },

    series: [{
      name: 'Dewpoint Avg',
      data: []
    }, {
      name: 'Delta T Avg',
      data: [],
      color: "#5D52E7"
    }],
    chart: {
      zoomType: 'x'
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  }

  constructor(
    private weatherChartService: WeatherChartService,
    private stateService: StateService) { }


  ngOnInit() {

    this.currentTheme = this.stateService.stateSettings.theme;

    this.weatherChartService.onChartFilterParametersSet.subscribe(
      params => {
        this.onChartFilterParametersSet(params);
      })

  }



  //https://stackoverflow.com/questions/17748244/date-time-plotting-in-highcharts

  setChartOptions(chartData, params) {

    let subtitleDateMask = 'DD-MM-YYYY';
    if (params.interval === 'HOURLY') {
      subtitleDateMask = 'DD-MM-YYYY hh:mma';
    }

    this.chartOptions = {
      title: {
        text: this.station.name + ' : Delta T and Spraying Conditions (' + params.interval[0].toUpperCase() + params.interval.toLowerCase().substring(1) + ')'
      },

      subtitle: {
        text: moment(params.event.value[0]).format(subtitleDateMask) + ' - ' + moment(params.event.value[1]).format(subtitleDateMask)
      },
      xAxis: {
        lineColor: '#000000',
        gridLineColor: '#000000',
        tickInterval: this.tickInterval,
        type: 'datetime'
      },
      yAxis: {
        lineColor: '#000000',
        gridLineColor: '#000000',
        tickInterval: 2.5,
        title: {
          text: 'oC'
        },
        plotBands: [
          {
            color: this.currentTheme === 'dark-gotham' ? DARK_MARGINAL_COLOUR : MARGINAL_COLOUR,
            from: 0, // Start of the plot band
            to: 2, // End of the plot band
            label: {
              text: 'Marginal', // Content of the label. 
              align: 'center' // Positioning of the label. 
            }
          },
          {
            color: this.currentTheme === 'dark-gotham' ? DARK_PREFERRED_COLOUR : PREFERRED_COLOUR,
            from: 2, // Start of the plot band
            to: 8, // End of the plot band
            label: {
              text: 'Preferred', // Content of the label. 
              align: 'center' // Positioning of the label. 
            }
          },
          {
            color: this.currentTheme === 'dark-gotham' ? DARK_MARGINAL_COLOUR : MARGINAL_COLOUR,
            from: 8, // Start of the plot band
            to: 10, // End of the plot band
            label: {
              text: 'Marginal', // Content of the label. 
              align: 'center' // Positioning of the label. 
            }
          },
          {
            color: this.currentTheme === 'dark-gotham' ? DARK_UNSUITABLE_COLOUR : UNSUITABLE_COLOUR,
            from: 10, // Start of the plot band
            to: 9999, // End of the plot band
            label: {
              text: 'Unsuitable', // Content of the label. 
              align: 'center' // Positioning of the label. 
            }
          }
        ],
      },
      series: [{
        name: 'Dewpoint Avg',
        data: Array(chartData.dewPointAvg)[0],
        color: DEWPOINT_SERIES_COLOUR,
          marker: {
            fillColor: DEWPOINT_SERIES_COLOUR,
            lineColor: '#FFFFFF',
            lineWidth: 1
          }
      }, {
        name: 'Delta T Avg',
        data: Array(chartData.deltaTAvg)[0],
        color: DELTAT_SERIES_COLOUR,
          marker: {
            fillColor: DELTAT_SERIES_COLOUR,
            lineColor: '#FFFFFF',
            lineWidth: 1
          }
      }]
    }
  }



  onChartFilterParametersSet(params) {

    let stationCode = params.station.code;
    let interval = params.interval;
    let limit = 1000;
    let offset = 0;
    this.station = params.station;

    // Date parameters to be sent UTC datetime.
    // split routine filtering milliseconds.
    let startDateTime =  moment(params.event.value[0]).format('YYYY-MM-DD HH:mm:ss');//params.event.value[0].toISOString().split('.')[0] + "Z";
    let endDateTime =   moment(params.event.value[1]).format('YYYY-MM-DD HH:mm:ss');//params.event.value[1].toISOString().split('.')[0] + "Z";

    this.weatherChartService.getStationDeltaTData(stationCode, interval, startDateTime, endDateTime, limit, offset).subscribe(data => {

      // Format data for series
      var chartData = { dewPointAvg: [], deltaTAvg: []};

      for (let index in data) {
        let momentDate = moment(data[index].dateFrom);
        var tz_offset = (new Date().getTimezoneOffset() * -1) * 60 * 1000;
        var unix_time = momentDate.toDate().getTime() + tz_offset;
        // Not interested in negative values as chart will show from 0 onwards with spraying condition options.
        let dewPointValue = data[index].dewPoint.avg < 0 ? 0 : data[index].dewPoint.avg;
        
        chartData.dewPointAvg.push(Array(unix_time, dewPointValue));
        chartData.deltaTAvg.push(Array(unix_time, data[index].deltaT.avg));
      }

      // if required set the interval for a better representation on the chart.
      // defaulting to DAY for now can be changed.
      // this.tickInterval = 
      switch (interval) {
        case "HOURLY":
          this.tickInterval = HOUR_INTERVAL;
          break;
        case "MONTHLY":
          this.tickInterval = MONTHLY_INTERVAL;
          break;
        case "YEARLY":
          this.tickInterval = YEARLY_INTERVAL;
          break;  
        default:
          //DAILY
          this.tickInterval = DAY_INTERVAL;
          break;
      }

      this.setChartOptions(chartData, params);

    });

    this.isDataLoaded = true;
  }
}
