import { PrecipitationSummaryMetrics } from './precipitation-summary-metrics.model';

export class RainfallSummary {

  public DateTime: string;
  public RadarCode: string;
  public Latitude: number;
  public Longitude: number;
  public DistanceMetres: number;

 
  public RainfallSince9AM: number;
  public RainfallCurrentHour: number;
  public RainfallMonthToDate: number;
  public RainfallYearToDate: number;

  // Derived fields for side bar indication of Radar used
  // when returning the closest point.
  public ClosestRadarCode: string;
  public ClosestRadarName: string;


  // Monthly metrics obtained from the summaries end point.
  public MonthlyRain: PrecipitationSummaryMetrics[];


  constructor(response?: any) {

    if (response !== undefined && response !== null) {
      this.DateTime = response.dateTime;
      this.RadarCode = response.radar.code;
      this.Latitude = response.radar.latitude;
      this.Longitude = response.radar.longitude;
      this.DistanceMetres = response.radar.distance;

      if (response.rainfall !== undefined && response.rainfall !== null) {
        this.RainfallSince9AM = response.rainfall.since9AM;
        this.RainfallCurrentHour = response.rainfall.currentHour;
        this.RainfallMonthToDate = response.rainfall.monthToDate;
        this.RainfallYearToDate = response.rainfall.yearToDate;
      }
    }
  }


  /**
   * Set the derived fields to the values so that they are refernced in
   * the sidebar, to indicate the closest Radar.
   * @param radar Object from state thet references Radar properties
   */
  public setClosestRadar(radar: any) {
    this.ClosestRadarCode = radar.id;
    this.ClosestRadarName = radar.name;
  }



}