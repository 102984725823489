
<div class="outer-wrapper">
  <div class="container top">
    <div class="row">
      <div class="content-page">
        <h2>Video Tips</h2>

        <h3>Navigating the new interface</h3>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/kUOSojTTusg"></iframe>
        </div>

        <h3>Using the legend filter menu</h3>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ax14eqYPo3c"></iframe>
        </div>

        <h3>Adding icon to home screen - iOS</h3>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/54z1K62upII"></iframe>
        </div>

        <h3>Adding icon to home screen - Android</h3>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/fW1nB7ATn2o"></iframe>
        </div>

      </div>
    </div>
  </div>
</div>
<app-mobile-filter-menu></app-mobile-filter-menu>
<app-station-table></app-station-table>