<div class="modal" [ngClass]="{'active': isOpen == true}">
    <div class="">
    <div class="container notification modal-content">
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span class="icon icon-cancel-button-icon"></span>
        </button>

        <div class="decoration col-12 col-lg-3 row align-items-center  justify-content-center" [ngClass]="colour">
            <div class="icon icon-notification"></div>
        </div>
        <div class="message col-12 col-lg-9 ">
            <h4 class="modal-title" [innerHTML]="title"></h4>

            <p [innerHTML]="message"></p>
        </div>
    </div>
    </div>
</div>  