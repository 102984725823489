import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { StateService } from '../services/state/state.service';
import { WeatherService } from '../services/weather/weather.service';
import { DataCache } from '../services/data-cache/data-cache.service';
import { StationModel } from '../models/station.model';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../services/google-analytics/google-analytics.service';
import { ObjectUtils } from 'primeng/utils';

import { Table } from 'primeng/table';
import * as moment from 'moment';
import { debug } from 'console';

@Component({
  selector: 'app-summary-metric-table',
  templateUrl: './summary-metric-table.component.html',
  styleUrls: ['./summary-metric-table.component.scss']
})
export class SummaryMetricTableComponent implements OnInit, OnChanges {

  @Output() activeChange = new EventEmitter();
  @Input() active = false;
  public mapSettings;
  public exportFilename;

  @ViewChild('dt', { static: true }) dataTable: Table;

  public selectedMetricOption: any;
  public currentSelectedMetricKey = 'rainfall';
  public currentlySelectedId = 1;
  public currentSelectedDescription = 'Rainfall';
  public currentSelectedValue = 'Total mm';

  dropdownSettings = {
    singleSelection: true,
    text: this.currentSelectedValue,
    enableSearchFilter: false,
    classes: 'selected-list',
    labelKey: 'value',
    primaryKey: 'id',
    showCheckbox: false,
    groupBy: 'category'

  };



  measurementOptions = [
    { id: 1, key: 'rainfall', value: 'Total mm', category: 'Rainfall', displayName: 'Total rainfall' },
    { id: 2, key: 'airTemperature', value: 'Minimum °C', collection: 'min', category: 'Temperature' },
    { id: 3, key: 'airTemperature', value: 'Maximum °C', collection: 'max', category: 'Temperature' },
    { key: 'airTemperature', value: 'Average °C', collection: 'avg', category: 'Temperature' },
    //  { key: 'airTemperatureMinAvg', value: 'Average Min °C', collection: 'minAvg' , category: 'Temperature'},
    //  { key: 'airTemperatureMaxAvg', value: 'Average Max °C', collection: 'maxAvg' , category: 'Temperature'},
    { id: 4, key: 'frostCondition', value: 'Cold duration (2°C and below)', collection: 'minutes', category: 'Extreme Temperature' },
    //  { id: 5, key: 'frostCondition', value: 'Cold days', collection: 'day', category: 'Extreme Temperature'},
    { id: 6, key: 'heatCondition', value: 'Heat duration (30°C and above)', collection: 'minutes', category: 'Extreme Temperature' },
    //  { id: 7, key: 'heatCondition', value: 'Heat days', collection: 'day' , category: 'Extreme Temperature'},

    //  { key: 'rainDays', value: 'Rainy days', collection: 'day', category: 'Rainfall' },
    { id: 8, key: 'relativeHumidity', value: 'Minimum %', collection: 'min', category: 'Relative Humidity' },
    { id: 9, key: 'relativeHumidity', value: 'Maximum %', collection: 'max', category: 'Relative Humidity' },
    { id: 10, key: 'relativeHumidity', value: 'Average %', collection: 'avg', category: 'Relative Humidity' },
    { id: 11, key: 'erosionCondition', value: 'High wind duration (28.8km/h and above)', collection: 'minutes', category: 'Extreme Wind' },
    //  { id: 12, key: 'erosionConditionDays', value: 'High wind days', collection: 'day' , category: 'Extreme Wind'},
    { id: 13, key: 'soilTemperature', value: 'Minimum °C', collection: 'min', category: 'Soil Temperature' },
    { id: 14, key: 'soilTemperature', value: 'Maximum °C', collection: 'max', category: 'Soil Temperature' },
    { id: 15, key: 'soilTemperature', value: 'Average °C', collection: 'avg', category: 'Soil Temperature' },
    { id: 16, key: 'solarExposure', value: 'Total kJ', category: 'Solar Radiation' },
    //{ key: 'solarExposure', value: 'Total kJ', category: 'Solar Radiation' },
    { id: 17, key: 'panEvaporation', value: 'Total to 9am mm', category: 'Pan Evaporation' },
    { id: 18, key: 'panEvaporation12AM', value: 'Total since 12am mm', category: 'Pan Evaporation' },
    { id: 19, key: 'evapotranspiration', collection: 'shortCrop', value: 'Short Crop mm', category: 'Evapotranspiration' },
    { id: 20, key: 'evapotranspiration', collection: 'tallCrop', value: 'Tall Crop mm', category: 'Evapotranspiration' },
    { id: 21, key: 'chillHours', value: 'Chill Hours', category: 'Chilling' },
    { id: 22, key: 'richardsonUnits', value: 'Richardson Chill Units', category: 'Chilling' },
    //  { key: 'relativeHumidity', value: 'Average Humidity %', collection: 'avg', category: 'General' },
    { id: 23, key: 'dewPoint', value: 'Maximum °C', collection: 'max', category: 'Dew Point' },
    { id: 24, key: 'dewPoint', value: 'Minimum °C', collection: 'min', category: 'Dew Point' },
    { id: 25, key: 'dewPoint', value: 'Average °C', collection: 'avg', category: 'Dew Point' },
    // { key: 'dewPointAvgMin', value: 'Average Mimimum °C', collection: 'avgMin', category: 'Dew Point' },
    // { key: 'dewPointAvgMax', value: 'Average Maximum °C', collection: 'avgMax', category: 'Dew Point' },
    { id: 26, key: 'wetBulb', value: 'Maximum °C', collection: 'max', category: 'Wet Bulb' },
    { id: 27, key: 'wetBulb', value: 'Minimum °C', collection: 'min', category: 'Wet Bulb' },
    { id: 28, key: 'wetBulb', value: 'Average °C', collection: 'avg', category: 'Wet Bulb' },
    //{ key: 'wetBulbMax', value: 'Average Maximum °C', collection: 'avgMax' , category: 'Wet Bulb'},
    //{ key: 'wetBulbMin', value: 'Average Minimum °C', collection: 'avgMin' , category: 'Wet Bulb'},
    { id: 29, key: 'deltaT', value: 'Maximum °C', collection: 'max', category: 'Delta T' },
    { id: 30, key: 'deltaT', value: 'Minimum °C', collection: 'min', category: 'Delta T' },
    { id: 31, key: 'deltaT', value: 'Average °C', collection: 'avg', category: 'Delta T' },
    //{ key: 'deltaTMaxAvg', value: 'Average Maximum °C', collection: 'avgMax' , category: 'Delta T'},
    //{ key: 'deltaTMinAvg', value: 'Average Minimum °C', collection: 'avgMin' , category: 'Delta T'},
    //{ key: 'wind', value: 'Average Wind Speed  @10m km/h' },
    //{ key: 'wind', value: 'Maximum Wind Speed  @10m km/h' },
    //{ key: 'wind', value: 'Average Wind Speed  @30m km/h' },
    //{ key: 'wind', value: 'Maximum Wind Speed  @30m km/h' },
    { id: 32, key: 'barometricPressure', value: 'Maximum hPa', collection: 'max', category: 'Barometric Pressure' },
    { id: 33, key: 'barometricPressure', value: 'Minimum hPa', collection: 'min', category: 'Barometric Pressure' },
    { id: 34, key: 'barometricPressure', value: 'Average hPa', collection: 'avg', category: 'Barometric Pressure' },
    { id: 35, key: 'stationAvailability', value: 'Percentage to 9am', collection: 'to9AM', category: 'Station Availability' },
    { id: 36, key: 'stationAvailability', value: 'Percentage since 9am', collection: 'since9AM', category: 'Station Availability' },
    { id: 37, key: 'stationAvailability', value: 'Percentage since 12am', collection: 'since12AM', category: 'Station Availability' },
    { id: 38, key: 'battery', value: 'Minimum Battery Voltage', collection: 'minVoltage', category: 'Station Diagnostics' },
    // { key: 'observations', value: 'Observations %', collection: 'percentage' , category: 'Station Diagnostics'}
  ];

  // Table Parameters
  loading: boolean = false;
  totalRecords: number;
  limit: number = 20;
  offset: number = 0;
  sort: string;
  page: number = 0;
  data: any;

  selectedData: any;
  selectedColumns: any[];

  cols: any[];

  public weatherStations: StationModel[];

  constructor(
    private stateService: StateService,
    private route: Router,
    private weatherService: WeatherService,
    private dataCacheService: DataCache,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.mapSettings = this.stateService.mapSettings;

    this.dataCacheService.getStations().subscribe(data => {
      this.weatherStations = data;
    });

    this.defineColumns();

    this.selectedColumns = this.cols.filter(column => column.default === true);

  }

  closeSummary() {

    this.activeChange.emit(false);
    //this.mapSettings.isMetricSummaryTableActive = false;
  }


  onMeasurementOptionSelected(metricSelected) {
    this.currentlySelectedId = metricSelected.id;
    this.currentSelectedMetricKey = metricSelected.key;
    this.currentSelectedValue = metricSelected.value;
    this.currentSelectedDescription = metricSelected.category;

    this.loadTabularDataLazy(null);
  }

  loadTabularDataLazy(event: any) { //LazyLoadEvent

    // Only filter if active.
    if (this.mapSettings !== undefined && this.mapSettings.isSummaryMetricTable) {
      this.loading = true;

      const startDate = moment().add(-10, 'days').format('YYYY-MM-DD');
      const endDate = moment().add(-1, 'days').format('YYYY-MM-DD');

      this.weatherService.getTimeSeries(startDate, endDate, this.currentSelectedMetricKey).subscribe(
        data => {

          this.data = this.parseTimeSeriesintoDatasource(data.collection);
          // Dyanmically create the windprobe columns as this changes from station to station
          // we can use the response to create the columns if they are not already mapped.
          //  this.initWindProbeColumns();
          // set current params for pagination

          this.totalRecords = data.metadata.collection.count;
          this.limit = data.metadata.collection.limit;
          this.offset = data.metadata.collection.offset;
        },
        err => { this.onResponseError(err) },
        () => this.onCompletedGetResponse());
    }

  }

  /*
  * Generate the columns for the tabular data.
  */
  defineColumns() {

    this.cols = [
      { field: 'stationName', header: 'Name', width: '200px', default: true, sortable: false, tooltip: '', order: 1, frozen: true }
    ];

    // Dynamically add last 10 days
    let i = 0;
    for (i = 10; i > 0; i--) {
      const date = moment();
      date.add(i * -1, 'days');
      this.cols.push({ field: date.format('YYYY-MM-DD'), header: date.format('DD/MM'), width: '150px', default: true, sortable: false, tooltip: '', order: i, frozen: false });
    }

  }

  /**
   * Helper function to check and object to detrmine if it is classed as empty.
   * @param obj
   */
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }


  /**
   * When there is an error with the reponse we will reset the spinner so the table stops loading and the interceptor for the generic
   * error handling will show the message why there was an error.
   * @param response
   */
  onResponseError(response) {
    this.loading = false;
  }

  /**
   * Completion of the HTTP call can indicate that the table is no longer loading.
   */
  onCompletedGetResponse() {
    this.loading = false;
  }

  /*
  * Function to return the value from the JSON object dynamically
  * TODO://Possibly make more flexible currently two levels with json and when 4 levels assumes array
  * used to allow dynamic grid columns and selectable functionality
  */
  getFieldValue(item, object) {


    let propertyName = item.field;

    var parts = propertyName.split("."),
      length = parts.length,
      i,
      property = object || this;

    if (length <= 2) {
      for (i = 0; i < length; i++) {
        property = property[parts[i]];
      }
    } else {
      try {
        property = property[parts[0]][parts[1]][parts[2]];
      }
      catch {
        //todo: determine a better way
        property = "";
      }
    }

    // Have the value now apply a format if required.
    if (item.type !== undefined) {
      if (item.type === 'Date' && item.format !== undefined) {
        property = moment(property).format(item.format);
      }
    }

    return property;
  }


  /**
   * 
   * @param changes Monitors the input variable active so it indicates when the component
   * is displayed. The change will initially load the default metric 'Rainfall'
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.active !== undefined && changes.active.currentValue) {
      // if (this.currentSelectedMetricKey === undefined || this. === '') {
      this.loadTabularDataLazy(null);
      //  }
    }
  }

  /*
   * Function to parse the timeseries into a format that can be mapped into the datatable.
   * function done here for now as it will only be used in this component.
   */
  parseTimeSeriesintoDatasource(response) {

    let dataRow = [];
    let collectionKey = this.measurementOptions.filter(m => m.id === this.currentlySelectedId)[0].collection;

    if (collectionKey === undefined) {
      // each station
      for (let index = 0; index < response.length; index++) {
        let stationRow = {};
        stationRow['stationCode'] = response[index].stationCode;
        stationRow['stationName'] = response[index].stationName + ' (' + response[index].stationCode + ')';
        for (let readingIndex = 0; readingIndex < response[index][this.currentSelectedMetricKey].length; readingIndex++) {
          let dateIndex = response[index][this.currentSelectedMetricKey][readingIndex][0];
          let metricValue = response[index][this.currentSelectedMetricKey][readingIndex][1];
          stationRow[dateIndex] = metricValue === '' || metricValue == null ? '-' : metricValue;

        }
        dataRow.push(stationRow);
      }
    } else {

      // each station

      for (let index = 0; index < response.length; index++) {
        let stationRow = {};
        stationRow['stationCode'] = response[index].stationCode;
        stationRow['stationName'] = response[index].stationName + ' (' + response[index].stationCode + ')';
        for (let readingIndex = 0; readingIndex < response[index][this.currentSelectedMetricKey][collectionKey].length; readingIndex++) {
          let dateIndex = response[index][this.currentSelectedMetricKey][collectionKey][readingIndex][0];

          let metricValue = response[index][this.currentSelectedMetricKey][collectionKey][readingIndex][1];
          if (collectionKey === 'minutes') {
            //format to HH:MM:SS
            metricValue = this.convertToTimeDisplay(metricValue); // passed in minutes
          }

          stationRow[dateIndex] = metricValue === '' || metricValue == null ? '-' : metricValue;

        }
        dataRow.push(stationRow);
      }
    }


    return dataRow;
  }

  convertToTimeDisplay(minutesDuration) {

    if (minutesDuration == null) {
      return 'NA';
    }
    const minutesPassed = parseInt(minutesDuration, 10);
    const secondsPassed = minutesPassed * 60;

    let displayHours = '';
    let displayMinutes = '';

    const hours = Math.floor(secondsPassed / 3600);
    const minutes = Math.floor((secondsPassed - (hours * 3600)) / 60);

    displayHours = hours < 10 ? '0' + hours : hours.toString();
    displayMinutes = minutes < 10 ? '0' + minutes : minutes.toString();


    return displayHours + ':' + displayMinutes;

  }



  export(selectedItems: boolean = false) {
    let data = this.dataTable.value; //First page

    if (selectedItems) {
      data = this.dataTable.selection || [];
    }

    this.exportCSV(data)
  }

  exportCSV(data) {

    if (data.length === 0) {
      this.stateService.openApplicationModal({ Title: "Export", Message: "There is no data or no selection made for the export", Colour: 'blue' });
      return;
    }

    this.googleAnalyticsService.emitEvent("TabularData", "Export", "10_DAY_" + this.currentSelectedMetricKey.toUpperCase());

    let dt = this.dataTable;
    let csv = '\ufeff';
    let csvSeparator = ',';


    // Timestamp the export with the prefix of the station and summary type also included
    this.exportFilename = '10DAY_' + this.currentSelectedMetricKey.toUpperCase() + '_' + moment().format('YYYYMMDDHHmmss');

    //*let objectutils = dt.objectUtils; // helper object

    // headers
    for (let i = 0; i < dt.columns.length; i++) {
      let column = dt.columns[i];
      if (column.field) {
        csv += '"' + (column.header || column.field) + '"';
        if (i < (dt.columns.length - 1)) {
          csv += csvSeparator;
        }
      }
    }

    // body
    data.forEach((record, i) => {
      csv += '\n';
      for (let i = 0; i < dt.columns.length; i++) {
        let column = dt.columns[i];
        if (column.field) {
          let cellData = ObjectUtils.resolveFieldData(record, column.field);
          // Addtional format control
          if (column.type !== undefined && cellData !== null) {
            if (column.type === 'Date' && column.format !== undefined) {
              cellData = moment(cellData).format(column.format);
            }
          };
          //end

          if (cellData != null)
            cellData = String(cellData).replace(/"/g, '""');
          else
            cellData = '';
          csv += '"' + cellData + '"';
          if (i < (dt.columns.length - 1)) {
            csv += csvSeparator;
          }
        }
      }
    });

    // Generate download
    let blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    });

    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, this.exportFilename + '.csv');
    }
    else {
      let link = document.createElement("a");
      link.style.display = 'none';
      document.body.appendChild(link);
      if (link.download !== undefined) {
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', this.exportFilename + '.csv');
        link.click();
      }
      else {
        csv = 'data:text/csv;charset=utf-8,' + csv;
        window.open(encodeURI(csv));
      }

      document.body.removeChild(link);
    }
  }


  navigateToDashboard(stationRow) {
    this.route.navigate(['station', stationRow.stationCode, 'daily']);
    return false;
  }

}
