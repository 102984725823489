import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { StateService } from '../../../services/state/state.service';


@Component({
  selector: 'app-map-layer-select',
  templateUrl: './map-layer-select.component.html',
  styleUrls: ['./map-layer-select.component.scss']
})

export class MapLayerSelectComponent implements OnInit {


  //public menuControlsisOpen: boolean = false;


  public mapSettings: any;
  public layerName: string;
  model = 'MAPBOX_GREY';

  @Input() isActive: boolean;
  @Output() onTogglePopOver = new EventEmitter();


  constructor(
    private stateService: StateService
  ) { }


  ngOnInit() {
    this.mapSettings = this.stateService.mapSettings;
    this.model = this.mapSettings.currentMapLayer;
    this.setLayerSelection();
  }

  toggleIsActive() {
    this.onTogglePopOver.emit('mapLayersPanelActive');
  }

  /**
  * Toggle the layers for the Map allowing for Google or Mapbox settings.
  * @param radarCode The code used by BOM to identify the Radar.
  */
  toggleMapLayer(layer) {

    // Prevent toggle being turned off when clicked if its the current layer
    if (layer === this.mapSettings.currentMapLayer) {
      return false;
    }

    this.stateService.raiseToggleLayer(layer);

    this.setLayerSelection();

    this.toggleIsActive(); // Auto closes after user selects an option
  }


  setLayerSelection() {
    this.layerName = this.getFriendlyMapLayerName(this.stateService.mapSettings.currentMapLayer);
  }

  getFriendlyMapLayerName(layerCode: string) {
    let friendlyName = '';

    switch (layerCode) {
      case 'OPEN_LAYERS':
        friendlyName = 'Open layers';
        break;
      case 'GOOGLE_HYBRID':
        friendlyName = 'Google hybrid';
        break;
      case 'GOOGLE_SATELLITE':
        friendlyName = 'Google satellite';
        break;
      case 'GOOGLE_STREETS':
        friendlyName = 'Google streets';
        break;
      case 'GOOGLE_TERRAIN':
        friendlyName = 'Google terrain';
        break;
      case 'MAPBOX_GREY':
        friendlyName = 'MapBox';
        break;
    }
    return friendlyName;
  }

}
