import { PlotStochasticDataGroupingOptions } from 'highcharts';

export class KeyValuePair {
    public key: string;
    public value: string;
}

export class StationGroupSetting {
    public GroupId: string;
    public isClustered: boolean;
    public isVisible: boolean;

  

}

const mapLayerOptions = [
  { key: 'MAPBOX_GREY', value: 'Default' },
  { key: 'GOOGLE_HYBRID', value: 'Google hybrid' },
  { key: 'GOOGLE_SATELLITE', value: 'Google satellite' },
  { key: 'GOOGLE_STREETS', value: 'Google streets' },
  { key: 'GOOGLE_TERRAIN', value: 'Google terrain' },
  { key: 'MAPBOX_GREY', value: 'Map box' },
  { key: 'OPEN_LAYERS', value: 'Open Layers'}
];

const metricOptions = [
  { key: 'RESET_MARKERS', value: 'Default' },
  { key: 'AirTemperature', value: 'Temperature °C' },
  { key: 'RainFallMetrics.Since9AM', value: 'Rainfall since 9am mm' },
  { key: 'RainFallMetrics.To9AM', value: 'Rainfall previous 24hrs to 9am mm' },
  { key: 'RainFallMetrics.Last7Days', value: 'Rainfall last 7 Days mm' },
  { key: 'RainFallMetrics.MonthToDate', value: 'Rainfall MTD mm' },
  { key: 'RainFallMetrics.YearToDate', value: 'Rainfall YTD mm' },
  { key: 'RAIN_WDR', value: 'Rainfall within date range mm' },
  { key: 'RelativeHumidity', value: 'Relative humidity' },
  { key: 'WIND_SPEED_KMH', value: 'Wind speed km/h (10min avg)' },
  { key: 'WIND_DIRECTION', value: 'Wind direction (10min avg)' },
  { key: 'SoilTemperature', value: 'Soil Temperature °C' },
  { key: 'SolarExposure', value: 'Solar exposure since 12am MJ/m²' },
  { key: 'EtoShortCrop', value: 'ETo short since 12am mm' },
  { key: 'EtoTallCrop', value: 'ETo tall since 12am mm' },
  { key: 'getLastUpdatedMinutes', value: 'Last updated (mins)' },
  { key: 'ErrorsToday', value: 'Errors since 12am' },
  { key: 'ErrorsLast7Days', value: 'Errors last 7 days' }
];

export class UserSettingsModel {

  public metricChosen: KeyValuePair;
  public mapDefaultLayer: KeyValuePair;
  public mapDefaultRegion: KeyValuePair;
  public hideMapControlsMenu: boolean = false;
  public hideMetricsPanel: boolean = false;
  public hideMetricsValueLabels: boolean = false;
  public hideAppHeader: boolean = false;

  public enableDarkMode: boolean = false;


  // Map page settings that are stored when the user changes the values
  // so the defaults are saved
  public isPerthRadarChecked: false;      // Perth
  public isNewdegateChecked: false;       // Newdegate
  public isKalgoorlieChecked: false;      // Kalgoorlie
  public isSouthDoodlakineChecked: false; // South Doodlakine
  public isWatherooChecked: false;        // Watheroo
  public isAlbanyChecked: false;          // Albany
  public isGeraldtonChecked: false;       // Geraldton
  public isEsperanceChecked: false;       // Esperance


  public stationLayers: StationGroupSetting[] = [];

  // Map Zoom and Position
  public mapZoomLevel = 6;
  public mapLat = null;
  public mapLng = null;

  constructor(jsonStringSettings?: string) {

    if (jsonStringSettings !== undefined && jsonStringSettings !== null) {

      let parseSettings = JSON.parse(jsonStringSettings);
      // Assign to the member variables parsed settings.
      this.metricChosen = parseSettings.metricChosen;
      this.mapDefaultLayer = parseSettings.mapDefaultLayer;
      this.mapDefaultRegion = parseSettings.mapDefaultRegion;
      this.hideMapControlsMenu = parseSettings.hideMapControlsMenu;
      this.hideMetricsPanel = parseSettings.hideMetricsPanel;
      this.hideMetricsValueLabels = parseSettings.hideMetricsValueLabels;
      this.hideAppHeader = parseSettings.hideAppHeader;
      this.enableDarkMode = parseSettings.enableDarkMode;

      this.isPerthRadarChecked = parseSettings.isPerthRadarChecked;           // Perth
      this.isNewdegateChecked = parseSettings.isNewdegateChecked;             // Newdegate
      this.isKalgoorlieChecked = parseSettings.isKalgoorlieChecked;           // Kalgoorlie
      this.isSouthDoodlakineChecked = parseSettings.isSouthDoodlakineChecked; // South Doodlakine
      this.isWatherooChecked = parseSettings.isWatherooChecked;               // Watheroo
      this.isAlbanyChecked = parseSettings.isAlbanyChecked;                   // Albany
      this.isGeraldtonChecked = parseSettings.isGeraldtonChecked;             // Geraldton
      this.isEsperanceChecked = parseSettings.isEsperanceChecked;             // Esperance

      this.mapZoomLevel = parseSettings.mapZoomLevel;
      this.mapLat = parseSettings.mapLat;
      this.mapLng = parseSettings.mapLng;

      // Parse Station Layers
      if (parseSettings.stationLayers !== undefined) {
        this.stationLayers = Object.values(parseSettings.stationLayers);
      }
      
    } else {

      this.metricChosen = new KeyValuePair();
      this.mapDefaultLayer = new KeyValuePair();
      this.mapDefaultRegion = new KeyValuePair();
    }
  }

  /**
   * Saving settings into cookie requires the object to be string so will use the stringify
   * object.
   */
  public toJson(): string {
      return JSON.stringify(this);
  }


  public setDefaultLayer(LayerName: string) {
    this.mapDefaultLayer = mapLayerOptions.filter(k => k.key === LayerName)[0];
  }

  public setMetricOptionDefault(MetricName: string) {

    if (MetricName == null) {
      MetricName = 'AirTemperature'; // Default to AirTemperature
    }
    this.metricChosen = metricOptions.filter( k => k.key === MetricName)[0];
  }
}
