import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { StateService } from '../services/state/state.service';

//declare var lightGallery: any;

@Component({
  selector: 'app-video-tips-page',
  templateUrl: './video-tips-page.component.html',
  styleUrls: ['./video-tips-page.component.scss']
})

export class VideoTipsPageComponent implements OnInit {

  constructor(public stateService: StateService) { }

  ngOnInit() {
    this.stateService.raiseApplicationReady();
  }

}
