<!--<app-station-search></app-station-search>-->
<div id="map-content">
 
  <div id="map-wrapper"
    [className]="(mapSettings.showMarkerLabels == false ? 'hide-marker-labels' : '') + ' ' + mapLegendFilter + (isRadarImageLoopRunning ? ' radarActive' : '')">

    <!-- Map control sidebar -->
    <!-- <app-map-controls-sidebar></app-map-controls-sidebar>-->
    <!-- /Map control sidebar -->

    <!-- Map -->
    <div [ngClass]="{'active' : mapSettings.isMapView == true}">
      <!-- main-map -->
      <div id="mainMap" leaflet [leafletOptions]="options" (leafletMapReady)="onMapReady($event)"
        [ngClass]="{'menu-open' : mapSettings.menuIsOpen}">



        <tpl-flip #CountDownFlip (finished)="onRefreshCountdownFinished()"></tpl-flip>
        <!-- Legend -->
        <app-map-weather-variables-legend [hidden]="selectedWeatherVariable == 'RESET_MARKERS'"
          (legendKeyClicked)="displayMarkersForLegend($event)" (onReset)="resetMarkersForLegend()"
          [metricType]="selectedWeatherVariable"></app-map-weather-variables-legend>
        <!-- /Legend -->


      </div>
      <leaflet-fullscreen-control
      [map]="leafletMap"
      [options]="fullscreenOptions"
      ></leaflet-fullscreen-control>
      <!-- /main-map -->

      <div class="radar-time" #radarTime [ngClass]="{'active' : activeRadarCount > 0}" ngDraggable [handle]="RadarTimeHandle">

        <div #RadarTimeHandle class="btn-wrapper"><img src="../../assets/images/move.svg" /> </div>

        <div class="custom-slider">
          <ng5-slider [(value)]="radarImageMinuteCounter" [options]="sliderOptions"
            (valueChange)="onSliderValueChange($event)" #radarSlider></ng5-slider>
          <button id="sliderPausePlayBtn" class="btn" [ngClass]="{'active' : radarSliderPlaying}" (click)="toggleRadarSlidePlaying()">
            <div *ngIf="radarSliderPlaying" class="icon icon-pause-icon"></div>
            <div *ngIf="!radarSliderPlaying" class="icon icon-play-icon-1" ></div>
          </button>
        </div>
        <p><span class="noselect">Current radar image time : </span>{{currentRadarDateTime}}</p>
      </div>

    </div>



  </div> <!-- /map-wrapper -->

</div> <!-- /map-content -->

<app-date-picker id="mapDatePicker" [hidden]="!isDateTimeRangeRequired" (onDateSelection)="onDateSelection($event)"
  pickerMode="dialog" pickerType="calendar" showTitle="true" [defaultDates]="datePickerDefaultDates">
</app-date-picker>

<app-radar-forecast *ngIf="mapSettings.isRadarForecastingChecked" style="display: block;
width: 400px;
z-index: 99999999;
min-width: 923px;
height: 400px;
position: absolute;
left: 300px;
top: 300px;"></app-radar-forecast>

<app-map-bottom-navbar (onMetricChanged)="onMetricChanged($event)"></app-map-bottom-navbar>


<!--<app-mobile-map-controls  *ngIf="screenWidth < 992" (onMetricChanged)="onMetricChanged($event)" [ngClass]="{'radarActive': isRadarImageLoopRunning}"></app-mobile-map-controls>-->

<!-- station listing slide panel 
<app-station-table></app-station-table>-->

<app-summary-metric-table (activeChange)="onActiveOverTableChanged($event)" [active]="mapSettings.isSummaryMetricTable">
</app-summary-metric-table>