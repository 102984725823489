import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { StationModel } from '../../models/station.model';
import { StationLatestModel } from '../../models/station-latest.model';
import { StationRainfallModel } from '../../models/station-rainfall.model';
import { StationNearbyModel } from '../../models/station-nearby.model';
import { StationSummaryModel } from '../../models/station-summary.model';
import { StationAvailabilityModel } from '../../models/station-availability.model';

import { Observable, forkJoin, throwError, of, Subject  } from 'rxjs';
import { share, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { WeatherService } from '../../services/weather/weather.service';
import { ForecastingService } from '../forecasting/forecasting.service';

// Import Radar service files required
import { RadarService } from '../../services/radar/radar.service';
import { RadarModel } from '../../services/radar/models/radar.model';
import { BoMStationModel } from '../gis-to-web/models/bom-station.model';
import { ɵNullViewportScroller } from '@angular/common';
import { GisToWebService } from '../gis-to-web/gis-to-web.service';
import { ForecastingViewModel } from '../forecasting/forecasting.model';



interface CacheContent {
  expiry: number;
  value: any;
}


@Injectable()
export class DataCache {

  apiUrl = environment.APIURI;

  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
  private inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();
  private DEFAULT_MAX_AGE: number = 300000;

  // Weather stations member variables for storage
  private stations: Array<StationModel> = null;
  private BoMstations: Array<BoMStationModel> = null;
  private stationsLatest: Array<StationLatestModel> = null;
  private stationsAvailability: Array<StationAvailabilityModel> = null;
  private stationsRainfall: Array<StationRainfallModel> = null;
  // private nearbyStations: Array<StationNearbyModel> = null;


  // Radar member variables for storage
  private radars: Array<RadarModel> = null;

  constructor(
    private weatherService: WeatherService,
    private radarService: RadarService,
    private gistowebService: GisToWebService,
    private forecastingService: ForecastingService
  ) {

  }


  // 5000 max age belive is 1.3 hours.
  getForecastingByLocation(lat: Number, lng: Number) {
    return this.get('location'+ lat.toString() + lng.toString(), this._getForecastingByLocation(lat,lng));
  }

  _getForecastingByLocation(lat: Number, lng: Number) {


    let observable = Observable.create(observer => {

      this.forecastingService.getForecastForLocation(lat,lng).subscribe(data => {
        observer.next(data);
        observer.complete();  
      });
 
    });

    return observable;

  }


  /**
   * Get the weather station.
   * The stations are called on their own as when trying to call multiple services in parrallel and
   * join them using forkJoin you can really notice the latency on the front end.
   */
  getStationsAndLatest(refresh = false) {
    const stations = this.getStations();
    const stationsLatest = this.getStationsLatest(refresh); // allow a refresh
    const stationsAvailabilty = this.getStationsAvailability();
    const BoMstations = this.getBoMStations(refresh);  // allow a refresh
    return forkJoin([stations, stationsLatest, stationsAvailabilty, BoMstations]);
  }

  getBoMStations(refresh = false) {

    if (refresh) {
      this.cache.delete("bom-stations");
      this.BoMstations = null;
    }

    return this.get('bom-stations', this.getBoMstationModels());
  }

  getStations() {
    return this.get('stations', this.getStationModels());
  }

  getStationsLatest(refresh = false) {

    if (refresh) {
      this.cache.delete("stations-latest");
      this.stationsLatest = null;
    }

    return this.get('stations-latest', this.getStationLatestModels());
  }

  getStationsAvailability() {
    return this.get('stations-availability', this.getStationAvailabilityModels());
  }

  getStationsNearby(lat: number, lng: number) {
    return this.get('nearby-' + lat.toString() + lng.toString(), this.getStationNearbyModel(lat, lng));
  }

  getStationSummary(stationCode: string, interval: string, startDateTime: string, endDateTime: string, limit: number, offset: number, refresh = false) {
    let cacheKey = stationCode + '-SUMMARY-' + interval.toUpperCase();

    if (refresh) {
      this.cache.delete(cacheKey);
    }

    return this.get(cacheKey, this.getStationSummaryModel(stationCode, interval, startDateTime, endDateTime, limit, offset));
  }


  getStationRainfallDateRange(startDate: string = "", endDate: string = "", stationCode = null) {
    let cacheKey = startDate.replace("/", "") + endDate.replace("/", "") + (stationCode == null ? "" : stationCode);
    return this.get(cacheKey, this.getStationRainfallDateRangeModel(startDate, endDate, stationCode));
  }


  getStationRainfall(refresh = false) {

    let cacheKey = 'station-rainfall';

    if (refresh) {
      this.cache.delete(cacheKey);
      this.stationsRainfall = null;
    }

    return this.get(cacheKey, this.getStationRainfallModel());
  }

  getBoMstationModels() {

    let self = this;
    let observable = Observable.create(observer => {
      if (this.BoMstations !== null) {
        observer.next(this.BoMstations);
        observer.complete();
      } else {
        this.gistowebService.getStations().subscribe(data => {
          self.BoMstations = data;//data.map(item => new BoMStationModel(item)); // Convert to type
          observer.next(self.BoMstations);
          observer.complete();
        });
      }
    });

    return observable;

  }

  getStationModels() {

    let self = this;
    let observable = Observable.create(observer => {
      if (this.stations !== null) {
        observer.next(this.stations);
        observer.complete();
      } else {
        this.weatherService.getStations().subscribe(data => {
          self.stations = data.map(item => new StationModel(item)); // Convert to type
          observer.next(self.stations);
          observer.complete();
        });
      }
    });

    return observable;

  }


  getStationLatestModels() {
    let self = this;
    let observable = Observable.create(observer => {
      if (this.stationsLatest !== null) {
        observer.next(this.stationsLatest);
        observer.complete();
      } else {
        this.weatherService.getStationLatest().subscribe(data => {
          self.stationsLatest = data.map(item => new StationLatestModel(item));
          observer.next(self.stationsLatest);
          observer.complete();
        });
      }
    });

    return observable;
  }



  getStationAvailabilityModels() {
    let self = this;
    let observable = Observable.create(observer => {
      if (this.stationsAvailability !== null) {
        observer.next(this.stationsAvailability);
        observer.complete();
      } else {
        this.weatherService.getStationsAvailability().subscribe(data => {
          self.stationsAvailability = data.map(item => new StationAvailabilityModel(item));
          observer.next(self.stationsAvailability);
          observer.complete();
        });
      }
    });

    return observable;

  }

  /**
   * Return the latest for a paticular station. The method will populate the latest cache if it has not been called
   * before making subsequent requests quicker.
   * @returns Observable<StationLatestModel>
   */
  getStationLatestModelsByCode(code: string, refresh = false) {

    if (refresh) {
      this.stationsLatest = null;
    }

    let self = this;
    let observable = Observable.create(observer => {
      if (this.stationsLatest !== null) {
        observer.next(this.stationsLatest.find(station => station.Code === code));
        observer.complete();
      } else {
        this.weatherService.getStationLatest().subscribe(data => {
          self.stationsLatest = data.map(item => new StationLatestModel(item));
          observer.next(self.stationsLatest.find(station => station.Code === code));
          observer.complete();
        });
      }
    });

    return observable;
  }



  getStationNearbyModel(lat: number, lng: number) {

    let observable = Observable.create(observer => {

      this.weatherService.getStationsNearby(lat, lng).subscribe(data => {
        observer.next(data.map(item => new StationNearbyModel(item)));
        observer.complete();
      });

    });

    return observable;
  }



  getStationSummaryModel(stationCode, interval, startDateTime, endDateTime, limit, offset) {
    let observable = Observable.create(observer => {
      this.weatherService.getSummaries(limit, offset, stationCode, startDateTime, endDateTime, interval).subscribe(data => {
        //data in this instance is APIResponse
        if (data.collection !== undefined) {
          observer.next(data.collection.map(item => new StationSummaryModel(item)));
        } else {
          observer.next(undefined);
        }

        observer.complete();
      });

    });

    return observable;
  }


  /**
   * Retuns a single station BoM Station
   * Assuming that the stations have been populated
   * TODO://Add additional checking and call service if this method is called and stations is empty
   */
  getBoMStationModel(stationId: string) {

    stationId = stationId.replace('BOM_STATION_', ''); // Remove the prefix added due to goign in shared model

    let self = this;
    let observable = Observable.create(observer => {
      if (this.BoMstations !== null) {
        observer.next(this.BoMstations.find(station => station.BoMId === stationId));
        observer.complete();
      } else {
        this.gistowebService.getStations().subscribe(data => {
          self.BoMstations = data.map(item => new BoMStationModel(item));
          observer.next(self.BoMstations.find(station => station.BoMId === stationId));
          observer.complete();
        });
      }
    });

    return observable;

  }


  /**
   * Retuns a single station
   * Assuming that the stations have been populated
   * TODO://Add additional checking and call service if this method is called and stations is empty
   */
  getStationModel(stationCode: string) {
    let self = this;
    let observable = Observable.create(observer => {
      if (this.stations !== null) {
        observer.next(this.stations.find(station => station.Code == stationCode));
        observer.complete();
      } else {
        this.weatherService.getStations().subscribe(data => {
          self.stations = data.map(item => new StationModel(item));
          observer.next(self.stations.find(station => station.Code == stationCode));
          observer.complete();
        });
      }
    });

    return observable;

  }


  getStationRainfallDateRangeModel(fromDate: string = "", toDate: string = "", stationCode: string = null) {
    let self = this;
    let observable = Observable.create(observer => {
      this.weatherService.getStationRainfall(fromDate, toDate, stationCode).subscribe(data => {
        let dateRangeRainfall = data.map(item => new StationRainfallModel(item));
        observer.next(dateRangeRainfall);
        observer.complete();
      });

    });

    return observable;
  }


  getStationRainfallModel(fromDate: string = "", toDate: string = "", stationCode: string = null) {
    let self = this;
    let observable = Observable.create(observer => {
      if (this.stationsRainfall !== null) {
        observer.next(this.stationsRainfall);
        observer.complete();
      } else {
        this.weatherService.getStationRainfall(fromDate, toDate, stationCode).subscribe(data => {
          self.stationsRainfall = data.map(item => new StationRainfallModel(item));
          observer.next(self.stationsRainfall);
          observer.complete();
        });
      }
    });

    return observable;
  }


  getStationRainfallModelByCode(code: string) {
    let self = this;
    let observable = Observable.create(observer => {
      if (this.stationsRainfall !== null) {
        observer.next(this.stationsRainfall.find(station => station.Code == code));
        observer.complete();
      } else {
        this.weatherService.getStationRainfall("", "").subscribe(data => {
          self.stationsRainfall = data.map(item => new StationRainfallModel(item));
          observer.next(self.stationsRainfall.find(station => station.Code == code));
          observer.complete();
        });
      }
    });

    return observable;
  }

  /*
   * RADAR Methods
   */

  getRadars(refresh = false) {

    if (refresh) {
      this.radars = null;
    }

    return this.get('radars', this.getRadarModels());
  }

  getRadarModels() {
    const self = this;
    const observable = new Observable(observer => {
      if (this.radars !== null) {
        observer.next(this.radars);
        observer.complete();
      } else {
        this.radarService.getRadars().subscribe(data => {
          self.radars = data; // Convertion to RadarModel within the radar service.
          observer.next(self.radars);
          observer.complete();
        });
      }
    });
    return observable;
  }

  // ** DATACACHE Method helpers next *******************************


  /**
   * Gets the value from cache if the key is provided.
   * If no value exists in cache, then check if the same call exists
   * in flight, if so return the subject. If not create a new
   * Subject inFlightObservable and return the source observable.
   */
  get(key: string, fallback?: Observable<any>, maxAge?: number): Observable<any> | Subject<any> {

    if (this.hasValidCachedValue(key)) {
      //console.log(`%cGetting from cache ${key}`, 'color: green');
      return of(this.cache.get(key).value);
    }

    if (!maxAge) {
      maxAge = this.DEFAULT_MAX_AGE;
    }

    if (this.inFlightObservables.has(key)) {
      return this.inFlightObservables.get(key);
    } else if (fallback && fallback instanceof Observable) {
      this.inFlightObservables.set(key, new Subject());
      //console.log(`%c Calling api for ${key}`, 'color: green');
      return fallback.pipe(tap((value) => { this.set(key, value, maxAge); }));
    } else {
      return  throwError(() => new Error('Requested key is not available in Cache'));
    }

  }

  /**
   * Sets the value with key in the cache
   * Notifies all observers of the new value
   */
  set(key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
    this.cache.set(key, { value: value, expiry: Date.now() + maxAge });
    this.notifyInFlightObservers(key, value);
  }

  /**
   * Checks if the a key exists in cache
   */
  has(key: string): boolean {
    return this.cache.has(key);
  }

  /**
   * Publishes the value to all observers of the given
   * in progress observables if observers exist.
   */
  private notifyInFlightObservers(key: string, value: any): void {
    if (this.inFlightObservables.has(key)) {
      const inFlight = this.inFlightObservables.get(key);
      const observersCount = inFlight.observers.length;
      if (observersCount) {
        //console.log(`%cNotifying ${inFlight.observers.length} flight subscribers for ${key}`, 'color: green');
        inFlight.next(value);
      }
      inFlight.complete();
      this.inFlightObservables.delete(key);
    }
  }

  /**
   * Checks if the key exists and   has not expired.
   */
  private hasValidCachedValue(key: string): boolean {
    if (this.cache.has(key)) {
      if (this.cache.get(key).expiry < Date.now()) {
        this.cache.delete(key);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}