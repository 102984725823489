import { Component, OnInit, NgModule } from '@angular/core';


@Component({
  selector: 'app-mobile-filter-menu',
  templateUrl: './mobile-filter-menu.component.html',
  styleUrls: ['./mobile-filter-menu.component.scss'],
  
})

export class MobileFilterMenuComponent implements OnInit {

  public isMobileFilterActive: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  closeFilter() {
    this.isMobileFilterActive = false;
  }

  activateMobileFilter() {
    this.isMobileFilterActive = true;
  }



}
