<div #mapLegend id="map-legend" [ngClass]="metricClass">
  <div class="FilterInside-Wrapper">
    <div class="filter"><button class="legend-tip" type="button" ngbTooltip="Click on a legend key to filter the map view." placement="right">
      Filter
    </button></div>
    <div *ngFor="let key of metricLegend; let i = index" style="height: 20px;text-align: center;"
    [className]="(selectedIndex === i ? 'active' : '') + ' ' + key.class"
    (click)="raiseLegendKeyClicked(key);selectedIndex = i;">{{key.label}}</div>
    <div id="map-legend-filter">

      <div style="height: 20px;background-color: white; color: black; text-align: center;" (click)="resetMarkersForLegend(); selectedIndex = -1">Reset</div>
    </div>
  </div>
</div>