<app-secondary-header></app-secondary-header>

<div class="outer-wrapper">

    <div class="container top">
        <div class="row">


            <div class="content-page">


                <h2>Terms and conditions</h2>


                <h3>1. Indemnity</h3>
                <p>The API User agrees to indemnify DPIRD including its officers, employees and agents against all
                    Loss(es) sustained or incurred by DPIRD as a result of actions, claims and demands (including the
                    cost of defending or settling any action, claim or demand) which may be instituted against DPIRD
                    arising out of or in connection with a failure by an API User, its Nominated User or its End Users
                    to comply with these Terms.</p>
                <h3>2. Breach of the API Terms</h3>
                    <p>If an API User becomes aware of any breach of these Terms due to use of the API or any Data that is
                    made available via the API, it must immediately notify DPIRD of the threatened or actual breach upon
                        becoming aware of such a breach by contacting DPIRD via Email at: <a href="mailto:enquiries@agric.wa.gov.au">enquiries@agric.wa.gov.au</a></p>
                <h3>3. Termination of Access</h3>
                    <p>DPIRD may, at its sole discretion, terminate or temporarily suspend an API Key (and/or a DPIRD User
                    Account) without notice to an API User and these Terms will survive termination and continue to be
                        in force during a suspension.</p>
                <h3>4. Disclaimer</h3>
                    <p>This disclaimer does not attempt or purport to exclude liability arising under statute if, and to
                        the extent, such liability cannot be lawfully excluded.</p>
                    <p>DPIRD does not accept responsibility for any Loss, however it is caused (including through
                    negligence), which an API User or its End User may directly or indirectly suffer in connection with
                    or arising from use of the API or the DPIRD Website (including any applications, links, databases or
                    catalogues) associated with the API or DPIRD Website including any reliance placed on Data or other
                    information contained in or accessed through the API or DPIRD Website by an API User or End User.
                    DPIRD accepts no liability for any failure by it to comply with these Terms including where such
                        failure is due to circumstance beyond its reasonable control.</p>
                    <p>If DPIRD waives any right available to an API User or End User under these Terms on one occasion,
                    this does not means that those rights will automatically be waived on any other occasion. If any of
                    these Terms are held to be invalid, unenforceable or illegal for any reason, the remaining Terms
                        will continue to be in full force and effect.</p>
                <h3>5. Changes or amendments to the terms and conditions</h3>
                    <p>DPIRD reserves the right to update or amend these Terms from time to time. Any changes made to these
                    Terms will become effective immediately and will be notified on the DPIRD Website. If an API User
                    continues to access and use the API, or allow its End Users to access and use the API through its
                    website, after a change to the Terms has been made, the continued access to and use of the API by
                    the API User and End User will mean that the API User has agreed to continue to be bound by the
                        Terms as amended.</p>
                <h3>6. Governing Law</h3>
                    <p>These Terms are governed by the laws in force in Western Australia and each API User and End User
                        agrees to submit to the exclusive jurisdiction of the courts of that jurisdiction.</p>
                <h3>7. Privacy Policy</h3>
                    <p>For further information about how DPIRD treats personal information (in addition to these terms and
                        conditions), please review our privacy policy at <a href="https://dpird.wa.gov.au/privacy-policy" target="_blank">dpird.wa.gov.au/privacy-policy</a></p>


            </div>


        </div>
    </div>


</div>
<app-station-table></app-station-table>