import { EventEmitter, Injectable } from '@angular/core';
import { PanelSetting } from '../../shared/models/panel.setting.model';
import { Observable, Subject } from 'rxjs';

/* Application Models */
//import { StationModel } from '../../models/station.model';
//import { PrecipitationSummaryModel } from '../../models/radar/precipitation-summary.model';

import { Map } from 'leaflet';
//import { PrecipitationSummary } from '../radar/models/precipitation-summary.model';


@Injectable()
export class StateService {


  connectionInfo = {
    status: 'ONLINE',
    isConnected: true
  };

  
  menuBottomPanel = {
    metricPanelActive: false,
    radarPanelActive: false,
    stationsPanelActive: false,
    mapLayersPanelActive: false,
    morePanelActive: false
  };

  // General state settings
  stateSettings = {
    dashBoard: {
      isTablesActive: false
    },
    isHeaderVisible: true,
    theme: '',
    currentPage: '',
    rainfallWDRDates: null
  }

  /*
   * Page State - so for map will use existing settings.
   * Seems to be handle your own looked at some references
   * https://scotch.io/tutorials/routing-angular-2-single-page-apps-with-the-component-router
   * https://github.com/angular/angular/issues/7965
   * https://github.com/angular/angular/issues/5275
   * 
   */
  /* Map Settings */
  mapSettings = {

    isRadarForecastingChecked: false,
    menuIsOpen: true,
    // defaultRegion: null,
    weatherVariablesPanelIsOpen: true,
    isMapView: true,
    isSummaryMetricTable: false,
    isFullScreen: false,
    showMarkerLabels: true,
    isStationTableActive: false,
    isInitMapLoad: true,
    currentMapLayer: 'OPEN_LAYERS',
    // CurrentSelectedLayer: null,
    metricSelected: null,
    mapZoomLevel: 6,
    mapCenter: { lat: -28.922, lng: 125.432 },
    previousClickedStation: undefined,
    PreviousClickedIcon: undefined,
    lastMapPoint: {
      isStation: false, // other option is then virtual station
      stationCode: null,
      latLng: { lat: null, lng: null },
    },
    layers: {
      googleHybrid: {
        isChecked: false
      },
      googleSatellite: {
        isChecked: false
      },
      googleStreets: {
        isChecked: false
      },
      googleTerrain: {
        isChecked: false
      },
      mapboxGrey: {
        isChecked: false
      },
      openLayers: {
        isChecked: false
      },
      radars: {
      },
      overlaySelectionLayer: null,
      weatherStations: null,
      /*weatherStations: {
        isStationsVisible: true,
        isClustered: false,
        isDBCAStationsVisible: true,
        isDBCAClustered: false,
        isDBCALayerVisible: false,
        isBoMStationsVisible: true,
        isBoMClustered: false,
        clusteredLayer: null,
        markerLayer: null,
        BoMclusteredLayer: null,
        BoMmarkerLayer: null,
        DBCALayer: null,
        DBCAClusteredLayer: null,
        CurrentSelectedLayer: null,
        isWaterCorpStationsVisible: true,
        isWaterCorpClustered: false,
        isWaterCorpLayerVisible: false,
        WaterCorpLayer: null,
        WaterCorpClusteredLayer: null
      }*/
    },
    isAllRadarsChecked: false,
    radarOptions: [
      {
        id: "IDR70AR",
        name: "Perth (Serpentine)",
        bounds: [[-33.5349617, 117.2365799], [-31.24287987, 114.52122498]],
        isVisible: false,
        layer: null,
        isOnline: true,
        isChecked: false,
        offlineReason: '',
        ImageErrorCount: 0,
        toolTipMessage: ''
      },
      {
        id: "IDR38AR",
        name: "Newdegate",
        bounds: [[-34.2401, 117.653], [-31.94794, 120.39]],
        isVisible: false,
        layer: null,
        isOnline: true,
        isChecked: false,
        offlineReason: '',
        ImageErrorCount: 0,
        toolTipMessage: ''
      },
      {
        id: "IDR48AR",
        name: "Kalgoorlie",
        bounds: [[-31.9279, 120.131], [-29.636, 122.8]],
        isVisible: false,
        layer: null,
        isOnline: true,
        isChecked: false,
        offlineReason: '',
        ImageErrorCount: 0,
        toolTipMessage: ''
      },
      {
        id: "IDR58AR",
        name: "South Doodlakine",
        bounds: [[-32.9204, 116.6159], [-30.62839, 119.313]],
        isVisible: false,
        layer: null,
        isOnline: true,
        isChecked: false,
        offlineReason: '',
        ImageErrorCount: 0,
        toolTipMessage: ''
      },
      {
        id: "IDR79AR",
        name: "Watheroo",
        bounds: [[-31.50371, 114.9719], [-29.21185, 117.6288]],
        isVisible: false,
        layer: null,
        isOnline: true,
        isChecked: false,
        offlineReason: '',
        ImageErrorCount: 0,
        toolTipMessage: ''
      },
      {
        id: "IDR31AR",
        name: "Albany",
        bounds: [[-36.08471, 116.43644], [-33.78256, 119.23467]],
        isVisible: false,
        layer: null,
        isOnline: true,
        isChecked: false,
        ImageErrorCount: 0,
        toolTipMessage: ''
      },
      {
        id: 'IDR06AR',
        name: 'Geraldton',
        bounds: [[-29.950214, 113.402230], [-27.645705, 116.020577]],
        isVisible: false,
        layer: null,
        isOnline: true,
        isChecked: false,
        offlineReason: '',
        ImageErrorCount: 0,
        toolTipMessage: ''
      },
      {
        id: 'IDR32AR',
        name: 'Esperance',
        bounds: [[-34.973453, 120.529794], [-32.670867, 123.291251]],
        isVisible: false,
        layer: null,
        isOnline: true,
        isChecked: false,
        offlineReason: '',
        ImageErrorCount: 0,
        toolTipMessage: ''
      }
    ],
    mapMarkers: []
  };

  public setMapSettingsLayersState() {
    this.mapSettings.layers.googleHybrid.isChecked = false;
    this.mapSettings.layers.googleSatellite.isChecked = false;
    this.mapSettings.layers.googleStreets.isChecked = false;
    this.mapSettings.layers.googleTerrain.isChecked = false;
    this.mapSettings.layers.mapboxGrey.isChecked = false;
  }

  onMapLayersPopulated = new EventEmitter();


  /* Application Modal EventEmmitter */
  onOpenApplicationModal = new EventEmitter<Object>();


  /* Favourite */
  //userFavourites: StationLatest[] = [];
  //onStationAddedToFavourites = new EventEmitter<StationLatest[]>();
  //onStationRemovedFromFavourites = new EventEmitter<StationLatest[]>();

  /* Global Events */
  private RadarSubject = new Subject<any>();

  raiseRadarMapLocationClicked(data: any) {
    this.RadarSubject.next(data);
  }

  //clearRadarMessage() {
  //  this.RadarSubject.next();
  // }

  onRadarMapLocationClicked(): Observable<any> {
    return this.RadarSubject.asObservable();
  }


  //
  private StationSearchSubject = new Subject<any>();

  OnStationSearchMatched(stationCode: string) {
    this.StationSearchSubject.next(stationCode);
  }

  clearStationSearchMessage() {
    this.StationSearchSubject.next(null);
  }

  getStationSearchMessage(): Observable<any> {
    return this.StationSearchSubject.asObservable();
  }
  //



  /* /Global Events */

  onToggleLeftMenu = new EventEmitter<boolean>();

  panelSettings = new PanelSetting(false);

  //onLeafletMapReadyEvent = new EventEmitter<Map>();


  /*
   * Leaflet Subject for shared events
   */

  //onLeafletReady = new EventEmitter<Map>();
  private LeafletMapReadySubject = new Subject<Map>();

  onLeafletMapReady(leafletMap: Map) {
    //  this.onLeafletMapReadyEvent.emit(leafletMap);
    this.LeafletMapReadySubject.next(leafletMap);
  }

  clearLeafletMapReadyMessage() {
    // this.LeafletMapReadySubject.next();
  }

  getLeafletMapReadyMessage(): Observable<any> {
    return this.LeafletMapReadySubject.asObservable();
  }


  //LEAFLET REST
  private LeafletMapRemovedSubject = new Subject();

  onLeafletMapRemoved(flag: boolean) {
    //  this.onLeafletMapReadyEvent.emit(leafletMap);
    this.LeafletMapRemovedSubject.next(flag);
  }

  clearLeafletMapRemovedMessage() {
    // this.LeafletMapReadySubject.next();
  }

  getLeafletMapRemovedMessage(): Observable<any> {
    return this.LeafletMapRemovedSubject.asObservable();
  }

  // Navigation event before leaving a page to go to the next.

  private NavigationPageSubject = new Subject<string>();

  onPreNavigateToPage(): Observable<any> {
    return this.NavigationPageSubject.asObservable();
  }

  raiseNavigationPageSubject(page: any) {
    this.NavigationPageSubject.next(page);
  }


  // Theme Subject

  private ThemeChangeSubject = new Subject<string>();

  getThemeChangeMessage(): Observable<any> {
    return this.ThemeChangeSubject.asObservable();
  }

  onThemeChange(theme: string) {
    this.ThemeChangeSubject.next(theme);
  }

  /**
   * map-controls-sidebar
   * Observable subjects triggered from toggle 
   */
  private toggleStationsSubject = new Subject<string>();

  onToggleStations(): Observable<string> {
    return this.toggleStationsSubject.asObservable();
  }

  raiseToggleStations(groupId: string) {
    this.toggleStationsSubject.next(groupId);
  }

  private toggleStationGroupingSubject = new Subject<string>();

  onToggleStationGroup(): Observable<string> {
    return this.toggleStationGroupingSubject.asObservable();
  }

  raiseToggleGroupStations(groupCode: string) {
    this.toggleStationGroupingSubject.next(groupCode);
  }


  // Map layers

  private toggleMapLayerSubject = new Subject<string>();

  onToggleMapLayer(): Observable<string> {
    return this.toggleMapLayerSubject.asObservable();
  }

  raiseToggleLayer(layer: string) {
    this.toggleMapLayerSubject.next(layer);
  }

  // Radars

  private toggleRadarSubject = new Subject<any>();
  private toggleAllRadarsSubject = new Subject<boolean>();

  onToggleRadar(): Observable<any> {
    return this.toggleRadarSubject.asObservable();
  }

  raiseToggleRadar(radar: any) {
    return this.toggleRadarSubject.next(radar);
  }

  onToggleAllRadars(): Observable<boolean> {
    return this.toggleAllRadarsSubject.asObservable();
  }

  raiseToggleAllRadars(allActive: boolean) {
    return this.toggleAllRadarsSubject.next(allActive);
  }

  // Toggle Radar Forecast
  private toggleRadarForecastSubject = new Subject<boolean>();

  onToggleRadarForecast(): Observable<boolean> {
    return this.toggleRadarForecastSubject.asObservable();
  }

  raiseToggleRadarForecast(flag: boolean) {
    this.toggleRadarForecastSubject.next(flag);
  }
  //

  // setting map view
  private setMapViewSubject = new Subject<any>();

  onSetMapView(): Observable<any> {
    return this.setMapViewSubject.asObservable();
  }

  raiseSetMapView(lat: number, lng: number, zoomLevel: number) {
    let obj = {
      lat: lat,
      lng: lng,
      zoomLevel: zoomLevel
    }
    this.setMapViewSubject.next(obj);
  }

  private resetMapViewSubject = new Subject();

  onResetMap() {
    return this.resetMapViewSubject.asObservable();
  }

  raiseResetMap() {
    this.resetMapViewSubject.next(null);
  }

  /*
   * Subject: is the equivalent to an EventEmitter, and the only way of multicasting
   * a value or event to multiple Observers.
   * http://reactivex.io/rxjs/manual/overview.html#subject
   */
  private subject = new Subject<any>();

  onStationMarkerSelected(station: any) {
    this.subject.next(station);
  }

  clearMessage() {
    this.subject.next(null);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }


  public toggleLeftMenu(state: boolean) {
    this.panelSettings.leftMenu = state;
    this.onToggleLeftMenu.emit(state);
  }


  private applicationReadySubject = new Subject();
  onApplicationReady() {
    return this.applicationReadySubject.asObservable();
  }

  raiseApplicationReady() {
    this.applicationReadySubject.next(true);
  }


  //Helper function remove object
  private remove(myObjects, prop, value) {
    return myObjects.filter(function (val) {
      return val[prop] !== value;
    });
  }

  /**
   * Raise the EventEmitter for the Application Modal so that custom notifications
   * can be displayed to the user.
   */
  public openApplicationModal(modalData: Object) {
    this.onOpenApplicationModal.emit(modalData);
  }

}
