import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpInterceptor
} from '@angular/common/http';

import {Injector} from '@angular/core';
import { StateService } from './services/state/state.service';
import { Observable, tap, timeout } from 'rxjs';




@Injectable()
export class APIKeyInterceptor implements HttpInterceptor {

  constructor(private inj: Injector) {

  }  //https://stackoverflow.com/questions/48294197/angular-5-http-interceptors-error-when-injecting-service?rq=1


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const state = this.inj.get(StateService)
    const defaultTimeout = 90000;
//request.url.indexOf('v2/radar') > 0 
    if (request.url.indexOf('v2/weather') > 0 || request.url.indexOf('v1/gis-to-web') > 0 ||  request.url.indexOf('v2/radar') > 0) { // Weather Service / GIS2WEB add the API Key into the header 
      // if (request.method == "GET") {
      request = request.clone({
        setHeaders: {
          api_key: environment.APIKey,
          'Access-Control-Max-Age': '600' // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Access-Control-Max-Age
        }
      });
    }

    const timeoutValue = Number(request.headers.get('timeout')) || defaultTimeout;
    request.headers.delete('timeout');

    return next.handle(request).pipe(timeout(timeoutValue)).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      // Can edit response here if required.
      }
     
       }, (error: any) => {

        if (error instanceof HttpErrorResponse) {

          if (error.url.indexOf('v1/radar/precip') > 0) {
            //ignore radar precip 404 not found
          } else {
  
          let title = "API Message";
          let alertColour = "orange";
          
          if (error.status !== 200 && error.status !== 0) {  // not OK Response
      
            if (error.status == 400) {
              alertColour = "blue";    
            }

           if (error.status == 500 || error.status == 503) {
             alertColour = "red";
           }

           let errorDescription = error.error !== undefined && error.error.error !== undefined ? error.error.error.description : error.message;

           //new version of API adds collection of errors , check here for them and add if applicable.
           if (error.error !== undefined && error.error.error !== undefined ) {
             if (error.error.error.errors !== undefined && error.error.error.errors !== null) {
               let errorCollection = error.error.error.errors;
               if (errorCollection.length > 0) {
                 for (var i = 0; i < errorCollection.length ; i++) {
                   errorDescription += "<div>" + errorCollection[i].description + "</div>";
                 }
               }
             }
          }

          // After all that error if no error description add generic error appending contact information
          if (errorDescription == "") {
            errorDescription = "The weather data (API) service is currently unavailable. We apologise for the disruption to normal service. <br/>Please bear with us as we work to restore our regular service as soon as possible.<br/>";
          }

          errorDescription += "<br/> For more information please report the error or ask for more information with our support team on <a href='mailto:econnectedgrainbelt@dpird.wa.gov.au'>econnectedgrainbelt@dpird.wa.gov.au</a><br/> Status Code: " + error.status.toString();

          state.openApplicationModal({Title: title, Message: errorDescription, Colour: alertColour });

        } else if (error.status === 0) {
          if (!state.connectionInfo.isConnected) {
            state.openApplicationModal({Title: 'No Internet connection', Message: 'There doesn\'t appear to be an available internet connection, please check your connectivity and try again.', Colour: 'blue' });
          }
        }
      } //else
    } //if error
    }));
  }
}
