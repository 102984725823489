import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StateService } from '../../../services/state/state.service';
import { DataCache } from '../../../services/data-cache/data-cache.service';

@Component({
  selector: 'app-station-select',
  templateUrl: './station-select.component.html',
  styleUrls: ['./station-select.component.scss']
})
export class StationSelectComponent implements OnInit {


  public mapSettings: any;
  public stationSelection: string;


  @Input() isActive: boolean;
  @Output() onTogglePopOver = new EventEmitter();

  constructor(
    private stateService: StateService,
    private dataCacheService: DataCache
  ) { }

  ngOnInit() {
    this.mapSettings = this.stateService.mapSettings;
    this.setSelectionLabel();


  }


  setSelectionLabel() {
    if (this.mapSettings.layers !== undefined && this.mapSettings.layers.weatherStations !== null ) {
      let visibleStations = this.mapSettings.layers.weatherStations.filter(s => s.isStationsVisible);
      if (visibleStations.length > 1 ) {
        this.stationSelection = 'Multiple';
      } else if (visibleStations.length === 1) {
        this.stationSelection = this.getStationsFriendlyName(visibleStations[0].groupId);
      } else {
        this.stationSelection = 'Select';
      }
    } else {
      this.stationSelection = 'None';
    }
  }



  toggleIsActive() {
    this.onTogglePopOver.emit('stationsPanelActive');
  }


  toggleStationLayer(groupId: string) {
    const StationLayer = this.mapSettings.layers.weatherStations.find(g => g.groupId === groupId);
    StationLayer.isStationsVisible = !StationLayer.isStationsVisible;
    this.stateService.raiseToggleStations(groupId);
  }

  /**
   * Toggle option for DPIRD weather stations to use Cluster layer.
   */
  onGroupToggle(groupId: string) {
    const StationLayer = this.mapSettings.layers.weatherStations.find(g => g.groupId === groupId);
    StationLayer.isClustered = !StationLayer.isClustered;
    this.stateService.raiseToggleGroupStations(groupId);
  }


  /**
   * Function to return friendly name , next version of API will have a label returned for
   * the grouping and will use that instead.
   * TODO:// API Update for additional grouping field.
   * @param group The short groupname used to seperate stations
   */
  showStationTypeName(groupId: string) {
    this.setSelectionLabel(); // Call this as it will run after the datasource has been updated.
    return this.getStationsFriendlyName(groupId);
  }


  getStationsFriendlyName(groupId: string) {

    let stationLabel = 'Other';

    switch (groupId.toLowerCase()) {
      case 'dpird':
        stationLabel =  'DPIRD Stations';
        break;
      case 'dprid': //CATER FOR CURRENT DATA ERROR FROM API
          stationLabel =  'DPIRD Stations';
          break;
      case 'dbca':
        stationLabel =  'DBCA Stations';
        break;
      case 'wc':
        stationLabel =  'WaterCorp Stations';
        break;
      case 'bom':
        stationLabel =  'BoM Stations';
        break;
      case 'pbc':
        stationLabel =  'Pardoo Beef Stations';
        break;
      case 'hw':
        stationLabel =  'Harvey Water Stations';
        break;
      case 'dfes':
        stationLabel =  'DFES Stations';
        break;
      default:
        // No need to do anyting
        break;
    }

    return stationLabel;
  }

}
