
<div class="outer-wrapper">
  <div class="container top">
    <div class="row">
      <div class="content-page">
        <h2>About</h2>

        <p>The Department of Primary Industries and Regional Development's (DPIRD) network of automatic
            weather stations and radars throughout the state provide timely, relevant and local weather 
            data to assist growers and regional communities make more-informed decisions.</p>

        <p>The weather station data includes air temperature, humidity, rainfall, wind speed and direction, 
            with most stations also measuring incoming solar radiation to calculate evaporation. This website 
            includes dashboards for each station to visualise this data.</p>

        <h3>Doppler radars</h3>
        <p>The Doppler radars provide live radar imagery, as well as estimated rainfall and are available for the following locations:</p>

        <ul>
            <li>Perth (Serpentine)</li>
            <li>Kalgoorlie</li>
            <li>South Doodlakine</li>
            <li>Newdegate</li>
            <li>Watheroo</li>
            <li>Geraldton</li>
            <li>Albany</li>
            <li>Esperance (upgrade to Doppler by June 2020)</li>
        </ul>

        <p>For additional information on Doppler radars, please go to the <a href="http://www.bom.gov.au/" target="_blank">Bureau of Meteorology website</a></p>

        <a class="bom-attribution" target="_blank" href="http://www.bom.gov.au/data-access/3rd-party-attribution.shtml"><img src="../../assets/images/BOM-attribution/banner_app_size01_noarrow_colour.png" alt="BOM attribution"></a>
      </div>
    </div>
  </div>
</div>
<app-mobile-filter-menu></app-mobile-filter-menu>
<app-station-table></app-station-table>