import { RainFallMetrics } from '../services/weather/models/station-rainfall.model';

export class RainFallMetricsModel extends RainFallMetrics {
  
  public Since9AM: number;
  public To9AM: number;
  public Last24Hours: number;
  public Last7Days: number;  // The rainfall recorded in the last 7 days to the previous 9am in millimetres.
  public Last14Days: number; // The rainfall recorded in the last 14 days to the previous 9am in millimetres
  public MonthToDate: number; // 
  public YearToDate: number;
  public Period: number; // Amount calculated from a given date range

  constructor(rainfallMetric: RainFallMetrics) { 
    
    super();

    if (rainfallMetric !== undefined) {
      this.Since9AM = rainfallMetric.Since9AM;
      this.To9AM = rainfallMetric.To9AM;
      this.Last24Hours = rainfallMetric.Last24Hours;
      this.Last7Days = rainfallMetric.Last7Days;
      this.Last14Days = rainfallMetric.Last14Days;
      this.MonthToDate = rainfallMetric.MonthToDate;
      this.YearToDate = rainfallMetric.YearToDate;
      this.Period = rainfallMetric.Period;
    }

  }

}