import { Injectable } from '@angular/core';

@Injectable()
export class AppConstants {

  readonly WEATHER_THEME = "DPIRD_WEATHER_THEME";
  readonly APPLICATION_VERSION = "2.0.2";
  readonly APPLICATION_VERSION_KEY = "DPIRD_WEATHER_VERSION";
  readonly USER_SETTINGS = "DPIRD_WEATHER_USER_SETTINGS";
  readonly KEEP_ALIVE_INTERVAL_SEC = 1080 ; // Amount of Seconds for KeepAlive routine.

}

