import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { StateService } from '../services/state/state.service';
import { Router } from '@angular/router';
import { AppConstants } from '../app.constants';

@Component({
  selector: 'app-left-sidebar-menu',
  templateUrl: './left-sidebar-menu.component.html',
  styleUrls: ['./left-sidebar-menu.component.scss']
})
export class LeftSidebarMenuComponent implements OnInit {

  isMenuOpen = false;
  versionNumber: string;

  constructor(
    private stateService: StateService,
    private route: Router,
    private appConstants: AppConstants
  ) {}

  ngOnInit() {
    this.isMenuOpen = this.stateService.panelSettings.leftMenu;
    this.stateService.onToggleLeftMenu.subscribe(data => { this.isMenuOpen = data; });

    this.versionNumber = this.appConstants.APPLICATION_VERSION;
  }

  onCloseButtonClicked() {
    this.stateService.toggleLeftMenu(false);
  }

  navigateToExternalPage(link) {
    window.open(link, '_blank');
    this.onCloseButtonClicked();
  }

  navigateToPage(state) {
    this.stateService.mapSettings.isStationTableActive = false;
    this.route.navigate([state]);
    this.onCloseButtonClicked();
    return false; // for A HREF tags
  }

}
