


<div class="outer-wrapper">
  <div class="container top">
    <div class="row">

      <div class="content-page">
        <h2>Privacy policy</h2>


        <p>
          This website privacy statement applies to  <a href="//www.dpird.wa.gov.au" target="_blank">dpird.wa.gov.au</a> administered by the Department
          of Primary Industries and Regional Development, Western Australia. When you follow a link from this
          website to another site, we recommend you read and consider the privacy statement of that site. </p>


        <h4>Collection of personal information</h4>

        <p>You can browse the general content of <a href="//www.dpird.wa.gov.au" target="_blank">dpird.wa.gov.au</a> anonymously, without disclosing your
          personal information. However, some of the functionality of the site may require you to provide
          personal information. Examples of this include where you wish to make an enquiry. We may need to
          collect, use or disclose personal information to allow us to answer your enquiry or process your
          transaction. </p>

        <h4>Use and disclosure of personal information</h4>
        <p>Any personal information we collect through <a href="//www.dpird.wa.gov.au" target="_blank">dpird.wa.gov.au</a> may be used or disclosed for
          the primary purpose for which it was collected. We will only use that information to communicate
          with you in the way indicated.</p>
        <p>In some limited circumstances we may also need to use or disclose personal information for other
          specific purposes, for example where required to do so by law, such as pursuant to the Freedom of
          Information Act 1992 or in response to a subpoena.
        </p>


        <h3>Collection and use of site visit data</h3>
        <p>The following information may be automatically recorded by this site's web server for statistical and
          system administration purposes only:</p>

        <ul>
          <li>Your server address;</li>
          <li>Your top level domain name (e.g. .com, .au, .gov);</li>
          <li>The date and time of your visit to the site;</li>
          <li>The pages you accessed and downloaded;</li>
          <li>The address of the last site you visited;</li>
          <li>Your operating system;</li>
          <li>The type of browser you are using.</li>

        </ul>

        <p>We will not attempt to identify individuals from the above information unless that is necessary to
          investigate a breach of law or regulation. </p>

        <h4>Cookies and web scripts</h4>
        <p>A cookie is a block of data that is shared between a web server and a user's browser. Cookies, and
          the information collected through them, are used by the Department of Primary Industries and
          Regional Development to make this website more efficient.</p>

        <h4>Enquires may be directed to:</h4>
        <p><span>Email:</span> <a href="mailto:enquiries@dpird.wa.gov.au">enquiries@dpird.wa.gov.au</a></p>










      </div>
    </div>

  </div>



</div>

<app-station-table></app-station-table>