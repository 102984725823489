import { Component, OnInit, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { LoggingService } from '../services/logging/logging.service';

import { TplFlipComponent } from '../components/flip-component/flip.component';

import { StateService } from '../services/state/state.service';
import { RadarService } from '../services/radar/radar.service';
import { SettingsService } from '../services/settings/settings.service';
import { GoogleAnalyticsService } from '../services/google-analytics/google-analytics.service';

import { AppConstants } from '../app.constants';
import { DataCache } from '../services/data-cache/data-cache.service';

import { UserSettingsModel, KeyValuePair } from '../models/user-settings.model';

import { environment } from '../../environments/environment';

//https://www.asymmetrik.com/ngx-leaflet-tutorial-angular-cli/
import { latLng, tileLayer, Map } from 'leaflet';

import * as L from 'leaflet';

//https://github.com/Leaflet/Leaflet.markercluster/issues/725
import 'leaflet.markercluster';
import * as moment from 'moment';

//https://github.com/salemdar/angular2-cookie/issues/37
import { CookieService, CookieOptions } from 'ngx-cookie';




import { StationModel } from '../models/station.model';
import { StationLatestModel } from '../models/station-latest.model';

import { StationRainFall } from '../services/weather/models/station-rainfall.model';


import { Observable } from 'rxjs';
import { Subscription } from "rxjs";

import { Router, NavigationEnd } from '@angular/router';

import { Options, ChangeContext, PointerType } from 'ng5-slider';
import { StationAvailability } from '../services/weather/models/station-availability.model';

//import { UserSettingsModel } from '../models/user-settings.model';
import '../shared/extensions/date.extensions';
import { BoMStationModel } from '../services/gis-to-web/models/bom-station.model';
import { NULL_EXPR, THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { StationConversionService } from '../services/station-conversion/station.conversion.service';
import { VirtualTimeScheduler } from 'rxjs';
import { debuglog } from 'util';

const METRIC_UNKNOWN_VALUE = 'NA';
const RADAR_PERIOD_WINDOW_HRS = 1;
const RADAR_IMAGE_LATENCY = 12;
const RADAR_LOOP_SPEED = 1000; // milliseconds



const RADAR_RECTANGLE_SETTINGS = {
  fillColor: 'transparent',
  color: '#888888',
  dashArray: '5,5',
  weight: 1,
  className: 'radarSquare'
};

const METRICS_OUTAGE_WATCH_LIST = [
  'airtemperature',
  'rainfallmetrics.since9am',
  'rainfallmetrics.monthtodate',
  'relativehumidity',
  'wind_speed_kmh',
  'wind_direction',
  'soiltemperature',
  'solarexposure',
  'etoshortcrop',
  'etotallcrop'];

const RADAR_COEFF = 1000 * 60 * 6;   // 6 MINUTES
const RADAR_SLIDING_WINDOW = 12; // Minutes

let gPreviousClickedStationCode = null;


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [LoggingService, CookieService, StationConversionService]
})
export class MapComponent implements OnInit, OnDestroy {

  //temp
  //value: number = 100;
  sliderOptions: Options = {
    floor: 0,
    ceil: 60,
    step: 6,
    tickStep: 6,
    tickValueStep: 6,
    hideLimitLabels: true,
    hidePointerLabels: true

  };
  //temp




  @ViewChild('CountDownFlip', { static: true }) FlipCounter: TplFlipComponent;

  screenHeight: number;
  screenWidth: number;

  public datePickerDefaultDates: any;

  //public userSettings: UserSettingsModel;
  public MapLocationMarker;
  public subscription: Subscription;
  public toggleStationsSubscription: Subscription;
  public toggleBoMStationsSubscription: Subscription;


  public toggleMapLayerSubscription: Subscription;

  public ThemeChangeSubscription: Subscription;
  public toggleRadarSubscription: Subscription;
  public toggleAllRadarsSubscription: Subscription;
  public toggleStationGroupSubscription: Subscription;
  public toggleBoMStationGroupSubscription: Subscription;
  public toggleRadarForecastSubscription: Subscription;
  public radarSliderIncrement: 1;

  public setMapViewSubscription: Subscription;

  public leafletMap: Map;
  public fullscreenOptions: {[key:string]:any} = {
    position: 'topleft',
    title: 'View Fullscreen',
    titleCancel: 'Exit Fullscreen',
  };
  public menuControlsisOpen: false;
  public selectedWeatherVariable: string = 'RESET_MARKERS'; //same value form toolbar setting default

  public mapLegendFilter: string = '';

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  //mapside
  public availableRadars;
  public currentRadarDateTime;
  public localRadarImageDateTime;

  public radarImageMinuteCounter: number = 0;
  public utcRadarImageDateTime;
  public UTCImageDateTime;
  public isRadarImageLoopRunning: boolean = false;
  public radarInterval; // handle for the interval function looping images
  public radarSliderPlaying: boolean = false;
  public activeRadarCount: 0;

  public ImageCache = {};
  //mapside

  // TODO: Seperate models at the moment need to merge into one Station Objects that then collates the data
  // together rather than being seperate.
  public weatherStations: StationModel[];
  public BoMweatherStations: BoMStationModel[];
  public weatherStationsLatest: StationLatestModel[] = [];
  public weatherStationsAvailability: StationAvailability[] = [];
  public weatherStationsRainFall: StationRainFall[] = [];


  public isDateTimeRangeRequired: boolean = false;
  mapSettings: any;

  //public markerCurrentSelectedLayer = L.featureGroup();
  //public markerClusterData = L.markerClusterGroup();
  //public markerFeatureGroup = L.featureGroup();

  //BoM Marker
  //public BoMmarkerClusterData = L.markerClusterGroup();
  //public BoMmarkerFeatureGroup = L.featureGroup();
  public generalMessageShown = false;

  private radarData;

  private googleHybrid;
  private googleSat;
  private googleStreets;
  private googleTerrain;
  private grayscale;
  private openLayers;

  private accessToken: string = 'pk.eyJ1IjoibmVpbHN0ZXZlbnRvbmRhZndhIiwiYSI6ImNpejd3ZXFnMzAwZ3UzMnFpM3U2dm9jMG0ifQ.YAHMMJ2qMS6BqDDSbtd-dQ';

  private mbAttr: string = '';// 'Map data &copy; <a href="//openstreetmap.org">OpenStreetMap</a> contributors, ' +     '<a href="//creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +   'Imagery © <a href="//mapbox.com">Mapbox</a>';

  private mbUrl: string = 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=' + this.accessToken;

  private mapTheme = this.stateService.stateSettings.theme == 'dark-gotham' ? 'mapbox.dark' : 'mapbox.light';


  // Depending on theme switch the map layers


  //private defaultLayer: any = L.tileLayer(this.mbUrl, {
  //  id: this.mapTheme,
  //  attribution: this.mbAttr
  //});

  private defaultLayer: any = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    id: 'open-layers',
    attribution: ''
  });

  public userSettings: UserSettingsModel;

  options = {
    layers: [
      this.defaultLayer
    ],
    zoom: 6,
    attributionControl: false,
    center: latLng(-28.922, 130.432)
  };

  public radarForecastLayer = L.rectangle([[-30.6192660590689, 114.883446654116], [-35.2200724955949, 120.358509022328]], { color: '#C1FF9D', weight: 1 });

  markerCollection = [];

  constructor(
    private loggingService: LoggingService,
    private stateService: StateService,
    private radarService: RadarService,
    private router: Router,
    private dataCacheService: DataCache,
    private googleAnalyticsService: GoogleAnalyticsService,
    private cookieService: CookieService,
    private constants: AppConstants,
    private settingsService: SettingsService,
    private stationConversionService: StationConversionService
  ) {

    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }



  ngOnInit() {

   
    this.mapSettings = this.stateService.mapSettings;


    this.mapTheme = this.stateService.stateSettings.theme;

    // Added as global as seemed to lose the value between refreshes not sure if some lifecycle event is being triggered in Angular
    // need to investigate more to confirm.
    gPreviousClickedStationCode = this.stateService.mapSettings.previousClickedStation;


    this.availableRadars = this.stateService.mapSettings.radarOptions;
    this.markerCollection = this.stateService.mapSettings.mapMarkers.length !== 0 ? this.stateService.mapSettings.mapMarkers : [];

    // Stations are fetched when the map is ready

    this.dataCacheService.getStationRainfall().subscribe(
      data => {
        this.weatherStationsRainFall = data;
      },
      err => { this.loggingService.logToConsole(err); });

    this.subscription = this.stateService.getStationSearchMessage().subscribe(message => this.onStationSearchSelected(message));

    //this.setMapOptions();

    this.toggleStationsSubscription = this.stateService.onToggleStations().subscribe(groupId => {
      this.setWeatherStationView(groupId);
    });


    this.toggleMapLayerSubscription = this.stateService.onToggleMapLayer().subscribe(data => {
      this.toggleMapLayer(data);
    });


    this.toggleStationGroupSubscription = this.stateService.onToggleStationGroup().subscribe(
      groupCode => {
        this.setWeatherStationGrouping(groupCode);
      }
    );

    this.toggleRadarSubscription = this.stateService.onToggleRadar().subscribe(radar => {
      this.toggleRadarBounds(radar);
    });

    this.toggleAllRadarsSubscription = this.stateService.onToggleAllRadars().subscribe(allActive => {
      this.toggleAllRadarBounds(allActive);
    });

    this.setMapViewSubscription = this.stateService.onSetMapView().subscribe(data => {
      this.leafletMap.setView([data.lat, data.lng], data.zoomLevel);
    });


    this.toggleRadarForecastSubscription = this.stateService.onToggleRadarForecast().subscribe(data => {
      this.toggleRadarForecastLayer(data);
    });

    // this.availableRadars = this.stateService.mapSettings.radarOptions;

    this.setSliderLastHour(); //initial

    //console.log('set markerCurrentSelectedLayer: from state');
    //this.markerCurrentSelectedLayer = this.mapSettings.CurrentSelectedLayer !== null ? this.mapSettings.CurrentSelectedLayer : L.featureGroup();
    //this.markerCurrentSelectedLayer = L.featureGroup();
    this.setGeneralMessageFlag();

    if (this.generalMessageShown !== true) {
     /* this.stateService.openApplicationModal({
        Title: 'Service Impacted', 
        Message: 'Please be informed that servicing of our weather station network may be impacted due to current COVID-19 regional travel restrictions. Any offline stations will be repaired as soon as possible.<br/>Inquiries – E: <a href="mailto:econnectedgrainbelt@dpird.wa.gov.au">econnectedgrainbelt@dpird.wa.gov.au</a>', 
        Colour: 'blue' });*/
        this.hideGeneralMessage();
    }

  }

  setGeneralMessageFlag() {
    const cookieValue = this.cookieService.get('GENERAL_MESSAGE');
    if (cookieValue !== undefined && cookieValue == 'TRUE' ) { 
      this.generalMessageShown = true;
    } else {
      this.generalMessageShown = false;
    }
  }

  hideGeneralMessage() {
    if (this.generalMessageShown === false) {
      const cookieDate = new Date();
      cookieDate.setTime(cookieDate.getTime() + 604800000); // Every Week
      this.cookieService.put('GENERAL_MESSAGE', 'TRUE', { expires: cookieDate.toUTCString() });
      this.generalMessageShown = true;
    }
  }

  //ngAfterContentInit() {ngOnInit
  // this.mapSettings = this.stateService.mapSettings;
  //}

  ngOnDestroy() {
    // Sidebar will be closed as it should only be shown when on the map page
    // the state will trigger this back on if a station was selected.
    this.stateService.panelSettings.isOpen = false;
    this.leafletMap.remove();
    this.ThemeChangeSubscription.unsubscribe();

    this.stateService.onLeafletMapRemoved(true);
    this.toggleStationsSubscription.unsubscribe();

    this.toggleMapLayerSubscription.unsubscribe();
    this.toggleRadarSubscription.unsubscribe();
    this.toggleAllRadarsSubscription.unsubscribe();
    this.toggleStationGroupSubscription.unsubscribe();

    this.setMapViewSubscription.unsubscribe();
    this.toggleRadarForecastSubscription.unsubscribe();

    this.clearRadarInterval();

  }



  /**
   * Refresh counter completion.
   * This event will trigger a refresh from the API to get the latest information. The metric thats selected will be updated and
   * side panel reset with latest details.
   */
  onRefreshCountdownFinished() {

    if (this.stateService.connectionInfo.isConnected) {
      // need to refresh the weather rainfall
      //should pair this up with the function below so they run together.
      this.dataCacheService.getStationRainfall(true).subscribe(
        data => {
          this.weatherStationsRainFall = data; // stationandlatest call will add rainfall again
        },
        err => { this.loggingService.logToConsole(err); });

      //https://github.com/cipchk/ngx-countdown/issues/17
      this.dataCacheService.getStationsAndLatest(true).subscribe(data => {
        this.weatherStations = data[0];
        this.weatherStationsLatest = data[1];
        this.weatherStationsAvailability = data[2];
        this.BoMweatherStations = data[3];

        // Append BoM againg now as we everything has refreshed
        this.appendBoMToStations();
        this.appendBoMToLatest();
        this.appendBoMToRainfall();


        this.setLastMetric();
        if (gPreviousClickedStationCode !== undefined && gPreviousClickedStationCode !== null) { //rename this field
          try {
            let station = this.weatherStations.filter(s => s.Code == gPreviousClickedStationCode)[0];
            //HACK: required to indicate BoM
            const stationId = station.Owner.toUpperCase() == 'BOM STATION' ? 'BOM_STATION_' + station.Code : station.Code;
   
            this.setStationSidePanel({ Code: station.Code, id: stationId ,Latitude: station.Latitude, Longitude: station.Longitude });
          }
          catch (e) {
            this.loggingService.logToConsole("onRefreshCountdownFinished: Error setting station");
          }
        }
      });

      // Restart the timer countdown to give visual feedback
      setTimeout(() => this.FlipCounter.counter.restart());
    }

  }

  toggleRadarForecastLayer(visible) {

    if (visible) {
      if (!this.leafletMap.hasLayer(this.radarForecastLayer)) {
        this.leafletMap.addLayer(this.radarForecastLayer);
      }

    } else {
      if (this.leafletMap.hasLayer(this.radarForecastLayer)) {
        this.leafletMap.removeLayer(this.radarForecastLayer);
      }
    }
  }

  /**
   * Captures the event emitter from the Map Legend when choosing weather variables
   * concats the legend label to the highlight class in order to allow the CSS to style
   * and show only the markers relevant from the selection.
   */
  displayMarkersForLegend(className: string) {
    this.mapLegendFilter = "highlight " + className;
  }

  /**
   * Captures the event emiiter from the Map Legend in order to reset the map markers
   * and make them all visibile.
   */
  resetMarkersForLegend() {
    this.mapLegendFilter = '';
  }


  //setMapOptions() {
  //  this.options.zoom = this.stateService.mapSettings.mapZoomLevel;
  //  let mapCenter = latLng(this.stateService.mapSettings.mapCenter.lat, this.stateService.mapSettings.mapCenter.lng);
  //  this.options.center = mapCenter;
  //}

  /*
  * Called form RESET map view button
  */
  setMapDefault() {

    this.userSettings = this.settingsService.getSettings();

    //console.log(this.userSettings);
    if (this.userSettings.mapLat !== undefined && this.userSettings.mapLat !== null &&
      this.userSettings.mapLng !== undefined && this.userSettings.mapLng !== null &&
      this.userSettings.mapZoomLevel !== undefined && this.userSettings.mapZoomLevel !== null) {

      this.leafletMap.setView([this.userSettings.mapLat, this.userSettings.mapLng,], this.userSettings.mapZoomLevel);
    } else {

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const longitude = position.coords.longitude;
          const latitude = position.coords.latitude;
          if (latitude !== null && longitude !== null) {
            this.leafletMap.setView([latitude, longitude,], 10);
          }
        });
      } else {

        if (this.screenWidth <= 375 && this.screenHeight < 700) { // iPhone 6/7/8   375x667
          this.leafletMap.setView([-31, 118.432,], 5);
        } else if (this.screenWidth <= 414 && this.screenHeight < 800) { // iPhone 6/7/8 Plus 414x735 
          this.leafletMap.setView([-31, 118.432,], 5);
        } else if (this.screenWidth <= 768 && this.screenHeight < 1025) { // iPad 768x1024 
          this.leafletMap.setView([-32, 117.432,], 6);
        } else if (this.screenWidth <= 1024 && this.screenHeight < 1366) { // iPad Pro 124x1366 
        } else {
          this.leafletMap.setView([-28.922, 125.432,], 6);
        }
      }

    }

    // Raise reset event so that mobile control or any other components can subscribe.
    this.stateService.raiseResetMap();

  }


  /************************************************************
   * Leaflet Map Ready event
   */
  onMapReady(map: Map) {

    console.log('OnMapReady');




    map.on('enterFullscreen', () => { map.invalidateSize(); this.stateService.mapSettings.isFullScreen = true; });
    map.on('exitFullscreen', () => { map.invalidateSize(); this.stateService.mapSettings.isFullScreen = false; });
    // EndFullScreen
    this.leafletMap = map;
    this.leafletMap.on('click', this.onMapClicked);


    this.leafletMap.on('moveend', this.onMapMoveEnd)

    this.googleHybrid = L.tileLayer('//{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    });


    this.googleSat = L.tileLayer('//{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    });


    this.googleStreets = L.tileLayer('//{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    });

    this.googleTerrain = L.tileLayer('//{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    });

    this.grayscale = L.tileLayer(this.mbUrl, {
      id: this.stateService.stateSettings.theme == 'dark-gotham' ? 'mapbox.dark' : 'mapbox.light',
      attribution: this.mbAttr
    });

    this.openLayers = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });

    this.stateService.setMapSettingsLayersState(); // set to false when initialised
    this.toggleMapLayer(this.mapSettings.currentMapLayer);


    this.ThemeChangeSubscription = this.stateService.getThemeChangeMessage().subscribe(data => {

      this.stateService.stateSettings.theme = data;//== 'dark-gotham' ? 'mapbox.dark' : 'mapbox.light'
      //theme name comes in but needed as at the moment we toggle between light and dark
      if (this.mapSettings.layers.mapboxGrey.isChecked) {
        if (this.leafletMap.hasLayer(this.grayscale)) {
          this.leafletMap.removeLayer(this.grayscale);
        }
        this.setMapBoxTheme(); //set theme
        this.leafletMap.addLayer(this.grayscale);
        this.mapSettings.currentMapLayer = "MAPBOX_GREY";
      }
    }); //ThemeChangeSubscription


    this.availableRadars = this.stateService.mapSettings.radarOptions;

    this.initRadarRectangle();

    this.setRadarLoopTime();

    //if (this.stateService.mapSettings.isInitMapLoad) {
    this.setMapDefault();


    //  this.stateService.mapSettings.isInitMapLoad = false;
    //}

    //tested to bounce map
    //map.setMaxBounds(map.getBounds());

    this.dataCacheService.getStationsAndLatest().subscribe(data => {
      this.weatherStations = data[0];
      this.weatherStationsLatest = data[1];
      this.weatherStationsAvailability = data[2];
      this.BoMweatherStations = data[3];
    },
      err => { this.loggingService.logToConsole(err); },
      () => this.onCompletedGetStationsAndLatest());


    this.initWeatherStationView();


  }


  /**
   * Store the Map last position and Zoom into settings service.
   */
  onMapMoveEnd = (e) => {

    try {
      const mapLatLng = this.leafletMap.getCenter();

      this.userSettings = this.settingsService.getSettings();

      this.userSettings.mapZoomLevel = this.leafletMap.getZoom();
      this.userSettings.mapLat = mapLatLng.lat;
      this.userSettings.mapLng = mapLatLng.lng;

      this.settingsService.saveSettings(this.userSettings);
    } catch (e) {
      this.loggingService.logToConsole('onMapMoveEnd:' + e);
    }

  }


  onMapClicked = (e) => {

    if (this.MapLocationMarker != undefined) {
      //this.leafletMap.removeLayer(MapLocationMarker);
      this.MapLocationMarker.remove();
      //this.markerCurrentSelectedLayer.remove(MapLocationMarker);
    }

    this.MapLocationMarker = L.circle([e.latlng.lat, e.latlng.lng], { radius: 500 });

    // If marker is within radar
    if (this.isMarkerInsideRadar(this.MapLocationMarker)) {


      //this.leafletMap.addLayer(MapLocationMarker);
      this.mapSettings.layers.overlaySelectionLayer.clearLayers();
      this.mapSettings.layers.overlaySelectionLayer.addLayer(this.MapLocationMarker);
      this.mapSettings.layers.overlaySelectionLayer.bringToFront();

      // this.stateService.mapSettings.CurrentSelectedLayer = this.markerCurrentSelectedLayer; // Added into state NEW

      // Initiate the service call for Radar data
      this.fetchRadarSummary(e);

    } else { // Outside of a Radar location
      this.setPopupBalloonMessage(e.latlng, 'No Radar coverage for this location.');
      this.stateService.panelSettings.isOpen = false;
    }

  }

  private fetchRadarSummary(e: any) {

    const closestRadar = this.calculateClosestRadar(e.latlng.lat, e.latlng.lng); // Find out now for future will pass into the API

    this.radarService.getRainfallSummary( e.latlng.lat, e.latlng.lng ).subscribe(
      data => {
        this.radarData = data;
        if (this.radarData == null) {
          this.showNoRadarDataAvailableAtPosition(e.latlng);
          return;
        }
        this.radarData.setClosestRadar(closestRadar); //TODO: use a model from service currently this is the details from state service     
      },
      err => {
        this.showNoRadarDataAvailableAtPosition(e.latlng);
        return;
      },
      () => {
        this.onCompletedRadarResponse();
        this.setStationSidePanel({ Code: 'VIRTUAL_STATION', Latitude: e.latlng.lat, Longitude: e.latlng.lng });
      }
    );

  }

  private showNoRadarDataAvailableAtPosition(latlng: any) {
    //OLD V1 Threw a 404 for no data - need to raise issue in V2 to do the same
    //if (err.status === 404) { // RADAR Gives a 404 FOR NO DATA
    this.setPopupBalloonMessage(latlng, 'No Radar data for this location.');
    this.radarData = null; // clear any previous data
    this.stateService.raiseRadarMapLocationClicked(null);
    //}
  }

  private calculateClosestRadar(lat: number, lng: number) {

    let closestRadar = null;

    if (lat !== null && lng !== null) {
      let distance = null;
      const fromLatLng = latLng(lat, lng);
      for (let index = 0; index < this.availableRadars.length; index++) {

        const radar = this.availableRadars[index];
        const radarBounds = L.latLngBounds(this.availableRadars[index].bounds[0], this.availableRadars[index].bounds[1]);
        const radarCenterPoint = radarBounds.getCenter();

        const calculatedDistance = fromLatLng.distanceTo(radarCenterPoint)
        if (distance == null || calculatedDistance < distance) {
          distance = calculatedDistance;
          closestRadar = radar
        }

      }
    }

    return closestRadar;

  }


  private setPopupBalloonMessage(latlng: any, message: string) {

    if (this.leafletMap !== undefined) {
      const popup = L.popup();

      popup
        .setLatLng(latlng)
        .setContent(message)
        .addTo(this.leafletMap).openPopup();

      // Hide the popup after it has been show for 1 second
      // have to use remove as closePopup doesnt do anything.
      setTimeout(function () {
        popup.remove();
      }, 1000);
    }
  }

  private onCompletedRadarResponse() {
    this.stateService.raiseRadarMapLocationClicked(this.radarData);
  }

  /**
   * Group a JSON Collection by a certain field.
   * Makes it easier to seperate the weather stations via feature layers.
   * @param xs 
   * @param f 
   */
  private groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  /*
   * Called once the Observable has completed, wired up in the ngOnInit method
   * to subscribe to the weather service call getting both the stations and latest to make sure
   * the application has the main parts for showing the data.
   */
  private onCompletedGetStationsAndLatest() {

    if (this.markerCollection.length === 0) {

      // BoM Stations to be converted and added into the generic Station Model
      // Comes from a Different API source so needs to align into the model.
      // Broken into seperate functions as there are multiple places they are required individually.
      this.appendBoMToStations();
      this.appendBoMToLatest();
      this.appendBoMToRainfall();


      // Create grouped version of Stations
      const groupedStations = this.groupBy(this.weatherStations, (w) => w.OwnerShortCode); // TODO: Get Shortcode from API rather than havong to hardcode names
      const featureGroups = [];

      // Iterate over the grouped items and initialize feature groups and cluster groups
      for (const field of Object.keys(groupedStations)) {
        const stationGroup = field.toLowerCase();
        
        // Default lasyer settings
        let isStationsVisible = true;
        let isStationsClustered = false;
        
        // Read in the settings for the layer
        // Stored into Cookie to keep users preference
        if (this.userSettings.stationLayers !== undefined && this.userSettings.stationLayers.length > 0) {
          let groupSetting = this.userSettings.stationLayers.filter(g => g.GroupId === stationGroup);
          if (groupSetting.length === 1) {
            isStationsVisible = groupSetting[0].isVisible;
            isStationsClustered = groupSetting[0].isClustered;
          }
        }

        featureGroups.push({
          groupId: stationGroup,
          isStationsVisible: isStationsVisible, // TODO: Settings that get saved need to load what user preferes
          isClustered: isStationsClustered,
          featureGroup: L.featureGroup(),
          clusterGroup: L.markerClusterGroup(
            {
              iconCreateFunction: function (cluster) {
                return new L.DivIcon({ html: cluster.getChildCount().toString(), className: 'marker-cluster ' + stationGroup, iconSize: new L.Point(40, 40) })
              }
            }
          )
        });
      }

      const _self = this;

      // Iterate weather stations addings to the correct feature and cluster groups
      this.weatherStations.forEach(function (station) {
        if (station.Online) { // Only generate markers for online stations
          let marker = _self.generateStationMarker(station.Latitude, station.Longitude, station.Code, station.Name, station.OwnerShortCode.toLowerCase());
          // console.log(station.OwnerShortCode.toLowerCase());
          featureGroups.find(g => g.groupId === station.OwnerShortCode.toLowerCase()).featureGroup.addLayer(marker);
          featureGroups.find(g => g.groupId === station.OwnerShortCode.toLowerCase()).clusterGroup.addLayer(marker);
          // Add marker to collection for referencing.
          _self.markerCollection.push({ stationCode: station.Code, stationName: station.Name, lat: station.Latitude, lng: station.Longitude, marker: marker });
        }
      });


      this.stateService.mapSettings.mapMarkers = this.markerCollection;

      // Set last station point clicked if in state
      //if (this.mapSettings.lastMapPoint.isStation && this.mapSettings.lastMapPoint.stationCode !== undefined) {
      //  this.onStationSearchSelected(this.mapSettings.lastMapPoint.stationCode);
      //}

      // Assign into state teh feature groups
      this.stateService.mapSettings.layers.weatherStations = featureGroups;
      //console.log('set layers :');
      //console.log(featureGroups);

      // Layer that will be used for adding the marker selection
      this.mapSettings.layers.overlaySelectionLayer = L.featureGroup();

      this.initWeatherStationView();
      //this.setDBCAWeatherStationView();
      //this.setWaterCorpWeatherStationView();
      //this.setBoMWeatherStationView();

      // ???this.leafletMap.addLayer(this.markerCurrentSelectedLayer);

      //this.isDataLoaded = true;
      this.stateService.raiseApplicationReady();

      this.setLastMetric();

    }

    // Set last station point clicked if in state
    if (this.mapSettings.lastMapPoint.isStation && this.mapSettings.lastMapPoint.stationCode !== undefined) {
      this.onStationSearchSelected(this.mapSettings.lastMapPoint.stationCode);
    }

  }


  appendBoMToLatest() {
    if (this.BoMweatherStations !== undefined) {
    for (let i = 0; i < this.BoMweatherStations.length; i++) {
      // const StationModel = StationConversionService.ConvertBoMToStationModel(this.BoMweatherStations[i]);
      const StationLatestModel = StationConversionService.ConvertBoMToStationLatestModel(this.BoMweatherStations[i]);
      //   const BoMRainfallModel = StationConversionService.convertBomToRainfallModel(this.BoMweatherStations[i]);

      //  this.weatherStations.push(StationModel);

      this.weatherStationsLatest.push(StationLatestModel);
      //  this.weatherStationsRainFall.push(BoMRainfallModel);
    }
  }

  }


  appendBoMToStations() {

  
      const bomsAdded = this.weatherStations.filter( s => s.Code === '9999' || s.Code === '2064');
      //HACKY : noticed Bom aded more than once so check first - revisit in new year
      if (bomsAdded.length === 0 && this.BoMweatherStations !== undefined) {

      for (let i = 0; i < this.BoMweatherStations.length; i++) {
        const StationModel = StationConversionService.ConvertBoMToStationModel(this.BoMweatherStations[i]);
        //  const StationLatestModel = StationConversionService.ConvertBoMToStationLatestModel(this.BoMweatherStations[i]);
        //  const BoMRainfallModel = StationConversionService.convertBomToRainfallModel(this.BoMweatherStations[i]);

        this.weatherStations.push(StationModel);
        //  this.weatherStationsLatest.push(StationLatestModel);
        //  this.weatherStationsRainFall.push(BoMRainfallModel);
      }
    }
  

  }


  appendBoMToRainfall() {
    if (this.BoMweatherStations !== undefined) {
    for (let i = 0; i < this.BoMweatherStations.length; i++) {
      // const StationModel = StationConversionService.ConvertBoMToStationModel(this.BoMweatherStations[i]);
      //  const StationLatestModel = StationConversionService.ConvertBoMToStationLatestModel(this.BoMweatherStations[i]);
      const BoMRainfallModel = StationConversionService.convertBomToRainfallModel(this.BoMweatherStations[i]);

      //this.weatherStations.push(StationModel);
      //this.weatherStationsLatest.push(StationLatestModel);
      this.weatherStationsRainFall.push(BoMRainfallModel);
    }
  }

  }

  /*
   *  Inludes function does not work in IE so simple function to do check
   */
  private includes(container, value) {
    let returnValue = false;
    const pos = container.indexOf(value);
    if (pos >= 0) {
      returnValue = true;
    }
    return returnValue;
  }


  private generateStationMarker(lat: number, lng: number, stationCode: string, stationName: string, stationOwner: string = 'dpird') {

    const stationClassName = 'station-marker ' + stationOwner;

    const myIcon = <StationDivMarker>L.divIcon({ className: stationClassName, html: '' });


    myIcon.stationCode = stationCode;
    myIcon.stationId = stationOwner.toLowerCase() == "bom" ? 'BOM_STATION_' + stationCode : stationCode;
    myIcon.stationName = stationName;
    myIcon.stationOwner = stationOwner;

    const marker = L.marker([lat, lng], { icon: myIcon });
    const popCustomOptions = { 'className': 'station-popup' };

    marker.on('click', this.onMapMarkerClicked);


    const _self = this;
    marker.on('mouseover', function (e) {

      if (stationOwner.toLowerCase() == 'bom') {
        this.bindPopup(stationName + '<div>Click for more infomation</div>');
      } else {
        this.bindPopup(_self.getStationPopupHtml(stationCode), popCustomOptions);
      }
      this.openPopup();
    });

    marker.on('mouseout', function (e) {
      this.closePopup();
    });

    return marker;
  }


  getStationPopupHtml(stationCode) {
    var station = this.weatherStationsLatest.find(s => s.Code === stationCode);
    if (station !== undefined && station !== null) {
      return station.Name + '(' + station.Code + ') ' + (this.selectedWeatherVariable.toLowerCase() !== 'getlastupdatedminutes' ? '<div> Last Updated:' + station.getLastUpdatedNoticeDuration() + '</div>' : '');
    }
    else {
      return "Not Available";
    }

  }


  private generateBeaconMarker(e: any): L.Marker {

    const newIcon = <StationDivMarker>L.divIcon({ className: 'station-marker', html: '<span class="beacon" style="z-index:9999"></span>' });
    newIcon.stationCode = e.sourceTarget.options.icon.stationCode;
    newIcon.stationOwner = e.sourceTarget.options.icon.stationOwner;
    newIcon.stationName = e.sourceTarget.options.icon.stationName;


    // Conditional check as when triggering a click event then the object doesnt get passed through
    // the same and we have to call the function getLatLng(): //TODO:  revisit this code.
    const lat = e.latlng !== undefined ? e.latlng.lat : e.sourceTarget.getLatLng().lat;
    const lng = e.latlng !== undefined ? e.latlng.lng : e.sourceTarget.getLatLng().lng;


    return L.marker([lat, lng], { icon: newIcon });
  }

  private onMapMarkerClicked = (e) => {

    // Remove beacon if active as now a station has been selected
    if (this.MapLocationMarker != undefined) {
      this.mapSettings.layers.overlaySelectionLayer.clearLayers(); // required otherwise coming back to map will show all markers again event though clearing it manually.
    }


    this.MapLocationMarker = this.generateBeaconMarker(e)

    const lat = e.latlng !== undefined ? e.latlng.lat : e.sourceTarget.getLatLng().lat;
    const lng = e.latlng !== undefined ? e.latlng.lng : e.sourceTarget.getLatLng().lng;


    this.mapSettings.layers.overlaySelectionLayer.addLayer(this.MapLocationMarker);
    //this.markerCurrentSelectedLayer.addLayer(this.MapLocationMarker);


    // console.log('save into state : current selected layer');
    // this.stateService.mapSettings.CurrentSelectedLayer = this.markerCurrentSelectedLayer; // Added into state NEW

    //end

    // Call RADAR Service also for the given station that has been clicked and within the RADAR bounds
    if (this.isMarkerInsideRadar(e.sourceTarget)) {

      // Calculate Radar From and To Date for API CALL
      //var radarStartMonthYear = moment().add(-1, 'year').format('YYYY-MM');
      //var radarEndMonthYear = moment().format('YYYY-MM');


      const closestRadar = this.calculateClosestRadar(lat, lng); // Find out now for future will pass into the API

      this.radarService.getRainfallSummary(lat, lng).subscribe(
        data => {
          this.radarData = data;
           if (this.radarData == null) {
            this.showNoRadarDataAvailableAtPosition(e.latlng);
            return;
          }
          this.radarData.setClosestRadar(closestRadar);
        },
        err => {
          this.showNoRadarDataAvailableAtPosition(e.latlng);
        },
        () => { this.onCompletedRadarResponse(); });
    } else {
      // RADAR data reset by calling event and passing in null.
      this.stateService.raiseRadarMapLocationClicked(null);
    }

    this.setStationSidePanel({ id: e.sourceTarget.options.icon.stationId, Code: e.sourceTarget.options.icon.stationCode, Latitude: lat, Longitude: lng });
    //this.recentreLeafletMap(new L.LatLng(lat, lng), 300, 0);

    // Save current clicked location to state
    this.mapSettings.lastMapPoint.isStation = true;
    this.mapSettings.lastMapPoint.latLng = { lat: lat, lng: lng };
    this.mapSettings.lastMapPoint.stationCode = e.sourceTarget.options.icon.stationCode;

    gPreviousClickedStationCode = e.sourceTarget.options.icon.stationCode;
    this.stateService.mapSettings.previousClickedStation = gPreviousClickedStationCode;

  }


  recentreLeafletMap(latlng, offsetx, offsety) {
    if (this.leafletMap !== undefined) {
      var currentZoom = this.leafletMap.getZoom();
      var center = this.leafletMap.project(latlng, currentZoom);
      center = L.point(center.x + offsetx, center.y + offsety);
      var target = this.leafletMap.unproject(center, currentZoom);
      let that = this;
      setTimeout(function () { that.leafletMap.panTo(target); }, 1000);  //HACK Avoid : ERROR TypeError: Cannot read property '_leaflet_pos' of undefined
    }
  }

  private setStationSidePanel(station) {

    // Make changes here otherwise doesnt take place and the component that receives
    //message needs to run detect changes
    if (!this.stateService.panelSettings.isOpen) {
      this.stateService.panelSettings.isOpen = true;
      this.stateService.panelSettings.isStationSelected = true;
    }

    this.stateService.onStationMarkerSelected(station);

  }

  // Change of the dropdown within the map toolbar triggers a metric change event allowing you
  // to capture which option has been chosen.
  onMetricChanged(metric) {

    //onMetricChanged $event can be undefined on loading.
    if (metric == undefined) {
      metric = "RESET_MARKERS";
    }
    //console.trace();
    this.selectedWeatherVariable = (metric == "RAIN_WDR" ? "RainFallMetrics.Period" : metric);

    switch (metric) {
      case "RAIN_WDR":
        this.isDateTimeRangeRequired = true;
        //before get a date use the last 14 Days as default or whats in settings
        if (this.stateService.stateSettings.rainfallWDRDates !== null) {
          this.onDateSelection(this.stateService.stateSettings.rainfallWDRDates);
        } else {
          this.onDateSelection([moment().subtract(20, 'd'), moment()]); // default last 14 days
        }

        //this.changeMarkerDisplay("RainFallMetrics.Last7Days");
        break;
      default:
        this.changeMarkerDisplay(metric);
        this.isDateTimeRangeRequired = false;
        break;
    }

    //this.changeMarkerDisplay(metric);
  }


  getMetricStyle(stationCode: string, owner: string, metric: string) {

    // Reset option put the markers back without values.
    if (metric == "RESET_MARKERS") {
      return {
        metricClass: 'station-marker ' + owner,
        metricHtml: ''
      }
    }

    // Wind Direction Metric requires different markup to the rest so alter the default
    let metricName = metric.toLowerCase().replace(".", "");

    let className = 'station-marker ' + owner + ' ' + metricName;
    let metricRawValue = this.getMetricValue(stationCode, metric);
    let metricValue = <string>metricRawValue;
    let metricHTML = '<div class="marker-value"><span class="icon  icon-play"></span>' + metricValue + '</div>';

    // Class name includes the metric but wind also has the value for the style arrow.
    if (metricName == "wind_direction") {
      let direction = metricValue;
      className += ' dir-' + direction;

      // Wind direction is also the only variable on the page will need to get the wind speed values for the colour coding
      metricRawValue = this.getMetricValue(stationCode, "WIND_SPEED_KMH");

      //now change value to wind speed value
      metricValue = <string>metricRawValue

      metricHTML = '<div class="marker-value-direction"><span class="icon  icon-direction-icon"></span><div class="marker-value"><span class="icon  icon-play"></span>' + direction + ' @ ' + metricValue + ' kmh</div></div>';

      metricName = "wind_speed_kmh";

    }


    // If the station is over 90 minutes old
    // the pin marker will be set to grey
    // const minutesSinceUpdate = this.getMetricValue(stationCode, 'getlastupdatedminutes');
    let minutesSinceUpdate = 5;
    const stationLatestReading = this.weatherStationsLatest.find(s => s.Code === stationCode);
    if (stationLatestReading !== null) {
      minutesSinceUpdate = stationLatestReading.getLastUpdatedMinutes();
    }


    if (minutesSinceUpdate > 90 && this.includes(METRICS_OUTAGE_WATCH_LIST, metricName)) {
      className += ' offline-marker ml-na';
    } else {
      // Add additional class depending on the grouping required based on the metric name
      // if the station has had a reading within 90 minutes
      className += this.addLegendIdentifier(metricName, metricRawValue, stationCode);
    }

    return {
      metricClass: className,
      metricHtml: metricHTML
    }
  }



  addLegendIdentifier(metric, metricValue, stationCode) {

    let value = parseFloat(metricValue);
    let legendClass = "";

    switch (metric) {

      case "airtemperature":

        switch (true) {

          case (value < -10):
            legendClass = " ml-ln10";
            break;
          case (value < -5):
            legendClass = " ml-ln5";
            break;
          case (value < 0):
            legendClass = " ml-l0";
            break;
          case (value < 5):
            legendClass = " ml-l5";
            break;
          case (value < 10):
            legendClass = " ml-l10";
            break;
          case (value < 15):
            legendClass = " ml-l15";
            break;
          case (value < 20):
            legendClass = " ml-l20";
            break;
          case (value < 25):
            legendClass = " ml-l25";
            break;
          case (value < 30):
            legendClass = " ml-l30";
            break;
          case (value < 35):
            legendClass = " ml-l35";
            break;
          case (value < 40):
            legendClass = " ml-l40";
            break;
          case (value < 45):
            legendClass = " ml-l45";
            break;
          case (value < 50):
            legendClass = " ml-l50";
            break;
          case (value >= 50):
            legendClass = " ml-g50";
            break;
          default:
            legendClass = " ml-na";
            break;
        }


        break;


      case "airtemperatureminlast24hrs":
        switch (true) {
          case (value < -5):
            legendClass = " ml-ln5";
            break;
          case (value < -4):
            legendClass = " ml-ln4";
            break;
          case (value < -3):
            legendClass = " ml-ln3";
            break;
          case (value < -2):
            legendClass = " ml-ln2";
            break;
          case (value < -1):
            legendClass = " ml-ln1";
            break;
          case (value < 0):
            legendClass = " ml-l0";
            break;
          case (value < 1):
            legendClass = " ml-l1";
            break;
          case (value < 2):
            legendClass = " ml-l2";
            break;
          case (value < 3):
            legendClass = " ml-l3";
            break;
          case (value < 4):
            legendClass = " ml-l4";
            break;
          case (value < 5):
            legendClass = " ml-l5";
            break;
          case (value < 10):
            legendClass = " ml-l10";
            break;
          case (value < 20):
            legendClass = " ml-l20";
            break;
          case (value >= 20):
            legendClass = " ml-g20";
            break;
          default:
            legendClass = " ml-na";
            break;
        }

        break;

      case "soiltemperature":

        switch (true) {

          case (value < -10):
            legendClass = " ml-ln10";
            break;
          case (value < -5):
            legendClass = " ml-ln5";
            break;
          case (value < 0):
            legendClass = " ml-l0";
            break;
          case (value < 5):
            legendClass = " ml-l5";
            break;
          case (value < 10):
            legendClass = " ml-l10";
            break;
          case (value < 15):
            legendClass = " ml-l15";
            break;
          case (value < 20):
            legendClass = " ml-l20";
            break;
          case (value < 25):
            legendClass = " ml-l25";
            break;
          case (value < 30):
            legendClass = " ml-l30";
            break;
          case (value < 35):
            legendClass = " ml-l35";
            break;
          case (value < 40):
            legendClass = " ml-l40";
            break;
          case (value < 45):
            legendClass = " ml-l45";
            break;
          case (value < 50):
            legendClass = " ml-l50";
            break;
          case (value >= 50):
            legendClass = " ml-g50";
            break;
          default:
            legendClass = " ml-na";
            break;

        }


        break;
      case "airtemperaturemaxlast24hrs":

        switch (true) {

          case (value < -10):
            legendClass = " ml-ln10";
            break;
          case (value < -5):
            legendClass = " ml-ln5";
            break;
          case (value < 0):
            legendClass = " ml-l0";
            break;
          case (value < 5):
            legendClass = " ml-l5";
            break;
          case (value < 10):
            legendClass = " ml-l10";
            break;
          case (value < 15):
            legendClass = " ml-l15";
            break;
          case (value < 20):
            legendClass = " ml-l20";
            break;
          case (value < 25):
            legendClass = " ml-l25";
            break;
          case (value < 30):
            legendClass = " ml-l30";
            break;
          case (value < 35):
            legendClass = " ml-l35";
            break;
          case (value < 40):
            legendClass = " ml-l40";
            break;
          case (value < 45):
            legendClass = " ml-l45";
            break;
          case (value < 50):
            legendClass = " ml-l50";
            break;
          case (value >= 50):
            legendClass = " ml-g50";
            break;
          default:
            legendClass = " ml-na";
            break;

        }


        break;
      case "etoshortcrop":  // updated #308
      case "etotallcrop":

        switch (true) {
          case (value < 1):
            legendClass = " ml-l1";
            break;
          case (value < 2):
            legendClass = " ml-l2";
            break;
          case (value < 4):
            legendClass = " ml-l4";
            break;
          case (value < 6):
            legendClass = " ml-l6";
            break;
          case (value < 8):
            legendClass = " ml-l8";
            break;
          case (value < 10):
            legendClass = " ml-l10";
            break;
          case (value < 12):
            legendClass = " ml-l12";
            break;
          case (value < 14):
            legendClass = " ml-l14";
            break;
          case (value >= 14):
            legendClass = " ml-g14";
            break;
          default:
            legendClass = " ml-na";
            break;
        }


        break;

      case "getlastupdatedminutes":

        switch (true) {
          case (value < 15):
            legendClass = " ml-l15";
            break;
          case (value < 30):
            legendClass = " ml-l30";
            break;
          case (value < 60):
            legendClass = " ml-l60";
            break;
          case (value < 180):
            legendClass = " ml-l180";
            break;
          case (value < 360):
            legendClass = " ml-l360";
            break;
          case (value < 720):
            legendClass = " ml-l720";
            break;
          case (value >= 720):
            legendClass = " ml-g720";
            break;
          default:
            legendClass = " ml-g720";
            break;
        }

        break;

      case "rainfallmetricssince9am":
      case "rainfallmetricsto9am":

        switch (true) {
          case (value < 1):
            legendClass = " ml-l1";
            break;
          case (value < 5):
            legendClass = " ml-l5";
            break;
          case (value < 10):
            legendClass = " ml-l10";
            break;
          case (value < 15):
            legendClass = " ml-l15";
            break;
          case (value < 25):
            legendClass = " ml-l25";
            break;
          case (value < 50):
            legendClass = " ml-l50";
            break;
          case (value < 100):
            legendClass = " ml-l100";
            break;
          case (value >= 100):
            legendClass = " ml-g100";
            break;
          default:
            legendClass = " ml-na";
            break;
        }


        break;

      case 'rainfallmetricsperiod':

        switch (true) {
          case (value < 5):
            legendClass = " ml-l5";
            break;
          case (value < 10):
            legendClass = " ml-l10";
            break;
          case (value < 15):
            legendClass = " ml-l15";
            break;
          case (value < 25):
            legendClass = " ml-l25";
            break;
          case (value < 50):
            legendClass = " ml-l50";
            break;
          case (value < 100):
            legendClass = " ml-l100";
            break;
          case (value < 200):
            legendClass = " ml-l200";
            break;
          case (value < 300):
            legendClass = " ml-l300";
            break;
          case (value < 400):
            legendClass = " ml-l400";
            break;
          case (value < 500):
            legendClass = " ml-l500";
            break;
          case (value < 750):
            legendClass = " ml-l750";
            break;
          case (value >= 750):
            legendClass = " ml-g100";
            break;
          default:
            legendClass = " ml-na";
            break;
        }


        break;

      case "rainfallmetricslast7days":

        switch (true) {

          case (value < 5):
            legendClass = " ml-l5";
            break;
          case (value < 10):
            legendClass = " ml-l10";
            break;
          case (value < 15):
            legendClass = " ml-l15";
            break;
          case (value < 25):
            legendClass = " ml-l25";
            break;
          case (value < 50):
            legendClass = " ml-l50";
            break;
          case (value < 100):
            legendClass = " ml-l100";
            break;
          case (value < 150):
            legendClass = " ml-l150";
            break;
          case (value >= 150):
            legendClass = " ml-g150";
            break;
          default:
            legendClass = " ml-na";
            break;
        }
        break;

      case 'rainfallmetricsmonthtodate':

        switch (true) {
          case (value < 1):
            legendClass = " ml-l1";
            break;
          case (value < 5):
            legendClass = " ml-l5";
            break;
          case (value < 10):
            legendClass = " ml-l10";
            break;
          case (value < 15):
            legendClass = " ml-l15";
            break;
          case (value < 25):
            legendClass = " ml-l25";
            break;
          case (value < 50):
            legendClass = " ml-l50";
            break;
          case (value < 100):
            legendClass = " ml-l100";
            break;
          case (value < 150):
            legendClass = " ml-l150";
            break;
          case (value < 200):
            legendClass = " ml-l200";
            break;
          case (value < 300):
            legendClass = " ml-l300";
            break;
          case (value < 400):
            legendClass = " ml-l400";
            break;
          case (value >= 400):
            legendClass = " ml-g400";
            break;
          default:
            legendClass = " ml-na";
            break;
        }
        break;

      case "rainfallmetricsyeartodate":
        // console.log(value);
        switch (true) {
          case (value < 25):
            legendClass = " ml-l25";
            break;
          case (value < 50):
            legendClass = " ml-l50";
            break;
          case (value < 100):
            legendClass = " ml-l100";
            break;
          case (value < 200):
            legendClass = " ml-l200";
            break;
          case (value < 300):
            legendClass = " ml-l300";
            break;
          case (value < 400):
            legendClass = " ml-l400";
            break;
          case (value < 500):
            legendClass = " ml-l500";
            break;
          case (value < 750):
            legendClass = " ml-l750";
            break;
          case (value < 1000):
            legendClass = " ml-l1000";
            break;
          case (value < 1500):
            legendClass = " ml-l1500";
            break;
          case (value < 2000):
            legendClass = " ml-l2000";
            break;
          case (value >= 2000):
            legendClass = " ml-g2000";
            break;
          default:
            legendClass = " ml-na";
            break;
        }


        break;
      case "relativehumidity":

        switch (true) {
          case (value < 10):
            legendClass = " ml-l10";
            break;
          case (value < 20):
            legendClass = " ml-l20";
            break;
          case (value < 30):
            legendClass = " ml-l30";
            break;
          case (value < 40):
            legendClass = " ml-l40";
            break;
          case (value < 50):
            legendClass = " ml-l50";
            break;
          case (value < 60):
            legendClass = " ml-l60";
            break;
          case (value < 70):
            legendClass = " ml-l70";
            break;
          case (value < 80):
            legendClass = " ml-l80";
            break;
          case (value < 90):
            legendClass = " ml-l90";
            break;
          case (value < 100):
            legendClass = " ml-l100";
            break;
          case (value >= 100):
            legendClass = " ml-g100";
            break;
          default:
            legendClass = " ml-na";
            break;
        }

        break;

      case "wind_direction":
      case "wind_speed_kmh":
        switch (true) {
          case (value < 5):
            legendClass = " ml-l5";
            break;
          case (value < 10):
            legendClass = " ml-l10";
            break;
          case (value < 15):
            legendClass = " ml-l15";
            break;
          case (value < 20):
            legendClass = " ml-l20";
            break;
          case (value < 30):
            legendClass = " ml-l30";
            break;
          case (value < 40):
            legendClass = " ml-l40";
            break;
          case (value < 50):
            legendClass = " ml-l50";
            break;
          case (value < 60):
            legendClass = " ml-l60";
            break;
          case (value < 70):
            legendClass = " ml-l70";
            break;
          case (value >= 70):
            legendClass = " ml-g70";
            break;
          default:
            legendClass = " ml-na";
            break;
        }
        break;

      case "solarexposure":
        switch (true) {
          case (value < 3):
            legendClass = " ml-l3";
            break;
          case (value < 6):
            legendClass = " ml-l6";
            break;
          case (value < 9):
            legendClass = " ml-l9";
            break;
          case (value < 12):
            legendClass = " ml-l12";
            break;
          case (value < 15):
            legendClass = " ml-l15";
            break;
          case (value < 18):
            legendClass = " ml-l18";
            break;
          case (value < 21):
            legendClass = " ml-l21";
            break;
          case (value < 24):
            legendClass = " ml-l24";
            break;
          case (value < 27):
            legendClass = " ml-l27";
            break;
          case (value < 30):
            legendClass = " ml-l30";
            break;
          case (value < 33):
            legendClass = " ml-l33";
            break;
          case (value < 36):
            legendClass = " ml-l36";
            break;
          case (value >= 36):
            legendClass = " ml-g36";
            break;

          default:
            legendClass = " ml-na";
            break;
        }


        break;

      case "errorstoday":
      case "errorslast7days":

        switch (true) {
          case (value < 1):
            legendClass = " ml-l1";
            break;
          case (value < 15):
            legendClass = " ml-l5";
            break;
          case (value < 20):
            legendClass = " ml-l20";
            break;
          case (value < 500):
            legendClass = " ml-l500";
            break;
          case (value >= 500):
            legendClass = " ml-g500";
            break;
        }

        break;

      default:

        break;





    }


    legendClass = this.setLegendBasedonAvailability(legendClass, metric, stationCode);

    return legendClass;

  }


  /*
  * Manipulate the class so if the availability is below a certain threshold we mark it as NA
  * because the data is not 100% accurate and should be classed as such.
  */
  setLegendBasedonAvailability(currentLegendClass: string, metric: string, stationCode: string) {
    let className = currentLegendClass;
    const station = this.weatherStationsAvailability.filter(s => s.Code === stationCode)[0];

    if (station !== undefined && station !== null) {
      switch (metric) {
        case 'rainfallmetricsyeartodate':
          if (station.YearToDateto9AM == undefined || station.YearToDateto9AM <= 90) {
            className = ' ml-na ml-lowavailability';
          }
          break;
        case 'rainfallmetricsmonthtodate':
          if (station.MonthToDateto9AM == undefined || station.MonthToDateto9AM <= 90) {
            className = ' ml-na ml-lowavailability';
          }
          break;
        case 'rainfallmetricslast7days' :
          if (station.Last7DaysSince9AM == undefined || station.Last7DaysSince9AM <= 90) {
            className = ' ml-na ml-lowavailability';
          }
          break;
        case 'rainfallmetricssince9am' :
          if (station.Since9AM == undefined || station.Since9AM <= 90) {
            className = ' ml-na ml-lowavailability';
          }
          break;
        case 'rainfallmetricsto9am' :
          if (station.To9AM == undefined || station.To9AM <= 90) {
            className = ' ml-na ml-lowavailability';
          }
          break;

        case 'rainfallmetricsperiod':  
          if (station.To9AM == undefined || station.To9AM <= 90) {
            className = ' ml-na ml-lowavailability';
          }
          break;
        default:
        // do nothing
      }

    }
    return className;
  }

  changeMarkerDisplay(metric: string) {

    if (this.weatherStations !== undefined) {
      for (var index = 0; index < this.weatherStations.length; index++) {
        try {
          let station = this.weatherStations[index];

          let m = this.markerCollection.find(f => f.stationCode == station.Code);

          if (this.markerCollection.find(f => f.stationCode == station.Code) !== undefined) {
            m = m.marker;
          
            let metricSetting = this.getMetricStyle(station.Code, m.options.icon.stationOwner, metric);

            let myIcon = <StationDivMarker>L.divIcon({
              className: metricSetting.metricClass,
              html: metricSetting.metricHtml
            });

            myIcon.stationCode = station.Code;
            myIcon.stationId = m.options.icon.stationId;
            myIcon.stationOwner = m.options.icon.stationOwner;
            myIcon.stationName = station.Name;

            m.setIcon(myIcon);
          } else {
            this.loggingService.logToConsole(station.Code + ' ' + station.Name + ' Not in markerCollection.');
          }
        }
        catch (err) {
          
          this.loggingService.logToConsole('Unable to change marker display => ' + err);
        }

      } //for
    } else {
      this.loggingService.logToConsole("weather stations currently undefined");
    }

  }


  getMetricValue(stationCode: string, property: string) {

    if (property.substr(0, 15) == "RainFallMetrics") {   // For RAIN properties currently use seperate object TODO:// will have a global object.

      var data = this.weatherStationsRainFall.find(s => s.Code === stationCode);

      if (data == undefined) {
        this.loggingService.logToConsole("Station not found metric value not assigned.");
        return METRIC_UNKNOWN_VALUE;
      }

      // property is found within the RainFallMetrics object.
      property = property.replace("RainFallMetrics.", "");

      return (data.RainFallMetrics[property] !== undefined && data.RainFallMetrics[property] !== null) ? data.RainFallMetrics[property] : METRIC_UNKNOWN_VALUE;

    }
    else if (property.substr(0, 5) === "WIND_") { // WIND information was moved into array collection for API
      var station = this.weatherStationsLatest.find(s => s.Code === stationCode);

      if (station == undefined) {
        this.loggingService.logToConsole("Station not found metric value not assigned. " + stationCode);
        return METRIC_UNKNOWN_VALUE;
      }

      if (station.WindProbes !== undefined && station.WindProbes.length > 0) {
        if (property == "WIND_DIRECTION") {
          return station.WindProbes[0].AvgDirectionCompassPoint;
        }
        if (property == "WIND_SPEED_KMH") {
          return station.WindProbes[0].AvgSpeed;
        }
      } else {
        return METRIC_UNKNOWN_VALUE;
      }

    }
    else {
      var station = this.weatherStationsLatest.find(s => s.Code === stationCode);

      if (station == undefined) {
        this.loggingService.logToConsole("Station not found metric value not assigned. " + stationCode);
        return METRIC_UNKNOWN_VALUE;
      }


      // convention to get calculated properties
      if (property.substr(0, 3) === "get") {
        return station[property]();
      }

      let value = null;

      if (station[property] !== undefined) { // Stations like BOM dont have all the sensors so we will return NA.
        value = station[property];
      }

      // Return NA with the properties here as if its NULL its down to that station not providing
      // the metric requested.
      return value !== null ? value : METRIC_UNKNOWN_VALUE;
    }

  }

  setLastMetric() {

    if (this.stateService.mapSettings.metricSelected !== null && !(this.isEmpty(this.stateService.mapSettings.metricSelected))) {
      this.selectedWeatherVariable = this.stateService.mapSettings.metricSelected.key;

      //todo: need the dates for RAIN_WDR so we keep this feature
      if (this.selectedWeatherVariable == "RAIN_WDR") {
        this.selectedWeatherVariable = "RainFallMetrics.Period";
      }
      //   this.onMetricChanged("RainFallMetrics.Last7Days");
      // } else {
      this.onMetricChanged(this.stateService.mapSettings.metricSelected.key); // make sure pass metric key
      // }


    }
  }


  /**
   * Determine if a poinst is within the RADAR locations
   * TODO:// Improve function currently loop through and check within rectangle
   * possibly create a polygone that represents the area and use that instead.
   * @param marker
   */
  isMarkerInsideRadar(marker) {

    let x = marker.getLatLng().lat, y = marker.getLatLng().lng;
    let LatLongPosition = L.latLng(x, y);


    var inside = false;
    for (var index = 0; index < this.availableRadars.length; index++) {
      let radar = new L.Rectangle(this.availableRadars[index].bounds);

      if (radar.getBounds().contains(LatLongPosition)) {
        inside = true;
      }
    }

    return inside;
  }



  // When searching the search event is fired when matched and we can trigger the nomal marker click.
  onStationSearchSelected(e) {

    let m = this.markerCollection.find(f => f.stationCode == e);
    m.marker.fire("click");

    // centre station for one that was searched
    // depending on large device offset
    let offsetCenter = 0;
    if (this.screenWidth >= 1024) {
      offsetCenter = 300;
    }

    this.recentreLeafletMap(new L.LatLng(m.lat, m.lng), offsetCenter, 0);

    // Delay with render so have to re-open the popup when navigating if its not open.
    // ideally would have done this on a event when completed but eveything seems to run before
    // everything is rendered.
    setTimeout(function () { if (!m.marker.isPopupOpen()) { m.marker.openPopup() }; }, 1000);
  }


  // Rainfall within date range calendar event.
  onDateSelection(event) {

    let startDateTime = moment(event[0]).format('YYYY-MM-DD'); //event[0].toISOString().substr(0,10);
    let endDateTime = moment(event[1]).format('YYYY-MM-DD');  //event[1].toISOString().substr(0,10);

    this.datePickerDefaultDates = [event[0], event[1]];
    this.stateService.stateSettings.rainfallWDRDates = this.datePickerDefaultDates;

    this.dataCacheService.getStationRainfallDateRange(startDateTime, endDateTime)
      .subscribe(
        data => { this.weatherStationsRainFall = data },
        err => { this.loggingService.logToConsole(err) },
        () => this.onCompletedGetStationRainFallByDateRange());
  }

  /**
   * Method called after resposne from REST service for given date ranges for rainfall
   */
  onCompletedGetStationRainFallByDateRange() {
    this.changeMarkerDisplay("RainFallMetrics.Period");
    this.appendBoMToRainfall(); // Object woul dhave been cleared from Date selection
  }



  initWeatherStationView() {

    const _self = this;

    if (this.mapSettings.layers.weatherStations !== null) {
      this.mapSettings.layers.weatherStations.forEach(
        function(item) {
          _self.setWeatherStationView(item.groupId);
        }
      )
    }

    // Overlay for Marker Selection
    this.setOverlaySelectionLayer();
  }


  setOverlaySelectionLayer() {
    const overlayLayer = this.mapSettings.layers.overlaySelectionLayer;
    if (overlayLayer !== null) {
      this.leafletMap.addLayer(overlayLayer);
    }
  }

  setWeatherStationView(groupId: string) {

    // console.log('setWeatherStationsView :' + groupId);
    if (this.mapSettings.layers.weatherStations !== null) {

      const view = this.mapSettings.layers.weatherStations.find(g => g.groupId == groupId);

      // console.log('stationVisible : ' + view.isStationsVisible);

      if (view !== undefined) {
        if (view.isStationsVisible) {
          if (view.isClustered) {

            if (this.leafletMap.hasLayer(view.featureGroup)) {
              this.leafletMap.removeLayer(view.featureGroup);
            };

            if (!this.leafletMap.hasLayer(view.clusterGroup)) {
              this.leafletMap.addLayer(view.clusterGroup);
            }

          } else {

            this.leafletMap.addLayer(view.featureGroup);
            this.leafletMap.removeLayer(view.clusterGroup);
          }
        } else { // Remove the layers

          if (this.leafletMap.hasLayer(view.featureGroup)) {
            this.leafletMap.removeLayer(view.featureGroup);
          }

          if (this.leafletMap.hasLayer(view.clusterGroup)) {
            this.leafletMap.removeLayer(view.clusterGroup);
          }

          // Turned off so default the grouping to off
          view.isClustered = false;
        }

     }

    }


  }



  setWeatherStationGrouping(groupId: string) {

    const view = this.mapSettings.layers.weatherStations.find(g => g.groupId == groupId.toLowerCase());

    if (view.isStationsVisible) {

      if (view.isClustered) {
        this.leafletMap.removeLayer(view.featureGroup);
        this.leafletMap.addLayer(view.clusterGroup);
      } else {
        this.leafletMap.removeLayer(view.clusterGroup);
        this.leafletMap.addLayer(view.featureGroup);
      }
    }
  }


  toggleMapLayer = function (layer) {

    switch (layer) {

      case 'OPEN_LAYERS':
        this.leafletMap.addLayer(this.openLayers);
        this.mapSettings.layers.openLayers.isChecked = !this.mapSettings.layers.openLayers.isChecked;

        this.mapSettings.layers.googleSatellite.isChecked = false;
        this.mapSettings.layers.googleStreets.isChecked = false;
        this.mapSettings.layers.googleTerrain.isChecked = false;
        this.mapSettings.layers.mapboxGrey.isChecked = false;
        this.mapSettings.layers.googleHybrid.isChecked = false;

        this.leafletMap.removeLayer(this.grayscale);
        this.leafletMap.removeLayer(this.googleSat);
        this.leafletMap.removeLayer(this.googleStreets);
        this.leafletMap.removeLayer(this.googleTerrain);
        this.leafletMap.removeLayer(this.googleHybrid);


        this.mapSettings.layers.openLayers.isChecked ? this.leafletMap.addLayer(this.openLayers) : this.leafletMap.removeLayer(this.openLayers);
        this.googleAnalyticsService.emitEvent('MapLayer', 'Selected', 'OPEN_LAYERS');

        this.mapSettings.currentMapLayer = 'OPEN_LAYERS';

        break;
      case 'GOOGLE_HYBRID':

        this.leafletMap.addLayer(this.googleHybrid);
        this.mapSettings.layers.googleHybrid.isChecked = !this.mapSettings.layers.googleHybrid.isChecked;
        this.mapSettings.layers.googleSatellite.isChecked = false;
        this.mapSettings.layers.googleStreets.isChecked = false;
        this.mapSettings.layers.googleTerrain.isChecked = false;
        this.mapSettings.layers.mapboxGrey.isChecked = false;
        this.mapSettings.layers.openLayers.isChecked = false;

        this.leafletMap.removeLayer(this.grayscale);
        this.leafletMap.removeLayer(this.googleSat);
        this.leafletMap.removeLayer(this.googleStreets);
        this.leafletMap.removeLayer(this.googleTerrain);
        this.leafletMap.removeLayer(this.openLayers);

        this.mapSettings.layers.googleHybrid.isChecked ? this.leafletMap.addLayer(this.googleHybrid) : this.leafletMap.removeLayer(this.googleHybrid);
        this.googleAnalyticsService.emitEvent("MapLayer", "Selected", "GOOGLE_HYBRID");

        this.mapSettings.currentMapLayer = "GOOGLE_HYBRID";

        break;

      case 'GOOGLE_SATELLITE':

        this.mapSettings.layers.googleHybrid.isChecked = false;
        this.mapSettings.layers.googleSatellite.isChecked = !this.mapSettings.layers.googleSatellite.isChecked;
        this.mapSettings.layers.googleStreets.isChecked = false;
        this.mapSettings.layers.googleTerrain.isChecked = false;
        this.mapSettings.layers.mapboxGrey.isChecked = false;
        this.mapSettings.layers.openLayers.isChecked = false;

        this.leafletMap.removeLayer(this.googleHybrid);
        this.leafletMap.removeLayer(this.grayscale);
        this.leafletMap.removeLayer(this.googleStreets);
        this.leafletMap.removeLayer(this.googleTerrain);
        this.leafletMap.removeLayer(this.openLayers);

        this.mapSettings.layers.googleSatellite.isChecked ? this.leafletMap.addLayer(this.googleSat) : this.leafletMap.removeLayer(this.googleSat);
        this.googleAnalyticsService.emitEvent("MapLayer", "Selected", "GOOGLE_SATELLITE");

        this.mapSettings.currentMapLayer = "GOOGLE_SATELLITE";
        break;

      case 'GOOGLE_STREETS':

        this.mapSettings.layers.googleHybrid.isChecked = false;
        this.mapSettings.layers.googleSatellite.isChecked = false;
        this.mapSettings.layers.googleStreets.isChecked = !this.mapSettings.layers.googleStreets.isChecked;
        this.mapSettings.layers.googleTerrain.isChecked = false;
        this.mapSettings.layers.mapboxGrey.isChecked = false;
        this.mapSettings.layers.openLayers.isChecked = false;

        this.leafletMap.removeLayer(this.googleHybrid);
        this.leafletMap.removeLayer(this.googleSat);
        this.leafletMap.removeLayer(this.grayscale);
        this.leafletMap.removeLayer(this.googleTerrain);
        this.leafletMap.removeLayer(this.openLayers);


        this.mapSettings.layers.googleStreets.isChecked ? this.leafletMap.addLayer(this.googleStreets) : this.leafletMap.removeLayer(this.googleStreets);
        this.googleAnalyticsService.emitEvent("MapLayer", "Selected", "GOOGLE_STREETS");

        this.mapSettings.currentMapLayer = "GOOGLE_STREETS";

        break;

      case 'GOOGLE_TERRAIN':

        this.mapSettings.layers.googleHybrid.isChecked = false;
        this.mapSettings.layers.googleSatellite.isChecked = false;
        this.mapSettings.layers.googleStreets.isChecked = false;
        this.mapSettings.layers.googleTerrain.isChecked = !this.mapSettings.layers.googleTerrain.isChecked;
        this.mapSettings.layers.mapboxGrey.isChecked = false;
        this.mapSettings.layers.openLayers.isChecked = false;

        this.leafletMap.removeLayer(this.googleHybrid);
        this.leafletMap.removeLayer(this.googleSat);
        this.leafletMap.removeLayer(this.grayscale);
        this.leafletMap.removeLayer(this.googleStreets);
        this.leafletMap.removeLayer(this.openLayers);


        this.mapSettings.layers.googleTerrain.isChecked ? this.leafletMap.addLayer(this.googleTerrain) : this.leafletMap.removeLayer(this.googleTerrain);
        this.googleAnalyticsService.emitEvent("MapLayer", "Selected", "GOOGLE_TERRAIN");

        this.mapSettings.currentMapLayer = "GOOGLE_TERRAIN";

        break;

      case 'MAPBOX_GREY':

        this.mapSettings.layers.googleHybrid.isChecked = false;
        this.mapSettings.layers.googleSatellite.isChecked = false;
        this.mapSettings.layers.googleStreets.isChecked = false;
        this.mapSettings.layers.googleTerrain.isChecked = false;
        this.mapSettings.layers.mapboxGrey.isChecked = !this.mapSettings.layers.mapboxGrey.isChecked;
        this.mapSettings.layers.openLayers.isChecked = false;

        this.leafletMap.removeLayer(this.googleHybrid);
        this.leafletMap.removeLayer(this.googleSat);
        this.leafletMap.removeLayer(this.googleStreets);
        this.leafletMap.removeLayer(this.googleTerrain);
        this.leafletMap.removeLayer(this.openLayers);

        this.setMapBoxTheme();

        this.mapSettings.layers.mapboxGrey.isChecked ? this.leafletMap.addLayer(this.grayscale) : this.leafletMap.removeLayer(this.grayscale);
        this.googleAnalyticsService.emitEvent("MapLayer", "Selected", "MAPBOX_GREY");

        this.mapSettings.currentMapLayer = "MAPBOX_GREY";

        break;

      default:
        break;

    }

    // store the current default in state service
    this.stateService.mapSettings.currentMapLayer = layer;

  }

  setMapBoxTheme() {
    this.grayscale = L.tileLayer(this.mbUrl, {
      id: this.stateService.stateSettings.theme == 'dark-gotham' ? 'mapbox.dark' : 'mapbox.light',
      attribution: this.mbAttr
    });
  }


  setRadarLoopTime() {

    //var coeff = 1000 * 60 * 6;
    var date = new Date();
    (date as any).addHours(-RADAR_PERIOD_WINDOW_HRS);

    var initialRadarDate = new Date(Math.round(date.getTime() / RADAR_COEFF) * RADAR_COEFF);


    let now = new Date(initialRadarDate.getTime());
    this.utcRadarImageDateTime = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    this.localRadarImageDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());

    this.UTCImageDateTime = new Date(this.utcRadarImageDateTime);

    this.currentRadarDateTime = this.localRadarImageDateTime;

 
    this.incrementRadarImage();

  }

  toggleRadarImagery(radarCode: string) {

    let radar = this.availableRadars.find(r => r.id === radarCode);




    if (radar.isChecked) {

      if (this.leafletMap.hasLayer(radar.layer)) {
        this.leafletMap.removeLayer(radar.layer);
      }

      radar.layer = new L.ImageOverlay(this.getImage(radarCode.substring(0, 5), this.utcRadarImageDateTime.formatRadarString(), this.utcRadarImageDateTime).src, radar.bounds, { interactive: false });
      this.leafletMap.addLayer(radar.layer);

      radar.layer.bringToFront();

    } else {
      if (this.leafletMap.hasLayer(radar.layer)) {
        this.leafletMap.removeLayer(radar.layer);
      }
    }


    // radar loop show if there are radars available
    this.activeRadarCount = this.availableRadars.filter(r => r.isChecked === true).length;

    if (this.activeRadarCount > 0) {
      this.radarSliderPlaying = true;
    } else {
      this.radarSliderPlaying = false;
    }
    //end loop setting

    this.incrementRadarImage();

  }

  setRadarImageInterval() {

    if (this.isRadarImageLoopRunning === false) {
      this.radarInterval = setInterval(() => {
        this.incrementRadarImage();
      }, RADAR_LOOP_SPEED);
    }

    this.isRadarImageLoopRunning = true;
  }

  clearRadarInterval() {
    this.isRadarImageLoopRunning = false; // no radar images selected
    clearInterval(this.radarInterval);
  }

  /**
   * Increment the Model value that is bound to the slider and call the updateimagery routine
   * so that changes are shown. The slider event value ofr valueChange doesnt get fired
   * unless the slider is changed in the UI
   */
  incrementRadarImage() {

    if (this.radarSliderPlaying) {
      let radars = this.availableRadars.filter(r => r.isChecked === true);
      if (radars.length > 0) {
        //  this.setRadarImageInterval(); // Set the interval
        //console.log('Radar Counter:' + this.radarImageMinuteCounter);
        if (this.radarImageMinuteCounter >= ((RADAR_PERIOD_WINDOW_HRS * 60) - RADAR_IMAGE_LATENCY)) {    // deduct latency of images arriving
          this.radarImageMinuteCounter = 0;
        } else {
          this.radarImageMinuteCounter += 6;
        }

        this.updateRadarImagery();
      } else {
        this.radarImageMinuteCounter = 0;
        //this.updateRadarImagery();
        this.clearRadarInterval();
      }
    }
  }


  getImage(radarCode, imageName, radar_image_date) {
    let verTimestamp = new Date().getTime();
    let index = radarCode + imageName;
    let imagePath = radar_image_date.getImagePath();

    if (this.ImageCache[index] == undefined) {
      this.ImageCache[index] = new Image();
      let imageURL = environment.RADAR_IMAGE_URL + imagePath + '/' + radarCode + '/' + radarCode + '3.T.' + radar_image_date.formatRadarString() + '.png?ver=' + verTimestamp;
      if (this.imageExist(imageURL)) {
        this.ImageCache[index].src = imageURL;
      } else {
        this.ImageCache[index].src = '/assets/images/radar-not-available.png';
        this.incrementRadarImageFaultCounter(radarCode);
        //this.loggingService.logToConsole('Radar Image Not Available : ' + imageURL);

      }
    }

    return this.ImageCache[index];

  }

  incrementRadarImageFaultCounter(radarCode) {
    radarCode = radarCode + 'AR';
    var radar = this.availableRadars.find(r => r.id === radarCode);

    if (radar !== null && radar.isOnline) {
      if (radar.ImageErrorCount > 2) {
        radar.toolTipMessage = 'The RADAR is currently missing images';
      }
      radar.ImageErrorCount += 1;
    }
  }

  imageExist(imageURL) {
    const http = new XMLHttpRequest();

    try {
      http.open('HEAD', imageURL, false);
      http.send();
    }
    catch (e) {
      return false;
    }

    return http.status != 404;
  }


  setViewBasedOnRadars() {

    let radar = this.availableRadars.find(r => r.isChecked == true);
    if (radar !== undefined && radar !== null) {
      let layers = [];


      for (let index = 0; index < this.availableRadars.length; index++) {
        if (this.availableRadars[index].isChecked == true) {
          layers.push(this.availableRadars[index].layer);
        }
      }

      let group = L.featureGroup(layers);

      this.leafletMap.fitBounds(group.getBounds());

    }
  }


  toggleRadarBounds = function (radar) {
    this.setRadarLayer(radar);
    this.positionRadarMapView();
  }


  setRadarLayer(radar) {


    if (radar !== undefined && radar !== null) {

      // Determine if we are adding or removing
      if (radar.isChecked === true) {
        if (radar.mapRectangleLayer == undefined) {
          radar.mapRectangleLayer = L.rectangle(radar.bounds, RADAR_RECTANGLE_SETTINGS);
        }

        if (radar.offlineReason !== null && radar.offlineReason !== '' && radar.isOnline == false) {
          radar.toolTipMessage = radar.offlineReason;
          // radar.mapRectangleLayer.bindTooltip(radar.offlineReason, { permanent: true});
        }

        if (!this.leafletMap.hasLayer(radar.mapRectangleLayer)) {
          radar.mapRectangleLayer.addTo(this.leafletMap);
        }

        //Log Radar analytics
        this.googleAnalyticsService.emitEvent("Radar", "Selected", radar.name + ' - ' + radar.id);

      } else {
        if (this.leafletMap.hasLayer(radar.mapRectangleLayer)) {
          this.leafletMap.removeLayer(radar.mapRectangleLayer);
        }
      }
      this.toggleRadarImagery(radar.id);
    }
  }


  positionRadarMapView() {
    const self = this;
    setTimeout(function () { self.setViewBasedOnRadars(); }, 500); // Leaflet chuggy so try to add some delay
  }


  toggleAllRadarBounds = function (allActive) {

    for (var index in this.availableRadars) {
      let radar = this.availableRadars[index];
      //if (radar.isEnabled) {
      radar.isChecked = allActive;
      this.setRadarLayer(radar);
      // }
    }

    this.setViewBasedOnRadars();

    this.stateService.mapSettings.isAllRadarsChecked = allActive;

    this.googleAnalyticsService.emitEvent("Radar", "Selected", "All Checked");
  }


  isRadarDisabled(radarCode) {
    //todo: fix this code
    if (this.availableRadars !== undefined) {
      return !this.availableRadars.find(r => r.id === radarCode).isOnline;
    }

    return false;
  }


  initRadarRectangle() {

    for (var index in this.availableRadars) {
      let radar = this.availableRadars[index];
      if (radar.isChecked) {
        radar.mapRectangleLayer = L.rectangle(radar.bounds, RADAR_RECTANGLE_SETTINGS).addTo(this.leafletMap);
      }
    }
  }




  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }



  setSliderLastHour() {
    //var coeff = 1000 * 60 * 6;
    var date = new Date();
    date.addHours(-RADAR_PERIOD_WINDOW_HRS);

    let radar_date_init = new Date(Math.round(date.getTime() / RADAR_COEFF) * RADAR_COEFF);

    let end_date = new Date(radar_date_init)
    end_date.addHours(RADAR_PERIOD_WINDOW_HRS);

    var diff = Math.abs((end_date as any) - (radar_date_init as any));
    var minutes = Math.floor((diff / 1000) / 60);

    this.radarImageMinuteCounter = 0;

    this.sliderOptions.ceil = (minutes - RADAR_SLIDING_WINDOW); // remove the last X minutes as we would not have the latest image.
    //console.log('ceiling:' + (minutes - RADAR_SLIDING_WINDOW));
  }



  updateRadarImagery() {
 
    //Only run routine if there is an image overlay for the radar enables
    let radars = this.availableRadars.filter(r => r.isChecked === true);


    if (radars.length > 0) {
      //console.log('counter : ' + this.radarImageMinuteCounter);

      this.UTCImageDateTime = new Date(this.utcRadarImageDateTime).addMinutes(this.radarImageMinuteCounter);
      this.currentRadarDateTime = new Date(this.localRadarImageDateTime).addMinutes(this.radarImageMinuteCounter); //new Date().toLocaleTimeString();

      this.setRadarImageInterval(); // Set the interval

      // Add 6 Minutes as the images are based on every 6 minutes
      let self = this;
      radars.forEach(function (radar) {

        if (self.leafletMap.hasLayer(radar.layer)) {
          self.leafletMap.removeLayer(radar.layer);
        }

        //toggle the stroke based on the image being available
        let radarImageSrc = self.getImage(radar.id.substring(0, 5), self.UTCImageDateTime.formatRadarString(), self.UTCImageDateTime).src;

        radar.layer = new L.ImageOverlay(radarImageSrc, radar.bounds, { interactive: false });

        try {
          self.leafletMap.addLayer(radar.layer);
        } catch (err) {
          //TODO: getPane in leaflet has issue need to work out why its undefined 
        }

      });

      this.UTCImageDateTime = new Date(this.utcRadarImageDateTime).addMinutes(this.radarImageMinuteCounter);
      this.currentRadarDateTime = new Date(this.localRadarImageDateTime).addMinutes(this.radarImageMinuteCounter); //new Date().toLocaleTimeString();
    }
  }


  /**
   * Slider Change event
   * https://stackblitz.com/edit/ng5-slider-issue-69?file=src%2Fapp%2Fapp.component.html
   * only gets called if you interact directly with slider the event should be called when the model updates
   * but doesnt
   */
  onSliderValueChange(changeContext: ChangeContext): void {
    //this.loggingService.logToConsole("onSliderValueChange..." + this.radarImageMinuteCounter);
    this.updateRadarImagery();
  }


  toggleRadarSlidePlaying() {
    this.radarSliderPlaying = !this.radarSliderPlaying;
    return false;
  }



  /*
   * Event when a table overlay is made active as this will be shown on-top
   * of the map page.
   */
  onActiveOverTableChanged(table: any) {
    //for now there is only one table
    this.mapSettings.isSummaryMetricTable = !this.mapSettings.isSummaryMetricTable;
  }




} //MapComponent





class StationDivMarker extends L.DivIcon {
  stationCode: string;
  stationId: string;
  stationName: string;
  stationOwner: string;

}


class StationMarker extends L.CircleMarker {
  stationCode: string;
  stationName: string;
}
