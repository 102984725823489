import { StationSummary } from '../services/weather/models/station-summary.model';

import { WindProbeModel } from './wind-probe.model';
import { StationPeriodModel } from './station-period.model';
import { AggregatedMetricsModel } from './aggregated-metrics.model';
import { EvapoTranspirationModel } from './evapo-transpiration.model';

import * as moment from 'moment';

export class StationSummaryModel extends StationSummary {

  constructor(stationSummmary: StationSummary){
    super();

    if (stationSummmary !== undefined && stationSummmary !== null) {
      this.AirTemperature = new AggregatedMetricsModel(stationSummmary.AirTemperature)  ;
      this.ApparentAirTemperature = stationSummmary.ApparentAirTemperature  ;
      this.BarometricPressure = new AggregatedMetricsModel(stationSummmary.BarometricPressure)  ;
      this.ChillHours = stationSummmary.ChillHours;
      this.DeltaT = new AggregatedMetricsModel(stationSummmary.DeltaT);
      this.DewPoint = new AggregatedMetricsModel(stationSummmary.DewPoint);
      this.ErrorCount = stationSummmary.ErrorCount;
      this.Evapotranspiration = stationSummmary.Evapotranspiration;
      this.FrostCondMinutes = stationSummmary.FrostCondMinutes;
      this.FrostCondStartTime = stationSummmary.FrostCondStartTime;
      this.HeadCondStartTime = stationSummmary.HeadCondStartTime;
      this.HeatCondMinutes = stationSummmary.HeatCondMinutes;
      this.MinBatteryVoltage = stationSummmary.MinBatteryVoltage;
      this.MinBatteryVoltageDateTime = stationSummmary.MinBatteryVoltageDateTime;
     // this.Observations = stationSummmary.Observations;
      this.PanEvaporation = stationSummmary.PanEvaporation;
      this.PanEvaporation12AM = stationSummmary.PanEvaporation12AM;
      this.Period = stationSummmary.Period;
      this.RainDays = stationSummmary.RainDays;
      this.Rainfall = stationSummmary.Rainfall;
      this.RelativeHumidity = new AggregatedMetricsModel(stationSummmary.RelativeHumidity);
      this.RichardsonUnits = stationSummmary.RichardsonUnits;
      this.SoilTemperature = new AggregatedMetricsModel(stationSummmary.SoilTemperature);
      this.TotalSolarExposure = stationSummmary.TotalSolarExposure;
      this.TotalSolarExposureMJ = stationSummmary.TotalSolarExposureMJ;
      this.WetBulb = new AggregatedMetricsModel(stationSummmary.WetBulb);
      this.WindProbes = stationSummmary.WindProbes.map(windItem => new WindProbeModel(windItem))
    }

  }
  
  getWindProbes() {
    return this.WindProbes;
  }
  


}
