import { PrecipitationSummaryMetrics } from './precipitation-summary-metrics.model';

/**
 * Based on V2 API for RADAR
 * Documentation found at https://api.dpird.wa.gov.au/v2/radar/openapi
 */
export class PrecipitationSummary {

  public RadarCode: string;

  public Latitude: number;
  public Longitude: number;
  public DistanceMetres: number;

  public MonthlyRain: PrecipitationSummaryMetrics[];

   
  constructor(response?: any) {
 
    if (response !== undefined && response !== null) {
      this.RadarCode = response.radar.code;
      this.Latitude = response.radar.latitude;
      this.Longitude = response.radar.longitude;
      this.DistanceMetres = response.radar.distance;

      this.MonthlyRain = response.summaries.map(item => new PrecipitationSummaryMetrics(item));
      if (this.MonthlyRain !== null) {
        this.MonthlyRain = this.MonthlyRain.reverse(); //reverse the order
      }
    }
  }
}