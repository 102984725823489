import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';

import { WeatherChartService } from '../../../services/weather/weather.chart.service';
import { StateService } from '../../../services/state/state.service';

import * as moment from 'moment'; // Include the library have to include reference in angular CLI to js file

// Add additional modules required
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);


const HOUR_INTERVAL = 3600000;
const DAY_INTERVAL = 86400000;       // 24 * 3600 * 1000;
const MONTHLY_INTERVAL = 2628000000;
const YEARLY_INTERVAL = 31536000000; // 365 Days


const CUMULATIVE_COLOUR = '#F65102';
const MONTHLY_COLOR = '#199AD2';


@Component({
  selector: 'app-rain-fall-chart',
  templateUrl: './rain-fall-chart.component.html',
  styleUrls: ['./rain-fall-chart.component.scss']
})
export class RainFallChartComponent implements OnInit {


  themeDark = {
    backgroundColour: '#23252C',
    textColour: '#ffffff',
    seriesColour: '#ffffff',
    markerFillColour: '#000000'
  };

  themeLight = {
    backgroundColour: '#ffffff',
    textColour: '#000000',
    seriesColour: '#000000',
    markerFillColour: '#ffffff'
  };

  themeCurrent = this.themeLight;

  station = { code: '', name: '' };

  Highcharts = Highcharts;
  isDataLoaded: boolean = false;
  tickInterval = DAY_INTERVAL;

  chartOptions: any = {
    credits: {
      enabled: false
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },
    yAxis: [{ //--- Primary yAxis
      title: {
        text: 'Rainfall (mm)'
      }
    }, { //--- Secondary yAxis
      title: {
        text: 'Cumulative rainfall (mm)',

      },
      opposite: true
    }],
    series: [
      {
        yAxis: 0,
        name: 'Rainfall (mm)',
        data: []
      },
      {
        yAxis: 1,
        type: 'spline',
        name: 'Cumulative rainfall (mm)',
        data: []
      }
    ],
    chart: {
      zoomType: 'x',
      type: 'column'
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    exporting: {
      chartOptions: {
        credits: {
          enabled: true,
          text: 'Copyright Western Australia Agriculture ' + new Date().getFullYear(),
          href: 'https://www.agric.wa.gov.au'
        },
        chart: {
          events: {
            load: function () {
              this.renderer.image('https://weather.agric.wa.gov.au/assets/images/chart_dpird_logo.jpg', 10, 10, 160, 46).add();

            }
          }
        }
      }
    },
  };

  constructor(
    private weatherChartService: WeatherChartService,
    private stateService: StateService) { 

      // Set HighChart Options
      // https://api.highcharts.com/highcharts/lang.thousandsSep
      Highcharts.setOptions({
          lang: {
            thousandsSep: ','
          }
      });
    }

  ngOnInit() {

    this.themeCurrent = (this.stateService.stateSettings.theme === '' ? this.themeLight : this.themeDark);

    this.weatherChartService.onChartFilterParametersSet.subscribe(
      params => {
        this.onChartFilterParametersSet(params);
      });
  }

  setChartOptions(chartData, params) {

    let subtitleDateMask = 'DD-MM-YYYY';
    if (params.interval === 'HOURLY') {
      subtitleDateMask = 'DD-MM-YYYY hh:mma';
    }

    let chartSeries = [
      {
        yAxis: 0,
        data: chartData.rainfall,
        color: MONTHLY_COLOR
      },
      {
        yAxis: 1,
        type: 'spline',
        name: 'Cumulative rainfall (mm)',
        data: chartData.totalRainfall,
        color: this.themeCurrent.seriesColour,
        marker: {
          fillColor: this.themeCurrent.markerFillColour,
          lineColor: this.themeCurrent.seriesColour,
          lineWidth: 1
        },
      }
    ];

    let yAxisSeries = [{ //--- Primary yAxis
      title: {
        text: 'Rainfall (mm)'
      }
      },
      { //--- Secondary yAxis
        title: {
          text: 'Cumulative rainfall (mm)',
          type: 'spline',
          data: []
      },
      opposite: true
    }];

    if ( params.interval === 'YEARLY') {
      chartSeries.pop(); // Remove last element from the Array
      yAxisSeries.pop();
    }

    this.chartOptions = {
      title: {
        text: this.station.name + ' : Rainfall (' + params.interval[0].toUpperCase() + params.interval.toLowerCase().substring(1) + ')'
      },
      subtitle: {
        text: moment(params.event.value[0]).format(subtitleDateMask) + ' - ' + moment(params.event.value[1]).format(subtitleDateMask)
      },
      yAxis: yAxisSeries,
      xAxis: {
        tickInterval: this.tickInterval,
        type: 'datetime'
      },
      series: chartSeries
    };
  }


  onChartFilterParametersSet(params) {

    let stationCode = params.station.code;
    let interval = params.interval;
    let limit = 1000;
    let offset = 0;
    this.station = params.station;

    // Date parameters to be sent UTC datetime.
    // split routine filtering milliseconds.
    let startDateTime = moment(params.event.value[0]).format('YYYY-MM-DD HH:mm:ss');//params.event.value[0].toISOString().split('.')[0] + "Z";
    let endDateTime = moment(params.event.value[1]).format('YYYY-MM-DD HH:mm:ss');//params.event.value[1].toISOString().split('.')[0] + "Z";

    this.weatherChartService.getStationRainFallData(stationCode, interval, startDateTime, endDateTime, limit, offset).subscribe(data => {

      // Format data for series
      var chartData = { rainfall: [], totalRainfall: [] };
      let totalCumulativeRainfall = 0;
      // Foreach record returned
      for (let index in data) {
        totalCumulativeRainfall += Number(Math.round(data[index].rainfall * 1e2) / 1e2);
        let momentDate = moment(data[index].dateFrom);
        var tz_offset = (new Date().getTimezoneOffset() * -1) * 60 * 1000;
        var unix_time = momentDate.toDate().getTime() + tz_offset;

        chartData.rainfall.push(Array(unix_time, data[index].rainfall));
        chartData.totalRainfall.push(Array(unix_time, parseFloat(totalCumulativeRainfall.toFixed(1))));
      }

      switch (interval) {
        case 'HOURLY':
          this.tickInterval = HOUR_INTERVAL;
          break;
        case 'MONTHLY':
          this.tickInterval = MONTHLY_INTERVAL;
          break;
        case 'YEARLY':
          this.tickInterval = YEARLY_INTERVAL;
          break;
        default:
           this.tickInterval = DAY_INTERVAL;
          break;
      }

      this.setChartOptions(chartData, params);

    });

    this.isDataLoaded = true;
  }

}