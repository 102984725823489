import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { WeatherChartService } from '../../services/weather/weather.chart.service';
import * as moment from 'moment';

// Add additional modules required
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);


const HOUR_INTERVAL = 3600000;
const DAY_INTERVAL = 86400000;       // 24 * 3600 * 1000;
const YEARLY_INTERVAL = 31536000000; // 365 Days

@Component({
  selector: 'app-chart-windrose',
  templateUrl: './chart-windrose.component.html',
  styleUrls: ['./chart-windrose.component.scss']
})
export class ChartWindroseComponent implements OnInit {

  @Input() title: string = "";
  @Input() stationCode: string = "";

  // Template for storing the count of items falling inside the range
  // this is duplicated for each series of data to store the data
  // used as Javascipt doesn't have an associate array so using JSON objects
  // is easier and will be rendered in the correct format later for use with
  // HighCharts library.
  windDirectionTemplate = {
    "N": 0,
    "NNE": 0,
    "NE": 0,
    "ENE": 0,
    "E": 0,
    "ESE": 0,
    "SE": 0,
    "SSE": 0,
    "S": 0,
    "SSW": 0,
    "SW": 0,
    "WSW": 0,
    "W": 0,
    "WNW": 0,
    "NW": 0,
    "NNW": 0
  }

  // Initialise object to gather the charting data
  // each series will have a data initialised to the JSON template above
  // to create a copy you have to parse into a new object using stringify
  windRoseData = {
    '2': {
      'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
    },
    '2TO8': {
      'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
    },
    '8TO15': {
      'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
    },
    '15TO22': {
      'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
    },
    '22TO29': {
      'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
    },
    '29TO36': {
      'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
    },
    '36': {
      'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
    }
  }

  // station = { code: '', name: '' };          

  Highcharts = Highcharts;
  isDataLoaded: boolean = false;

  chartOptions = {
    //  credits: {
    //    enabled: true,
    //    text: 'Copyright Western Australia Agriculture ' + new Date().getFullYear(),
    //    href: 'https://www.agric.wa.gov.au'
    //  },
    title: {
      text: this.title
    },
    subtitle: {
      text: 'Wind - Last 4 hours'
    },
    chart: {
      polar: true,
      type: 'column'
    },
    pane: {
      size: '85%'
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      y: 100,
      layout: 'vertical'
    },
    xAxis: {
      tickmarkPlacement: 'on',
      type: 'category'
    },
    yAxis: {
      min: 0,
      endOnTick: false,
      showLastLabel: true,
      reversedStacks: false,
      title: {
        text: 'Frequency (%)'
      }
    },
    series: [{
      "data": [],
      "type": "column",
      "name": "< 2 km/h",
      "color": '#038AFF'
    },
    {
      "data": [],
      "type": "column",
      "name": "2 to 8 km/h",
      "color": '#008E8A'
    },
    {
      "data": [],
      "type": "column",
      "name": "8 to 15 km/h",
      "color": '#00D034'
    },
    {
      "data": [],
      "type": "column",
      "name": "15 to 22 km/h",
      "color": '#386A02'
    },
    {
      "data": [],
      "type": "column",
      "name": "22 to 29 km/h",
      "color": '#ffcc00'
    },
    {
      "data": [],
      "type": "column",
      "name": "29 to 36 km/h",
      "color": '#F48300'
    },
    {
      "data": [],
      "type": "column",
      "name": "> 36 km/h",
      "color": '#C02302'
    }],
    plotOptions: {
      series: {
        stacking: 'normal',
        shadow: false,
        groupPadding: 0,
        pointPlacement: 'on'
      }
    },
    exporting: {
      chartOptions: {
        credits: {
          enabled: true,
          text: 'Copyright Western Australia Agriculture ' + new Date().getFullYear(),
          href: 'https://www.agric.wa.gov.au'
        },
        chart: {
          events: {
            load: function () {
              this.renderer.image('https://weather.agric.wa.gov.au/assets/images/chart_dpird_logo.jpg', 10, 10, 200, 66).add();

            }
          }
        }
      }
    },
  }

  constructor(private weatherChartService: WeatherChartService) { }

  ngOnInit() {
    this.initChart();


  }

  initChart() {
    //let stationCode =  'SP' ; //params.station.code;
    let interval = 'MINUTE_DATA';//params.interval;
    let limit = 1000;
    let offset = 0;
    // this.station = {code: 'SP', name: 'test'}; //params.station;

    // Date parameters to be sent UTC datetime.
    // split routine filtering milliseconds.
    // let startDateTime =  moment(params.event.value[0]);
    // let endDateTime =   moment(params.event.value[1]);

    //let duration = moment.duration(endDateTime.diff(startDateTime)); // #258

    let startDateTime = moment().subtract(4, 'hours').format('YYYY-MM-DDTHH:mm:ss');//params.event.value[0].toISOString().split('.')[0] + "Z";
    let endDateTime = moment().format('YYYY-MM-DDTHH:mm:ss');//params.event.value[1].toISOString().split('.')[0] + "Z";

    // if (duration.asHours() < 7) {
    //   interval = "MINUTE_DATA";
    // }
    let params = { recordCount: 0 };
    let seriesIndex = '36';

    // Format data for series
    var chartData = {};

    this.weatherChartService.getStationWindData(this.stationCode, interval, startDateTime, endDateTime, limit, offset).subscribe(data => {

      this.resetWindRoseData();
      // Foreach record returned
      // tslint:disable-next-line: forin
      for (let index in data) {
        // Add the speed to the correct category
       var windValue = data[index].wind.avg.speed;
        // TODO: get more about the business rules for direction
        // compass direction is not shown at levels above hourly
        var direction = data[index].wind.avg.direction !== undefined ? data[index].wind.avg.direction.compassPoint : data[index].wind.max.direction.compassPoint;

        if (windValue !== null && direction !== null) {
          switch (true) {
            case (windValue < 2): //Less than 2
              seriesIndex = '2';
              break;
            case (windValue < 9): // Between 2 and 9
              seriesIndex = '2TO8';
              break;
            case (windValue < 16): // Between 8 - 15
              seriesIndex = '8TO15';
              break;
            case (windValue < 23): // Between 16 - 22
              seriesIndex = '15TO22';
              break;
            case (windValue < 30): // Between 23 - 29
              seriesIndex = '22TO29';
              break;
            case (windValue < 37): // Between 30 - 36
              seriesIndex = '29TO36';
              break;
            default: // 37 And Greater than
              seriesIndex = '36'; // Already defaulted but add to make clear.
              break;
          }

          // Increment the count for the series and direction
          this.windRoseData[seriesIndex]['data'][direction] += 1;
        }
      }

      // Store record count in params to work out percentage.
      //params['recordCount'] = data.length;
      // Set HighCharts with the new data.
      params.recordCount = data.length;

      this.setChartOptions(params);
    });

    this.isDataLoaded = true;
  }

  setChartOptions(params) {
    this.chartOptions = <any>{
      credits: {
        enabled: false
      },
      title: {
        text: this.title
      },
      //subtitle: {
      //  text: this.title
      //},
      series: [{
        "data": this.convertToChartData(this.windRoseData['2']['data'], params.recordCount),
        "type": "column",
        "name": "< 2 km/h"
      },
      {
        "data": this.convertToChartData(this.windRoseData['2TO8']['data'], params.recordCount),
        "type": "column",
        "name": "2 to 8 km/h"
      },
      {
        "data": this.convertToChartData(this.windRoseData['8TO15']['data'], params.recordCount),
        "type": "column",
        "name": "8 to 15 km/h"
      },
      {
        "data": this.convertToChartData(this.windRoseData['15TO22']['data'], params.recordCount),
        "type": "column",
        "name": "15 to 22 km/h"
      },
      {
        "data": this.convertToChartData(this.windRoseData['22TO29']['data'], params.recordCount),
        "type": "column",
        "name": "22 to 29 km/h"
      },
      {
        "data": this.convertToChartData(this.windRoseData['29TO36']['data'], params.recordCount),
        "type": "column",
        "name": "29 to 36 km/h"
      },
      {
        "data": this.convertToChartData(this.windRoseData['36']['data'], params.recordCount),
        "type": "column",
        "name": "> 36 km/h"
      }]
    }
  }




  resetWindRoseData() {
    this.windRoseData = {
      '2': {
        'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
      },
      '2TO8': {
        'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
      },
      '8TO15': {
        'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
      },
      '15TO22': {
        'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
      },
      '22TO29': {
        'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
      },
      '29TO36': {
        'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
      },
      '36': {
        'data': JSON.parse(JSON.stringify(this.windDirectionTemplate))
      }
    };
  }

  /**
   * Helper function to convert the JSON object into an associative array
   * that Highcharts can use to render the series.
   * @param data
   * @param recordCount 
   */
  convertToChartData(data, recordCount) {
    let arr = [];
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        // format to 2 decimal places 1e2 for two decimal places.
        arr.push([key.toString(), Math.round(data[key] / recordCount * 100 * 1e2) / 1e2]);
      }
    }
    return arr;
  }


  ngOnChanges(changes: SimpleChanges) {
    this.initChart();
  }


  chartCallback(chart) {

    //chart.renderer.image(
    //  '/assets/images/chart_dpird_logo.jpg', 10, 10, 200, 66)
    //  .add();
  }

}
