<app-secondary-header></app-secondary-header>

<div class="outer-wrapper">

    <div class="container top">
        <div class="row">


            <div class="content-page">

                <p>This version of the application does not require a User login , Version 2 will add this functionality which will then provide ;</p>
                <ul>
                    <li>Personalised dashboard, name, username, password, avatar email address.</li>
                    <li>allow location to be used</li>
                    <li>low bandwidth option </li>
                    <li>Push notifications email or sms - set with sliders</li>
                    <li>sign up for any email newsletters</li>
                    <li>customisation options</li>
                    <li>Favourites attached to a user profile</li>
                </ul>


                <p>Things to do Steve</p>
                <ul>
                    <li class="orange">Responsive styling</li>
                    <li class="green">about us page has example - mobile filter ui </li>
                    <li class="green">about us page has example - mobile controls </li>
                    <li class="red">map page - slide panel needs a div wrapped around station summary with overflow-y auto </li>
                    <li class="red">For fixed headers, a shadow on scroll should be added. </li>
                    <li class="red">text styles, style guide page </li>
                    <li class="red">design user account </li>
                </ul>

                <p>Things to do Neil</p>
                <ul>
                    <li class="red">map page search field. the 'clear text 'x' should show only when user starts typing</li>
                    <li class="orange">map page - clicking on specific weather variable (top right) brings up movable date range widget </li>
                    <li class="red">all stations table - when user makes station a favourite and has scroll a long way down page, the page should jump back to the top to see station in fav table.  </li>
                    <li class="red">all stations table - should be able to click on station name (row) and the drop down to dashboard links should be shown (like fav table).  </li>
                    <li class="red">Dashboard page - <1200  .mobile-filter-menu button onClick add 'Active' class to .weather data  </li>
                    <li class="red">Dashboard - extreme-weather block needs onClick #tab add active class to .tab-content to view tabs </li>
                    <li class="red"> add .active class to .user-dropdown in controls (top right) onClick the user link</li>
                </ul>



                <p>Mobile filter menu on about us page example</p>
                <ul>
                    <li class="red">onClick #filter-btn add class 'active'  to '.filter-menu-wrapper' and also #option1.filter-options</li>
                    <li class="red">need to target buttons inside the 3 options and also back and close buttons</li>
                </ul>



                <p>Wish list</p>
                <ul>
                    <li>payment settings  </li>
                </ul>



            </div>


        </div>
    </div>

</div>


