import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StateService } from '../../services/state/state.service';

@Component({
  selector: 'app-map-bottom-navbar',
  templateUrl: './map-bottom-navbar.component.html',
  styleUrls: ['./map-bottom-navbar.component.scss']
})
export class MapBottomNavbarComponent implements OnInit {

  @Output() onMetricChanged = new EventEmitter();

  public isActive: boolean = false;
  public PanelSettings: any;

  constructor(private stateService: StateService) { }


  ngOnInit() {
    this.PanelSettings = this.stateService.menuBottomPanel;
  }


  metricChanged(metric) {
    // Emit up to the MAP level from the metric-select component
    // Map will alter the values then correctly.
    this.onMetricChanged.emit(metric);
  }


  closeMenuBarPopUps() {
    this.isActive = false;
    const panels = Object.keys(this.PanelSettings);
    for (let i = 0 ; i < panels.length ; i++) {
        this.PanelSettings[panels[i]] = false;
    }
  }


  togglePopOvers(metricPanel: string) {

    this.PanelSettings[metricPanel] = !this.PanelSettings[metricPanel];
    this.isActive = this.PanelSettings[metricPanel];

    const panels = Object.keys(this.PanelSettings);

    for (let i = 0 ; i < panels.length ; i++) {
      if (panels[i] !== metricPanel) {
        this.PanelSettings[panels[i]] = false;
      }
    }

  }


  closePopOver(metricPanel: string) {
    this.PanelSettings[metricPanel] = false;
    this.isActive = false;
  }



}
