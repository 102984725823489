import { StateService } from '../../../services/state/state.service';
import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { CookieService } from 'ngx-cookie';
import { AppConstants } from '../../../app.constants';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-more-select',
  templateUrl: './more-select.component.html',
  styleUrls: ['./more-select.component.scss']
})
export class MoreSelectComponent implements OnInit {

  @Input() isActive: boolean;
  @Output() onTogglePopOver = new EventEmitter();

  public enableDarkTheme: boolean = false;
  public moreVisited: boolean = false;
  versionNumber: string;

  constructor(
    private stateService: StateService,
    private route: Router,
    private cookieService: CookieService,
    private appConstants: AppConstants
  ) { }

  ngOnInit() {
    this.enableDarkTheme = this.stateService.stateSettings.theme === 'dark-gotham' ? true : false;
    this.versionNumber = this.appConstants.APPLICATION_VERSION;
    this.checkMoreVisited();
  }


  toggleTheme() {
    // console.log(this.enableDarkTheme);
    if (!this.enableDarkTheme) {
      // turning True
      this.stateService.onThemeChange(true ? 'dark-gotham' : '');
    } else {
      this.stateService.onThemeChange(false ? 'dark-gotham' : '');
    }

    this.toggleIsActive();

  }

  toggleIsActive() {
    this.onTogglePopOver.emit('morePanelActive');
    this.setMoreVisited(); // Will set cookie if required
  }

  navigateToPage(state) {
    this.toggleIsActive();
    this.stateService.mapSettings.isStationTableActive = false;
    this.route.navigate([state]);
  }


  checkMoreVisited() {
    const cookieValue = this.cookieService.get('DPIRD_MORE_READ_FLAG');
    if (cookieValue !== undefined) { //Fact its defined means its true in this case :)
      this.moreVisited = true;
    }
  }


  setMoreVisited() {
    if (this.moreVisited === false) {
      const cookieDate = new Date();
      cookieDate.setTime(cookieDate.getTime() + 31540000000); // Year Plenty :)
      this.cookieService.put('DPIRD_MORE_READ_FLAG', 'TRUE', { expires: cookieDate.toUTCString() });
      this.moreVisited = true;
    }
  }

}
