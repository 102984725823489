import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { StateService } from '../services/state/state.service';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {

  constructor(public stateService: StateService) { }

  ngOnInit() {
    this.stateService.raiseApplicationReady();
  }

}
