export class WindProbe {

  public Height: number;
  public AvgSpeed: number;
  public AvgSpeedKnots: number;
  public AvgDirectionDegrees: number; // Field only available for Hourly and Minutes
  public AvgDirectionCompassPoint: string;
  

  //Max
  public MaxSpeed: number;
  public MaxTime: string;

  public MaxDirectionDegrees: number;
  public MaxCompassPoint: string;


  // Simpler to pass the raw response in and manage mapping in model
  // can easily extract business login into here then where certain fields
  // are not available depending on the interval used.
  constructor(windResponse?: any) {

 
    if (windResponse !== null && windResponse !== undefined) {
      this.Height = windResponse.height;
      this.AvgSpeed = windResponse.avg.speed !== null ? windResponse.avg.speed.toFixed(0) : windResponse.avg.speed;
      this.AvgSpeedKnots = Number.parseFloat((this.AvgSpeed / 1.825).toPrecision(2));

      if (windResponse.avg.direction !== undefined) { // only available for Hourly and Minutes
        this.AvgDirectionDegrees = windResponse.avg.direction.degrees !== undefined ? windResponse.avg.direction.degrees : null;
        this.AvgDirectionCompassPoint = windResponse.avg.direction.compassPoint !== undefined ? windResponse.avg.direction.compassPoint: null ;
      }

      if (windResponse.max !== undefined) {
        this.MaxSpeed = windResponse.max.speed !== null ? windResponse.max.speed.toFixed(0) : windResponse.max.speed;
        this.MaxDirectionDegrees = windResponse.max.direction !== undefined && windResponse.max.direction.degrees !== undefined ? windResponse.max.direction.degrees: null;
        this.MaxCompassPoint = windResponse.max.direction !== undefined && windResponse.max.direction.compassPoint !== undefined ? windResponse.max.direction.compassPoint : null;
        this.MaxTime = windResponse.max.time !== undefined ? windResponse.max.time : null ;
      }
    }

 
  }

}