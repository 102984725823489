import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { BoMStationModel } from './models/bom-station.model';


@Injectable()
export class GisToWebService {

  apiUrl = environment.GIS2WEB_APIURI;

  constructor(private http: HttpClient) {
  }

  getStations(): Observable<BoMStationModel[]> {


    return this.http.get<BoMStationModel[]>(this.apiUrl + 'IDZ20010_rainfall_24hr.json')
        .pipe(map(res => {

          if (res == null) return;
        return (<any>res).collection.map(item => {
          return new BoMStationModel(item);
        });
       

      }));

  }
}