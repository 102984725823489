import { Component, OnInit, ViewChild, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { StateService } from '../../services/state/state.service';


import * as L from 'leaflet';

@Component({
  selector: 'app-map-weather-variables-legend',
  templateUrl: './map-weather-variables-legend.component.html',
  styleUrls: ['./map-weather-variables-legend.component.scss']
})
export class MapWeatherVariablesLegendComponent implements OnInit {

  @ViewChild('mapLegend', {static: true}) mapLegend: HTMLDivElement;

  @Input() metricType: string = "";
  public metricClass: string = "";

  @Output() legendKeyClicked = new EventEmitter();
  @Output() onReset = new EventEmitter();

  selectedColour: string = "white";
  selectedIndex: any;
  // Preset legens and the settings to be shown
  legends: Object = {
    'AirTemperature': [ // #200  #297
      {label: '>= 50', class: 'ml-g50'},
      {label: '< 50', class: 'ml-l50'},
      {label: '< 45', class: 'ml-l45'},
      {label: '< 40', class: 'ml-l40'},
      {label: '< 35', class: 'ml-l35'},
      {label: '< 30', class: 'ml-l30'},
      {label: '< 25', class: 'ml-l25'},
      {label: '< 20', class: 'ml-l20'},
      {label: '< 15', class: 'ml-l15' },
      {label: '< 10', class: 'ml-l10' },
      {label: '< 5', class: 'ml-l5' },
      {label: '< 0', class: 'ml-l0' },
      {label: '< -5', class: 'ml-ln5'},
      {label: '< -10', class: 'ml-ln10'},
      {label: 'NA', class: 'ml-na'},
      ]
     ,
     'AirTemperatureMaxLast24Hrs': [
      {label: '>= 50', class: 'ml-g50'},
      {label: '< 50', class: 'ml-l50'},
      {label: '< 45', class: 'ml-l45'},
      {label: '< 40', class: 'ml-l40'},
      {label: '< 35', class: 'ml-l35'},
      {label: '< 30', class: 'ml-l30'},
      {label: '< 25', class: 'ml-l25'},
      {label: '< 20', class: 'ml-l20'},
      {label: '< 15', class: 'ml-l15' },
      {label: 'NA', class: 'ml-na'},
      ]
     ,
     'AirTemperatureMinLast24Hrs': [
      {label: '>= 20', class: 'ml-g20'},
      {label: '< 20', class: 'ml-l20'},
      {label: '< 10', class: 'ml-l10'},
      {label: '< 5', class: 'ml-l5'},
      {label: '< 4', class: 'ml-l4'},
      {label: '< 3', class: 'ml-l3'},
      {label: '< 2', class: 'ml-l2'},
      {label: '< 1', class: 'ml-l1'},
      {label: '< 0', class: 'ml-l0' },
      {label: '< -1', class: 'ml-ln1' },
      {label: '< -2', class: 'ml-ln2' },
      {label: '< -3', class: 'ml-ln3' },
      {label: '< -4', class: 'ml-ln4' },
      {label: '< -5', class: 'ml-ln5'},
      {label: 'NA', class: 'ml-na'},
      ]
     ,
    'RainFallMetrics.Since9AM':[ // #202
      {label: '>= 100', class: 'ml-g100'},
      {label: '< 100 ', class: 'ml-l100'},
      {label: '< 50', class: 'ml-l50'},
      {label: '< 25', class: 'ml-l25'},
      {label: '< 15', class: 'ml-l15'},
      {label: '< 10', class: 'ml-l10'},
      {label: '< 5', class: 'ml-l5'},
      {label: '< 1', class: 'ml-l1'},
      {label: 'NA', class: 'ml-na'}
     ],
    'RainFallMetrics.To9AM': [ // #202 //#395
      {label: '>= 100', class: 'ml-g100'},
      {label: '< 100', class: 'ml-l100'},
      {label: '< 50', class: 'ml-l50'},
      {label: '< 25', class: 'ml-l25'},
      {label: '< 15', class: 'ml-l15'},
      {label: '< 10', class: 'ml-l10'},
      {label: '< 5', class: 'ml-l5'},
      {label: '< 1', class: 'ml-l1'},
      {label: 'NA', class: 'ml-na'}
     ],
    'RainFallMetrics.Last7Days': [ // #202 //#395 
      {label: '>= 150', class: 'ml-g150'},
      {label: '< 150', class: 'ml-l150'},
      {label: '< 100 ', class: 'ml-l100'},
      {label: '< 50', class: 'ml-l50'},
      {label: '< 25', class: 'ml-l25'},
      {label: '< 15', class: 'ml-l15'},
      {label: '< 10', class: 'ml-l10'},
      {label: '< 5', class: 'ml-l5'},
      {label: 'NA', class: 'ml-na'}
     
     ],
    'RainFallMetrics.MonthToDate': [ // #202
      {label: '>= 400', class: 'ml-g400'},
       {label: '< 400', class: 'ml-l400'},
       {label: '< 300', class: 'ml-l300'},
       {label: '< 200', class: 'ml-l200'},
       {label: '< 150', class: 'ml-l150'},
       {label: '< 100 ', class: 'ml-l100'},
       {label: '< 50', class: 'ml-l50'},
       {label: '< 25', class: 'ml-l25'},
       {label: '< 15', class: 'ml-l15'},
       {label: '< 10', class: 'ml-l10'},
       {label: '< 5', class: 'ml-l5'},
       {label: '< 1', class: 'ml-l1'},
       {label: 'NA', class: 'ml-na'}
     ],
    'RainFallMetrics.YearToDate': [ // #202 // #392   //#395
       {label: '>= 2000', class: 'ml-g2000'},
       {label: '< 2000', class: 'ml-l2000'},
       {label: '< 1500', class: 'ml-l1500'},
       {label: '< 1000', class: 'ml-l1000'},
       {label: '< 750', class: 'ml-l750'},
       {label: '< 500 ', class: 'ml-l500'},
       {label: '< 400', class: 'ml-l400'},
       {label: '< 300', class: 'ml-l300'},
       {label: '< 200', class: 'ml-l200'},
       {label: '< 100', class: 'ml-l100'},
       {label: '< 50', class: 'ml-l50'},
       {label: '< 25', class: 'ml-l25'},
       {label: 'NA', class: 'ml-na'}
     ],
    'RainFallMetrics.Period': [ // #202  //@395
       {label: '>= 750', class: 'ml-g750'},
       {label: '< 750', class: 'ml-l750'},
       {label: '< 500', class: 'ml-l500'},
       {label: '< 400', class: 'ml-l400'},
       {label: '< 300', class: 'ml-l300'},
       {label: '< 200', class: 'ml-l200'},
       {label: '< 100 ', class: 'ml-l100'},
       {label: '< 50', class: 'ml-l50'},
       {label: '< 25', class: 'ml-l25'},
       {label: '< 15', class: 'ml-l15'},
       {label: '< 10', class: 'ml-l10'},
       {label: '< 5', class: 'ml-l5'},
       {label: 'NA', class: 'ml-na'}
     ],
    'RelativeHumidity': [
       {label: '100', class: 'ml-g100'},
       {label: '< 100', class: 'ml-l100'},
       {label: '< 90', class: 'ml-l90'},
       {label: '< 80', class: 'ml-l80'},
       {label: '< 70', class: 'ml-l70'},
       {label: '< 60', class: 'ml-l60' },
       {label: '< 50', class: 'ml-l50' },
       {label: '< 40', class: 'ml-l40' },
       {label: '< 30', class: 'ml-l30' },
       {label: '< 20', class: 'ml-l20'},
       {label: '< 10', class: 'ml-l10'},
       {label: 'NA', class: 'ml-na'}
       
    ],
    'WIND_SPEED_KMH': [
      {label: '> 70', class: 'ml-g70'},
      {label: '< 70', class: 'ml-l70'},
      {label: '< 60', class: 'ml-l60'},
      {label: '< 50', class: 'ml-l50'},
      {label: '< 40', class: 'ml-l40'},
      {label: '< 30', class: 'ml-l30'},
      {label: '< 20', class: 'ml-l20'},
      {label: '< 15', class: 'ml-l15'},
      {label: '< 10', class: 'ml-l10'},
      {label: '< 5', class: 'ml-l5' },
      {label: 'NA', class: 'ml-na'}
       
    ],
    'WIND_DIRECTION': [
       {label: '>= 70', class: 'ml-g70'},
       {label: '< 70', class: 'ml-l70'},
       {label: '< 60', class: 'ml-l60'},
       {label: '< 50', class: 'ml-l50'},
       {label: '< 40', class: 'ml-l40'},
       {label: '< 30', class: 'ml-l30'},
       {label: '< 20', class: 'ml-l20'},
       {label: '< 15', class: 'ml-l15'},
       {label: '< 10', class: 'ml-l10'},
       {label: '< 5', class: 'ml-l5' },
       {label: 'NA', class: 'ml-na'}
       
    ],
    'SoilTemperature': [ // #200  revisitied #297
      {label: '>= 50', class: 'ml-g50'},
      {label: '< 50', class: 'ml-l50'},
      {label: '< 45', class: 'ml-l45'},
      {label: '< 40', class: 'ml-l40'},
      {label: '< 35', class: 'ml-l35'},
      {label: '< 30', class: 'ml-l30'},
      {label: '< 25', class: 'ml-l25'},
      {label: '< 20', class: 'ml-l20'},
      {label: '< 15', class: 'ml-l15' },
      {label: '< 10', class: 'ml-l10' },
      {label: '< 5', class: 'ml-l5' },
      {label: '< 0', class: 'ml-l0' },
      {label: '< -5', class: 'ml-ln5'},
      {label: '< -10', class: 'ml-ln10'},
      {label: 'NA', class: 'ml-na'}
    ],
    'SolarExposure': [
       {label: '> 36', class: 'ml-g36'},
       {label: '< 36', class: 'ml-l36'},
       {label: '< 33', class: 'ml-l33'},
       {label: '< 30', class: 'ml-l30'},
       {label: '< 27', class: 'ml-l27'},
       {label: '< 24', class: 'ml-l24'},
       {label: '< 21 ', class: 'ml-l21'},
       {label: '< 18', class: 'ml-l18'},
       {label: '< 15', class: 'ml-l15'},
       {label: '< 12', class: 'ml-l12'},
       {label: '< 9', class: 'ml-l9'},
       {label: '< 6', class: 'ml-l6'},
       {label: '< 3', class: 'ml-l3'},
       {label: 'NA', class: 'ml-na'}
       
     ],
    'EtoShortCrop': [ // #200
       {label: '> 14', class: 'ml-g14'},
       {label: '< 14', class: 'ml-l14'},
       {label: '< 12 ', class: 'ml-l12'},
       {label: '< 10', class: 'ml-l10'},
       {label: '< 8', class: 'ml-l8'},
       {label: '< 6', class: 'ml-l6'},
       {label: '< 4', class: 'ml-l4'},
       {label: '< 2', class: 'ml-l2'},
       {label: '< 1', class: 'ml-l1'},
       {label: 'NA', class: 'ml-na'}
        
      ],
    'EtoTallCrop': [ // #202
       {label: '> 14', class: 'ml-g14'},
       {label: '< 14', class: 'ml-l14'},
       {label: '< 12 ', class: 'ml-l12'},
       {label: '< 10', class: 'ml-l10'},
       {label: '< 8', class: 'ml-l8'},
       {label: '< 6', class: 'ml-l6'},
       {label: '< 4', class: 'ml-l4'},
       {label: '< 2', class: 'ml-l2'},
       {label: '< 1', class: 'ml-l1'},
       {label: 'NA', class: 'ml-na'}
       
     ],
    'getLastUpdatedMinutes': [ // #206
       {label: '> 720', class: 'ml-g720'},
       {label: '< 720', class: 'ml-l720'},
       {label: '< 360', class: 'ml-l360'},
       {label: '< 180', class: 'ml-l180'},
       {label: '< 60', class: 'ml-l60'},
       {label: '< 30', class: 'ml-l30'},
       {label: '< 15', class: 'ml-l15'}
    ],
    'ErrorsToday': [ // #207
       {label: '> 500', class: 'ml-g500'},
       {label: '< 500', class: 'ml-l500'},
       {label: '< 20', class: 'ml-l20'},
       {label: '< 5', class: 'ml-l5'},
       {label: '< 1', class: 'ml-l1'}
    ],
    'ErrorsLast7Days': [ // #207
       {label: '> 500', class: 'ml-g500'},
       {label: '< 500', class: 'ml-l500'},
       {label: '< 20', class: 'ml-l20'},
       {label: '< 5', class: 'ml-l5'},
       {label: '< 1', class: 'ml-l1'}
    ],  
  }

  metricLegend: any;

  constructor(private stateService: StateService) { 

 
  }

  ngOnInit() {

    this.metricLegend = this.legends['AirTemperature'];

    // Stop bubbling up events as leaflet map wold detect the click
    let DivElement =   <HTMLDivElement>(this.mapLegend as any).nativeElement;
    L.DomEvent.on(DivElement, 'click', function (ev) {
      L.DomEvent.stopPropagation(ev);
    });

    L.DomEvent.on(DivElement, 'doubleclick', function (ev) {
      L.DomEvent.stopPropagation(ev);
    });

     
    this.metricLegend = this.legends[this.metricType];
    this.metricClass = this.metricType.replace('.','').toLowerCase();

    if (this.stateService.mapSettings.metricSelected !== null && !this.isEmpty(this.stateService.mapSettings.metricSelected)) {
      if (this.stateService.mapSettings.metricSelected.key == "RAIN_WDR") {
        this.metricType = "RainFallMetrics.Period";
      } else {
        this.metricType = this.stateService.mapSettings.metricSelected.key;
      }

      if (this.metricType !== undefined) {
        this.metricLegend = this.legends[this.metricType];
        this.metricClass = this.metricType.replace('.','').toLowerCase();
        this.onReset.emit();
      }
      
    }
    
    //this.metricLegend = this.legends[this.metricType];
    //this.metricClass = this.metricType.replace('.','').toLowerCase();
    
    this.selectedIndex = -1;
    

  }


  /**
   * Raise the emitter event passing in the legend key as the argument
   * this will be handled by the map component
   */
  raiseLegendKeyClicked(legendKey: any) {
    this.selectedColour = legendKey.colour;
    this.legendKeyClicked.emit(legendKey.class);

  }

  resetMarkersForLegend() {
    this.onReset.emit();
  }




  ngOnChanges(changes: SimpleChanges) {
    if (changes.metricType !== null && changes.metricType.currentValue !== changes.metricType.previousValue && this.metricType !== undefined) {
      this.metricType = changes.metricType.currentValue;
      this.metricLegend = this.legends[this.metricType];
      this.metricClass = this.metricType.replace('.','').toLowerCase();
      this.onReset.emit();
      this.selectedIndex = -1;
    }
  }

  isEmpty(obj) {
    for(var key in obj) {
      if(obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  
}
