<div class="tabular-data-container" [ngClass]="{'active' : active == true}">





  <div class="record-count">
    Records: {{totalRecords}}
  </div>
  <!-- Buttons to switch the data for the table --> 
  <div class="tabular-data-buttons">
    <button class="btn btn-primary" (click)="toggleTable('MINUTE')" [ngClass]="{'active': summaryType == 'MINUTE'}">Minute</button>
    <button class="btn btn-primary" (click)="toggleTable('HOURLY')" [ngClass]="{'active': summaryType == 'HOURLY'}">Hourly</button>
    <button class="btn btn-primary" (click)="toggleTable('DAILY')" [ngClass]="{'active': summaryType == 'DAILY'}">Daily</button>
    <button class="btn btn-primary" (click)="toggleTable('MONTHLY')" [ngClass]="{'active': summaryType == 'MONTHLY'}">Monthly</button>
    <button class="btn btn-primary" (click)="toggleTable('YEARLY')" [ngClass]="{'active': summaryType == 'YEARLY'}">Yearly</button>
    <button class="btn btn-primary" (click)="toggleTable('CHARTS')" [ngClass]="{'active': summaryType == 'CHARTS'}">Charts</button>
  </div>
  <!-- /Buttons to switch the data for the table --> 






  <!--[scrollable]="true" [scrollable]="true" scrollDirection="both"            [scrollHeight]="scrollHeight"-->
  <p-table #dt [columns]="selectedColumns"
           [value]="data"
           [resizableColumns]="true"  
           [scrollable]="true"
           responsiveLayout="scroll"
           selectionMode="multiple"
           sortOrder="descending" 
           sortMode="single"
           [(selection)]="selectedData"
           [reorderableColumns]="true"
           [lazy]="true" (onLazyLoad)="loadTabularDataLazy($event)"
           [paginator]="false"
           [rows]="limit"
           [totalRecords]="totalRecords"
           [loading]="loading"
           [exportFilename]="exportFilename"
           [dataKey]="dataKey" >

    <!--<div class="tabular-data-inside-container" #tabularContainer>-->
      <ng-template pTemplate="caption">
        <button type="button" class="close" aria-label="Close" (click)="closeTable('MINUTE')">
          <span aria-hidden="true"><span class="icon icon-cancel-button-icon"></span></span>
         </button>
        <h2 class="title" (click)="displayFilterMenu = false">{{summaryType}}<div class="small-station-name">{{stationName}}<span>({{stationCode}})</span></div></h2>
        <div class="toolbar-wrapper" [ngClass]="{'active' : displayFilterMenu == true}">
          <button (click)="toggleFilterMenu()" class="btn btn-primary table-filter-btn" [ngClass]="{'active' : displayFilterMenu == true}">Date range &amp; export <span class="icon icon-play"></span> </button>
            <div class="toolbar-wrapper-inside" [ngClass]="{'active' : displayFilterMenu == true}">

              <form class="form">
                <app-date-picker 
                  (onDateSelection)="onDateSelection($event)" 
                  [(defaultDates)]="defaultMomentDateRange"
                  [startView]="datePickerStartView"
                  [pickerType]="datePickerType"
                  [pickerMode]="dialog">
                </app-date-picker>
                <div class="divider"></div>
              </form>

             <div class="select-menu">
              <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                             selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}"
                             defaultLabel="Choose Columns" (onChange)="updateSelectedColumns($event)"></p-multiSelect>
            </div>
            <div class="ui-helper-wrapper">
              <div class="ui-helper-clearfix">
                
                <button type="button" (click)="export()">Export All</button>
                <button type="button" (click)="export(true)">Export Selected</button>
              </div>
            </div>
            </div>
  


        </div>
        
        <div class="toolbar-wrapper-container"  [ngClass]="{'active' : isMobile === true && displayFilterMenu === true}" (click)="closeDatePickerOverlay()">
         </div>

      </ng-template>


      <ng-template pTemplate="colgroup" let-columns>


        <colgroup>
          <col *ngIf="summaryType !== 'MINUTE'" [ngStyle]="{'width': '90px'}">
          <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}"> 
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <!--<th style="width: 10px">s
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>-->
        <th *ngIf="summaryType !== 'MINUTE'" style="min-width:90px">
        </th>
            <th *ngFor="let col of columns"  pTooltip="{{col.header}}" tooltipPosition="top" pReorderableColumn
            [ngStyle]="{'width': col.width}" [pSortableColumn]="col.sortable == true ? col.field : null">
            {{col.header}}
            <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </tr>

      </ng-template>

    <!--</div>-->

    <ng-template id="primeNGBody" let-data pTemplate="body" let-columns="columns" let-rowIndex="rowIndex">
      <tr [pSelectableRow]="data" [pReorderableRow]="index" [pSelectableRowIndex]="rowIndex">
        <!--<td style="width: 10px">
      </td>-->
        <td style="min-width:90px" *ngIf="summaryType !== 'MINUTE'">
          <a href="#" (click)="drillDownData(data)" ><div class="icon icon-search-icon" alt="more details" title="more details"></div></a>
        </td>
         <td *ngFor="let c of columns"  [ngStyle]="{'width': c.width}">
          {{getFieldValue(c, data)}}
        </td> 
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">
          There is no data for the date range provided.
        </td>
      </tr>
    </ng-template>

  </p-table>

  <div class="paginator">
    <div class="paginator-controls">
      <p-paginator [rows]="limit" [totalRecords]="totalRecords" pageLinkSize="3" (onPageChange)="loadTabularDataLazy($event)" #paginator></p-paginator>
    </div>
  </div>

</div>


