import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { StateService } from '../services/state/state.service';


@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {

  constructor(
    public stateService: StateService) { }

  ngOnInit() {
    this.stateService.raiseApplicationReady();
  }

}
