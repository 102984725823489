<div id="date-picker-wrapper" #datePicker>

  <!--<div #DragHandle class="btn-wrapper"><img src="../../assets/images/move.svg" /> </div>-->
  <label>
    <div *ngIf="showTitle">{{titleText}}</div>
    <input placeholder="Select a date range" 
    [max]="maxDate" 
    [owlDateTimeTrigger]="dt" 
    [owlDateTime]="dt" 
    [selectMode]="'range'" 
    [(ngModel)]="selectedMoments" 
    (dateTimeChange)="onDateTimeChange($event)" readonly>
    <owl-date-time [pickerType]="pickerType"
     [startView]="startView" 
     [pickerMode]="pickerMode"
     (yearSelected)="chosenYearHandler($event)"
     (monthSelected)="chosenMonthHandler($event, dt)" 
     #dt="owlDateTime" ></owl-date-time>
    <span class="icon icon-calendar"></span>
  </label>
</div>