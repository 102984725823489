<div id="filter-btn" (click)="activateMobileFilter()"> <div class="icon icon-plus-icon"></div></div>

<div  class="filter-menu-wrapper" [ngClass]="{'active' : isMobileFilterActive === true}">


    <div id="option1" class="filter-options"  [ngClass]="{'active' : isMobileFilterActive === true}">

        <div class="icon icon-delete" (click)="closeFilter()"></div>

        <button class="dropdown-item">Filter by weather station</button>
        <button class="dropdown-item">Filter by distance from current location</button>

    </div>


    <div id="option2" class="filter-options">
        <div class="icon icon-left-arrow-icon"><span>Back</span></div>
        <div class="icon icon-delete"></div>

        <button class="dropdown-item">South Perth</button>
        <button class="dropdown-item">Pemberton</button>
        <button class="dropdown-item">Millstream Chichester National Park</button>

    </div>


    <div id="option3" class="filter-options">
        <div class="icon icon-left-arrow-icon"><span>Back</span></div>
        <div class="icon icon-delete"></div>

        <button class="dropdown-item">Closest Weather station</button>
        <button class="dropdown-item">Furthest away weather station</button>


    </div>


</div>