import { WindProbe } from './wind-probe.model';
import * as moment from 'moment';


export class StationLatest {

  //
  //public isFavourite: boolean = false;
  //public isOnline: boolean = false;

  //


  Code: string;
  Name: string;
  DateTimeUTC: string;
  AirTemperature: number;
  ApparentTemperature: number;
  BarometricPressure: number;
  RelativeHumidity: number;
  SoilTemperature: number;
  DewPoint: number;
  DeltaT: number;
  WetBulb: number;
  SolarExposure: number;
  SolarIrradiance: number;
  SprayingCondition: string;
  SprayingConditionWithWind: string; //derived property
  SprayingConditionWindOnly: string; //derived property
  WindProbes: WindProbe[];
  EtoShortCrop: number;
  EtoTallCrop: number;
  RainFallSince9AM: number;
  PanEvaporation: number;
  PanEvaporation12AM: number;
  BatteryVoltage: number;
  ErrorsToday: null;
  ErrorsLast7Days: null;
  AirTemperatureMaxLast24Hrs: number;
  AirTemperatureMinLast24Hrs: number;




  public getLastUpdatedMinutes() {
    let lastUpdateDate = new Date(this.DateTimeUTC);
    let currentDateTime = new Date();
    let diff = Math.abs(lastUpdateDate.getTime() - currentDateTime.getTime());
    var minutes = Math.floor((diff / 1000) / 60);
    return minutes;
  };

  constructor(response?: any) {
    if (response !== null && response !== undefined) {
      this.Code = response.stationCode;
      this.Name = response.stationName;
      this.DateTimeUTC = response.dateTime;
      this.AirTemperature = response.airTemperature !== null ? response.airTemperature.toFixed(1) : response.airTemperature;
      this.ApparentTemperature = response.apparentTemperature;
      this.BarometricPressure = response.barometricPressure;
      this.RelativeHumidity = response.relativeHumidity !== null ? response.relativeHumidity.toFixed(0) : response.relativeHumidity  ;
      this.SoilTemperature = response.soilTemperature;
      this.DewPoint = response.dewPoint;
      this.DeltaT = response.deltaT;
      this.WetBulb = response.wetBulb;
      this.SprayingCondition = response.sprayingConditions !== null ? response.sprayingConditions.substr(0, 1).toUpperCase() + response.sprayingConditions.substr(1) :  response.sprayingConditions ;
      this.SolarExposure = response.solarExposure !== null ? response.solarExposure.toFixed(1) : response.solarExposure ;
      this.SolarIrradiance = response.solarIrradiance;
      this.EtoShortCrop = response.etoShortCrop !== null ? response.etoShortCrop.toFixed(1) : response.etoShortCrop;
      this.EtoTallCrop = response.etoTallCrop !== null ? response.etoTallCrop.toFixed(1) : response.etoTallCrop;
      this.RainFallSince9AM = response.rainfallSince9AM;
      this.PanEvaporation = response.panEvaporation;
      this.PanEvaporation12AM = response.panEvaporation12AM;
      this.BatteryVoltage = response.batteryVoltage;
      this.ErrorsToday = response.errors.today;
      this.ErrorsLast7Days = response.errors.last7Days;
      this.WindProbes = response.wind.map(windItem => new WindProbe(windItem));
      this.AirTemperatureMaxLast24Hrs = response.airTemperatureMaxLast24Hrs;
      this.AirTemperatureMinLast24Hrs = response.airTemperatureMinLast24Hrs;


    }

    

  }

}
