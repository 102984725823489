<div class="countdown-container" #countdownContainer>
  <div id="countdown">
    <div id="countdown-controls">
      <countdown [config]="config" (finished)="onFinished()" #countdown>
        <button id="countdownTimerBtn"class="btn">
          <div class="time">$!m!</div>
          <div class="time">:</div>
          <div class="time">$!s!</div></button> 
      </countdown>
      <button id="countdownRefreshBtn"class="btn">
        <div class="icon icon-refresh-icon" (click)="onForceRefresh()"></div>
      </button>
      <button id="countdownPausePlayBtn" class="btn" [ngClass]="{'active': isPaused}" (click)="onPauseResume()">
        <div class="icon icon-pause-icon"></div><div class="icon icon-play-icon-1"></div>
      </button>
      <div class="tip-content">
        <div>Last Updated</div>
        <div>{{currentDateTimeUTC}}</div>
        <div>{{currentDateTimeLocal}}</div>
      </div>
    </div>
    <svg>
      <circle #circle class="runAnimation" r="18" cx="20" cy="20"></circle>
    </svg>
  </div>
</div> 

