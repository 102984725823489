<div id="filter-btn" (click)="activateMobileFilter()" [ngClass]="{'active' : active === true}">
    <div class="icon icon-plus-icon"></div>
</div>

<div class="filter-menu-wrapper" [ngClass]="{'active' : isMobileFilterActive === true, 'desktop-active': active === true}">


    <div id="weather-data-btns" class="filter-options" [ngClass]="{'active' : isMobileFilterActive === true}">

        <div class="icon icon-cancel-button-icon" (click)="closeFilter()"></div>

        <h2>Weather station data</h2>


        <div class="filter-container">
            <button class="btn btn-primary" (click)="toggleTable('MINUTE')">Minute
            </button>

            <button class="btn btn-primary" (click)="toggleTable('HOURLY')">Hourly
            </button>

            <button class="btn btn-primary" (click)="toggleTable('DAILY')">Daily
            </button>


            <button class="btn btn-primary" (click)="toggleTable('MONTHLY')">Monthly
            </button>

            <button class="btn btn-primary" (click)="toggleTable('YEARLY')">Yearly
            </button>

            <button class="btn btn-primary" (click)="toggleTable('CHARTS')">Charts
            </button>
        </div>

    </div>


</div>