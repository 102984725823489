<!-- header -->
<div id="header">
  <!-- button menu : for overlay left menu -->
  <a id="sidebar-button" (click)="toggleLeftMenu()"><span class="icon icon-hamburger-icon"></span> Menu</a>

  <span class="maximize">
    <a href="#" (click)="maximizeScreen()"><span class="icon icon-arrow-up-icon"></span></a>
  </span>
  <div class="button-wrapper">
    <app-rd-icon-button name="10 day summary" icon="icon-calendar" (click)="setSummaryMetricTableActive()" href="#">
    </app-rd-icon-button>
    <!--<app-rd-icon-button name="All stations list" icon="icon-list-icon" (click)="displayStationTable()" href="#">
    </app-rd-icon-button>-->
  </div>
</div>

<!-- /header -->

<!-- search -->
<div id="search-container" class="search-container" #wrapper [ngClass]="{'scrolling': isContentScrolling == true }">
  <div class="search-container-content" (scroll)="scrollHandler($event)">

    <div class="search-wrapper">
      <input #findStation type="textbox" (focus)="addActiveClass(wrapper)" placeholder="Find weather station"
        (input)="filterStations(findStation.value)" />

      <button class="close-button" (click)="removeActiveClass(wrapper)">
        <span class="icon icon-cancel-button-icon"></span>
      </button>


      <button class="cancel" [hidden]="!hasSearchValue">
        <span class="icon icon-cancel-button-icon" (click)="findStation.value = ''; resetFilteredItems();"></span>
      </button>

        <div class="outer-filter-wrapper">
          <div class="filter-options">


            <button [ngClass]="{'active': ownerFilter == 'all'}" (click)="setStationFilter('all')">All</button>
            <button [ngClass]="{'active': ownerFilter == 'dpird'}" (click)="setStationFilter('dpird')">DPIRD</button>
            <button [ngClass]="{'active': ownerFilter == 'bom'}" (click)="setStationFilter('bom')">BoM</button>
            <button [ngClass]="{'active': ownerFilter == 'dbca'}" (click)="setStationFilter('dbca')">DBCA</button>
            <button [ngClass]="{'active': ownerFilter == 'wcorp'}" (click)="setStationFilter('wcorp')">WaterCorp</button>

          </div>
    </div>

    </div>

    <!-- search-content -->
    <div class="search-content">
      <div class="letter-wrapper" *ngFor="let stationGroup of filteredWeatherStations | keyvalue">
        <div class="search-group-letter">{{stationGroup.key}}</div>
        <div class="listing" *ngFor="let station of stationGroup.value">
          <div class="code">{{station.Code}}</div>
          <div class="station-name">{{station.Name}}</div>
          <div class="cta-wrappper">
            <div class="cta" (click)="navigateToPage('map', station.Code)">Map</div>
            <div class="cta" *ngIf="station.Owner != 'BoM Station'" (click)="navigateToPage('station', station.Code)">Dashboard</div>
            <div class="cta disabled" *ngIf="station.Owner == 'BoM Station'">Dashboard</div>

          </div>
        </div>
      </div>
    </div>
    <!-- /search-content-->

  </div>


</div>
<!--/ search -->