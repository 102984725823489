import { WindProbe } from './wind-probe.model';

import * as moment from 'moment';

export class MinuteData {
  private DateTimeUTC: string;
  private DateTimeLocal: string;
  private AirTemperature: number;
  private RelativeHumidity: number;
  private SoilTemperature: number;
  private SolarIrradiance: number;
  private RainFall: number;
  private DewPoint: number;
  private WetBulb: number;
  private WindProbes: WindProbe[];
  private BatteryVoltage: number;
  private BarometricPressure: number;
  
  constructor(response: any) {
    
    this.DateTimeUTC = response.dateTime
    this.DateTimeLocal = this.getDateTime('DD-MM-YYYY HH:mm');
    this.AirTemperature = response.airTemperature
    this.RelativeHumidity = response.relativeHumidity
    this.SoilTemperature = response.soilTemperature
    this.SolarIrradiance = response.solarIrradiance
    this.RainFall = response.rainfall
    this.DewPoint = response.dewPoint
    this.WetBulb = response.wetBulb;
    this.WindProbes = response.wind.map( windItem => new WindProbe(windItem));
    this.BarometricPressure = response.barometricPressure;
    this.BatteryVoltage = response.batteryVoltage;
  }
  
  getWindProbes() {
    return this.WindProbes;
  }
  
  getDateTime(formatMask) {
    
    if ( this.DateTimeUTC == null) {
      return "unknown";
    }

    return moment(this.DateTimeUTC).format(formatMask);
  }
}