import { Component, OnInit, Input, SimpleChanges, OnDestroy } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Subscription } from "rxjs";
import { WeatherChartService } from '../../services/weather/weather.chart.service';
import * as moment from 'moment'; // Include the library have to include reference in angular CLI to js file

import { StateService } from '../../services/state/state.service';

// Add additional modules required
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);


const HOUR_INTERVAL = 3600000;
const DAY_INTERVAL = 86400000;       // 24 * 3600 * 1000;
const MONTH_INTERVAL = 2419200000;       // 24 * 3600 * 1000 * 28; Adjust automatically use 28 shotest month
const YEARLY_INTERVAL = 31536000000; // 365 Days


@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() stationCode: string;

  station = { code: '', name: '' };

  Highcharts = Highcharts;
  isDataLoaded: boolean = false;
  tickInterval = MONTH_INTERVAL;

  private ThemeChangeSubscription: Subscription;

  themeDark = {
    backgroundColour: '#23252C',
    textColour: '#ffffff',
    seriesColour: '#ffffff',
    markerFillColour: '#000000'
  };

  themeLight = {
    backgroundColour: '#ffffff',
    textColour: '#000000',
    seriesColour: '#000000',
    markerFillColour: '#ffffff'
  };

  themeCurrent = this.themeLight;




  chartOptions: any = {
    credits: {
      enabled: false
    },
    legend: {
    },
    yAxis: [{ //--- Primary yAxis
      title: {
        text: 'Monthly rainfall (mm)',
        style: {
          color: '#000000'
        },
      }
    }, { //--- Secondary yAxis
      title: {
        text: 'Cumulative rainfall (mm)'
      },
      opposite: true
    }],
    xAxis: {
        tickInterval: this.tickInterval,
        type: 'datetime'
      },
    series: [
      {
        yAxis: 0,
        name: 'Monthly rainfall (mm)',
        color: '#028AFF',
        data: []
      },
      {
        yAxis: 1,
        type: 'spline',
        name: 'Cumulative rainfall (mm)',
        color: '#454644',
        data: []
      }
    ],
    chart: {
      zoomType: 'x',
      type: 'column',
      backgroundColor: this.themeCurrent.backgroundColour
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    exporting: {
      chartOptions: {
        credits: {
          enabled: true,
          text: 'Copyright Western Australia Agriculture ' + new Date().getFullYear(),
          href: 'https://www.agric.wa.gov.au'
        },
        chart: {
          events: {
            load: function () {
              this.renderer.image('https://weather.agric.wa.gov.au/assets/images/chart_dpird_logo.jpg', 10, 10, 160, 46).add();

            }
          }
        }
      }
    },
  }

  constructor(
    private weatherChartService: WeatherChartService,
    private stateService: StateService) { }

  ngOnInit() {

    this.initChart();

    this.themeCurrent = (this.stateService.stateSettings.theme === '' ? this.themeLight : this.themeDark);

    this.ThemeChangeSubscription = this.stateService.getThemeChangeMessage().subscribe(data => {
      this.themeCurrent = (data == 'dark-gotham' ?  this.themeDark : this.themeLight );

   //this.toggleChartTheme();

    });
  }

  initChart() {
    let params = {};

  
    //let stationCode = "SP" ;//params.station.code;
    let interval = "MONTHLY" //params.interval;
    let limit = 1000;
    let offset = 0;
    //this.station = {code: 'SP', name: 'South Perth'} ;//params.station;

    // Date parameters to be sent UTC datetime.
    // split routine filtering milliseconds.
    let startDateTime =  moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD');//params.event.value[0].toISOString().split('.')[0] + "Z";
    let endDateTime =   moment().endOf('month').format('YYYY-MM-DD');//params.event.value[1].toISOString().split('.')[0] + "Z";



    this.weatherChartService.getStationRainFallData(this.stationCode, interval, startDateTime, endDateTime, limit, offset).subscribe(data => {

      // Format data for series
      var chartData = { rainfall: [], totalRainfall: []};
      let totalCumulativeRainfall = 0;
      // Foreach record returned
      for (let index in data) {
        totalCumulativeRainfall += Number(Math.round(data[index].rainfall * 1e2) / 1e2);
        let momentDate = moment(data[index].dateFrom);
        var tz_offset = (new Date().getTimezoneOffset() * -1) * 60 * 1000;
        var unix_time = momentDate.toDate().getTime() + tz_offset;

        chartData.rainfall.push(Array(unix_time, data[index].rainfall));
        chartData.totalRainfall.push(Array(unix_time, Number.parseFloat(totalCumulativeRainfall.toFixed(1))));
      }

      this.setChartOptions(chartData, params);

     

    });

     this.isDataLoaded = true;
  }


  toggleChartTheme() {
     this.chartOptions = {
      labels: {
      style: {
          color: '#ffffff'
      },
      chart: {
        backgroundColor: this.themeCurrent.backgroundColour,
        color: "#ffffff"
      }
    }
    }
  }

  setChartOptions(chartData, params) {

    this.chartOptions = {
      title: {
        text: this.title
      },
      subtitle: {
        text:  'Actual rainfall for the last 12 months'
      },
      series: [
        {
          yAxis: 0,
          data: chartData.rainfall
        },
        {
          yAxis: 1,
          data: chartData.totalRainfall,
          color: this.themeCurrent.seriesColour,
          marker: {
            fillColor: this.themeCurrent.markerFillColour,
            lineColor: this.themeCurrent.seriesColour,
            lineWidth: 1
          },
        }
      ]
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    this.initChart();
  }

   ngOnDestroy() {
    this.ThemeChangeSubscription.unsubscribe();
  }

}
