import { Component, OnInit } from '@angular/core';

import * as Highcharts from 'highcharts';

import { WeatherChartService } from '../../../services/weather/weather.chart.service';
import { StateService } from '../../../services/state/state.service';

import * as moment from 'moment'; // Include the library have to include reference in angular CLI to js file

// Add additional modules required
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);


const HOUR_INTERVAL = 3600000;
const DAY_INTERVAL = 86400000;       // 24 * 3600 * 1000;
const MONTHLY_INTERVAL = 2628000000;
const YEARLY_INTERVAL = 31536000000; // 365 Days


const CUMULATIVE_COLOUR = '#F65102';
const MONTHLY_COLOR = '#199AD2';



@Component({
  selector: 'app-radar-forecast',
  templateUrl: './radar-forecast.component.html',
  styleUrls: ['./radar-forecast.component.scss']
})
export class RadarForecastComponent implements OnInit {


  themeDark = {
    backgroundColour: '#23252C',
    textColour: '#ffffff',
    seriesColour: '#ffffff',
    markerFillColour: '#000000'
  };

  themeLight = {
    backgroundColour: '#ffffff',
    textColour: '#000000',
    seriesColour: '#000000',
    markerFillColour: '#ffffff'
  };

  themeCurrent = this.themeLight;

  station = { code: '', name: '' };

  Highcharts = Highcharts;
  isDataLoaded: boolean = true;
  tickInterval = DAY_INTERVAL;

  tz_offset = (new Date().getTimezoneOffset() * -1) * 60 * 1000;

  chartOptions: any = {
    title: {
      text: 'Radar Forecast'
    },
    tooltip: { //https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/tooltip/formatter-simple/
      formatter: function () {
          return this.y;

      }
  },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    yAxis: [{ //--- Primary yAxis
      title: {
        text: 'Predicted rainfall (mm)'
      }
      }],
    xAxis: [
        {
        type: 'datetime',
        minTickInterval: 1000 * 60 * 10, // data every 10 minutes
        tickInterval: 1000 * 60 * 10, // data every 10 minutes
       labels: {
        //format: '{value:%H:%M}'
       }}],
    series: [
      {
        name: 'Member 1',
        data: [
          [ moment('2019-10-10T01:40:00Z').toDate().getTime() + this.tz_offset  , 2],
          [ moment('2019-10-10T01:50:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T02:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:30:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T03:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:00:00Z').toDate().getTime() + this.tz_offset  , 0.5],
          [ moment('2019-10-10T05:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:10:00Z').toDate().getTime() + this.tz_offset  , 1.1],
          [ moment('2019-10-10T06:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:10:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T08:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:50:00Z').toDate().getTime() + this.tz_offset  , 0]
        ],
        color: '#5a5e5c',
        marker: {
          symbol: 'circle'
        },
        label: {
          enabled: false
        }
      },
      {
        name: 'Member 2',
        data: [
          [ moment('2019-10-10T01:40:00Z').toDate().getTime() + this.tz_offset  , 2],
          [ moment('2019-10-10T01:50:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T02:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:30:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T03:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:00:00Z').toDate().getTime() + this.tz_offset  , 0.5],
          [ moment('2019-10-10T05:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:10:00Z').toDate().getTime() + this.tz_offset  , 1.1],
          [ moment('2019-10-10T06:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:10:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T08:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:50:00Z').toDate().getTime() + this.tz_offset  , 0]
        ],
        color: '#5a5e5c',
        marker: {
          symbol: 'circle'
        },
        label: {
          enabled: false
        }
      },
      {
        name: 'Member 3',
        data: [
          [ moment('2019-10-10T01:40:00Z').toDate().getTime() + this.tz_offset  , 2],
          [ moment('2019-10-10T01:50:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T02:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:30:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T03:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:00:00Z').toDate().getTime() + this.tz_offset  , 0.5],
          [ moment('2019-10-10T05:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:10:00Z').toDate().getTime() + this.tz_offset  , 1.1],
          [ moment('2019-10-10T06:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:10:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T08:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:50:00Z').toDate().getTime() + this.tz_offset  , 0]
        ],
        color: '#5a5e5c',
        marker: {
          symbol: 'circle'
        },
        label: {
          enabled: false
        }
      },
      {
        name: 'Member 4',
        data: [
          [ moment('2019-10-10T01:40:00Z').toDate().getTime() + this.tz_offset  , 2],
          [ moment('2019-10-10T01:50:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T02:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:30:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T03:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:00:00Z').toDate().getTime() + this.tz_offset  , 0.5],
          [ moment('2019-10-10T05:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:10:00Z').toDate().getTime() + this.tz_offset  , 1.1],
          [ moment('2019-10-10T06:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:10:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T08:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:50:00Z').toDate().getTime() + this.tz_offset  , 0]
        ],
        color: '#5a5e5c',
        marker: {
          symbol: 'circle'
        },
        label: {
          enabled: false
        }
      },
      {
        name: 'Member 5',
        data: [
          [ moment('2019-10-10T01:40:00Z').toDate().getTime() + this.tz_offset  , 2],
          [ moment('2019-10-10T01:50:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T02:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:30:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T03:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:00:00Z').toDate().getTime() + this.tz_offset  , 0.5],
          [ moment('2019-10-10T05:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:10:00Z').toDate().getTime() + this.tz_offset  , 1.1],
          [ moment('2019-10-10T06:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:10:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T08:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:50:00Z').toDate().getTime() + this.tz_offset  , 0]
        ],
        color: '#5a5e5c',
        marker: {
          symbol: 'circle'
        },
        label: {
          enabled: false
        }
      },
      {
        name: 'Member 6',
        data: [
          [ moment('2019-10-10T01:40:00Z').toDate().getTime() + this.tz_offset  , 2],
          [ moment('2019-10-10T01:50:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T02:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:30:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T03:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:00:00Z').toDate().getTime() + this.tz_offset  , 0.5],
          [ moment('2019-10-10T05:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:10:00Z').toDate().getTime() + this.tz_offset  , 1.1],
          [ moment('2019-10-10T06:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:10:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T08:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:50:00Z').toDate().getTime() + this.tz_offset  , 0]
        ],
        color: '#5a5e5c',
        marker: {
          symbol: 'circle'
        },
        label: {
          enabled: false
        }
      },
      {
        name: 'Member 7',
        data: [
          [ moment('2019-10-10T01:40:00Z').toDate().getTime() + this.tz_offset  , 2],
          [ moment('2019-10-10T01:50:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T02:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:30:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T03:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:00:00Z').toDate().getTime() + this.tz_offset  , 0.5],
          [ moment('2019-10-10T05:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:10:00Z').toDate().getTime() + this.tz_offset  , 1.1],
          [ moment('2019-10-10T06:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:10:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T08:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:50:00Z').toDate().getTime() + this.tz_offset  , 0]
        ],
        color: '#5a5e5c',
        marker: {
          symbol: 'circle'
        },
        label: {
          enabled: false
        }
      },
      {
        name: 'Member 8',
        data: [
          [ moment('2019-10-10T01:40:00Z').toDate().getTime() + this.tz_offset  , 2],
          [ moment('2019-10-10T01:50:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T02:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:30:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T03:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:00:00Z').toDate().getTime() + this.tz_offset  , 0.5],
          [ moment('2019-10-10T05:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:10:00Z').toDate().getTime() + this.tz_offset  , 1.1],
          [ moment('2019-10-10T06:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:10:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T08:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:50:00Z').toDate().getTime() + this.tz_offset  , 0]
        ],
        color: '#5a5e5c',
        marker: {
          symbol: 'circle'
        },
        label: {
          enabled: false
        }
      },
      {
        name: 'Member 9',
        data: [
          [ moment('2019-10-10T01:40:00Z').toDate().getTime() + this.tz_offset  , 2],
          [ moment('2019-10-10T01:50:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T02:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:30:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T03:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:00:00Z').toDate().getTime() + this.tz_offset  , 0.5],
          [ moment('2019-10-10T05:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:10:00Z').toDate().getTime() + this.tz_offset  , 1.1],
          [ moment('2019-10-10T06:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:10:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T08:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:50:00Z').toDate().getTime() + this.tz_offset  , 0]
        ],
        color: '#5a5e5c',
        marker: {
          symbol: 'circle'
        },
        label: {
          enabled: false
        }
      },
      {
        name: 'Member 10',
        data: [
          [ moment('2019-10-10T01:40:00Z').toDate().getTime() + this.tz_offset  , 2],
          [ moment('2019-10-10T01:50:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T02:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T02:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:30:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T03:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T03:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T04:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:00:00Z').toDate().getTime() + this.tz_offset  , 0.2],
          [ moment('2019-10-10T05:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T05:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:30:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T06:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T06:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T07:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:10:00Z').toDate().getTime() + this.tz_offset  , 1],
          [ moment('2019-10-10T08:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T08:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T09:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T10:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T11:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T12:50:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:00:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:10:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:20:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:30:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:40:00Z').toDate().getTime() + this.tz_offset  , 0],
          [ moment('2019-10-10T13:50:00Z').toDate().getTime() + this.tz_offset  , 0]
        ],
        color: '#5a5e5c',
        marker: {
          symbol: 'circle'
        },
        label: {
          enabled: false
        }
      },
    ],
    chart: {
      zoomType: 'x',
      type: 'scatter'
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  }

  constructor(
    private weatherChartService: WeatherChartService,
    private stateService: StateService) { }

  ngOnInit() {

    this.themeCurrent = (this.stateService.stateSettings.theme === '' ? this.themeLight : this.themeDark);

  }


  setChartOptions(chartData, params) {


    this.chartOptions = {
      title: {
        text: 'test'
      },
      subtitle: {
        text: 'test'
      },
      xAxis: {
        tickInterval: this.tickInterval,
        type: 'datetime'
      },
      series: [
        {
          yAxis: 0,
          data: [1,4,6],
          color: MONTHLY_COLOR
        },
        {
          yAxis: 1,
          data: chartData.totalRainfall,
          color: this.themeCurrent.seriesColour,
          marker: {
            fillColor: this.themeCurrent.markerFillColour,
            lineColor: this.themeCurrent.seriesColour,
            lineWidth: 1
          },
        }
      ]
    }
  }


}
