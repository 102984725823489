import { Component, ViewEncapsulation, EventEmitter, Output, ViewChild, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Config, CountdownComponent } from 'ngx-countdown';
import * as L from 'leaflet';

@Component({
  selector: 'tpl-flip',
  templateUrl: 'flip.component.html',
  styleUrls: ['flip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TplFlipComponent implements OnInit {
  [x: string]: any;

  @ViewChild('countdownContainer', {static: true} ) countdownContainer: HTMLDivElement;
  @ViewChild('countdown', {static: true}) counter: CountdownComponent;

  @ViewChild('circle', {static: true}) circle: SVGCircleElement;

  currentDateTimeLocal = new Date();
  currentDateTimeUTC = new Date().toUTCString();

  @Output()
  finished = new EventEmitter();
  isPaused: boolean = false;

  config: Config = {
    leftTime: 300 // Update Frequency of weather stations data updates,
    //repaint: function() {
    //  const me: any = this;
    //  console.log(me.left);
    //},
    /*repaint: function() {

      const me: any = this;
      let content: string;

      me.hands.forEach((hand: any) => {
        if (hand.lastValue !== hand.value) {
          content = '';
          const cur = me.toDigitals(hand.value + 1, hand.bits).join(''),
            next = me.toDigitals(hand.value, hand.bits).join('');

          hand.node.innerHTML = `
            <span class="count curr top">${cur}</span>
            <span class="count next top">${next}</span>
            <span class="count next bottom">${next}</span>
            <span class="count curr bottom">${cur}</span>
          `;
          hand.node.parentElement.className = 'time';
          setTimeout(() => {
            hand.node.parentElement.className = 'time flip';
          });
        }
      });
    },*/
  };

  ngOnInit() {
    // Stop bubbling up events as leaflet map wold detect the click
    let DivElement =   <HTMLDivElement>(this.countdownContainer as any).nativeElement;
    L.DomEvent.on(DivElement, 'click', function (ev) {
      L.DomEvent.stopPropagation(ev);
    });

    L.DomEvent.on(DivElement, 'doubleclick', function (ev) {
      L.DomEvent.stopPropagation(ev);
    });
  }

  onFinished() {
      this.finished.emit(0);
      this.currentDateTimeLocal = new Date();
      this.currentDateTimeUTC = new Date().toUTCString();
  }



  /**
   * This will force the finished event to automatically reset and fetch the new data.
   * In order for the UI to update two classes are required so that can be toggled on and off
   * which will reset the animations.
   */
  onForceRefresh() {

    this.isPaused = false;

    this.onFinished();
    //try {
    //  if ((this.circle as any).nativeElement.classList.contains('runAnimation')) {
    //    (this.circle as any).nativeElement.classList.remove('runAnimation');
    //    (this.circle as any).nativeElement.classList.add('runAnimation2');
    //  } else {
    //    (this.circle as any).nativeElement.classList.remove('runAnimation2');
    //    (this.circle as any).nativeElement.classList.add('runAnimation');
    //  }
    //}
    //catch(e) {
      // wont' happen
    //}    
  }
  
  /**
   * This will pause/resume the counter so that the user can leave the current data on the screen without the worry
   * of the automatic refresh occuring.
   */
  public onPauseResume() {
 
      if (!this.isPaused) {
        this.counter.pause();
        this.isPaused = true;
      } else {
        this.isPaused = false;
        this.counter.resume();
      }
  }

}