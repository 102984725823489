<div id="mapInfoPanel"
  [ngClass]="{'menu-open' : panelSettings.isOpen == true, 'stationSelected' : panelSettings.isStationSelected == true}">
  <button type="button" class="close" aria-label="Close" (click)="onCloseButtonClicked()">
    <span aria-hidden="true">
      <span *ngIf="panelSettings.isOpen == true" class="icon icon-arrow-right-icon"></span>
      <span *ngIf="!panelSettings.isOpen == true" class="icon icon-arrow-left-icon"></span>
      <span class="icon icon-cancel-button-icon"></span>
    </span>
  </button>




  <div class="header-bg" *ngIf="currentStationCode !== 'VIRTUAL_STATION' && currentStationCode !== 'BOM_STATION'">

    <h2 *ngIf="stationModel !== null">{{stationModel.Name}} <span>({{stationModel.Code}})</span></h2>
    <div *ngIf="stationModel !== null" style="text-align: center">{{stationModel.Owner}}</div>
    <div *ngIf="stationLatestModel !== null" class="station-updated">Last Updated: {{stationLastUpdated}}</div>

  </div>

  <div class="header-bg radar" *ngIf="currentStationCode == 'VIRTUAL_STATION'">

    <h2>Virtual station </h2>
    <div class="station-updated" *ngIf="radarData != undefined">{{radarData.Latitude}} , {{radarData.Longitude}}</div>

  </div>

  <div class="header-bg radar" *ngIf="isBomStation">

    <h2 *ngIf="BoMstationModel !== null">{{BoMstationModel.StationName}}</h2>
    <div *ngIf="BoMstationModel !== null" style="text-align: center">{{BoMstationModel.Owner}}</div>
    <div class="station-updated" *ngIf="BoMstationModel !== null">Last Updated: {{BoMstationModel.LastUpdateLocalTime}}
    </div>

  </div>


  <div class="station-variables-wrapper">


    <ngb-tabset type="pills" #tabs [activeId]="activeIdString">
      <ngb-tab title="Summary" [disabled]="currentStationCode == 'VIRTUAL_STATION'" id="tabSummary">
        <ng-template ngbTabContent *ngIf="stationLatestModel !== null && stationLatestModel !== null">

          <!-- BoM weather station -->
          <div *ngIf="isBomStation && BoMstationModel !== null">
            <div class="row" *ngIf="BoMstationModel.AirTemperature !== null">
              <div>Temperature</div>
              <div>{{BoMstationModel.AirTemperature}}<span>&#176;C</span></div>
            </div>

            <div class="row" *ngIf="BoMstationModel.ApparentTemperature !== null">
              <div>Feels like</div>
              <div>{{BoMstationModel.ApparentTemperature}}<span>&#176;C</span></div>
            </div>

            <div class="row" *ngIf="BoMstationModel.MaxAirTemp !== null">
              <div>Maximum temp 6am - 9pm</div>
              <div>{{BoMstationModel.MaxAirTemp}}<span>&#176;C</span></div>
            </div>

            <div class="row" *ngIf="BoMstationModel.MinAirTemp !== null">
              <div>Minimum temp 6pm - 9am</div>
              <div>{{BoMstationModel.MinAirTemp}}<span>&#176;C</span></div>
            </div>

            <div class="row" *ngIf="BoMstationModel.RelativeHumidity !== null">
              <div>Relative humidity</div>
              <div>{{BoMstationModel.RelativeHumidity}}<span>%</span></div>
            </div>

            <div class="row" *ngIf="BoMstationModel.RainSince9AM !== null">
              <div>Rainfall since 9am</div>
              <div>{{BoMstationModel.RainSince9AM}}<span>mm</span></div>
            </div>

            <div class="row" *ngIf="BoMstationModel.RainPrev24Hrs !== null">
              <div>Rainfall previous 24 hours to 9am</div>
              <div>{{BoMstationModel.RainPrev24Hrs}}<span>mm</span></div>
            </div>

            <div class="row" *ngIf="BoMstationModel.WindSpeedKmh !== null">
              <div>Wind speed @10m</div>
              <div>{{BoMstationModel.WindSpeedKmh}}<span>km/h</span></div>
            </div>

            <div class="row" *ngIf="BoMstationModel.WindDirectionDegrees !== null">
              <div>Wind direction</div>
              <div>{{BoMstationModel.WindDrectionCompass}}<span></span></div>
            </div>

            <div class="row" *ngIf="BoMstationModel.WindGustKmh !== null">
              <div>Wind gust @10m</div>
              <div>{{BoMstationModel.WindGustKmh}}<span>km/h</span></div>
            </div>

            <div class="row" *ngIf="BoMstationModel.DewPoint !== null">
              <div>Dew point</div>
              <div>{{BoMstationModel.DewPoint}}<span>&#176;C</span></div>
            </div>


            <div class="row" *ngIf="BoMstationModel.MeanSeaLevelPressure !== null">
              <div>Mean sea level pressure</div>
              <div>{{BoMstationModel.MeanSeaLevelPressure}}<span>hPa</span></div>
            </div>


            <div class="row" *ngIf="BoMstationModel.VisibilityKmh !== null">
              <div>Visibility</div>
              <div>{{BoMstationModel.VisibilityKmh}}<span>km</span></div>
            </div>


          </div>
          <!-- /BoM weather station -->

          <div class="row" *ngIf="stationLatestModel == undefined">Station currently not reporting</div>
          <div *ngIf="isBomStation === false && stationLatestModel !== undefined">
            <!-- AirTemperature -->
            <div class="row" *ngIf="stationLatestModel.AirTemperature !== null">
              <div>Temperature</div>
              <div>{{stationLatestModel.AirTemperature}}<span>&#176;C</span></div>
            </div>
            <!-- /AirTemperature -->

            <!-- Additional Fields from Events endpoint -->
            <div class="row" *ngIf="stationLatestModel.MinTempPast24Hrs !== null">
              <div>Min temp. past 24hrs</div>
              <div>{{stationLatestModel.AirTemperatureMinLast24Hrs}}<span>&#176;C</span></div>
            </div>

            <div class="row" *ngIf="stationLatestModel.MaxTempSince9am !== null">
              <div>Max temp. since 9am</div>
              <div>{{stationLatestModel.MaxTempSince9am}}<span>&#176;C</span></div>
            </div>

             <div class="row" *ngIf="stationLatestModel.MaxTempPast24HrsTo9am !== null">
              <div>Max temp. previous 24hrs to 9am</div>
              <div>{{stationLatestModel.MaxTempPast24HrsTo9am}}<span>&#176;C</span></div>
            </div>




            <!-- /Additional Fields from Events endpoint -->


            <!-- ApparentTemperature -->
            <div class="row" *ngIf="stationLatestModel.ApparentTemperature !== null">
              <div>Feels like</div>
              <div>{{stationLatestModel.ApparentTemperature}}<span>&#176;C</span></div>
            </div>
            <!-- /ApparentTemperature -->

            <!-- BarometricPressure -->
            <div class="row" *ngIf="stationLatestModel.BarometricPressure !== null">
              <div>Barometric pressure</div>
              <div>{{stationLatestModel.BarometricPressure}}<span>hPa</span></div>
            </div>
            <!-- /BarometricPressure -->

            <!-- RelativeHumidity -->
            <div class="row" *ngIf="stationLatestModel.RelativeHumidity !== null">
              <div>Relative humidity</div>
              <div>{{stationLatestModel.RelativeHumidity}}%</div>
            </div>
            <!-- /RelativeHumidity -->

            <!-- Rainfall -->
            <div class="row" *ngIf="stationLatestModel.RainFallSince9AM !== null">
              <div>Rainfall since 9am</div>
              <div>{{stationLatestModel.RainFallSince9AM}}<span>mm</span></div>
            </div>
            <!-- /Rainfall -->

            <!-- Wind Probes-->
            <div *ngFor="let windProbe of stationLatestModel.WindProbes">
              <div class="row">
                <div>Wind height</div>
                <div>{{windProbe.Height}}<span>m</span></div>
              </div>

              <div class="row" *ngIf="windProbe.AvgSpeed !== null">
                <div>Wind speed <span class="small">(10min avg.)</span></div>
                <div>{{windProbe.AvgSpeed}}<span>km/h</span></div>
              </div>

              <div class="row" *ngIf="windProbe.AvgSpeed !== null">
                <div>Wind speed <span class="small">(10min avg.)</span></div>
                <div>{{windProbe.AvgSpeedKnots}}<span>knots</span></div>
              </div>
              

              <div class="row" *ngIf="windProbe.AvgDirectionCompassPoint !== null">
                <div>Wind direction <span class="small">(10min avg.)</span></div>
                <div>{{windProbe.AvgDirectionCompassPoint}}</div>
              </div>
            </div>
            <!-- /End Wind Probes -->


            <div class="row"
              *ngIf="stationLatestModel.SoilTemperature !== undefined && stationLatestModel.SoilTemperature !== null">
              <div>Soil temperature</div>
              <div>{{stationLatestModel.SoilTemperature}}<span>&#176;C</span></div>
            </div>


            <div class="row" *ngIf="stationLatestModel.DewPoint !== null">
              <div>Dew point</div>
              <div>{{stationLatestModel.DewPoint}}<span>&#176;C</span></div>
            </div>

            <div class="row">
              <div>DeltaT</div>
              <div>{{stationLatestModel.DeltaT}}<span>&#176;C</span></div>
            </div>



            <div class="row" *ngIf="stationLatestModel.DeltaT !== undefined">
              <div>Delta-T spray condition</div>
              <div>
                <span class="alert"
                  [ngClass]="stationLatestModel.getSprayingConditionColour()">{{stationLatestModel.SprayingCondition}}</span>
              </div>
            </div>

            <div class="row" *ngIf="stationLatestModel.hasWindProbe == true">
              <div>Wind Spraying condition</div>
              <div>
                <span class="alert"
                  [ngClass]="stationLatestModel.getSprayingConditionColourWind()">{{stationLatestModel.SprayingConditionWindOnly}}</span>
              </div>
            </div>

            <!--<div class="row" *ngIf="stationLatestModel.DeltaT !== undefined">
              <div>Combined Spraying condition</div>
              <div>
                <span class="alert"
                  [ngClass]="stationLatestModel.getSprayingConditionColour2()">{{stationLatestModel.SprayingConditionWithWind}}</span>
              </div>
            </div>-->

            <div class="row" *ngIf="stationLatestModel.SolarIrradiance !== null">
              <div>Solar irradiance</div>
              <div>{{stationLatestModel.SolarIrradiance}}<span>W/m<sup>2</sup></span></div>
            </div>

            <div class="row" *ngIf="stationLatestModel.SolarExposure !== null">
              <div>Solar exposure since 12am</div>
              <div>{{stationLatestModel.SolarExposure}}<span>MJ</span></div>
            </div>

            <div class="row" *ngIf="stationLatestModel.PanEvaporation12AM !== null">
              <div>Pan evap. since 12am</div>
              <div>{{stationLatestModel.PanEvaporation12AM}}<span>mm</span></div>
            </div>

            <div class="row" *ngIf="stationLatestModel.EtoShortCrop !== null">
              <div>ETo short since 12am</div>
              <div>{{stationLatestModel.EtoShortCrop}}<span>mm</span></div>
            </div>

            <div class="row" *ngIf="stationLatestModel.EtoTallCrop !== null">
              <div>ETo tall since 12am</div>
              <div>{{stationLatestModel.EtoTallCrop}}<span>mm</span></div>
            </div>

            <div class="row">
              <div>Wet bulb</div>
              <div>{{stationLatestModel.WetBulb}}<span>&#176;C</span></div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="tabRadar" [disabled]="radarData == null">
        <ng-template ngbTabTitle>Radar</ng-template>
        <ng-template ngbTabContent>
          <div *ngIf="radarData != undefined">
            <div class="radar-summary-wrapper">
              <div style="font-size: 11px;">Estimated rainfall data is sourced from the Doppler Radar service provided
                by the Bureau of Meteorology under license. The rainfall estimates come from automated equipment and are
                not verified by DPIRD.</div>
              <h2>Closest radar grid cell centre point</h2>
              <!--  <div class="row">
                <div>Closest radar</div>
                <div>{{radarData.ClosestRadarName}}</div>
              </div>-->
              <div class="row">
                <div>Radar used</div>
                <div>{{radarData.ClosestRadarName}}</div>
              </div>
              <div class="row">
                <div>Latitude</div>
                <div>{{radarData.Latitude}}</div>
              </div>
              <div class="row">
                <div>Longitude</div>
                <div>{{radarData.Longitude}}</div>
              </div>
              <div class="row">
                <div>Distance</div>
                <div>{{radarData.DistanceMetres}}m</div>
              </div>
              <h2>Estimated rain mm</h2>
              <div class="row">
                <div>Current hour</div>
                <div>{{radarData.RainfallCurrentHour}}</div>
              </div>
              <div class="row">
                <div>Today (since 9am)</div>
                <div>{{radarData.RainfallSince9AM}}</div>
              </div>
              <div class="row">
                <div>Month to date</div>
                <div>{{radarData.RainfallMonthToDate}}</div>
              </div>
              <div class="row">
                <div>Year to date</div>
                <div>{{radarData.RainfallYearToDate}}</div>
              </div>

              <h2>Previous estimated rain mm</h2>
              <div *ngIf="(radarData.MonthlyRain === undefined || radarData.MonthlyRain === null) && isRadarTimedOut == false">
                <img src="/assets/images/loading_dots.gif" alt="loading" style="width:50px;"/>
              </div>
              <div *ngIf="isRadarTimedOut == true">
                <p>Radar Summary API currently unavailable.</p>
              </div>
              <div class="row" *ngFor="let month of radarData.MonthlyRain">
                <div>{{month.MonthShortCode}} {{month.Year}}</div>
                <div>{{month.EstimatedRainfall}}</div>
              </div>

            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Nearby" id="tabNearby" [disabled]="isBomStation">
        <ng-template ngbTabContent>

          <div *ngIf="stationNearby === null || stationNearby.length === 0">
            <p>The are no weather stations nearby within 100km from this location.</p>
          </div>

          <div class="nearby-stations" *ngFor="let nearbyStation of stationNearby"
            (click)="showNearbyStation(nearbyStation)">
            <div class="row">
              <div class="col-md-12"><span class="nearby-station-name">{{nearbyStation.Name}}</span> <span
                  class="station-code">({{nearbyStation.Code}})</span> </div>
              <div class="col-md-12"><span class="nearby-station-distance">{{nearbyStation.Distance}}</span><span
                  class="km">km</span><span class="icon-station-alert-icon online"></span></div>
            </div>
          </div>

        </ng-template>
      </ngb-tab>
      <ngb-tab title="Forecast" id="tabForecast">
        <ng-template ngbTabContent>
          <div class="row" style="font-size: 0.8rem;font-style: italic;color: darkgrey;">Data from MET Norway</div>
          <!-- headers -->
          <div class="row" style="display: flex;">
            <div style="flex: 1; text-align: center;">Time</div>
            <div style="flex: 1;text-align: center; font-weight: inherit">Air Temp<span>&#176;C</span></div>
            <div style="flex: 1;text-align: center;">Wind Speed m/s</div>
            <div style="flex: 1;text-align: center;">Precipitation Next Hr</div>
            <div style="flex: 1;text-align: center;">Wind Direction</div>
          </div>
          <!-- /headers -->
          <div *ngFor="let forecast of forecastHourlyData">
            <div class="row">
                <div style="font-weight: bold;">{{forecast[0]}}</div>
            </div>

            <div class="row" style="display: flex;" *ngFor="let reading of forecast[1]">
              <div style="flex: 1;">{{reading.ForecastDateTimeOnlyFormatted}}</div>
              <div style="font-weight: inherit;flex: 1;text-align: center;">{{reading.AirTemperature}}</div>
              <div style="flex: 1;text-align: center;">{{reading.WindSpeed}}</div>
              <div style="flex: 1;text-align: center;">{{reading.PrecipitationNextHour}}</div>
              <div style="flex: 1;text-align: center;">
                <app-wind-direction [degrees]="reading.WindDirection"></app-wind-direction>
              </div>
            </div>

          </div>
          
        </ng-template>
      </ngb-tab>

    </ngb-tabset>


    <!--<a uiSref="station" [uiParams]="{stationCode: 'SP'}" iSrefActive="active" class="station-view-button">View Station</a>-->
<!--(click)="goToStationDashboard()"-->

  </div>
  <button style="z-index: 999999;" [routerLink]="['/station', currentStationCode]" [disabled]="currentStationCode == 'VIRTUAL_STATION' || isBomStation"
    class="btn btn-primary btn-long view-dashboard" >View dashboard</button>
</div>