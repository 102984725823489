import { Component, OnInit } from '@angular/core';

import { StateService } from '../services/state/state.service';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit  {

  isOpen: boolean = false;
  closeResult: string;
  title: string;
  message: string;
  colour: string = "blue"; // colour for indication of severity , red , orange , blue ( blue by default anyway with style)
  
  
  private modalSubscription: Subscription;

  
  constructor(private stateService: StateService) { }

 
  ngOnInit() {

    this.modalSubscription = this.stateService.onOpenApplicationModal.subscribe(
      oldObj => { 
         if (this.isOpen === false) {  
          this.title = (oldObj as any).Title;
          this.message = (oldObj as any).Message;
          this.colour = (oldObj as any).Colour;
       
          this.isOpen = true;
         }

      })
  }


  close() {
    this.isOpen = false;
  }

}
