export class StationRainFall {

  public Code: string;
  public Name: string;
  public Latitude: number;
  public Longitude: number;
  public RainFallMetrics: RainFallMetrics;

  constructor(response?: any) {

    if (response !== undefined) {
      this.Code = response.stationCode;
      this.Name = response.stationName;
      this.Latitude = response.latitude;
      this.Longitude = response.longitude;
   
      if (response.rainfall !== undefined) {
        this.RainFallMetrics = new RainFallMetrics(response.rainfall);
      }
    }

  }
}


export class RainFallMetrics {

  public Since9AM: number;
  public To9AM: number;
  public Last24Hours: number;
  public Last7Days: number; // The rainfall recorded in the last 7 days to the previous 9am in millimetres.
  public Last14Days: number; // The rainfall recorded in the last 14 days to the previous 9am in millimetres
  public MonthToDate: number; // 
  public YearToDate: number;
  public Period: number // Amount calculated from a given date range

  constructor(response?: any) { 

    if (response !== undefined) {
      this.Since9AM = response.since9AM;
      this.To9AM = response.to9AM;
      this.Last24Hours = response.last24Hrs;
      this.Last7Days = response.last7Days;
      this.Last14Days = response.last14Days;
      this.MonthToDate = response.monthToDate;
      this.YearToDate =  response.yearToDate != null ? response.yearToDate.toFixed(1) : response.yearToDate;
      this.Period = response.period;
    }
  }

}
