import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
//import { SecondaryHeaderComponent } from '../../secondary-header/secondary-header.component';
import { StateService } from '../../services/state/state.service';
import { LoggingService } from '../../services/logging/logging.service';
import { RadarService } from '../../services/radar/radar.service';
import { Subscription } from "rxjs";


import { DataCache } from '../../services/data-cache/data-cache.service';


//import * as L from 'leaflet';
//import * as moment from 'moment'; // Include the library have to include reference in angular CLI to js file

//import '../../shared/extensions/date.extensions';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-mobile-map-controls',
  templateUrl: './mobile-map-controls.component.html',
  styleUrls: ['./mobile-map-controls.component.scss'],
  providers:[]
})
export class MobileMapControlsComponent implements OnInit {

 public menuControlsisOpen: boolean = false;

  public mapSettings: any;

  public screenHeight: number;
  public screenWidth: number;

  public availableRadars;

  @HostListener('window:resize', ['$event'])
    onResize(event?) {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
    }

  constructor(
    private stateService: StateService,
    private loggingService: LoggingService,
    private dataCacheService: DataCache,
    private cookieService: CookieService) { }

  //todo: duplicated const look at global area for this
  public mapRegions = [
    { 'name': 'Kimberley', 'center': { 'lat': -15.65, 'lng': 128.71, 'zoom': 8 } },
    { 'name': 'Gascoyne', 'center': { 'lat': -24.86, 'lng': 113.72, 'zoom': 8 } },
    { 'name': 'Mid West', 'center': { 'lat': -29.71, 'lng': 115.90, 'zoom': 8 } },
    { 'name': 'Central', 'center': { 'lat': -32.13, 'lng': 118.00, 'zoom': 8 } },
    { 'name': 'South West', 'center': { 'lat': -33.605, 'lng': 117.26, 'zoom': 8 } },
    { 'name': 'South Coast', 'center': { 'lat': -33.75, 'lng': 121.28, 'zoom': 8 } }
  ]

  public metricChosen: string = "default";
  public currentMapRegion: string = "default";
  public currentMapTiles: string = "MapBox";
  
  public onResetMapSubscription: Subscription;
  public toggleMapLayerSubscription: Subscription;

  public isMobileFilterActive: boolean = false;

  filterMenu = {
    option1: false,
    option2: false,
    option3: false,
    option4: false
  }

  @Output() onMetricChanged = new EventEmitter();

  ngOnInit() {
    this.onResize();

    this.mapSettings = this.stateService.mapSettings;
    this.availableRadars = this.stateService.mapSettings.radarOptions;

    this.onResetMapSubscription = this.stateService.onResetMap().subscribe( data => {
      this.currentMapRegion = 'default';
    });

    this.toggleMapLayerSubscription = this.stateService.onToggleMapLayer().subscribe(data => {
      this.setCurrentMapLayerName(data);
    });

    // State will be updated by the app when loading to get the default values
    // so we can set local properties based on the state of the application.
    this.metricChosen = this.stateService.mapSettings.metricSelected.value;
    this.setCurrentMapLayerName(this.stateService.mapSettings.currentMapLayer);
   // this.currentMapRegion = this.stateService.mapSettings.defaultRegion;

  //$  this.checkRadarAvailability();
  }

  /**
   * Displays the friendly name of the layer in the menu for mobile control
   * to easily see what layer has been set.
   * @param layerCode constant used to differentiate the layers.
   */
  setCurrentMapLayerName(layerCode: string) {
    switch (layerCode) {
      case 'GOOGLE_SATELLITE':
        this.currentMapTiles = 'Google Satellite';
      break;
      case 'GOOGLE_HYBRID':
        this.currentMapTiles = 'Google Hybrid';
      break;
      case 'GOOGLE_STREETS':
        this.currentMapTiles = 'Google Streets';
      break;
      case 'GOOGLE_TERRAIN':
        this.currentMapTiles = 'Google Terrain';
      break;
      default:
        this.currentMapTiles = 'MapBox';
      break;
    }
  }

  ngOnDestroy() {
   //$//$ this.onResetMapSubscription.unsubscribe();
   //$ this.toggleMapLayerSubscription.unsubscribe();
  }


  setMapRegion(region) {
    //this.loggingService.logToConsole("leaflet map to region view");
    this.stateService.raiseSetMapView(region.center.lat, region.center.lng, region.center.zoom);
    this.currentMapRegion = region.name;
    this.toggleMobileFilter();
  }

  toggleMobileFilter() {
    this.isMobileFilterActive = !this.isMobileFilterActive;

    if (this.isMobileFilterActive) {
      this.filterMenu.option1 = true;
    } else {
      this.resetOptionsFilter();
    }

  }

  setOption(option: string) {
    this.resetOptionsFilter();
    this.filterMenu[option] = true;
  }

  resetOptionsFilter() {
    for (var key in this.filterMenu) {
      if (this.filterMenu.hasOwnProperty(key)) {
        this.filterMenu[key] = false;
      }
    }


  }

  // code from map toolbar
   onChangeMetricDisplay(value: string, display: string) {
    // Some metrics will require further input from the user
    // example: Rainfall within date range mm will require a
    // fromDate to ToDate
    this.metricChosen = display;

    // Raise event will be captured with MAP Component
    this.onMetricChanged.emit(value);
    this.toggleMobileFilter();
  }

}
