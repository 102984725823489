import { Injectable } from '@angular/core';

declare var gtag: Function;

@Injectable()
export class GoogleAnalyticsService {

  constructor() {}

  /**
  * Emit google analytics event , originaly used Google Analytics but updated to use the new GA4
  * where the field names and function have been updated. see more with link below
  * https://developers.google.com/analytics/devguides/migration/ua/analyticsjs-to-gtagjs
  * The GTAG is added to support the custom firing of events , if you spend more time the events could
  * be implemented in GTM and managed via Googles GTM management ui.
  * https://support.google.com/tagmanager/answer/7582054?hl=en
  * Fire event example:
  * this.emitEvent("testCategory", "testAction", "testLabel", 10);
  * @param {string} eventCategory
  * @param {string} eventAction
  * @param {string} eventLabel
  * @param {number} eventValue
  */
  public emitEvent(eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = 0) {

    if (typeof gtag === 'function') {
      gtag('trackCustomEvent', eventCategory,  {
        'event_category': eventCategory,
        'event_label': eventLabel,
        'event_action': eventAction,
        'value': eventValue
      });
    }
  }
}