import { AggregatedMetrics } from '../services/weather/models/aggregated-metrics.model';

import * as moment from 'moment';

const AVAIL_AFTER_9AM_TEXT = 'avail. after 9am';

export class AggregatedMetricsModel extends AggregatedMetrics {

  constructor(Agmatrics: AggregatedMetrics) {
    super();

    if (Agmatrics !== undefined && Agmatrics !== null) {
      this.Min = Agmatrics.Min;
      this.MinTime = Agmatrics.MinTime;
      this.MinTimeUTC = Agmatrics.MinTimeUTC; 
      this.Max = Agmatrics.Max;
      this.MaxTime = Agmatrics.MaxTime;
      this.MaxTimeUTC = Agmatrics.MaxTimeUTC;
      this.Avg = Agmatrics.Avg;
      //Monthly and Yearly
      this.MinAvg = Agmatrics.MinAvg;
      this.MaxAvg = Agmatrics.MaxAvg;
    }
  }


  getMaxDateTimeConditionalString(dateMask) {
    if (this.MaxTimeUTC !== null) {
      return moment(this.MaxTimeUTC).format(dateMask);
    }

    if (moment().hours() <= 9 ) { //explicit showing that null here should be to get to this line anyway
      return AVAIL_AFTER_9AM_TEXT; // All other fields should be filled so having this clause for special 9am attributes won't be an issue
    }  

    // Still null after 9am indicating API or database problem so show unkown date.
    return "unknown date";
  }

  getMinDateTimeConditionalString(dateMask) {

    if (this.MinTimeUTC !== null) {
      return  moment(this.MinTimeUTC).format(dateMask);
    }

    if (moment().hours() <= 9 ) { //explicit showing that null here should be to get to this line anyway
      return AVAIL_AFTER_9AM_TEXT; // All other fields should be filled so having this clause for special 9am attributes won't be an issue
    }  

    return "unknown date";
  }

  getMaxDateTime(dateMask) {
    if (this.MaxTimeUTC !== null) {
      return moment(this.MaxTimeUTC).format(dateMask);
    }
    return null;
  }

  getMinDateTime(dateMask) {

    if (this.MinTimeUTC !== null) {
      return  moment(this.MinTimeUTC).format(dateMask);
    }
    return null;
  }

}