//https://stackoverflow.com/questions/47065569/angular-cli-how-to-share-prototype-functions-across-the-application
export { };

declare global {
  interface Date {
    addDays(days: number): Date;
    addHours(hours: number): Date;
    addMinutes(minutes: number): Date;
    formatRadarString(): string;
    getImagePath(): string;
  }

  interface Number {
    getMonthName(): string;
  }
}

Date.prototype.addDays = function (days: number): Date {
  if (!days) return this;
  let date = this;
  date.setDate(date.getDate() + days);

  return date;
   };


Date.prototype.addMinutes = function (m) {
  this.setMinutes(this.getMinutes() + m);
  return this;
}

Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h);
  return this;
}

Date.prototype.formatRadarString = function () {
  var now = this; //should be date object
  var pad = function (num) {
    var norm = Math.abs(Math.floor(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  return now.getFullYear() +
    pad(now.getMonth() + 1) +
    pad(now.getDate()) +
    pad(now.getHours()) +
    pad(now.getMinutes());
}


Date.prototype.getImagePath = function () {
  var now = this; //should be date object
  var pad = function (num) {
    var norm = Math.abs(Math.floor(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  return now.getFullYear() + '/' + pad(now.getMonth() + 1) + '/' + pad(now.getDate());
}

Number.prototype.getMonthName = function() {
  const shortMonthCodes = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return shortMonthCodes[this - 1];
}
