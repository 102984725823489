import { Injectable, EventEmitter } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap } from "rxjs/operators";
//import 'rxjs/add/observable/of';

import { Station } from './models/station.model';
import { StationLatest } from './models/station-latest.model';
import { StationRainFall, RainFallMetrics } from './models/station-rainfall.model';
import { StationSummary } from './models/station-summary.model';


import { StationPeriod } from './models/station-period.model';
import { EvapoTranspiration } from './models/evapo-transpiration.model';
import { AggregatedMetrics } from './models/aggregated-metrics.model';


import { WeatherService } from './weather.service';


@Injectable()
export class WeatherChartService {

  // Charting events
  // TODO: Will need refactoring and move logic into this layer from weather service
  onChartFilterParametersSet = new EventEmitter();
  
  constructor(
    private weatherService: WeatherService
  ) { }


  //https://stackoverflow.com/questions/37771855/chaining-observables-in-rxjs
  getStationSelectedSummaryProperty(stationCode, interval, startDateTime, endDateTime, limit, offset, property) {
    return this.weatherService.getShorterStationSummary(stationCode, interval, startDateTime, endDateTime, limit, offset, property).pipe(mergeMap(data => {
      return of(data);
    }));
  }


  getStationEvaporationData(stationCode, interval, startDateTime, endDateTime, limit, offset) {
    return this.weatherService.getStationEvaporationData(stationCode, interval, startDateTime, endDateTime, limit, offset).pipe(mergeMap(data => {
      return of(data);
    }));
  }


  getStationSolarExposureData(stationCode, interval, startDateTime, endDateTime, limit, offset) {
    return this.weatherService.getStationSolarExposureData(stationCode, interval, startDateTime, endDateTime, limit, offset).pipe(mergeMap(data => {
      return of(data);
    }));
  }


  getStationWindData(stationCode, interval, startDateTime, endDateTime, limit, offset) {

    if (interval == "MINUTE_DATA") {
      return this.weatherService.getStationWindRoseMinuteData(limit,offset, stationCode, startDateTime, endDateTime).pipe(mergeMap( data => {
        return of(data);
      }));
    } 

    return this.weatherService.getStationWindData(stationCode, interval, startDateTime, endDateTime, limit, offset).pipe(mergeMap(data => {
      return of(data);
    }));


  }


  getStationDeltaTData(stationCode, interval, startDateTime, endDateTime, limit, offset) {
    return this.weatherService.getStationDeltaTData(stationCode, interval, startDateTime, endDateTime, limit, offset).pipe(mergeMap(data => {
      //return Observable.of(data);
      return of(data);
    }));
  }


  getStationRainFallData(stationCode, interval, startDateTime, endDateTime, limit, offset) {
    return this.weatherService.getStationRainFallData(stationCode, interval, startDateTime, endDateTime, limit, offset).pipe(mergeMap(data => {
      return of(data);
    }));
  }

}
