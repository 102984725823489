import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ForecastingViewModel } from './forecasting.model';
import * as moment from 'moment';


@Injectable()
export class ForecastingService {

  apiUrl = environment.FORECASTING_APIURI;

  constructor(
    private http: HttpClient
  ) { }

  getForecastForLocation(lat: Number, lng: Number): Observable<ForecastingViewModel[]> {
    return this.http.get<ForecastingViewModel[]>(this.apiUrl + "?lat=" + Number.parseFloat(lat.toFixed(4)) + "&lon=" + Number.parseFloat(lng.toFixed(4)),)
        .pipe(map(res => {
        return (<any>res).properties.timeseries.map(item => {
          return new ForecastingViewModel(item);
        }).sort((a, b) => {
          const dateA = moment.utc(a.ForcastDateTime);
          const dateB = moment.utc(b.ForcastDateTime);
          return dateA.diff(dateB);
        });
      }));
  }
}