<div class="menuBarItemContainer"  [ngClass]="{'active': isActive }">
  <div class="inner-wrapper">


    <div class="toggle-button-wrapper">
      Dark theme
      <span class="togglebutton">
        <label>
          <input type="checkbox" [(ngModel)]="enableDarkTheme" (click)="toggleTheme()" />
          <span class="toggle"></span>
        </label>
      </span>
    </div>



    <button (click)="navigateToPage('change-log')">What's new <span class="notification version-number">v{{versionNumber}} </span>
      <div class="icon icon-arrow-right-icon"></div>
    </button>
    <button (click)="navigateToPage('video-tips')">Video tips <span class="notification version-number">added</span>
      <div class="icon icon-arrow-right-icon"></div>
    </button>




  </div>
  <div class="metric-arrow-down"></div>
</div>

<button type="button" class="btn btn-outline-secondary" (click)="toggleIsActive()">
  <div class="wrapper more">
    <div class="icon icon-sub-nav-horizontal-icon"></div>
    <div class="dock-title">More</div>
  </div>



  <span class="dot" *ngIf="moreVisited !== true">
    <div class="number">1</div>
  </span>
</button>