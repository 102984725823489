<div class="menuBarItemContainer" [ngClass]="{'active': isActive }">


  <div class="inner-wrapper" data-simplebar>
    <div class="DropDownInner">
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'AirTemperature' }"
        (click)="onChangeMetricDisplay('AirTemperature')">Temperature
        <span>°C</span>

      </button>

      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'AirTemperatureMaxLast24Hrs' }"
      (click)="onChangeMetricDisplay('AirTemperatureMaxLast24Hrs')">
      Max
      temperature
      <span>last 24Hrs °C</span>
    </button>

      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'AirTemperatureMinLast24Hrs' }"
        (click)="onChangeMetricDisplay('AirTemperatureMinLast24Hrs')">
        Min
        temperature
        <span>last 24Hrs °C</span>
      </button>

      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'RainFallMetrics.Since9AM' }"
        (click)="onChangeMetricDisplay('RainFallMetrics.Since9AM')">
        Rainfall <span>since 9am mm</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'RainFallMetrics.To9AM' }"
        (click)="onChangeMetricDisplay('RainFallMetrics.To9AM')">
        Rainfall <span>previous 24hrs to 9am mm</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'RainFallMetrics.Last7Days' }"
        (click)="onChangeMetricDisplay('RainFallMetrics.Last7Days')">
        Rainfall<span>last 7 Days mm</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'RainFallMetrics.MonthToDate' }"
        (click)="onChangeMetricDisplay('RainFallMetrics.MonthToDate')">Rainfall
        <span>MTD
          mm</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'RainFallMetrics.YearToDate' }"
        (click)="onChangeMetricDisplay('RainFallMetrics.YearToDate')">Rainfall
        <span>YTD mm</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'RAIN_WDR' }"
        (click)="onChangeMetricDisplay('RAIN_WDR')">Rainfall <span> within
        date range
          mm</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'RelativeHumidity' }"
        (click)="onChangeMetricDisplay('RelativeHumidity')">Relative humidity
        <span>%</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'WIND_DIRECTION' }"
        (click)="onChangeMetricDisplay('WIND_DIRECTION')">Wind
        direction/speed <span>&nbsp;(10min avg)</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'SoilTemperature' }"
        (click)="onChangeMetricDisplay('SoilTemperature')">Soil temperature
        <span> °C</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'SolarExposure' }"
        (click)="onChangeMetricDisplay('SolarExposure')">Solar
        exposure <span>since 12am MJ/m²</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'EtoShortCrop' }"
        (click)="onChangeMetricDisplay('EtoShortCrop')">ETo short  <span>  since
       12am
          mm</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'EtoTallCrop' }"
        (click)="onChangeMetricDisplay('EtoTallCrop')">ETo tall <span>  since  12am
          mm</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'getLastUpdatedMinutes' }"
        (click)="onChangeMetricDisplay('getLastUpdatedMinutes')">Last updated
        <span>
          (mins)</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'ErrorsToday' }"
        (click)="onChangeMetricDisplay('ErrorsToday')">Errors
        <span>since 12am</span>
      </button>
      <button class="dropdown-item" [ngClass]="{'active' : weatherVariableSelected == 'ErrorsLast7Days' }"
        (click)="onChangeMetricDisplay('ErrorsLast7Days')">Errors <span>last 7 days</span>
      </button>
    </div>

  </div>

  <div class="actions">
    <button type="button" class="labels" (click)="toggleMarkersValue()"
      [ngClass]="{'active': mapSettings.showMarkerLabels == true}">{{metricValuesOptionLabel}}
    </button>
  </div>

  <div class="metric-arrow-down"></div>
</div>


<button type="button" class="btn btn-outline-secondary" (click)="toggleIsActive()">
  <div class="icon-container"><span class="icon icon-weather-variables"></span></div>
  <div class="wrapper">
    <div class="dock-title">Weather</div>
    <div class="selected"> {{metricChosen}}<span>{{metricAdditionalInfomation}}</span></div>
    <div class="icon icon-play"></div>
  </div>
</button>