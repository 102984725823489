<div id="summary-metric-container" [ngClass]="{'active' : mapSettings.isSummaryMetricTable == true}">

  <button type="button" class="close" aria-label="Close" (click)="closeSummary()">
    <span aria-hidden="true"><span class="icon icon-cancel-button-icon"></span></span>
  </button>


  <!-- Summary Table [style]="{width:'100%'}"-->
  <p-table #dt [columns]="selectedColumns" [value]="data" [resizableColumns]="true" scrollHeight="85vh" [scrollable]="true"
      [responsive]="false" selectionMode="multiple" sortOrder="descending"
    sortMode="single" [(selection)]="selectedData" [reorderableColumns]="true" [lazy]="true"
    (onLazyLoad)="loadTabularDataLazy($event)" [paginator]="false" [rows]="limit" [totalRecords]="totalRecords"
    [loading]="loading" exportFilename="exportFilename" dataKey="stationCode">

    <!--<div class="tabular-data-inside-container" #tabularContainer>-->
      <!-- Caption-->
      <ng-template pTemplate="caption">



        <div class="selectWrapper">
          <label>10 day summary </label>


          <div class="toolbar-wrapper" [ngClass]="{'active' : displayFilterMenu == true}">

            <div class="toolbar-wrapper-inside" [ngClass]="{'active' : displayFilterMenu == true}">


              <div class="select-wrapper">
                <span class="category-title">{{currentSelectedDescription}}</span>
                <!-- Measurement Selection List -->
                <angular2-multiselect [data]="measurementOptions" [(ngModel)]="selectedMeasurementOption"
                  [settings]="dropdownSettings" (onSelect)="onMeasurementOptionSelected($event)">

                </angular2-multiselect>

              </div>

              <!-- /Measurement Selection List -->


              <div class="ui-helper-wrapper">
                <div class="ui-helper-clearfix">
                  <button type="button" (click)="export()">Export All</button>
                  <button type="button" (click)="export(true)">Export Selected</button>
                </div>
              </div>

            </div>


          </div>




        </div>


      </ng-template>
      <!-- /Caption-->



      <!-- Column Groups --><!--
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col style="width: 100px;">
          <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}" [frozen]="col.frozen">
        </colgroup>
      </ng-template>-->
      <!-- /Column Groups -->



      <!-- Column Headers -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="max-width: 100px;"></th>

          <th *ngFor="let col of columns" pTooltip="{{col.header}}" tooltipPosition="top" pReorderableColumn [ngStyle]="{'max-width': col.width}"  [frozen]="col.frozen"
            [pSortableColumn]="col.sortable == true ? col.field : null">{{col.header}}
            <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <!-- /Column Headers -->

    <!--</div>-->

    <!-- Table Data -->
    <ng-template id="primeNGBody" let-data pTemplate="body" let-columns="columns" let-rowIndex="rowIndex">
      <tr [pSelectableRow]="data" [pReorderableRow]="index" [pSelectableRowIndex]="rowIndex">
        <td style="width: 100px;">
          <a href="#" (click)="navigateToDashboard(data)">
            <div class="icon icon-search-icon" alt="daily recordings" title="daily recordings"></div>
          </a>
        </td>
        <td *ngFor="let c of columns" class="ui-resizable-column" [ngStyle]="{'width': c.width}">
          {{getFieldValue(c, data)}}
        </td>
      </tr>
    </ng-template>
    <!-- /Table Data -->

    <!-- No Data Records Message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <!--<td [attr.colspan]="columns.length">There is no data for the date range provided.</td>-->
      </tr>
    </ng-template>
    <!-- No Data Records Message -->

  </p-table>
  <!-- /Summary Table -->

</div>