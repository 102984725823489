<div class="menuBarModalOverlay" [ngClass]="{'active': isActive}" (click)="closeMenuBarPopUps()"></div>
<div class="menuBar">
  <div class="menuBarItem">
    <app-metric-select (onMetricChanged)="metricChanged($event)" (onClosePopOver)="closePopOver($event)" (onTogglePopOver)="togglePopOvers($event)" [isActive]="PanelSettings.metricPanelActive"></app-metric-select>
  </div>
  <div class="menuBarItem">
    <app-radar-select (onTogglePopOver)="togglePopOvers($event)" [isActive]="PanelSettings.radarPanelActive"></app-radar-select>
  </div>
  <div class="menuBarItem">
    <app-station-select (onTogglePopOver)="togglePopOvers($event)" [isActive]="PanelSettings.stationsPanelActive"></app-station-select>
  </div>
  <div class="menuBarItem">
    <app-map-layer-select (onTogglePopOver)="togglePopOvers($event)" [isActive]="PanelSettings.mapLayersPanelActive"></app-map-layer-select>
  </div>
  <div class="menuBarItem menuBarSettingsItem">
    <app-more-select (onTogglePopOver)="togglePopOvers($event)" [isActive]="PanelSettings.morePanelActive"></app-more-select>
  </div>
</div>