import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StateService } from '../../../services/state/state.service';
import { DataCache } from '../../../services/data-cache/data-cache.service';

@Component({
  selector: 'app-radar-select',
  templateUrl: './radar-select.component.html',
  styleUrls: ['./radar-select.component.scss']
})
export class RadarSelectComponent implements OnInit {


  public availableRadars;
  public selectedRadars: string;
  public isAnyRadarOffline: boolean = false;
  public mapSettings;
  public selectButtonText: string;
  @Input() isActive: boolean;

  @Output() onTogglePopOver= new EventEmitter();

  public showRadarWarningPanel: boolean = false;
  public radarOutageMessage: string;

  constructor(
    private stateService: StateService,
    private dataCacheService: DataCache
  ) { }


  ngOnInit() {
    this.availableRadars = this.stateService.mapSettings.radarOptions;
    this.mapSettings = this.stateService.mapSettings;
    this.checkRadarAvailability();
    this.updateDisplayString();
  }

  toggleIsActive() {
    //this.isActive = !this.isActive;
    if (this.showRadarWarningPanel === true) {
      this.showRadarWarningPanel = false;
    }
    this.onTogglePopOver.emit('radarPanelActive');
  }

  /**
   * Toggle and raise the change that the Radar is being toffle on/off
   * which will be picked up by any subscribers to the ToggleRadarSubject that
   * is within the state service.
   * @param radar The code used by BOM to identify the Radar.
   */
  toggleRadarBounds(radar) {
    radar.isChecked = !radar.isChecked;
    this.stateService.raiseToggleRadar(radar);
    this.updateDisplayString();
  }


  /**
   * Display to be shown in the toolbar for the Radar menu button to indicate
   * o the user the selection that has been made.
   */
  updateDisplayString() {
    const countSelection = this.availableRadars.filter(r => r.isChecked === true).length;
    if (countSelection === 0) {
      this.selectedRadars = 'Select';
    } else if (countSelection === 1) {
      // Show the one selected
      this.selectedRadars = this.availableRadars.filter(r => r.isChecked === true)[0].name;
    } else {
      this.selectedRadars = 'Multiple';
    }


    if (countSelection === this.availableRadars.length) {
      this.selectButtonText = 'Deselect all';
    } else {
      this.selectButtonText = 'Select All';
    }

  }


  /**
   * Check the availability of the Radar using the new service
   * This will determine if the RADAR is online or enabled.
   */
  checkRadarAvailability() {

    this.dataCacheService.getRadars().subscribe(
      data => {
        // Update the Radars in state

        for (let i = 0; i < this.availableRadars.length; i++) {
          if (this.availableRadars[i] !== undefined) {
            const radarInfo = data.find(r => r.Code === this.availableRadars[i].id);
            if (radarInfo !== undefined && radarInfo !== null) {
              this.availableRadars[i].isOnline = radarInfo.isOnline;
              this.availableRadars[i].offlineReason = radarInfo.OfflineMessage;

              if (radarInfo.isOnline === false) {
                this.availableRadars[i].toolTipMessage = 'Radar Offline';
                this.isAnyRadarOffline = true;
              }

              if (this.availableRadars[i].isChecked) {
                this.stateService.raiseToggleRadar(this.availableRadars[i]);
              }

            }
          }
        }
      });
  }


  /**
   * Select All input allowing all of the radars to be turned on / off in one go
   * The value of the input is passed for toggling the Radars.
   */
  changeAllRadarCheckBox(event: any) {
    const toggleValue =  this.selectButtonText === 'Select All' ? true : false;
    this.stateService.raiseToggleAllRadars(toggleValue);
    this.updateDisplayString();
  }

  /**
   * Clicking on the warning triangle we can show more information about the radar
   * and why it is down.
   * @param item radar with details.
   */
  showRadarInformationPanel(item) {
    this.showRadarWarningPanel = true;
    this.radarOutageMessage = item.toolTipMessage;
  }


  /**
   * Close the Radar warning panel
   */
  closeRadarInformationPanel(){
    this.showRadarWarningPanel = false;
  }

}
