export class StationAvailability {

  Code: string;
  Name: string;

  To9AM: number;
  Since9AM: number;
  Since12AM: number;
  CurrentHour: number;
  Last24Hours: number;
  Last7DaysSince9AM: number;
  Last7DaysSince12AM: number;
  Last14DaysSince9AM: number;
  Last14DaysSince12AM: number;
  MonthToDateto9AM: number;
  MonthToDateSince12AM: number;
  YearToDateto9AM: number;
  YearToDateSince12AM: number;

  constructor(response?: any) {
    if (response !== null && response !== undefined) {
     this.Code = response.stationCode;
     this.Name = response.stationName;
     this.To9AM = response.availability.to9AM;
     this.Since9AM = response.availability.since9AM;
     this.Since12AM = response.availability.since12AM;
     this.CurrentHour = response.availability.currentHour;
     this.Last24Hours = response.availability.last24Hours;
     this.Last7DaysSince9AM = response.availability.last7Days.since9AM;
     this.Last7DaysSince12AM = response.availability.last7Days.since12AM;
     this.Last14DaysSince9AM = response.availability.last14Days.since9AM;
     this.Last14DaysSince12AM = response.availability.last14Days.since12AM;
     this.MonthToDateto9AM = response.availability.monthToDate.to9AM;
     this.MonthToDateSince12AM = response.availability.monthToDate.since12AM;
     this.YearToDateto9AM = response.availability.yearToDate.to9AM;
     this.YearToDateSince12AM = response.availability.yearToDate.since12AM;
    }
  }
}
