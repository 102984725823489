import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PrecipitationSummary } from './models/precipitation-summary.model';
import { RainfallSummary } from './models/rainfall-summary.model';
import { RadarModel } from './models/radar.model';


@Injectable()
export class RadarService {

  private ENDPOINT_URI: string = 'v2/radar';

  constructor(private http: HttpClient) {
  }

  getRadars(): Observable<RadarModel[]> {
    return this.http.get<RadarModel[]>(environment.RadarAPIURI + this.ENDPOINT_URI + '/radars?offset=0&limit=25&includeClosed=false')
        .pipe(map(res => {
        return (<any>res).collection.map(item => {
          return new RadarModel(item);
        });
      }));
  }


  /**
   * Get the rain ( precipitation ) summary from the RADAR tables via the API
   * for the given latitude and longitude.
   * @param lat the latitude point
   * @param lng the longitude point
   * @return Object 
   */
  getPrecipitationSummary(lat: number, lng: number, startMonthYear: string, endMonthYear: string): Observable<any> {
    return this.http.get<any>(environment.RadarAPIURI + this.ENDPOINT_URI  + '/summaries/monthly?startMonth=' + startMonthYear + '&endMonth=' + endMonthYear +'&latitude=' + lat +'&longitude=' + lng + '&select=radar,radarCode,radarLatitude,radarLongitude,radarDistance,period,periodFrom,periodTo,periodYear,periodMonth,periodDay,periodHour,periodMinute,rainfall&offset=0&limit=25')
      .pipe(map(res => {
        return new PrecipitationSummary(res.data);
      }));
  }

  /**
   * Get the rainfall for a specific radar
   * @param lat A latitude that will be used to find the nearest radar point.  
   * @param lng A longitude that will be used to find the nearest radar point
   */
  getRainfallSummary(lat: number, lng: number): Observable<any> {
      return this.http.get<any>(environment.RadarAPIURI + this.ENDPOINT_URI + '/rainfall?latitude=' + lat +'&longitude=' + lng + '&&select=dateTime,radar,radarCode,radarDistance,radarLatitude,radarLongitude,rainfall,rainfallCurrentHour,rainfallMonthToDate,rainfallSince9AM,rainfallYearToDate&offset=0&limit=25')
      .pipe(map(res => {
        return new RainfallSummary(res.data);
      }));
  }



  getPrecipitationAndRainfallSummary(lat: number, lng: number, startMonthYear: string, endMonthYear: string): Observable<any> {
    const summary = this.getPrecipitationSummary(lat, lng, startMonthYear, endMonthYear);
    const rainfall = this.getRainfallSummary(lat, lng);
    return forkJoin([summary, rainfall]); //https://www.agiratech.com/whats-new-in-rxjs-v6-5-and-check-what-has-changed
  }



}
