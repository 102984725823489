import { environment } from '../../../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class LoggingService {
  logToConsole(message: string, forceOutput: boolean = false) {
    if (window.console && (environment.production == false || forceOutput == true)) {
      console.log(message);
    }
  }

}
