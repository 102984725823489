import { StationAvailability } from '../services/weather/models/station-availability.model';

export class StationAvailabilityModel extends StationAvailability {

    constructor(stationAvailability?: StationAvailability) {
      super();

      if (stationAvailability !== undefined &&  stationAvailability !== null) {
        this.Code = stationAvailability.Code;
        this.Name = stationAvailability.Name;
        this.To9AM = stationAvailability.To9AM;
        this.Since9AM = stationAvailability.Since9AM;
        this.Since12AM = stationAvailability.Since12AM;
        this.CurrentHour = stationAvailability.CurrentHour;
        this.Last24Hours = stationAvailability.Last24Hours;
        this.Last7DaysSince9AM = stationAvailability.Last7DaysSince9AM;
        this.Last7DaysSince12AM = stationAvailability.Last7DaysSince12AM;
        this.Last14DaysSince9AM = stationAvailability.Last14DaysSince9AM;
        this.Last14DaysSince12AM = stationAvailability.Last14DaysSince12AM;
        this.MonthToDateto9AM = stationAvailability.MonthToDateto9AM;
        this.MonthToDateSince12AM = stationAvailability.MonthToDateSince12AM;
        this.YearToDateto9AM = stationAvailability.YearToDateto9AM;
        this.YearToDateSince12AM = stationAvailability.YearToDateSince12AM;
      }
    }
}
