<div class="outer-wrapper">
  <div class="container top">
    <div class="row">
      <div class="content-page">
        <h2>Disclaimer</h2>

        <p>Website Disclaimer for the Department of Primary Industries and Regional Development, Western
          Australia. </p>

        <p>
          The Western Australian Government is committed to quality service to its customers and seeks to
          ensure accuracy, currency and reliability of information and transactions provided over the
          internet, but this cannot be guaranteed. Accordingly, to the fullest extent permitted by law, the
          State of Western Australia and its officers and employees are released from liability (including in
          respect of negligence) for any loss, damage, cost and expense caused by use of or reliance on this
          website and the information contained on it. The State of Western Australia Government has no
          control over the content of material accessible on any non-Western Australian Government site that
          is referenced from this website. It is your responsibility to make your own decisions about the
          suitability, relevance, accuracy, currency, reliability and privacy practices of any external
          websites.
        </p>


        <h4>Reasons weather data may be inaccurate or unavailable</h4>

        <p>Weather observations may be incorrect for reasons including:</p>
        <ul>

          <li>Probe or partial equipment failure, leading to faults affecting some observations and not others</li>
          <li>Failing battery</li>
          <li>A probe may lose its calibration</li>
          <li>Telecommunications interference</li>



        </ul>

        <p>Weather stations may fail and provide only bad data or no data at all for reasons including:</p>

        <ul>

          <li>Battery failure</li>
          <li>Equipment failure or damage</li>
          <li>Lightning strike</li>
          <li>Telecommunications outage</li>

        </ul>


      </div>
    </div>
  </div>
</div>
<app-station-table></app-station-table>