/* Extended Imports */
import { Station } from '../services/weather/models/station.model';

/* General Imports */
//import { StationLatestModel } from './station-latest.model';
//import { PrecipitationSummaryModel } from '../services/radar/models/precipitation-summary.model';
//import { RainFallMetricsModel } from './rainfall-metrics.model';
//import { StationNearbyModel } from './station-nearby.model';

import * as moment from 'moment';

/*
 * Application Model of a Weather Station
 * 
 */
export class StationModel extends Station {

  // Additional Fields for helping with application
  public isFavourite: false;
  public isFavoriteRowCollapsed: false;
  public OwnerShortCode: string;
  // End Additional Fields

  //public Radar: PrecipitationSummaryModel;
  //public Rainfall: RainFallMetricsModel;

  // public LatestSummary: StationLatestModel;

  // public NearbyStations: StationNearbyModel


  //constructor() {
  //    super();
  // }

  constructor(station?: Station) {
    super();
    if (station !== undefined && station !== null) {
      this.Code = station.Code;
      this.Name = station.Name;
      this.Latitude = station.Latitude;
      this.Longitude = station.Longitude;
      this.Owner = station.Owner;
      this.OwnerCode = station.OwnerCode;
      this.Altitude = station.Altitude;
      this.Model = station.Model;
      this.StartDate = station.StartDate;
      this.EndDate = station.EndDate;
      this.ProbeHeight = station.ProbeHeight;
      this.RainGaugeHeight = station.RainGaugeHeight;
      this.WindProbeHeights = station.WindProbeHeights;
      this.Comments = station.Comments;
      this.Status = station.Status;
      this.Online = station.Online;
      this.JobNo = station.JobNo;

      /*
      // Assign a code to the station to be used to determine the type
      // current based on the owner name string;
      // and also set the station owner name to something smaller.
      // Currently a change going through to have a shorter name
      */

      switch (this.OwnerCode.toLowerCase()) {
        case 'dpird':
          this.Owner =  'DPIRD Station';
          break;
        case 'dprid': //CATER FOR CURRENT DATA ERROR FROM API
          this.Owner =  'DPIRD Station';
          this.OwnerCode = 'dpird';
          break;  
        case 'dbca':
          this.Owner =  'DBCA Station';
          break;
        case 'wc':
          this.Owner =  'WaterCorp Station';
          break;
        case 'bom':
          this.Owner =  'BoM Station';
          break;
        case 'pbc':
          this.Owner =  'Pardoo Beef Station';
          break;
        case 'hw':
          this.Owner =  'Harvey Water Station';
          break;
        case 'dfes':
          this.Owner =  'DFES Station';
          break;
        default:
          // No need to do anyting
          break;
      }

      this.OwnerShortCode = this.OwnerCode.toUpperCase();


    }

    //  this.Radar = null;
    //  this.Rainfall = null;
    //  this.LatestSummary = null;
    //  this.NearbyStations = null;

  }



  public isStationOnline() {
    return (this.Online === true);
  }

  public hasWindProbes() {
    return (this.WindProbeHeights.length !== 0);
  }

  public getWindHeightsDescription() {
    if (this.hasWindProbes) {
      let description = "";
      for (var i = 0; i < this.WindProbeHeights.length; i++) {
        description += this.WindProbeHeights[i] + (i != this.WindProbeHeights.length - 1 ? "m," : "m");
      }
      return description;
    }
    return "";
  }

  public getStartDate(formatMask: string) {
    if (this.StartDate !== null) {
      return moment(this.StartDate).format(formatMask);
    }
  }

  public getEndDate(formatMask: string) {
    if (this.EndDate !== null) {
      return moment(this.EndDate).format(formatMask);
    }
  }

}