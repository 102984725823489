/*
 * Definition of a Weather Station
 */
export class Station {

  public Code: string;
  public Name: string;
  public Latitude: number;
  public Longitude: number;
  public Owner: string;
  public OwnerCode: string; // Added in later API
  public Altitude: number;
  public Model: string;
  public StartDate: string;
  public EndDate: string;
  public ProbeHeight: number;
  public RainGaugeHeight: number;
  public WindProbeHeights: Array<number>;
  public Comments: string;
  public Status: string;
  public Online: boolean;
  public JobNo: string;

  constructor(response?: any ) {
    if (response !== null && response !== undefined) {
      this.Code = response.stationCode;
      this.Name = response.stationName;
      this.Latitude = response.latitude;
      this.Longitude = response.longitude;
      this.Owner = response.owner;
      this.OwnerCode = response.ownerCode;
      this.Altitude = response.altitude;
      this.Model = response.model;
      this.StartDate = response.startDate;
      this.EndDate = response.endDate;
      this.ProbeHeight = response.probeHeight;
      this.RainGaugeHeight = response.rainGaugeHeight;
      this.WindProbeHeights = response.windProbeHeights;
      this.Comments = response.comments;
      this.Status = response.status;
      this.Online = response.online;
      this.JobNo = response.jobNumber;
    }
  }
}