import { WindProbe } from './wind-probe.model';
import { StationPeriod } from './station-period.model';
import { AggregatedMetrics } from './aggregated-metrics.model';
import { EvapoTranspiration } from './evapo-transpiration.model';

import * as moment from 'moment';

export class StationSummary {

  public Period: StationPeriod;
  public AirTemperature: AggregatedMetrics;
  public ApparentAirTemperature: AggregatedMetrics;
  public RelativeHumidity: AggregatedMetrics;
  public DewPoint: AggregatedMetrics;
  public PanEvaporation: number;
  public PanEvaporation12AM: number;
  public Evapotranspiration: EvapoTranspiration;
  public RichardsonUnits: number;
  public ChillHours: number;
  public TotalSolarExposure: number;
  public TotalSolarExposureMJ: number;
  public Rainfall: number;
  public RainDays: number;
  public SoilTemperature: AggregatedMetrics;
  public DeltaT: AggregatedMetrics;
  public WetBulb: AggregatedMetrics;

  public BarometricPressure: AggregatedMetrics;


  public FrostCondMinutes: number;
  public FrostConditionDays: number;
  public FrostCondStartTime: string;

  public HeatCondMinutes: number;
  public HeatConditionDays: number;
  public HeadCondStartTime: string;

  public MinBatteryVoltage: number;
  public MinBatteryVoltageDateTime: string;
 // public Observations: number;
  public AvailabilityHours: number;
  public AvailabilityHoursTime: string;
  public ErrorCount: number;

  public ErosionConditionMinutes: number;
  public ErosionConditionDays: number;
  public ErosionConditionStartTime: string;


  public StationAvailabilityTo9AM: string;
  public StationAvailabilitySince12AM: string;

  public StationAvailabilityTo9AMTime: string;
  public StationAvailabilitySince12AMTime: string;


  public WindProbes: WindProbe[];

  constructor(response?: any ) {

    if (response !== null && response !== undefined) {
      
      this.Period = new StationPeriod(
        response.period.from,
        response.period.to,
        response.period.year,
        response.period.month,
        response.period.day,
        response.period.hour,
        response.period.minute);

      this.AirTemperature = new AggregatedMetrics(response.airTemperature);
      this.ApparentAirTemperature = new AggregatedMetrics(response.apparentAirTemperature);
      this.RelativeHumidity = new AggregatedMetrics(response.relativeHumidity);
      this.DewPoint = new AggregatedMetrics(response.dewPoint);
      this.PanEvaporation = response.panEvaporation;
      this.PanEvaporation12AM = response.panEvaporation12AM != undefined ? response.panEvaporation12AM  : null;
      this.Evapotranspiration = new EvapoTranspiration(response.evapotranspiration.shortCrop, response.evapotranspiration.tallCrop );
      this.RichardsonUnits = response.richardsonUnits;
      this.ChillHours = response.chillHours;
      this.TotalSolarExposure = response.solarExposure !== null ? response.solarExposure.toFixed(1)  : response.solarExposure ;
      this.TotalSolarExposureMJ = response.solarExposure !== null ? (response.solarExposure / 1000).toFixed(1)  : response.solarExposure ;
      this.Rainfall = response.rainfall;
      this.RainDays = response.rainDays;
      this.SoilTemperature = new AggregatedMetrics(response.soilTemperature);
      this.DeltaT = new AggregatedMetrics(response.deltaT);
      this.WetBulb = new AggregatedMetrics(response.wetBulb);

  
      this.BarometricPressure = new AggregatedMetrics(response.barometricPressure);

      this.FrostCondMinutes = response.frostCondition.minutes;
      this.FrostConditionDays = response.frostCondition.days;
      this.FrostCondStartTime = response.frostCondition.startTime !== null ? moment(response.frostCondition.startTime).format("DD-MM-YYYY HH:mm") : null;

      this.MinBatteryVoltage = response.battery.minVoltage;
      this.MinBatteryVoltageDateTime = response.battery.minVoltageDateTime !== undefined && response.battery.minVoltageDateTime !== null ? moment(response.battery.minVoltageDateTime).format('DD-MM-YYYY HH:mm') : response.battery.minVoltageDateTime ;
 
      this.ErrorCount = response.errors;
      this.WindProbes = response.wind.map(windItem => new WindProbe(windItem));

      // Additional fields with API update
      // Availability
      this.AvailabilityHours = response.stationAvailability !== undefined ? response.stationAvailability : null;
      this.AvailabilityHoursTime = response.stationAvailability !== undefined ? this.formatDecimaltoTime(response.stationAvailability, 3600) : null;

      this.HeatCondMinutes = response.heatCondition.minutes;
      this.HeatConditionDays = response.heatCondition.days;
      this.HeadCondStartTime = response.heatCondition.startTime !== null ? moment(response.heatCondition.startTime).format('DD-MM-YYYY HH:mm') : null;

      this.ErosionConditionMinutes = response.erosionCondition.minutes;
      this.ErosionConditionDays = response.erosionCondition.days;
      this.ErosionConditionStartTime = response.erosionCondition.startTime !== null ? moment(response.erosionCondition.startTime).format('DD-MM-YYYY HH:mm') : null;

      this.StationAvailabilityTo9AM = response.stationAvailability.to9AM ;
      this.StationAvailabilitySince12AM = response.stationAvailability.since12AM;
      this.StationAvailabilityTo9AMTime = response.stationAvailability.to9AM !== null ? this.formatDecimaltoTime(response.stationAvailability.to9AM ) : null;
      this.StationAvailabilitySince12AMTime = response.stationAvailability.since12AM !== null ? this.formatDecimaltoTime(response.stationAvailability.since12AM) : null;  

    }
  }

  /*
  * Return the WindProbes collection.
  */
  getWindProbes() {
    return this.WindProbes;
  }


  /*
  * Format the percentage gived for the availability into
  * the number of hours/minutes that data was available.
  * depending on the summary the period of seconds would have to be
  * passed in if required.
  * 86400 seconds for day, 3600 seconds for hour.
  */
  formatDecimaltoTime(percentage: number, periodSeconds: number = 86400) {

    if (periodSeconds === 86400 && percentage === 100) { // Daily
      return '24:00'; // requirement to represent the full day as 24 hours in hh:mm format.
    }

    const decimalTimeString = (((percentage / 100) * periodSeconds)).toString();
    const baseDate = new Date(0, 0);

    baseDate.setSeconds(+decimalTimeString);
    return (baseDate.toTimeString().slice(0, 5));
  }
}
