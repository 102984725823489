<div id="filter-btn" (click)="toggleMobileFilter()">
  <div class="icon icon-plus-icon"></div>
</div>

<div class="filter-menu-wrapper" [ngClass]="{'active' : isMobileFilterActive === true}">


  <div id="option1" class="filter-options" [ngClass]="{'active' : filterMenu.option1 === true}">

    <div class="icon icon-cancel-button-icon top" (click)="toggleMobileFilter()"></div>

    <h2>Map options</h2>
    <div class="filter-container">

      <button (click)="setOption('option2')">
        <div class="icon-wrapper">
          <span class="icon icon-weather-icon"></span>
        </div>Weather variables
        <span class="sub-text">Set to {{metricChosen}}</span>
        <div class="icon icon-arrow-right-icon"></div>
      </button>

      <button (click)="setOption('option3')">
        <div class="icon-wrapper">
          <span class="icon icon-controls-icon"></span>
        </div> Map controls
        <span class="sub-text">Set to {{currentMapTiles}}</span>
        <div class="icon icon-arrow-right-icon"></div>
      </button>

      <button (click)="setOption('option4')">
        <div class="icon-wrapper">
          <span class="icon icon-map-icon"></span>
        </div> Map regions
        <span class="sub-text">Set to {{currentMapRegion}}</span>
        <div class="icon icon-arrow-right-icon"></div>
      </button>

    </div>


  </div>



  <div id="option2" class="filter-options" [ngClass]="{'active' : filterMenu.option2 === true}">
    <div class="icon icon-left-arrow-icon" (click)="setOption('option1')"></div>
    <div class="icon icon-cancel-button-icon top" (click)="toggleMobileFilter()"></div>

    <h2>Weather variables</h2>


    <div class="filter-container">
      <button class="dropdown-item reset"
        (click)="onChangeMetricDisplay('RESET_MARKERS', 'Weather variables')">Reset</button>
      <button class="dropdown-item" (click)="onChangeMetricDisplay('AirTemperature', 'Temperature °C')">Temperature
        °C</button>
        <button class="dropdown-item" (click)="onChangeMetricDisplay('AirTemperatureMinLast24Hrs', 'Min Temperature Last 24Hrs °C')">Min temperature last 24Hrs °C
        </button>
      <button class="dropdown-item"
        (click)="onChangeMetricDisplay('RainFallMetrics.Since9AM', 'Rainfall since 9am mm')">Rainfall since 9am
        mm</button>
      <button class="dropdown-item"
        (click)="onChangeMetricDisplay('RainFallMetrics.To9AM', 'Rainfall previous 24hrs to 9am mm')">Rainfall previous
        24hrs to 9am mm</button>
      <button class="dropdown-item"
        (click)="onChangeMetricDisplay('RainFallMetrics.Last7Days', 'Rainfall last 7 Days mm')">Rainfall last 7 Days
        mm</button>
      <button class="dropdown-item"
        (click)="onChangeMetricDisplay('RainFallMetrics.MonthToDate', 'Rainfall MTD mm')">Rainfall MTD mm</button>
      <button class="dropdown-item"
        (click)="onChangeMetricDisplay('RainFallMetrics.YearToDate', 'Rainfall YTD mm')">Rainfall YTD mm</button>
      <button class="dropdown-item"
        (click)="onChangeMetricDisplay('RAIN_WDR', 'Rainfall within date range mm')">Rainfall within date range
        mm</button>
      <button class="dropdown-item" (click)="onChangeMetricDisplay('RelativeHumidity', 'Relative humidity')">Relative
        humidity %</button>
      <button class="dropdown-item"
        (click)="onChangeMetricDisplay('WIND_SPEED_KMH', 'Wind speed km/h (10min avg)')">Wind speed km/h (10min
        avg)</button>
      <button class="dropdown-item" (click)="onChangeMetricDisplay('WIND_DIRECTION', 'Wind direction (10min avg)')">Wind
        direction (10min avg)</button>
      <button class="dropdown-item" (click)="onChangeMetricDisplay('SoilTemperature', 'Soil temperature °C')">Soil
        temperature °C</button>
      <button class="dropdown-item"
        (click)="onChangeMetricDisplay('SolarExposure', 'Solar exposure since 12am MJ/m²')">Solar exposure since 12am
        MJ/m²</button>
      <button class="dropdown-item" (click)="onChangeMetricDisplay('EtoShortCrop', 'ETo short since 12am mm')">ETo short
        since 12am mm</button>
      <button class="dropdown-item" (click)="onChangeMetricDisplay('EtoTallCrop', 'ETo tall since 12am mm')">ETo tall
        since 12am mm</button>
      <button class="dropdown-item" (click)="onChangeMetricDisplay('getLastUpdatedMinutes', 'Last updated (mins)')">Last
        updated (mins)</button>
      <button class="dropdown-item" (click)="onChangeMetricDisplay('ErrorsToday', 'Errors since 12am')">Errors since
        12am</button>
      <button class="dropdown-item" (click)="onChangeMetricDisplay('ErrorsLast7Days', 'Errors last 7 days')">Errors last
        7 days</button>
    </div>
  </div>


  <div id="option3" class="filter-options" [ngClass]="{'active' : filterMenu.option3 === true}">
    <div class="icon icon-left-arrow-icon" (click)="setOption('option1')"></div>
    <div class="icon icon-cancel-button-icon top" (click)="toggleMobileFilter()"></div>

    <h2>Map Controls</h2>

    <div class="filter-container">
        <app-map-controls-sidebar></app-map-controls-sidebar>
    </div>

  </div>


  <div id="option4" class="filter-options" [ngClass]="{'active' : filterMenu.option4 === true}">
    <div class="icon icon-left-arrow-icon" (click)="setOption('option1')"></div>
    <div class="icon icon-cancel-button-icon top" (click)="toggleMobileFilter()"></div>
    <h2>Map Regions</h2>
    <div class="filter-container">
      <button class="btn btn-sm region-button" *ngFor="let region of mapRegions"
        (click)="setMapRegion(region)">{{region.name}}</button>
    </div>
  </div>
</div>