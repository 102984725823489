import { StationNearby } from '../services/weather/models/station-nearby.model';

export class StationNearbyModel extends StationNearby {

  Code: string;
  Name: string;
  StartDate: string;
  EndDate: string;
  Distance: number;
  Latitude: number;
  Longitude: number;
  Owner: string;
  Altitude: number;
  Model: string;
  Online: boolean;
  Status: string;
  Comments: string;
  JobNumber: string;
 
  constructor(stationNearby: StationNearby) {
    super();

    this.Code = stationNearby.Code;
    this.Name = stationNearby.Name;
    this.StartDate = stationNearby.StartDate;
    this.EndDate = stationNearby.EndDate;
    this.Distance = stationNearby.Distance;
    this.Latitude = stationNearby.Latitude;
    this.Longitude = stationNearby.Longitude;
    this.Owner = stationNearby.Owner;
    this.Altitude = stationNearby.Altitude;
    this.Model = stationNearby.Model;
    this.Online = stationNearby.Online;
    this.Status = stationNearby.Status;
    this.Comments = stationNearby.Comments;
    this.JobNumber = stationNearby.JobNumber;
    
  }
}
