<div class="menuBarItemContainer" [ngClass]="{'active': isActive }">

  <div class="inner-wrapper" data-simplebar>
    <div class="close-button" (click)="toggleIsActive()"><span class="icon icon-arrow-down-icon"></span></div>



    <div class="multiselect btn-group btn-group-toggle">

      <div class="radar-outage-info" [ngClass]="{'active': showRadarWarningPanel }">
        <div class="icon icon-cancel-button-icon" (click)="closeRadarInformationPanel()"></div>
        {{radarOutageMessage}}     
      </div>

      <!-- Available Radars -->
      <label *ngFor="let item of availableRadars; let i = index" class="btn-primary" ngbButtonLabel>
        <input type="checkbox" ngbButton [(ngModel)]="item.isChecked" (click)="toggleRadarBounds(item)"> {{item.name}}
        <button (click)="showRadarInformationPanel(item)" class="icon info-icon" [ngClass]="{'red-warning': item.isOnline == false}"
          *ngIf="item.isOnline === false || item.ImageErrorCount > 2"><span class="icon icon-alert-triangle"></span>
        </button>
      </label>
      <!-- /Available Radars-->

      <!-- Select All Button -->
      <label class="btn-primary all-radars-btn" ngbButtonLabel>
        <input type="checkbox" ngbButton [(ngModel)]="mapSettings.isAllRadarsChecked"
          (click)="changeAllRadarCheckBox($event)"> {{selectButtonText}}
      </label>
      <!-- /Select All Button-->

    </div>
  </div>
  <div class="metric-arrow-down"></div>
</div>

<!-- Radar Toolbar Button -->
<button type="button" class="radar btn btn-outline-secondary" (click)="toggleIsActive()">
  <div class="icon-container"><span class="icon icon-radar"></span></div>
  <div class="wrapper" [ngClass]="{'active': isAnyRadarOffline }">
    <div class="dock-title">Radars</div>
    <div class="selected">{{selectedRadars}}</div>
    <div class="dock icon icon-alert-triangle"></div>
    <div class="icon icon-play"></div>
  </div>
</button>
<!-- /Radar Toolbar Button -->