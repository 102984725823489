import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';

import { WeatherChartService } from '../../../services/weather/weather.chart.service';

import * as moment from 'moment'; // Include the library have to include reference in angular CLI to js file

// Add additional modules required
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);


const HOUR_INTERVAL = 3600000;
const DAY_INTERVAL = 86400000;       // 24 * 3600 * 1000;
const MONTHLY_INTERVAL = 2628000000;
const YEARLY_INTERVAL = 31536000000; // 365 Days


const PANEVAP_SERIES_COLOUR = '#199AD2';
const SHORT_SERIES_COLOUR = '#FFB202';
const TALL_SERIES_COLOUR = '#F65102';


@Component({
  selector: 'app-evaporation-chart',
  templateUrl: './evaporation-chart.component.html',
  styleUrls: ['./evaporation-chart.component.scss']
})
export class EvaporationChartComponent implements OnInit {

  station = { code: '', name: '' };

  Highcharts = Highcharts;
  isDataLoaded: boolean = false;

  tickInterval = DAY_INTERVAL;

  chartOptions: any = {
    credits: {
      enabled: false
    },
    yAxis: {
      title: {
        text: 'omm'
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },

    series: [{
      name: 'Pan Evapotranspiration',
      data: []
    }, {
      name: 'Short Crop',
      data: []
    }, {
      name: 'Tall Crop',
      data: []
    }
    ],
    chart: {
      zoomType: 'x'
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    exporting: {
      chartOptions: {
        credits: {
          enabled: true,
          text: 'Copyright Western Australia Agriculture ' + new Date().getFullYear(),
          href: 'https://www.agric.wa.gov.au'
        },
        chart: {
          events: {
            load: function () {
              this.renderer.image('https://weather.agric.wa.gov.au/assets/images/chart_dpird_logo.jpg', 10, 10, 160, 46).add();

            }
          }
        }
      }
    },
  }

  constructor(private weatherChartService: WeatherChartService) { }


  ngOnInit() {
    this.weatherChartService.onChartFilterParametersSet.subscribe(
      params => {
        this.onChartFilterParametersSet(params);
      });
  }



  setChartOptions(chartData, params) {

    let subtitleDateMask = 'DD-MM-YYYY';
    if (params.interval === 'HOURLY') {
      subtitleDateMask = 'DD-MM-YYYY hh:mma';
    }

    this.chartOptions = {
      title: {
        text: this.station.name + ' : Evapotranspiration (' + params.interval[0].toUpperCase() + params.interval.toLowerCase().substring(1) + ')'
      },
      subtitle: {
        text: moment(params.event.value[0]).format(subtitleDateMask) + ' - ' + moment(params.event.value[1]).format(subtitleDateMask)
      },
      xAxis: {
        tickInterval: this.tickInterval,
        type: 'datetime'
      },
      series: [{
        name: 'Pan Evapotranspiration',
        data: chartData.panEvaporation,
        color: PANEVAP_SERIES_COLOUR,
        marker: {
          fillColor: PANEVAP_SERIES_COLOUR,
          lineColor: '#FFFFFF',
          lineWidth: 1
        }
      }, {
        name: 'Short Crop',
        data: chartData.shortCrop,
        color: SHORT_SERIES_COLOUR,
        marker: {
          fillColor: SHORT_SERIES_COLOUR,
          lineColor: '#FFFFFF',
          lineWidth: 1
        }
      }, {
        name: 'Tall Crop',
        data: chartData.tallCrop,
        color: TALL_SERIES_COLOUR,
        marker: {
          fillColor: TALL_SERIES_COLOUR,
          lineColor: '#FFFFFF',
          lineWidth: 1
        }
      }
      ]
    }
  }



  onChartFilterParametersSet(params) {

    let stationCode = params.station.code;
    let interval = params.interval;
    let limit = 1000;
    let offset = 0;
    this.station = params.station;

    // Date parameters to be sent UTC datetime.
    // split routine filtering milliseconds.
    let startDateTime = moment(params.event.value[0]).format('YYYY-MM-DD HH:mm:ss');//params.event.value[0].toISOString().split('.')[0] + "Z";
    let endDateTime = moment(params.event.value[1]).format('YYYY-MM-DD HH:mm:ss');//params.event.value[1].toISOString().split('.')[0] + "Z";

    this.weatherChartService.getStationEvaporationData(stationCode, interval, startDateTime, endDateTime, limit, offset).subscribe(data => {

      // Format data for series
      var chartData = { panEvaporation: [], panEvaporation12AM: [], shortCrop: [], tallCrop: [] };

      for (let index in data) {
        let momentDate = moment(data[index].dateFrom);

        var tz_offset = (new Date().getTimezoneOffset() * -1) * 60 * 1000;
        var unix_time = momentDate.toDate().getTime() + tz_offset;
        chartData.panEvaporation.push(Array(unix_time, data[index].panEvaporation));
        //12AM is only for daily
        chartData.shortCrop.push(Array(unix_time, data[index].shortCrop));
        chartData.tallCrop.push(Array(unix_time, data[index].tallCrop));
      }

      // if required set the interval for a better representation on the chart.
      // defaulting to DAY for now can be changed.
      // this.tickInterval = 
      switch (interval) {
        case "HOURLY":
          this.tickInterval = HOUR_INTERVAL;
          break;
        case "MONTHLY":
          this.tickInterval = MONTHLY_INTERVAL;
          break;
        case "YEARLY":
          this.tickInterval = YEARLY_INTERVAL;
          break;
        default:
          //DAILY
          this.tickInterval = DAY_INTERVAL;
          break;
      }

      this.setChartOptions(chartData, params);

    });

    this.isDataLoaded = true;
  }
}