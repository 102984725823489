export class StationNearby {

  Code: string;
  Name: string;
  StartDate: string;
  EndDate: string;
  Distance: number;
  Latitude: number;
  Longitude: number;
  Owner: string;
  Altitude: number;
  Model: string;
  Online: boolean;
  Status: string;
  Comments: string;
  JobNumber: string;
 
  constructor(response?: any) {

    if (response !== undefined && response !== null) {
      this.Code = response.stationCode;
      this.Name = response.stationName;
      this.StartDate = response.dateTime;
      this.EndDate = response.endDate;
      this.Altitude = response.altitude;
      this.Model = response.model;
      this.Owner = response.owner;
      this.Online = response.online;
      this.Status = response.status;
      this.Comments = response.comments;
      this.JobNumber = response.jobNumber;
      this.Distance = response.distance;
      this.Latitude = response.latitude;
      this.Longitude = response.longitude;
    }
  }
}
