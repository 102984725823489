<div id="station-content" *ngIf="(stationModel !== undefined && stationModel !== null) && (stationLatestModel !== undefined && stationLatestModel !== null)">

    <app-station-table></app-station-table>


    <!--[ngClass]="{'scrolling': isContentScrolling == true }"-->
        <div class="station-header scrolling">

            <div class="station-info-container">

                <!-- <div class="icon icon-favourite-full-icon"
                      (click)="toggleFavourite(stationLatest)"
                      [ngClass]="{'active' : stationLatest.isFavourite == true}"></div>-->
            <div class="station-info-wrapper">


                <div class="BackToMap">
                    <app-rd-icon-button name="" icon="icon-left-arrow-icon" (click)="backToMap()"
                                        href="#"></app-rd-icon-button>
                </div>

                <div class="StationStatusWrapper"><span class="StationStatus" [ngClass]="{'orange' : stationIndicatorCode == 'O', 'red' : stationIndicatorCode == 'R' }"></span></div>
               
                <tpl-flip #CountDownFlipDashboard (finished)="onRefreshCountdownFinished()"></tpl-flip>

                <div class="StationMetaWrapper">


                    <div class="StationNameWrapper">
                        <div class="station-name">{{stationModel.Name}}</div>
                        <div class="station-code">({{stationCode}})</div>
                        <div class="ToolTip">
                            <button type="button" (click)="openVerticallyCentered(tipStation)">
                                <div class="icon icon-info-icon"></div>
                            </button>
                        </div>

                        <!-- Template Modal for Station -->
                        <ng-template #tipStation let-d="dismiss">
                            <div class="DashboardModal">

                                <div class="modal-header Meta">
                                    <div class="TitleWrapper">
                                        <h4 class="modal-title">{{stationModel.Name}} ({{stationModel.Code}})</h4>
                                        <div class="SubText">{{stationModel.Owner}}</div>
                                    </div>


                                    <div class="VariableWrapper">
                                        <div><strong>Job number:</strong></div>
                                        <div class="Value">{{stationModel.JobNo == null ? 'NA' : stationModel.JobNo}}</div>
                                    </div>


                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                        <span aria-hidden="true"><span
                                                class="icon icon-cancel-button-icon"></span></span>
                                    </button>
                                </div>

                                <div class="ToolTipWrapper modal-body Meta">

                                    <div class="VariablesOuterWrapperCol">

                                        <div class="VariableWrapper">
                                            <div class="variable">Code:</div>
                                            <div class="Value">{{stationModel.Code}}</div>
                                        </div>


                                        <div class="VariableWrapper">
                                            <div class="variable">Name:</div>
                                            <div class="Value">{{stationModel.Name}}</div>
                                        </div>

                                        <div class="VariableWrapper">
                                            <div class="variable">Owner:</div>
                                            <div class="Value">{{stationModel.Owner}}</div>
                                        </div>


                                        <div class="VariableWrapper">
                                            <div class="variable">Start date:</div>
                                            <div class="Value">{{stationModel.getStartDate('Do MMMM YYYY')}}</div>
                                        </div>

                                        <div class="VariableWrapper">
                                            <div class="variable">End date:</div>
                                            <div class="Value">{{stationModel.getEndDate('Do MMMM YYYY')}}</div>
                                        </div>
                                        <div class="VariableWrapper">
                                            <div class="variable">Altitude:</div>
                                            <div class="Value">{{stationModel.Altitude}}m</div>
                                        </div>


                                        <div class="VariableWrapper">
                                            <div class="variable">Model:</div>
                                            <div class="Value">{{stationModel.Model}}</div>
                                        </div>

                                    </div>

                                    <div class="VariablesOuterWrapperCol">
                                        <p><strong>Probe Heights</strong></p>

                                        <div class="VariableWrapper">
                                            <div>Air temp/humidity:</div>
                                            <div class="Value">{{stationModel.ProbeHeight}}m</div>
                                        </div>


                                        <div class="VariableWrapper">
                                            <div>Rain gauge:</div>
                                            <div class="Value">{{stationModel.RainGaugeHeight}}m</div>
                                        </div>


                                        <div class="VariableWrapper">
                                            <div>Wind:</div>
                                            <div class="Value">{{stationModel.getWindHeightsDescription()}}</div>
                                        </div>

                                    </div>

                                </div>
                                <div class="CommentsWrapper">
                                    <div class="Title">Comments</div>
                                    <div class="CommentsContainer">{{stationModel.Comments}}</div>
                                </div>

                                <div class="emphasized Meta">
                                    <div class="ColourLegend Mata">

                                        <div><span class="green"></span><strong>Online:</strong>
                                            <p>Station has reported data in the last hour.</p></div>
                                        <div><span class="orange"></span><strong>Short Outage:</strong>
                                            <p>Station has not reported data for between 1 and 3 hours.</p></div>
                                        <div><span class="red"></span><strong>Long Outage:</strong>
                                            <p>Station has not reported data for more than 3 hours.</p></div>


                                    </div>
                                </div>


                            </div>
                        </ng-template>
                        <!-- /Template Station -->
                    </div>
                    <div *ngIf="stationLatestModel !== null && stationLastUpdated !== ''" class="last-updated">Last updated :
                        {{stationLastUpdated}} <a
                                href="#" (click)="openStationNewWindow()" 
                                title="Open station in a new window"><span class="icon icon-new-window-icon"></span></a>
                    </div>


                </div>




            </div>

        </div>
        <div class="station-data-options">

            <div class="mobile-filter-menu" [ngClass]="{'active' : displayMobileFilterContextMenu == true}">
                <button class="btn btn-primary" (click)="toggleMobileFilterContextMenu()"> Weather data <span
                        class="icon icon-play"></span></button>
            </div>

            <div class="weather-data" [ngClass]="{'active' : displayMobileFilterContextMenu == true}">
                <button class="btn btn-primary" (click)="toggleTable('MINUTE')"
                        [ngClass]="{'active' : activeTables.isMinuteTableActive == true}">Minute
                </button>

                <button class="btn btn-primary" (click)="toggleTable('HOURLY')"
                        [ngClass]="{'active' : activeTables.isHourlyTableActive == true}">Hourly
                </button>

                <button class="btn btn-primary" (click)="toggleTable('DAILY')"
                        [ngClass]="{'active' : activeTables.isDailyTableActive == true}">Daily
                </button>


                <button class="btn btn-primary" (click)="toggleTable('MONTHLY')"
                        [ngClass]="{'active' : activeTables.isMonthlyTableActive == true}">Monthly
                </button>

                <button class="btn btn-primary" (click)="toggleTable('YEARLY')"
                        [ngClass]="{'active' : activeTables.isYearlyTableActive == true}">Yearly
                </button>

                <button class="btn btn-primary" (click)="toggleTable('CHARTS')"
                        [ngClass]="{'active' : activeTables.isChartsActive == true}">Charts
                </button>
            </div>
        </div>
    </div>


    <div id="dashboard-content"
         [class.no-radar]="stationModel == undefined || stationModel.Radar == undefined || stationModel.Radar == null" 
         *ngIf="stationRainfallModel !== null && stationDailySummary.RelativeHumidity !== undefined && stationDailySummary.RelativeHumidity !== null &&  stationLatestModel.RelativeHumidity !== null; else dashboard_loading" 
         (scroll)='onScroll($event);' data-simplebar>
         <!--perfectScrollbar-->

        <div id="dashboard-content-inside">






            <!-- MetricBoxWrapper  perfectScrollbar-->
            <div class="MetricBoxWrapper" data-simplebar>
                <div class="MetricBoxInnerWrapper">

                    <!--MetricBox-->
                    <div class="MetricBoxContainer"
                         *ngIf="stationDailySummary.AirTemperature !== undefined && stationDailySummary.AirTemperature !== null">
                        <div class="MetricBoxHeader">
                            <div class="MetricBoxTitle">Temperature</div>
                            <div class="MetricBoxInfo">
                                <!-- <div class="icon icon-info-icon"></div>-->
                            </div>
                        </div>

                        <div class="MetricBoxBody">
                            <div class="MetricBoxBodyInner">
                                <div class="MetricBoxValue">{{stationLatestModel.AirTemperature}}
                                    <div class="MetricBoxDesc"><span class="top"></span><span
                                            class="bottom">&#176;C</span>
                                    </div>
                                </div>
                                <div class="MetricBoxSubText">Feels like
                                    {{stationLatestModel.ApparentTemperature}}&#176;C
                                </div>
                            </div>
                        </div>

                        <div class="MetricBoxFooter">
                            <div class="left">Min {{stationDailySummary.AirTemperature.Min}}°C<br>{{stationDailySummary.AirTemperature.getMinDateTimeConditionalString('HH:mma')}}
                            </div>
                            <div class="right">Max {{stationDailySummary.AirTemperature.Max}}°C<br>{{stationDailySummary.AirTemperature.getMaxDateTimeConditionalString('HH:mma')}}
                            </div>

                        </div>
                    </div>
                    <!--/MetricBox-->


                    <!--MetricBox-->
                    <div class="MetricBoxContainer">
                        <div class="MetricBoxHeader">
                            <div class="MetricBoxTitle">Relative humidity</div>
                            <div class="MetricBoxInfo">
                                <!-- <div class="icon icon-info-icon"></div>-->
                            </div>
                        </div>

                        <div class="MetricBoxBody">
                            <div class="MetricBoxBodyInner">
                                <div class="MetricBoxValue">{{stationLatestModel.RelativeHumidity}}
                                    <div class="MetricBoxDesc"><span class="top"></span><span class="bottom">%</span>
                                    </div>
                                </div>
                                <div class="MetricBoxSubText">Current relative humidity</div>
                            </div>
                        </div>

                        <div class="MetricBoxFooter">
                            <div class="left">Min {{stationDailySummary.RelativeHumidity.Min}}%<br>
                                {{stationDailySummary.RelativeHumidity.getMinDateTimeConditionalString('HH:mma')}}
                            </div>
                            <div class="right">Max {{stationDailySummary.RelativeHumidity.Max}}%<br>
                                {{stationDailySummary.RelativeHumidity.getMaxDateTimeConditionalString('HH:mma')}}
                            </div>

                        </div>
                    </div>
                    <!--/MetricBox-->


                    <!--MetricBox-->
                    <div class="MetricBoxContainer">
                        <div class="MetricBoxHeader">
                            <div class="MetricBoxTitle">Rainfall</div>
                            <div class="MetricBoxInfo">
                                <!-- <div class="icon icon-info-icon"></div>-->
                            </div>
                        </div>

                        <div class="MetricBoxBody">
                            <div class="MetricBoxBodyInner">
                                <div class="MetricBoxValue">{{stationLatestModel.RainFallSince9AM !== null ? stationLatestModel.RainFallSince9AM : 0}}
                                    <div class="MetricBoxDesc"><span class="top"></span><span class="bottom">mm</span>
                                    </div>
                                </div>
                                <div class="MetricBoxSubText">Since 9am</div>
                            </div>
                        </div>

                        <div class="MetricBoxFooter">
                            <div class="left">24hrs to 9am
                                <br>{{stationRainfallModel.RainFallMetrics.To9AM}}mm
                            </div>
                            <div class="right">Last 14 days<br>{{stationRainfallModel.RainFallMetrics.Last14Days}}mm
                            </div>
                        </div>
                    </div>
                    <!--/MetricBox-->

                    <!--MetricBox-->
                    <div class="MetricBoxContainer"
                         *ngIf="stationDailySummary.WindProbes !== undefined && stationLatestModel.WindProbes !== undefined && stationDailySummary.WindProbes.length !== 0 && stationLatestModel.WindProbeAvgSpeed !== null; else windmetric_na">
                        <div class="MetricBoxHeader">
                            <div class="MetricBoxTitle">Wind</div>
                            <div class="MetricBoxInfo">
                                <!-- <div class="icon icon-info-icon"></div>-->
                            </div>
                        </div>

                        <div class="MetricBoxBody">
                            <div class="MetricBoxBodyInner">
                                <div class="MetricBoxValue">{{stationLatestModel.WindProbeAvgSpeed}}
                                    <div class="MetricBoxDesc">
                                        <span class="top"></span>
                                        <span class="bottom">km/h</span></div>
                                </div>
                                <div class="MetricBoxSubText">10min average at
                                    {{stationLatestModel.WindProbes[0].Height}}m
                                </div>
                            </div>
                        </div>

                        <div class="MetricBoxFooter {{stationLatestModel.WindProbeAvgDirectionCompassPoint}}">
                            <div class="left"><span class="icon icon-direction-icon"></span><span
                                    class="Direction">{{stationLatestModel.WindProbeAvgDirectionCompassPoint}}</span>
                            </div>
                            <div class="right">Max {{stationDailySummary.WindProbes[0].MaxSpeed}}km/h<br>{{stationDailySummary.WindProbes[0].MaxCompassPoint}}
                                {{stationDailySummary.WindProbes[0].getMaxDateTimeConditionalString('HH:mma')}}
                            </div>

                        </div>
                    </div>

                    <ng-template #windmetric_na>
                        <div class="MetricBoxContainer">
                            <div class="MetricBoxHeader">
                                <div class="MetricBoxTitle">Wind</div>
                                <div class="MetricBoxInfo">
                                    <!-- <div class="icon icon-info-icon"></div>-->
                                </div>
                            </div>

                            <div class="MetricBoxBody">
                                <div class="MetricBoxBodyInner">
                                    <div class="MetricBoxValue">
                                        <div class="MetricBoxDesc"><span class="top"></span><span
                                                class="bottom"></span></div>
                                    </div>
                                    <div class="MetricBoxSubText">Not available for this station</div>
                                </div>
                            </div>

                            <div class="MetricBoxFooter">
                                <div class="left"></div>
                                <div class="right">
                                    <br>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                    <!--/MetricBox-->

                    <!--MetricBox-->

                    <div class="MetricBoxContainer">
                        <div class="MetricBoxHeader">
                            <div class="MetricBoxTitle">Spraying Condition</div>
                            <div class="MetricBoxInfo">
                                <button type="button" class="icon icon-info-icon"
                                        (click)="openVerticallyCentered(tipContentDeltaT)"></button>
                                <!-- Template Modal for Spraying condition -->
                                <ng-template #tipContentDeltaT let-d="dismiss">
                                    <div class="DashboardModal">

                                        <div class="modal-header">
                                            <h4 class="modal-title">Spraying condition</h4>
                                            <button type="button" class="close" aria-label="Close"
                                                    (click)="d('Cross click')">
                                                <span aria-hidden="true"><span
                                                        class="icon icon-cancel-button-icon"></span></span>
                                            </button>
                                        </div>

                                        <div class="ToolTipWrapper modal-body">
                                            <p><strong>Spraying Condition</strong> is based on Delta T to help determine
                                                if conditions are
                                                appropriate for spraying pesticides. Delta T provides an indication of
                                                water
                                                evaporation rate and droplet lifetime.</p>
                                        </div>

                                        <div class="emphasized">
                                            <div class="intro"><p>We indicate the spraying condition as follows:</p>
                                            </div>


                                            <div class="ColourLegend">

                                                <div><span class="green"></span><strong>Preferred:</strong>
                                                    <p>Ideal with Delta T between 2°C and 8°C.</p></div>
                                                <div><span class="orange"></span><strong>Marginal:</strong>
                                                    <p>Suitability is in transition. Delta T between 8°C and 10°C.</p>
                                                </div>
                                                <div><span class="red"></span><strong>Unsuitable:</strong>
                                                    <p>Delta T above 10°C implies low humidity and rapid evaporation of
                                                        droplets.</p></div>


                                            </div>


                                        </div>


                                        <div class="ModalFooter">
                                            <p><a href="http://www.bom.gov.au/lam/deltat.shtml" target="_blank">More
                                                information</a></p>
                                        </div>
                                    </div>

                                </ng-template>
                                <!-- /Template Spraying condition -->
                            </div>
                        </div>

                        <div class="MetricBoxBody">
                            <div class="MetricBoxBodyInner">
                                <div class="MetricBoxValue">{{stationLatestModel.DeltaT}}
                                    <div class="MetricBoxDesc"><span class="top"></span><span
                                            class="bottom">&#176;C</span>
                                    </div>
                                </div>
                                <div class="MetricBoxSubText">Current Delta-T</div>
                            </div>
                        </div>

                        <div class="MetricBoxFooter">
                            <div class="left">
                                <div class="alert" [ngClass]="getSprayingConditionStyle()"><span></span>{{stationLatestModel.SprayingCondition}}
                                </div>
                            </div>
                            <div class="right">Dewpoint<br>
                                {{stationLatestModel.DewPoint}}&#176;C
                            </div>

                        </div>
                    </div>
                    <!--/MetricBox-->


                    <!--MetricBox-->
                    <div class="MetricBoxContainer"
                         *ngIf="stationLatestModel.EtoShortCrop !== null; else evap_na">
                        <div class="MetricBoxHeader">
                            <div class="MetricBoxTitle">Evapotranspiration</div>
                            <div class="MetricBoxInfo">
                                <!-- <div class="icon icon-info-icon"></div>-->
                            </div>
                        </div>

                        <div class="MetricBoxBody">
                            <div class="MetricBoxBodyInner">

                                <div class="MetricBoxValue">{{stationLatestModel.EtoShortCrop}}
                                    <div class="MetricBoxDesc"><span class="top">ETo short</span><span
                                            class="bottom">mm</span></div>
                                </div>
                                <div class="MetricBoxSubText">Since 12am</div>

                            </div>
                        </div>
                        <div class="MetricBoxFooter">
                            <div class="left">ETo tall<br>
                                {{stationLatestModel.EtoTallCrop}}mm
                            </div>
                            <div class="right">Pan evap.<br>
                                {{stationLatestModel.PanEvaporation12AM}}mm
                            </div>

                        </div>
                    </div>

                    <!-- When no Evapotranspiration -->
                    <ng-template #evap_na>
                        <div class="MetricBoxContainer">
                            <div class="MetricBoxHeader">
                                <div class="MetricBoxTitle">Evapotranspiration</div>
                                <div class="MetricBoxInfo">
                                    <!-- <div class="icon icon-info-icon"></div>-->
                                </div>
                            </div>

                            <div class="MetricBoxBody">
                                <div class="MetricBoxBodyInner">

                                    <div class="MetricBoxValue">
                                        <div class="MetricBoxDesc"><span class="top"></span><span
                                                class="bottom"></span></div>
                                    </div>
                                    <div class="MetricBoxSubText">Not available for this station</div>

                                </div>
                            </div>
                            <div class="MetricBoxFooter">
                                <div class="left"></div>
                                <div class="right"></div>

                            </div>
                        </div>
                    </ng-template>
                    <!-- /When no Evapotranspiration -->

                    <!--/MetricBox-->
                </div>
            </div>


            <!--/MetricBoxWrapper-->

            <div class="DashboardComponentWrapper">
                <div class="row">

                    <!--ComponentBoxRainfall-->
                    <div class="Layout Graph">
                        <div class="ComponentBoxContainer">
                            <div class="ComponentBoxContainerHeader">
                                <div class="ComponentBoxContainerTitle">Rainfall</div>
                                <div class="ComponentBoxContainerInfo">
                                    <!-- <div class="icon icon-info-icon"></div>-->
                                </div>
                            </div>
                            <div class="ComponentBoxContainerBody">
                                <app-chart [title]="stationModel.Name + ' ' + stationCode"
                                           [stationCode]="stationCode"></app-chart>
                            </div>
                        </div>
                    </div>
                    <!--/ComponentBoxRainfall-->


                    <!--ComponentBoxWind-->
                    <div class="Layout Graph">
                        <div class="ComponentBoxContainer">
                            <div class="ComponentBoxContainerHeader">
                                <div class="ComponentBoxContainerTitle">Wind Last 4 hours</div>
                                <div class="ComponentBoxContainerInfo">
                                    <!-- <div class="icon icon-info-icon"></div>-->
                                </div>
                            </div>
                            <div class="ComponentBoxContainerBody">
                                <app-chart-windrose [title]="stationModel.Name + ' ' + stationCode"
                                                    [stationCode]="stationCode"></app-chart-windrose>
                            </div>
                        </div>
                    </div>
                    <!--/ComponentBoxWind-->


                    <!--ComponentBoxSoil-->
                    <div class="Layout Soil">
                        <div class="ComponentBoxContainer ">

                            <div class="ComponentBoxContainerBody">

                                <!-- Barometric Pressure -->
                                <div class="ComponentBoxContainerItem"
                                     *ngIf="stationLatestModel.BarometricPressure !== null && stationDailySummary.BarometricPressure !== undefined">
                                    <div class="ComponentBoxContainerHeader">
                                        <div class="ComponentBoxContainerTitle">Barometric Pressure</div>
                                        <div class="ComponentBoxContainerInfo">
                                            <!-- <div class="icon icon-info-icon"></div>-->
                                        </div>
                                    </div>

                                    <div class="ComponentBoxContainerBody">


                                        <div class="row">
                                            <div class="col-4">
                                                <div class="Title">Current Pressure</div>
                                                <div class="Variable">
                                                    {{stationLatestModel.BarometricPressure}}hPa
                                                </div>
                                            </div>

                                            <div class="col-4 text-center">
                                                <div class="Title">Min</div>
                                                <div class="Variable">{{stationDailySummary.BarometricPressure.Min}}hPa
                                                </div>
                                                <div class="Time">
                                                    {{stationDailySummary.BarometricPressure.getMinDateTimeConditionalString('HH:mma')}}
                                                </div>
                                            </div>

                                            <div class="col-4 text-right">
                                                <div class="Title">Max</div>
                                                <div class="Variable">{{stationDailySummary.BarometricPressure.Max}}hPa
                                                </div>
                                                <div class="Time">
                                                    {{stationDailySummary.BarometricPressure.getMaxDateTimeConditionalString('HH:mma')}}
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <!-- /Barometric Pressure -->


                                <div class="ComponentBoxContainerItem"
                                     *ngIf="stationDailySummary.SoilTemperature !== undefined && stationLatestModel.SoilTemperature !== null; else soiltemp_na">
                                    <div class="ComponentBoxContainerHeader">
                                        <div class="ComponentBoxContainerTitle">Soil temperature</div>
                                        <div class="ComponentBoxContainerInfo">
                                            <!-- <div class="icon icon-info-icon"></div>-->
                                        </div>
                                    </div>
                                    <div class="ComponentBoxContainerBody">


                                        <div class="row">
                                            <div class="col-4">
                                                <div class="Title">Current temp.</div>
                                                <div class="Variable">{{stationLatestModel.SoilTemperature}}°C
                                                </div>
                                            </div>

                                            <div class="col-4 text-center">
                                                <div class="Title">Min</div>
                                                <div class="Variable">{{stationDailySummary.SoilTemperature.Min}}°C
                                                </div>
                                                <div class="Time">
                                                    {{stationDailySummary.SoilTemperature.getMinDateTimeConditionalString('HH:mma')}}
                                                </div>
                                            </div>

                                            <div class="col-4 text-right">
                                                <div class="Title">Max</div>
                                                <div class="Variable">{{stationDailySummary.SoilTemperature.Max}}°C
                                                </div>
                                                <div class="Time">
                                                    {{stationDailySummary.SoilTemperature.getMaxDateTimeConditionalString('HH:mma')}}
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <ng-template #soiltemp_na>
                                    <div class="ComponentBoxContainerItem">
                                        <div class="ComponentBoxContainerHeader">
                                            <div class="ComponentBoxContainerTitle">Soil temperature</div>
                                            <div class="ComponentBoxContainerInfo">
                                                <!-- <div class="icon icon-info-icon"></div>-->
                                            </div>
                                        </div>
                                        <div class="ComponentBoxContainerBody">
                                            <div class="row">
                                                <div class="col-12 Title">Not available for this station</div>
                                            </div>
                                        </div>
                                    </div>

                                </ng-template>

                                <div class="ComponentBoxContainerItem">
                                    <div class="ComponentBoxContainerHeader">
                                        <div class="ComponentBoxContainerTitle">Solar radiation</div>
                                        <div class="ComponentBoxContainerInfo">
                                            <!-- <div class="icon icon-info-icon"></div>-->
                                        </div>
                                    </div>
                                    <div class="ComponentBoxContainerBody"
                                         *ngIf="stationLatestModel.SolarIrradiance !== null; else no_solar_radiation">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="Title">Solar exposure</div>
                                                <div class="Variable">{{(stationDailySummary.TotalSolarExposure /
                                                    1000).toFixed(1)}}MJ/m<sup>2</sup></div>
                                                <div class="Time">since 12.00am</div>
                                            </div>

                                            <div class="col-6">
                                                <div class="Title">Current irradiance</div>
                                                <div class="Variable">
                                                    {{stationLatestModel.SolarIrradiance}}W/m<sup>2</sup>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <ng-template #no_solar_radiation>
                                        <div class="ComponentBoxContainerBody">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="Title">Not available for this station</div>
                                                </div>

                                            </div>
                                        </div>
                                    </ng-template>
                                </div>

                                <div class="ComponentBoxContainerItem"
                                     *ngIf="stationLatestModel.BarometricPressure == null">
                                    <div class="ComponentBoxContainerHeader">
                                        <div class="ComponentBoxContainerTitle">Chilling</div>
                                        <div class="ComponentBoxContainerInfo">
                                            <!-- <div class="icon icon-info-icon"></div>-->
                                        </div>
                                    </div>
                                    <div class="ComponentBoxContainerBody">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="Title">Richardson units</div>
                                                <div class="Variable">{{stationDailySummary.RichardsonUnits}} units
                                                </div>
                                                <div class="Time"> 24 hours to 9am</div>

                                            </div>

                                            <div class="col-6">
                                                <div class="Title">Chill hours</div>
                                                <div class="Variable">{{stationDailySummary.ChillHours}} hrs</div>
                                                <div class="Time"> 24 hours to 9am</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--/ComponentBoxSoil-->


                    <!--ComponentBoxEstimatedRainfall-->
                    <div class="Layout Graph"
                         *ngIf="stationModel !== undefined && stationModel.Radar !== undefined && stationModel.Radar !== null">
                        <div class="ComponentBoxContainer">
                            <div class="ComponentBoxContainerHeader">
                                <div class="ComponentBoxContainerTitle">Radar estimated rainfall</div>
                                <div class="ComponentBoxContainerInfo">
                                    <!-- <div class="icon icon-info-icon"></div>-->
                                </div>
                            </div>
                            <div class="ComponentBoxContainerBody">
                                <highcharts-chart [Highcharts]="Highcharts"
                                                  [options]="chartOptions"
                                                  style="width: 100%; height: 400px; display: block;"></highcharts-chart>

                            </div>
                        </div>
                    </div>
                    <!--/ComponentBoxEstimatedRainfall-->

                    <!--ComponentBoxRadarEstimatedRainfall-->
                    <div class="Layout EstimatedRainfall"
                         *ngIf="stationModel !== undefined && stationModel.Radar !== undefined && stationModel.Radar !== null">
                        <div class="ComponentBoxContainer">
                            <div class="ComponentBoxContainerHeader">
                                <div class="ComponentBoxContainerTitle">Radar monthly estimated rain (12 months)
                                </div>
                                <div class="ComponentBoxContainerInfo">
                                    <!-- <div class="icon icon-info-icon"></div>-->
                                </div>
                            </div>
                            <div class="ComponentBoxContainerBody">

                                <div class="column-wrapper">
                                    <div class="Variable" *ngFor="let month of stationModel.Radar.MonthlyRain">
                                        <div class="date-wrapper">{{month.Month}} {{month.Year}}</div>
                                        <div class="estimated-rainfall">{{month.EstimatedRain !== null ?
                                            month.EstimatedRain.toFixed(1) : 'NA' }}<span>mm</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!--/ComponentBoxRadarEstimatedRainfall-->


                    <!--ComponentBoxRadarSummary-->
                    <div class="Layout RadarSummary"
                         *ngIf="stationModel !== undefined && stationModel.Radar !== undefined && stationModel.Radar !== null">
                        <div class="ComponentBoxContainer">
                            <div class="ComponentBoxContainerHeader">
                                <div class="ComponentBoxContainerTitle">Radar rainfall summary</div>
                                <div class="ComponentBoxContainerInfo">
                                    <!-- <div class="icon icon-info-icon"></div>-->
                                </div>
                            </div>
                            <div class="ComponentBoxContainerBody">
                                <div class="DateContainer">
                                    <div class="Date">{{stationModel.Radar.getDateTime('Do MMMM')}}</div>
                                    <div class="Time">{{stationModel.Radar.getDateTime('hh:mma')}}</div>
                                </div>

                                <div class="RainfallSummaryWrapper">
                                    <div class="RainfallSummaryValueWrapper">
                                        <div class="Hour">
                                            <div class="Title">Hour</div>
                                            <div class="Variable">
                                                {{stationModel.Radar.EstimatedRain.Hour.toFixed(1)}}<span>mm</span>
                                            </div>
                                        </div>
                                        <div class="Day">
                                            <div class="Title">Day</div>
                                            <div class="Variable">
                                                {{stationModel.Radar.EstimatedRain.Day.toFixed(1)}}<span>mm</span>
                                            </div>
                                        </div>
                                        <div class="Month">
                                            <div class="Title">Month</div>
                                            <div class="Variable">
                                                {{stationModel.Radar.EstimatedRain.Month.toFixed(1)}}<span>mm</span>
                                            </div>
                                        </div>
                                        <div class="Year">
                                            <div class="Title">Year</div>
                                            <div class="Variable">
                                                {{stationModel.Radar.EstimatedRain.Year.toFixed(1)}}<span>mm</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="km">
                                    <div class="Title">Radar grid cell centre point distance</div>
                                    <div class="Variable">{{stationModel.Radar.Distance * 1000}}m</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--/ComponentBoxRadarSummary-->


                    <!--ComponentBoxYearToDate-->
                    <div class="Layout YearToDate">
                        <div class="ComponentBoxContainer ">
                            <div class="ComponentBoxContainerHeader">
                                <div class="ComponentBoxContainerTitle">Year to date</div>
                                <div class="ComponentBoxContainerInfo">
                                    <!-- <div class="icon icon-info-icon"></div>-->
                                </div>
                            </div>
                            <div class="ComponentBoxContainerBody">

                                <div class="LeftColumn">
                                    <div class="air-temp" *ngIf="stationYearlySummary.AirTemperature !== undefined">
                                        <div class="Value-Wrapper">
                                            <div class="Title">Min temperature</div>
                                            <div class="Variable">{{stationYearlySummary.AirTemperature.Min}}°C</div>
                                            <div class="Time">
                                                {{stationYearlySummary.AirTemperature.getMinDateTimeConditionalString('hh:mma
                                                Do MMMM')}}
                                            </div>
                                        </div>

                                        <div class="Value-Wrapper">
                                            <div class="Title">Max temperature</div>
                                            <div class="Variable">{{stationYearlySummary.AirTemperature.Max}}°C</div>
                                            <div class="Time">
                                                {{stationYearlySummary.AirTemperature.getMaxDateTimeConditionalString('hh:mma
                                                Do MMMM')}}
                                            </div>
                                        </div>
                                    </div>


                                    <div class="wind"
                                         *ngIf="stationYearlySummary.WindProbes !== undefined && stationYearlySummary.WindProbes[0] !== undefined">
                                        <div class="Title">Max windspeed</div>
                                        <div class="Variable">{{stationYearlySummary.WindProbes[0].MaxSpeed}}km/h
                                            {{stationYearlySummary.WindProbes[0].MaxCompassPoint}}
                                        </div>
                                        <div class="Time">{{stationYearlySummary.WindProbes[0].getMaxDateTimeConditionalString('hh:mma Do
                                            MMMM')}}
                                        </div>
                                    </div>
                                </div>


                                <div class="rain">
                                    <div class="Value-Wrapper">
                                        <div class="Title">Rain</div>
                                        <div class="Variable">{{stationYearlySummary.Rainfall}}mm</div>
                                        <div class="Time">&nbsp;</div>
                                    </div>
                                    <div class="Value-Wrapper">
                                        <div class="Title">Rain days</div>
                                        <div class="Variable">{{stationYearlySummary.RainDays}}</div>
                                        <div class="Time">&nbsp;</div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <!--/ComponentBoxYearToDate-->

                    <!--ComponentBoxSeeAlso-->
                    <div class="Layout SeeAlso">
                        <div class="ComponentBoxContainer">
                            <div class="ComponentBoxContainerHeader">
                                <div class="ComponentBoxContainerTitle">See also</div>
                                <div class="ComponentBoxContainerInfo">
                                    <!-- <div class="icon icon-info-icon"></div>-->
                                </div>
                            </div>
                            <div class="ComponentBoxContainerBody">


                                <div class="ToolWrapper">

                                    <a href="https://www.agric.wa.gov.au/drought-and-dry-seasons/seasonal-climate-information"
                                       target="_blank">
                                        <div class="IconWrapper"><span class="icon icon-weather-icon"></span></div>
                                        <span>Seasonal climate information</span></a>
                                </div>

                                <div class="ToolWrapper">

                                    <a href="https://www.agric.wa.gov.au/climate-weather/rainfall-date?stationCode={{stationCode}}"
                                       target="_blank">
                                        <div class="IconWrapper"><span class="icon icon-rainfall-icon"></span></div>
                                        <span>Rainfall to date</span></a>

                                </div>

                                <div class="ToolWrapper">
                                    <a href="https://www.agric.wa.gov.au/climate-weather/soil-water-tool?stationCode={{stationCode}}"
                                       target="_blank">
                                        <div class="IconWrapper"><span class="icon icon-soil-moisture-icon"></span>
                                        </div>
                                        <span>Soil water tool</span></a>

                                </div>
                                <div class="ToolWrapper">

                                    <a href="https://www.agric.wa.gov.au//climate-weather/extreme-weather-events"
                                       target="_blank">
                                        <div class="IconWrapper"><span class="icon icon-extreme-weather-icon"></span>
                                        </div>
                                        <span>Extreme weather events</span></a>

                                </div>

                            </div>
                        </div>
                    </div>
                    <!--/ComponentBoxSeeAlso-->


                    <!--Layout.DuelContainer-->
                    <div class="Layout DuelContainer">

                        <!--ComponentBoxAppStores-->
                        <div class="AppStores">
                            <div class="ComponentBoxContainer">
                                <div class="ComponentBoxContainerHeader">
                                    <div class="ComponentBoxContainerTitle">App Stores</div>
                                    <div class="ComponentBoxContainerInfo">
                                        <!-- <div class="icon icon-info-icon"></div>-->
                                    </div>
                                </div>
                                <div class="ComponentBoxContainerBody">


                                    <div class="app-store-wrapper">

                                        <a href="https://play.google.com/store/apps/developer?id=DPIRD+WA">
                                            <div class="icon icon-google-play-icon"></div>
                                            <div class="store-name">Google play</div>
                                        </a>
                                    </div>
                                    <div class="app-store-wrapper">

                                        <a href="https://itunes.apple.com/au/developer/western-australian-agriculture-authority/id548248116?mt=8">
                                            <div class="icon icon-apple-icon"></div>
                                            <div class="store-name">Apple store</div>
                                        </a>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <!--/ComponentBoxAppStores-->

                        <!--ComponentBoxFeedBack-->
                        <div class="Feedback">
                            <div class="ComponentBoxContainer">
                                <div class="ComponentBoxContainerHeader">
                                    <div class="ComponentBoxContainerTitle">Feedback</div>
                                    <div class="ComponentBoxContainerInfo">
                                        <!-- <div class="icon icon-info-icon"></div>-->
                                    </div>
                                </div>
                                <div class="ComponentBoxContainerBody">
                                    <div class="icon icon-speech-bubble-icon"></div>
                                    <p>
                                        We are continually working on further improvements to our weather stations app
                                        with
                                        exciting new features and increased stability. If you have any ideas or general
                                        comments we would love to hear from
                                        you. Please direct your feedback via <a
                                            href="mailto:econnectedGrainBelt@dpird.wa.gov.au?Subject=Weather stations app feedback">econnectedGrainBelt@dpird.wa.gov.au</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!--/ComponentBoxFeedBack-->
                    </div>

                    <!--/Layout.DuelContainer-->

                </div>
            </div>


        </div>
    </div>

     <!-- dashboard loading -->
     <ng-template  #dashboard_loading>
         <div class="loadingPlaceHolder" *ngIf="isStationOffline == false">
             <img src="/assets/images/clouds.svg" width="300px"  alt="loading" />
         </div>
         <!-- Add a flag here to indicate that the station is down/offline  based on no daily summary -->
         <div *ngIf="isStationOffline == true" id="dashboard-content-inside">
           <div class="station-outage"><div class="alert alert-danger"> Data is not currently available for display due to an outage at this station.</div></div>
         </div>

     </ng-template>
     <!-- /dashboard loading -->

</div>

<!-- Tabular data -->
<app-summary-data-table *ngIf="stationModel !== undefined && stationModel !== null" [(dateRange)]="activeTables.minuteDateRange" [(active)]="activeTables.isMinuteTableActive"
                        [stationCode]="stationCode" [stationName]="stationModel.Name"
                        summaryType="MINUTE" (tableMetricChange)="onActiveChanged($event)"></app-summary-data-table>

<app-summary-data-table *ngIf="stationModel !== undefined && stationModel !== null" [(dateRange)]="activeTables.hourlyDateRange"  [(active)]="activeTables.isHourlyTableActive"
                        [stationCode]="stationCode" [stationName]="stationModel.Name"
                        summaryType="HOURLY" (tableMetricChange)="onActiveChanged($event)"></app-summary-data-table>

<app-summary-data-table *ngIf="stationModel !== undefined && stationModel !== null" [(dateRange)]="activeTables.dailyDateRange"  [(active)]="activeTables.isDailyTableActive"
                        [stationCode]="stationCode" [stationName]="stationModel.Name"
                        summaryType="DAILY" (tableMetricChange)="onActiveChanged($event)"></app-summary-data-table>

<app-summary-data-table *ngIf="stationModel !== undefined && stationModel !== null" [(dateRange)]="activeTables.monthlyDateRange"  [(active)]="activeTables.isMonthlyTableActive"
                        [stationCode]="stationCode" [stationName]="stationModel.Name"
                        summaryType="MONTHLY" (tableMetricChange)="onActiveChanged($event)"></app-summary-data-table>

<app-summary-data-table *ngIf="stationModel !== undefined && stationModel !== null" [(dateRange)]="activeTables.yearlyDateRange"  [(active)]="activeTables.isYearlyTableActive"
                        [stationCode]="stationCode" [stationName]="stationModel.Name"
                        summaryType="YEARLY" (tableMetricChange)="onActiveChanged($event)"></app-summary-data-table>
<!-- /Tabular data -->

<app-charts *ngIf="stationModel !== undefined && stationModel !== null" [station]="stationModel" [(active)]="activeTables.isChartsActive"
            (activeChange)="onActiveChanged($event)"></app-charts>

<app-mobile-weather-data-btns (onMenuSelection)="toggleTable($event,null)"
                              [(active)]="activeTables.isMinuteTableActive || activeTables.isHourlyTableActive || activeTables.isDailyTableActive || activeTables.isMonthlyTableActive || activeTables.isYearlyTableActive || activeTables.isChartsActive"></app-mobile-weather-data-btns>