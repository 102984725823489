

<div class="outer-wrapper">

  <div class="container top api">

      <div class="content-page">

        <iframe width="100%" height="100%" [src]="APIPage | safe"></iframe>
      </div>

  </div>


</div>
<app-station-table></app-station-table>