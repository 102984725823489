import { Component, ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { StateService } from '../services/state/state.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-developer-api-page',
  templateUrl: './developer-api-page.component.html',
  styleUrls: ['./developer-api-page.component.scss']
})
export class DeveloperApiPageComponent implements OnInit {

  APIPage: string = environment.APIURI + '/openapi/'; 

  constructor(public stateService: StateService) { }

  ngOnInit() {
    this.stateService.raiseApplicationReady(); // remove loading screen
  }
 
}
