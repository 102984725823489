import * as moment from 'moment';

export class AggregatedMetrics {
  public Min: number;
  public MinTime: string;
  public MinTimeUTC: string;
  public Max: number;
  public MaxTime: string;
  public MaxTimeUTC: string;
  public Avg: number;

  // Monthly and Yearly also have min and max average properties
  public MinAvg: Number;
  public MaxAvg: Number;

  constructor(responseItem?: any) {
    if (responseItem !== null && responseItem !== undefined) {
      this.Min = responseItem.min;
      this.MinTime = responseItem.minTime !== undefined && responseItem.minTime !== null ? moment(responseItem.minTime).format("DD-MM-YYYY HH:mm") : null;  // Format to local
      this.MinTimeUTC = moment(responseItem.minTime).toISOString(); 
      this.Max = responseItem.max;
      this.MaxTime = responseItem.maxTime !== undefined && responseItem.maxTime !== null ? moment(responseItem.maxTime).format("DD-MM-YYYY HH:mm") : null;  // Format to local
      this.MaxTimeUTC = moment(responseItem.maxTime).toISOString();
      this.Avg = responseItem.avg;

      //Monthly and Yearly
      this.MinAvg = responseItem.minAvg !== undefined ? responseItem.minAvg : null;
      this.MaxAvg = responseItem.maxAvg !== undefined ? responseItem.maxAvg : null;

    }
  }

}