import { BoMStationModel } from "../gis-to-web/models/bom-station.model";
import { StationModel } from "../../models/station.model";
import { StationLatestModel } from "../../models/station-latest.model";
import { Station } from "../weather/models/station.model";
import { StationLatest } from "../weather/models/station-latest.model";
import { WindProbe } from "../weather/models/wind-probe.model";
import { StationRainFall, RainFallMetrics } from "../weather/models/station-rainfall.model";

import * as moment from 'moment';
import { Injectable } from "@angular/core";

/**
 * Conversion Service so that this version of the Application can transalte from BoM stations
 * to the global version of Station with the Latest information applied.
 */

@Injectable()
export class StationConversionService {

  /**
   * Converts a BoM Station to a Station Object
   * @param BoMStation BoM Station Object
   * @returns StationModel Object
   */
  public static ConvertBoMToStationModel(BoMStation: BoMStationModel): StationModel {
    const station = new StationModel();

    station.Code = BoMStation.BoMId;
    station.Name = BoMStation.StationName;
    station.Owner = 'BoM Station';
    station.OwnerShortCode = 'BoM';
    station.Latitude = BoMStation.Latitude;
    station.Longitude = BoMStation.Longitude;
    station.Online = true;

    return station;
  }

  public static ConvertBoMToStationLatestModel(BoMStation: BoMStationModel): StationLatestModel {
    const stationLatest = new StationLatestModel();

    stationLatest.Code = BoMStation.BoMId;
  
    //todo: API needs to return ISO string correctly
    stationLatest.DateTimeUTC = BoMStation.LastUpdated;
   
    stationLatest.Name = BoMStation.StationName;
    stationLatest.AirTemperature = BoMStation.AirTemperature;
    stationLatest.ApparentTemperature = BoMStation.ApparentTemperature;
    stationLatest.RelativeHumidity = BoMStation.RelativeHumidity;
    stationLatest.DewPoint = BoMStation.DewPoint;
    stationLatest.RainFallSince9AM = BoMStation.RainSince9AM;
    stationLatest.AirTemperatureMaxLast24Hrs = BoMStation.MaxAirTemp;
    stationLatest.AirTemperatureMinLast24Hrs = BoMStation.MinAirTemp;

    // Add windprobe
    const windProbe = new WindProbe();
    windProbe.Height = 10;
    windProbe.AvgSpeed = BoMStation.WindSpeedKmh;
    windProbe.AvgDirectionDegrees = BoMStation.WindDirectionDegrees;
    windProbe.AvgDirectionCompassPoint = BoMStation.WindDrectionCompass;
    windProbe.MaxSpeed = BoMStation.WindGustKmh;


    stationLatest.WindProbes = [];
    stationLatest.WindProbes.push(windProbe);


    return stationLatest;

  }


  public static convertBomToRainfallModel(BoMStation: BoMStationModel): StationRainFall {
    const rainfallModel = new StationRainFall();

    rainfallModel.Code = BoMStation.BoMId;
    rainfallModel.Name = BoMStation.StationName;
    rainfallModel.Latitude = BoMStation.Latitude;
    rainfallModel.Longitude = BoMStation.Latitude;

    rainfallModel.RainFallMetrics = new RainFallMetrics();

    rainfallModel.RainFallMetrics.Since9AM = BoMStation.RainSince9AM;
    rainfallModel.RainFallMetrics.To9AM = BoMStation.RainPrev24Hrs;
    rainfallModel.RainFallMetrics.YearToDate = null;
    rainfallModel.RainFallMetrics.Last14Days = null;
    rainfallModel.RainFallMetrics.Last7Days = null;
 

    return rainfallModel;
  }


}
