import { ChangeDetectionStrategy, Component, OnInit, Output, Input, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import {
    DateTimeAdapter,
    OWL_DATE_TIME_FORMATS,
    OWL_DATE_TIME_LOCALE,
    OwlDateTimeComponent,
    OwlDateTimeFormats
} from '@danielmoncada/angular-datetime-picker';
//import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import * as _moment from 'moment';
import { Moment } from 'moment';
import * as L from 'leaflet';

export const MY_MOMENT_DATE_TIME_FORMATS: OwlDateTimeFormats = {
    parseInput: 'MM/YYYY',
    fullPickerInput: 'l LT',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
        // `MomentDateTimeAdapter` and `OWL_MOMENT_DATE_TIME_FORMATS` can be automatically provided by importing
        // `OwlMomentDateTimeModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
       //* {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},

        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_DATE_TIME_FORMATS},
    ],
})
export class DatePickerComponent implements OnInit {

  selectedMoments: any;
  public maxDate = new Date().addDays(1);

  @Output() onDateSelection: EventEmitter<Object> = new EventEmitter<Object>();
  @Output() onChosenMonth: EventEmitter<Object> = new EventEmitter<Object>();
  @Input() pickerType: string = "both";
  @Input() startView: string = "month";
  @Input() pickerMode: string = "popup";
  @Input() defaultDates: any;
  @Input() showTitle: boolean = false;
  @Input() titleText: string = "Select a date range:";
  
  @ViewChild('datePicker', {static: true} ) datePicker: HTMLDivElement;

  constructor() { }

  ngOnInit() {
    this.selectedMoments = this.defaultDates;

    let DivElement =   <HTMLDivElement>(this.datePicker as any).nativeElement;
    L.DomEvent.on(DivElement, 'move', function (ev) {
      L.DomEvent.stopPropagation(ev);
    });

  }

  onDateTimeChange(event) {
    this.onDateSelection.emit(event.value);
  }
  
  ngOnChanges(changes: SimpleChanges) {
    // Had to reset dates here so that the picker gets updated between nvagiation from station to station
    this.selectedMoments = this.defaultDates;
  }
  
  chosenYearHandler( normalizedYear: Moment ) {
    // const ctrlValue = this.dateTime.value;
    // ctrlValue.year(normalizedYear.year());
    // this.dateTime.setValue(ctrlValue);
  }
  
  chosenMonthHandler( normalizedMonth: Moment, datepicker: OwlDateTimeComponent<Moment> ) {
    this.onChosenMonth.emit( {Month: normalizedMonth , datePicker: datepicker});
  }
  
}
