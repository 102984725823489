<app-secondary-header></app-secondary-header>


<div class="outer-wrapper">
    <div class="container top">
        <div class="row">
            <div class="filter-options">
                <div ngbDropdown class="d-inline-block">
                    <button class="btn drop-down-button " id="weather-station-filter" ngbDropdownToggle>Filter by
                        weather station
                    </button>
                    <div ngbDropdownMenu aria-labelledby="weather-station-filter">
                        <div class="icon icon-play"></div>
                        <button class="dropdown-item">South Perth</button>
                        <button class="dropdown-item">Kensington</button>
                        <button class="dropdown-item">Kings Park</button>
                        <button class="dropdown-item">Pinjar Tower</button>

                    </div>
                </div>
                <div ngbDropdown class="d-inline-block">
                    <button class="btn drop-down-button " id="chronological-filter" ngbDropdownToggle>Recent</button>
                    <div ngbDropdownMenu aria-labelledby="chronological-filter">
                        <div class="icon icon-play"></div>
                        <button class="dropdown-item">Recent</button>
                        <button class="dropdown-item">Oldest</button>
                        <button class="dropdown-item">Ascending (A-Z)</button>
                        <button class="dropdown-item">Descending (Z-A)</button>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="title"><h2>Weather station outages</h2></div>
        </div>
        <div class="notification-wrapper">
            <div class="row">
                <div class="col-1 alert-status"><span class="icon icon-station-alert amber"></span></div>
                <div class="col-2 station-name">Pemberton</div>
                <div class="col-4 notification">Outage February 15-16 2018</div>
                <div class="col-3 issue-date">Issued February 15 2018</div>
                <div class="col-2 alert-message"><span class="alert amber">Rainfall reading</span></div>
            </div>
            <div class="row">
                <div class="col-1 alert-status"><span class="icon icon-station-alert amber"></span></div>
                <div class="col-2 station-name">Kensington</div>
                <div class="col-4 notification">Outage February 15-16 2018</div>
                <div class="col-3 issue-date">Issued February 15 2018</div>
                <div class="col-2 alert-message"><span class="alert amber">Rainfall reading</span></div>
            </div>

        </div>





        <div class="row">
            <div class="title"><h2>Weather station down</h2></div>
        </div>
        <div class="notification-wrapper">
            <div class="row">
                <div class="col-1 alert-status"><span class="icon icon-station-alert red"></span></div>
                <div class="col-2 station-name">South Perth</div>
                <div class="col-4 notification">Offline February 15-16 2018</div>
                <div class="col-3 issue-date">Issued February 15 2018</div>
                <div class="col-2 alert-message"><span class="alert red">No data available</span></div>
            </div>
            <div class="row">
                <div class="col-1 alert-status"><span class="icon icon-station-alert red"></span></div>
                <div class="col-2 station-name">Kensington</div>
                <div class="col-4 notification">Offline January 27 2018</div>
                <div class="col-3 issue-date">Issued January 27  2018</div>
                <div class="col-2 alert-message"><span class="alert red">No data available</span></div>
            </div>

        </div>
    </div>
</div>