<app-modal></app-modal>
<app-left-sidebar-menu></app-left-sidebar-menu>


 <div id="api-map-loading" *ngIf="!isDataLoaded"> <img src="/assets/images/clouds.svg" width="300px"  alt="loading" /></div>
 
 <div id="idleTimeoutOverlay" *ngIf="timedOut"></div>
 <div id="idleTimeoutContainer" *ngIf="timedOut"> 
    <h1>Session Expired</h1>
    <p>Looks like you have been inactive for a while and to make sure you have the latest information we recommend refreshing your browser.</p>
    <a href="/" alt="reload">> Reload</a>
  </div>

<div id="main-container"
 [className]="currentUrl + (settings.mapSettings.isFullScreen == true ? ' map-fullscreen' : '' ) + (settings.stateSettings.isHeaderVisible == false ? ' app-no-header' : '' ) ">

  

  <app-corporate-header></app-corporate-header>
  <app-primary-header></app-primary-header> 
  
  <app-station-sidebar *ngIf="settings.stateSettings.currentPage == 'map-page'"></app-station-sidebar>

  <div id="main-content">
    <router-outlet></router-outlet>
  </div>


</div>
