import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { StateService } from '../../../services/state/state.service';
import { LoggingService } from '../../../services/logging/logging.service';

import { Station } from '../../../services/weather/models/station.model';


import { DataCache } from '../../../services/data-cache/data-cache.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics/google-analytics.service';


//import 'rxjs/add/operator/map';

//import 'rxjs/add/operator/merge';
//import 'rxjs/add/operator/mergeMap'; //https://stackoverflow.com/questions/38481764/rxjs-flatmap-missing
//import 'rxjs/add/operator/debounceTime';
//import 'rxjs/add/operator/distinctUntilChanged';



@Component({
  selector: 'app-metric-select',
  templateUrl: './metric-select.component.html',
  styleUrls: ['./metric-select.component.scss']
})
export class WeatherVariablesComponent implements OnInit {


  public model: any = "";
  public autoCloseOption: false;
  public metricValuesOptionLabel: string;

  @Input() isActive: boolean;

  @ViewChild('findStationList', { static: true }) test: HTMLInputElement;



  public mapSettings: any;
  public weatherStations: Station[];

  searchHasFocus: boolean = false;

  public metricChosen: string = 'AirTemperature';
  public metricAdditionalInfomation: string = '';
  public weatherVariableSelected: string = ""; //todo: rename above variable to more of a display one as its not the actual var chosen

  @Output() onMetricChanged = new EventEmitter();
  @Output() onTogglePopOver = new EventEmitter();
  @Output() onClosePopOver = new EventEmitter();


  constructor(
    private stateService: StateService,
    private loggingService: LoggingService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private dataCacheService: DataCache
  ) { }

  ngOnInit() {
    this.mapSettings = this.stateService.mapSettings;



    // get the stations and latest as when we trigger the last metric selected the latest needs to have been fetched.
    this.dataCacheService.getStationsAndLatest()
      .subscribe(data => {
        //this.weatherStations = data;
        this.weatherStations = data[0];
        //   this.weatherStationsLatest = data[1];

        if (this.stateService.mapSettings.metricSelected !== null) {
          this.onChangeMetricDisplay(this.stateService.mapSettings.metricSelected.key, false);
        }

        this.setMetricChoiceLabel();

      });


  }

  setMetricChoiceLabel() {
    this.metricValuesOptionLabel = this.mapSettings.showMarkerLabels === true ? 'Hide values' : 'Show values';
  }

  toggleIsActive() {
    this.onTogglePopOver.emit('metricPanelActive');
  }


  toggleMarkersValue() {
    this.mapSettings.showMarkerLabels = !this.mapSettings.showMarkerLabels;
    this.setMetricChoiceLabel();
    this.googleAnalyticsService.emitEvent("WeatherVariables", "Show Values", this.mapSettings.showMarkerLabels);

  }

  toggleMapControls() {
    this.stateService.mapSettings.menuIsOpen = !this.stateService.mapSettings.menuIsOpen;
    return false; // stops the issue with loader showing - good to have this anyway like e.preventDefault();
  }

  displayStationTable() {
    //Turn of Panels
    this.stateService.mapSettings.isStationTableActive = true;
    return false;
  }



  onChangeMetricDisplay(value: string, logChange: boolean = true) {

    if (value === 'RESET_MARKERS') {
      this.mapSettings.showMarkerLabels = true;
    }

    // Some metrics will require further input from the user
    // example: Rainfall within date range mm will require a
    // fromDate to ToDate

    if (value !== undefined) {

      this.setMetricLabels(value);

      
    }

    if (logChange) {
      this.googleAnalyticsService.emitEvent('WeatherVariables', 'Selected', value);
    }

    // Because the triggering of the event we have to manually set this one rather than raising the toggle
    this.isActive = false; // Auto closes after user selects an option
    this.onClosePopOver.emit('metricPanelActive');
  }


  setMetricLabels(value: string) {

    this.weatherVariableSelected = value; // phase this in for new options

    switch(value) {
      case 'AirTemperature':
        this.metricChosen = 'Temperature °C';
        this.metricAdditionalInfomation = '';
        break;
      case 'AirTemperatureMaxLast24Hrs':
        this.metricChosen = 'Max Temperature';
        this.metricAdditionalInfomation = 'Last 24Hrs °C';  
        break;
      case 'AirTemperatureMinLast24Hrs':
        this.metricChosen = 'Min Temperature';
        this.metricAdditionalInfomation = 'Last 24Hrs °C';  
        break;
      case 'RainFallMetrics.Since9AM':
        this.metricChosen = 'Rainfall';
        this.metricAdditionalInfomation = 'since 9am mm';
        break;
      case 'RainFallMetrics.To9AM':
        this.metricChosen = 'Rainfall';
        this.metricAdditionalInfomation = 'previous 24hrs to 9am mm';
        break;
      case 'RainFallMetrics.Last7Days':
        this.metricChosen = 'Rainfall';
        this.metricAdditionalInfomation = 'last 7 Days mm';
        break;
      case 'RainFallMetrics.MonthToDate':
        this.metricChosen = 'Rainfall';
        this.metricAdditionalInfomation = 'MTD mm';
        break;
      case 'RainFallMetrics.YearToDate':
        this.metricChosen = 'Rainfall';
        this.metricAdditionalInfomation = 'YTD mm';
        break;
      case 'RAIN_WDR':
        this.metricChosen = 'Rainfall';
        this.metricAdditionalInfomation = 'within date range mm';
        break;
      case 'RelativeHumidity':
        this.metricChosen = 'Relative humidity';
        this.metricAdditionalInfomation = '';   
        break;
      case 'WIND_DIRECTION':
        this.metricChosen = 'Wind direction';
        this.metricAdditionalInfomation = '(10min avg)';
        break;
      case 'SoilTemperature':
        this.metricChosen = 'Soil Temperature °C';
        this.metricAdditionalInfomation = '';
        break;
      case 'SolarExposure':
        this.metricChosen = 'Solar exposure';
        this.metricAdditionalInfomation = 'since 12am MJ/m²';
        break;
      case 'EtoShortCrop':
        this.metricChosen = 'ETo short';
        this.metricAdditionalInfomation = 'since 12am mm';
        break;
      case 'EtoTallCrop':
        this.metricChosen = 'ETo tall';
        this.metricAdditionalInfomation = '';
        break;
      case 'getLastUpdatedMinutes':
        this.metricChosen = 'Last updated';
        this.metricAdditionalInfomation = '(mins)';
        break;
      case 'ErrorsToday':
        this.metricChosen =  'Errors';
        this.metricAdditionalInfomation = 'since 12am';
        break;
      case 'ErrorsLast7Days':
        this.metricChosen = 'Errors';
        this.metricAdditionalInfomation = 'last 7 days';
        break;
      default:
        this.metricChosen = '';
        this.metricAdditionalInfomation = '';
    }

    // Raise event will be captured with MAP Component
    this.onMetricChanged.emit(value);
    this.mapSettings.metricSelected = { 'value': this.metricChosen, 'key': value };

  }
  
}
