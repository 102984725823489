import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-corporate-header',
  templateUrl: './corporate-header.component.html',
  styleUrls: ['./corporate-header.component.scss']
})
export class CorporateHeaderComponent implements OnInit {

  constructor(
    private route: Router) {
   }

  ngOnInit() {
  }

  navigateToPage(state) {
      this.route.navigate([state]);
      return false; // for A HREF tags
    }

}
