import { Component, OnInit } from '@angular/core';
import { DataCache } from '../services/data-cache/data-cache.service';

import { StationLatestModel } from '../models/station-latest.model';

import { LazyLoadEvent } from 'primeng/api';
import { StateService } from '../services/state/state.service';

import * as moment from 'moment';

@Component({
  selector: 'app-station-list',
  templateUrl: './station-list.component.html',
  styleUrls: ['./station-list.component.scss']
})
export class StationListComponent implements OnInit {

  data: StationLatestModel[];
  selectedData: any;

  cols: any[];

   // Table paramters
  loading: boolean = false;
  totalRecords: number;
  limit: number = 20;
  offset: number = 0;
  sort: string;
  page: number =  0;
  dataKey: string;

filterParams = {
    startDateTime: "",
    endDateTime: ""
  };


  constructor(
    private dataCacheService: DataCache, 
    private stateService: StateService) { }

  ngOnInit() {

    this.stateService.raiseApplicationReady();
    this.cols = [
          { field: 'AirTemperature', header: 'Temp °C', width: '90px', default: true, sortable: false, tooltip: ""}
        
        ];

  }


    loadTabularDataLazy(event: any ) { //LazyLoadEvent

    // Only filter if active.
   // if (this.active) {
      this.loading = true;

      //in a real application, make a remote request to load data using state metadata from event
      //event.first = First row offset
      //event.rows = Number of rows per page
      //event.sortField = Field name to sort with
      //event.sortOrder = Sort order as number, 1 for asc and -1 for dec
      //filters: FilterMetadata object having field as key and filter value, filter matchMode as value
      this.page = event.page == undefined ? this.page : event.page;

      this.offset = this.page * event.rows;

      if (event.filters !==  undefined ) {
        this.sort = (event.sortOrder === -1 ? '-' : '') + 'periodFrom';

        if (event.sortField === null) {
          this.sort = "-periodFrom";
        }
      }


  





      this.dataCacheService.getStationsLatest().subscribe(
        data => {
          this.data = data;

          // Dyanmically create the windprobe columns as this changes from station to station
          // we can use the response to create the columns if they are not already mapped.
         // this.initWindProbeColumns();
          // set current params for pagination
        //  this.totalRecords = data.metadata.collection.count;
        //  this.limit = data.metadata.collection.limit;
        //  this.offset = data.metadata.collection.offset;
        },
        err => { },
        () =>  this.loading = false);
    //}

  }



  getFieldValue(item, object) {


    let propertyName = item.field;

    var parts = propertyName.split("."),
      length = parts.length,
      i,
      property = object || this;

    if (length <= 2) {
      for (i = 0; i < length; i++) {
        property = property[parts[i]];
      }
    } else {
      try {
        property = property[parts[0]][parts[1]][parts[2]];
      }
      catch {
        //todo: determine a better way
        property = "";
      }
    }

    // Have the value now apply a format if required.
    if (item.type !== undefined) {
      if (item.type === 'Date' && item.format !== undefined) {
        property = moment(property).format(item.format);
      } 
    }
    
    return property;
  }

}
