<div class="menuBarItemContainer" [ngClass]="{'active': isActive }">
  <div class="inner-wrapper">
    <div class="singleselect btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="model">
      <label ngbButtonLabel class="btn-primary">
        <input ngbButton type="radio" [value]="'GOOGLE_HYBRID'" (click)="toggleMapLayer('GOOGLE_HYBRID')"> Google hybrid
      </label>
      <label ngbButtonLabel class="btn-primary">
        <input ngbButton type="radio" [value]="'GOOGLE_SATELLITE'" (click)="toggleMapLayer('GOOGLE_SATELLITE')"> Google
        satellite
      </label>
      <label ngbButtonLabel class="btn-primary">
        <input ngbButton type="radio" [value]="'GOOGLE_STREETS'" (click)="toggleMapLayer('GOOGLE_STREETS')"> Google
        streets
      </label>
      <label ngbButtonLabel class="btn-primary">
        <input ngbButton type="radio" [value]="'GOOGLE_TERRAIN'" (click)="toggleMapLayer('GOOGLE_TERRAIN')"> Google
        terrain
      </label>
    <!--  <label ngbButtonLabel class="btn-primary">
        <input ngbButton type="radio" [value]="'MAPBOX_GREY'" (click)="toggleMapLayer('MAPBOX_GREY')"> MapBox
      </label> -->
      <label ngbButtonLabel class="btn-primary">
        <input ngbButton type="radio" [value]="'OPEN_LAYERS'" (click)="toggleMapLayer('OPEN_LAYERS')"> Open layers
      </label>
    </div>
  </div>
  <div class="metric-arrow-down"></div>
</div>

<button type="button" class="btn btn-outline-secondary" (click)="toggleIsActive()">
  <div class="icon-container">
    <span class="icon icon-map-layers"></span>
  </div>
  <div class="wrapper">
    <div class="dock-title">Map layers</div>
    <div class="selected">{{layerName}}</div>
    <div class="icon icon-play"></div>
  </div>
</button>