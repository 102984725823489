import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { WeatherChartService } from '../../../services/weather/weather.chart.service';
import * as moment from 'moment'; // Include the library have to include reference in angular CLI to js file

// Add additional modules required
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

//https://api.highcharts.com/highcharts/xAxis.tickInterval
const HOUR_INTERVAL = 3600000;
const DAY_INTERVAL = 86400000;       // 24 * 3600 * 1000;
const MONTHLY_INTERVAL = 2628000000;
const YEARLY_INTERVAL = 31536000000; // 365 Days

const MIN_SERIES_COLOUR = '#199AD2';
const MAX_SERIES_COLOUR = '#AC0011';
const AVG_SERIES_COLOUR = '#FFB202';

@Component({
  selector: 'app-temperature-chart',
  templateUrl: './temperature-chart.component.html',
  styleUrls: ['./temperature-chart.component.scss']
})
export class TemperatureChartComponent implements OnInit {


  station = { code: '', name: '' };

  Highcharts = Highcharts;
  isDataLoaded: boolean = false;

  tickInterval = DAY_INTERVAL;

  chartOptions: any = {
    credits: {
      enabled: false
    },
    yAxis: {
      title: {
        text: '°C',
        useHTML: false
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },
    series: [{
      name: 'Min',
      data: [],
      color: "#41E8FF"
    }, {
      name: 'Max',
      data: [],
      color: "#FF9801"
    }, {
      name: 'Avg',
      data: [],
      color: "#5D52E7"
    }],
    chart: {
      zoomType: 'x'
    },
    lang: {
      noData: "No data available"
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    },
    exporting: {
      chartOptions: {
        credits: {
          enabled: true,
          text: 'Copyright Western Australia Agriculture ' + new Date().getFullYear(),
          href: 'https://www.agric.wa.gov.au'
        },
        chart: {
          events: {
            load: function () {
              this.renderer.image('https://weather.agric.wa.gov.au/assets/images/chart_dpird_logo.jpg', 10, 10, 160, 46).add();

            }
          }
        }
      }
    },
  }

  constructor(private weatherChartService: WeatherChartService) { }


  ngOnInit() {

    this.weatherChartService.onChartFilterParametersSet.subscribe(
      params => {
        this.onChartFilterParametersSet(params);
      })

  }


  setChartOptions(chartData, params) {

    let subtitleDateMask = 'DD-MM-YYYY';
    if (params.interval === 'HOURLY') {
      subtitleDateMask = 'DD-MM-YYYY hh:mma';
    }

    this.chartOptions = {
      title: {
        text: this.station.name + ' : Temperature (' + params.interval[0].toUpperCase() + params.interval.toLowerCase().substring(1) + ')'
      },
      subtitle: {
        text: moment(params.event.value[0]).format(subtitleDateMask) + ' - ' + moment(params.event.value[1]).format(subtitleDateMask)
      },
      xAxis: {
        tickInterval: this.tickInterval,
        type: 'datetime'
      },
      series: [{
        data: chartData.min,
        color: MIN_SERIES_COLOUR,
        marker: {
          fillColor: MIN_SERIES_COLOUR,
          lineColor: '#FFFFFF',
          lineWidth: 1
        }
      }, {
        data: chartData.max,
        color: MAX_SERIES_COLOUR,
        marker: {
          fillColor: MAX_SERIES_COLOUR,
          lineColor: '#FFFFFF',
          lineWidth: 1
        }
      }, {
        data: chartData.avg,
        color: AVG_SERIES_COLOUR,
        marker: {
          fillColor: AVG_SERIES_COLOUR,
          lineColor: '#FFFFFF',
          lineWidth: 1
        }
      }
      ]
    }
  }



  onChartFilterParametersSet(params) {

    let stationCode = params.station.code;
    let interval = params.interval;
    let limit = 1000;
    let offset = 0;
    this.station = params.station

    // Date parameters to be sent UTC datetime.
    // split routine filtering milliseconds.
    let startDateTime = moment(params.event.value[0]).format('YYYY-MM-DD HH:mm:ss');//params.event.value[0].toISOString().split('.')[0] + "Z";
    let endDateTime = moment(params.event.value[1]).format('YYYY-MM-DD HH:mm:ss');//params.event.value[1].toISOString().split('.')[0] + "Z";

    this.weatherChartService.getStationSelectedSummaryProperty(stationCode, interval, startDateTime, endDateTime, limit, offset, 'airTemperature').subscribe(data => {

      // Format data for series
      var chartData = { min: [], max: [], avg: [] };

      for (let index in data) {
        let momentDate = moment(data[index].dateFrom).local();
        var tz_offset = (new Date().getTimezoneOffset() * -1) * 60 * 1000;
        var unix_time = momentDate.toDate().getTime() + tz_offset;

        chartData.min.push(Array(unix_time, data[index].min));
        chartData.max.push(Array(unix_time, data[index].max));
        chartData.avg.push(Array(unix_time, data[index].avg));
      }

      // if required set the interval for a better representation on the chart.
      // defaulting to DAY for now can be changed.
      // this.tickInterval = 
      switch (interval) {
        case "HOURLY":
          this.tickInterval = HOUR_INTERVAL;
          break;
        case "MONTHLY":
          this.tickInterval = MONTHLY_INTERVAL;
          break;
        case "YEARLY":
          this.tickInterval = YEARLY_INTERVAL;
          break;
        default:
          //DAILY
          this.tickInterval = DAY_INTERVAL;
          break;
      }


      this.setChartOptions(chartData, params);

    });

    this.isDataLoaded = true;
  }
}