import { Component, OnInit , Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-rd-icon-button',
  templateUrl: './rd-icon-button.component.html',
  styleUrls: ['./rd-icon-button.component.scss']
})
export class RdIconButtonComponent implements OnInit {

  @Input() name: string;
  @Input() icon: string;
  @Input() href: string;
  constructor() { }

  ngOnInit() {
  }

}
