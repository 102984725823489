import { Component, OnInit } from '@angular/core';
import { StateService } from '../services/state/state.service';

import { MobileFilterMenuComponent } from '../shared/mobile-filter-menu/mobile-filter-menu.component';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],

  
})
export class AboutComponent implements OnInit {

  constructor(
    public stateService: StateService) {
   }

  ngOnInit() {
    this.stateService.raiseApplicationReady();
  }
}
