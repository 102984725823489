<div id="leftMenu" [ngClass]="{'active' : isMenuOpen === true}">
  <button type="button" class="close" aria-label="Close" (click)="onCloseButtonClicked()">
    <span aria-hidden="true"><span class="icon  icon-cancel-button-icon"></span></span>
  </button>

  <div class="profile-wrapper">
    <div class="profile-pic"><img src="../../assets/images/default.png" alt="profile pic placeholder"> </div>
    <div class="user-name">DPIRD Weather Stations</div>
  </div>

  <ul class="menu">
    <li (click)="navigateToPage('map')">Home</li>
    <li (click)="navigateToPage('about')">About</li>
    <!--<li> <a href="https://www.agric.wa.gov.au/weather-stations-and-radar" target="_blank">Legacy weather station app</a> </li>-->
    <li (click)="navigateToPage('developer-api')">Developer API</li>
    <li (click)="navigateToPage('disclaimer')">Disclaimer</li>
    <li (click)="navigateToPage('privacy-policy')">Privacy policy</li>
    <li (click)="navigateToPage('terms-and-conditions')">Terms &amp; conditions</li>
    <li><a class="feedback" href="mailto:econnectedGrainBelt@dpird.wa.gov.au?Subject=Weather stations app enquiry">Feedback</a></li>
    <li><!--divider--></li>
    <li class="version-number">v{{versionNumber}} <span (click)="navigateToPage('change-log')">release notes</span></li>
  </ul>
</div>

<div class="overlay" [ngClass]="{'active' : isMenuOpen === true}" (click)="onCloseButtonClicked()"></div>
