<div class="tabular-data-container" style="overflow-y: auto; ">


  <p-table #dt
    [value]="data"   
    [lazy]="true"
    [resizableColumns]="true"
    [scrollable]="true"
    sortMode="single"
    (onLazyLoad)="loadTabularDataLazy($event)"
    [loading]="loading" [responsive]="false">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th pSortableColumn="Code">Code</th>
        <th>Name</th>
        <th>Last Updated</th>
        <th>Temp &#176;C</th>
        <th>RH %</th>
        <th>Rain since 9am mm</th>
        <th>Delta T &#176;C</th>
        <th>Dewpoint &#176;C</th>
        <th>Soil Temp &#176;C</th>
        <th>Solar Irradiance W/m²</th>
        <th>Solar Exp MJ from 12am</th>
        <th>Pan Evap mm from 12am</th>
        <th>ETo Short mm from 12am</th>
        <th>ETo Tall mm from 12am</th>
        <th>Battery V min</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-station>
      <tr>              
        <td>{{station.Code}}</td>
        <td>{{station.Name}}</td>
        <td>{{station.getLastUpdatedDateTime('DD-MM-YYYY HH:mm')}}</td>
        <td>{{station.AirTemperature}}</td>
        <td>{{station.RelativeHumidity}}</td>
        <td>{{station.RainFallSince9AM}}</td>
        <td>{{station.DeltaT}}</td>
        <td>{{station.DewPoint}}</td>
        <td>{{station.SoilTemperature}}</td>
        <td>{{station.SolarIrradiance}}</td>
        <td>{{station.SolarExposure}}</td>
        <td>{{station.PanEvaporation12AM}}</td>
        <td>{{station.EtoShortCrop}}</td>
        <td>{{station.EtoTallCrop}}</td>
        <td>{{station.BatteryVoltage}}</td>
      </tr>
    </ng-template>

  </p-table>

</div>

<app-station-table></app-station-table>