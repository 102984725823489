import { Component, OnInit, OnChanges, SimpleChanges, AfterViewChecked, Input, Output, EventEmitter } from '@angular/core';
import { StationLatest } from '../../services/weather/models/station-latest.model';
import { WeatherChartService } from '../../services/weather/weather.chart.service';

import { StateService } from '../../services/state/state.service';
import { DataCache } from '../../services/data-cache/data-cache.service';
import { Station } from '../../services/weather/models/station.model';

import * as moment from 'moment';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit, AfterViewChecked,  OnChanges {

  @Input() active = false;
  @Input() station = new StationLatest(null);
  @Output() activeChange = new EventEmitter();

  currentIntervalFilter: string = "DAILY"; // Default for reporting
  pickerType: string = "calendar"; // Default shows calendar and timer
  public displayFilterMenu: boolean = false;
  
  public defaultMomentDateRange: any = [moment().subtract(14, 'days'), moment()];

  public stationModel = null; // Global object that will be used.

  selectedMoments: any;

  constructor( 
    private weatherChartService: WeatherChartService,
    private stateService: StateService,
    private dataCacheService: DataCache) { }

  ngOnInit() {
    this.setDefaultDates();

   // this.stationModel = this.stateService.getStationModel(this.station.Code);
   this.dataCacheService.getStationModel(this.station.Code).subscribe(
      data => { this.stationModel = data }
   );
  }

  setDefaultDates() {
    this.selectedMoments = this.defaultMomentDateRange;
  }

  ngAfterViewChecked(){
   // added in to stop expression errors
   //https://github.com/angular/angular/issues/6005
   //this.changeDetectionRef.detectChanges(); 
  }

  /**
   * When the calendar is changed the event is emmited with the relevant
   * data parameters, interval and the basic station information
   * @param event
   */
  onDateTimeChange(event) {

    this.weatherChartService.onChartFilterParametersSet.emit(
      {
        event: event,
        interval: this.currentIntervalFilter,
        station: { code: this.station.Code, name: this.station.Name }
      });
  }

  triggerDateSelection() {
    this.onDateTimeChange({'value': this.selectedMoments});
  }

  ngOnChanges(changes: SimpleChanges) {
  
    this.selectedMoments = this.defaultMomentDateRange;

    if (changes.active !== undefined && changes.active.currentValue) {
      //Now loaded into view so request data if the from and to date have not been set
      // Default dates.
      //if (this.filterParams.startDateTime === "" && this.filterParams.endDateTime === "") {
       this.setDefaultDates();
       this.currentIntervalFilter = "DAILY";
       this.triggerDateSelection();
        //this.onDateTimeChange(this.defaultMomentDateRange);
        //this.changeDetectionRef.detectChanges();

      }
    }
  


  setIntervalFilter(interval: string) {
    this.currentIntervalFilter = interval;

    // Configure DateTime Picker based on the interval
    switch (interval) {
      case "HOURLY":
        this.pickerType = "both";
        this.selectedMoments = [moment().subtract(12, 'hours'), moment()];
      break;
      case "DAILY":
        this.pickerType = "calendar";
        this.selectedMoments = [moment().subtract(14, 'days'), moment()];
      break;
      case "MONTHLY":
        this.pickerType = "calendar";
        this.selectedMoments = [moment().subtract(12, 'months'), moment()];
      break;

      case "YEARLY":
        this.pickerType = "calendar";
        this.selectedMoments = [moment().subtract(12, 'years'), moment()];
      break;
    }

    this.triggerDateSelection();
    // note to self convert to dates using toDate and we should be able to show in the input.

  }

  closeTable() {
    this.active = false;
    this.activeChange.emit(false);

    if (this.displayFilterMenu) {
      this.displayFilterMenu = false;
    }
  }

  onActiveChanged(interval: string) {
    this.active = false;
    this.activeChange.emit({ 'summaryType': interval, 'dateRange': null});
    if (this.displayFilterMenu) {
      this.displayFilterMenu = false;
    }

  }

  toggleFilterMenu() {
    this.displayFilterMenu = !this.displayFilterMenu;
  }

}
