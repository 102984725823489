import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChildren, TemplateRef} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ActivatedRoute} from "@angular/router";
import { TplFlipComponent } from '../components/flip-component/flip.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as Highcharts from 'highcharts';
import * as L from 'leaflet';

import { LoggingService } from '../services/logging/logging.service';
import { WeatherService } from '../services/weather/weather.service';
import { DataCache } from '../services/data-cache/data-cache.service';
import { RadarService } from '../services/radar/radar.service';

import { StationLatestModel } from '../models/station-latest.model';
import { StationSummaryModel }  from '../models/station-summary.model';
import { StationRainfallModel } from '../models/station-rainfall.model';

import { StateService } from '../services/state/state.service';

import * as moment from 'moment'; //Include the library have to include reference in angular CLI to js file
import { Station } from '../services/weather/models/station.model';
import { GoogleAnalyticsService } from '../services/google-analytics/google-analytics.service';
//import { debug } from 'util';


const ONLINE_INDICATOR_THRESHOLD = 61;
const OUTAGE_INDICATOR_THRESHOLD = 181;

@Component({
  selector: 'app-station-dashboard',
  templateUrl: './station-dashboard.component.html',
  styleUrls: ['./station-dashboard.component.scss'],
  providers: [LoggingService]
})
export class StationDashboardComponent implements OnInit, OnDestroy {

//https://stackoverflow.com/questions/34947154/angular-2-viewchild-annotation-returns-undefined
  @ViewChildren('CountDownFlipDashboard') FlipCounter: TplFlipComponent;
  isContentScrolling: Boolean = true; //forced to true until can work onscroll
  Highcharts = Highcharts;


  chartOptions: any = {
    credits: {
      enabled: false
    },
    chart: {
      zoomType: 'x',
      type: 'column'
    },
    title: {
      text: 'Radar estimated rainfall'
    },
    subtitle: {
      text: 'Last 12 Months'
    },
    xAxis: {
      categories: []
    },
    yAxis: {
      title: {
        text: 'Estimated rainfall'
      }
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        },
        enableMouseTracking: false
      }
    },
    series: [{
      name: 'Estimated rainfall',
      data: []
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }
  }



  stationCode: string;
  stationIndicatorCode: string;
  isStationOffline: boolean = false;
  myDate: Date;



  // Object to hold the state of the summary tables that are v
  activeTables = {
    isMinuteTableActive: false,
    minuteDateRange: null,
    isHourlyTableActive: false,
    hourlyDateRange: null,
    isDailyTableActive: false,
    dailyDateRange: null,
    isMonthlyTableActive: false,
    monthlyDateRange: null,
    isYearlyTableActive: false,
    yearlyDateRange: null,
    isChartsActive: false
  };


  public stationModel = null; // Global object that will be used.
  public stationLatestModel = new StationLatestModel(null); //TODO: Possibly enhance to have data saved in service
  public stationDailySummary = new StationSummaryModel(null);
  public stationYearlySummary = new StationSummaryModel(null);
  public stationRainfallModel = null;

  public precipitationSummary: any; //add precipitation which is RADAR.
  public stationLastUpdated: string = '';

  //broken into seperate variables as angular reference to arrays was having some issues 
  //todo:// refactor this into the one object when refactoring.
  public stationLatestWindValue;
  public stationLatestWindDirection;

  public availableRadars: any;
  public station: Station;
  public displayMobileFilterContextMenu: boolean = false;

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private weatherService: WeatherService,
    private radarService: RadarService,
    private loggingService: LoggingService,
    private stateService: StateService,
    private dataCacheService: DataCache,
    private ref: ChangeDetectorRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private modalService: NgbModal
  ) {
    this.myDate = new Date();
    this.myDate.setMilliseconds(0);
    let now = moment();
   
  }

  ngOnInit() {

    this.radarService.getRadars().subscribe( data => { 
      this.availableRadars = data; 
    });

  

    this.activatedRoute.params.subscribe( params => {

      this.stationCode = params.stationCode;
      this.googleAnalyticsService.emitEvent("Page", "View", this.stationCode );

      this.closeTabularData(); // Opening of dashboard clear previously shown tables


      this.initData();


      // determine if the route has a table to be shown
      if (params.summaryType !== undefined) {
 
        switch (params.summaryType.toUpperCase()) {
          case 'MINUTE':
            this.setActiveTableStates('isMinuteTableActive');
            break;
          case 'HOURLY':
            this.setActiveTableStates('isHourlyTableActive');
              break;
          case 'DAILY':
            this.setActiveTableStates('isDailyTableActive');
               break;
          case 'MONTHLY':
            this.setActiveTableStates('isMonthlyTableActive');
            break;
          case 'YEARLY':
            this.setActiveTableStates('isYearlyTableActive');
            break;
        //  case 'CHARTS':
        //    this.setActiveTableStates('isChartsActive');
        //    break;
          default:
            break;
        }
      }


    }); //activatedRoute

  }


  ngOnDestroy() {
   // if (this.availableRadars.params !== undefined) {
   //   this.availableRadars.params.unsubscribe();
   // }
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  isLocationInsideRadar(lat: number, lng: number) {
    var inside = false;
    try {

    let LatLongPosition = L.latLng(lat, lng);

    for (var index = 0; index < this.availableRadars.length; index++) {
      let radar = new L.Rectangle(this.availableRadars[index].Bounds);

      if (radar.getBounds().contains(LatLongPosition)) {
        inside = true;
      }      
    }
     }
     catch {
       this.loggingService.logToConsole("ouch");
     }

    return inside;
  }

  getSprayingConditionStyle() {
    return this.stationLatestModel.getSprayingConditionColour();
  }


  refreshChart() {

    if (this.stationModel !== undefined && this.stationModel.Radar !== undefined && this.stationModel.Radar !== null) {
      this.chartOptions = {
        credits: {
          enabled: false
        },
        chart: {
          zoomType: 'x',
          type: 'column'
        },
        title: {
          text: this.stationModel.Name + ' Radar estimated rainfall'
        },
        subtitle: {
          text: 'Last 12 Months'
        },
        yAxis: {
          title: {
            text: 'Rain (mm)'
         }
        },
        xAxis: {
          categories: this.stationModel.Radar.MonthlyRain.reverse().map(m => { return m.Month + ' \'' + m.Year.toString().substr(2,2) })
        },
        series: [{
          name: 'Estimated rainfall',
          data: this.stationModel.Radar.MonthlyRain.map(m => { return m.EstimatedRain }),
          color: '#028AFF'
        }]
      }
    }
  }



  toggleTable(dataTable: string, dateParams?: any) {

    //console.log(this.activeTables);
    switch (dataTable) {
      case 'MINUTE':
        this.setActiveTableStates('isMinuteTableActive');
        this.activeTables.minuteDateRange = dateParams;
        this.googleAnalyticsService.emitEvent("TabularData", "View", "MINUTE - " + this.stationCode );
        break;
      case 'HOURLY':
        this.setActiveTableStates('isHourlyTableActive');
        this.activeTables.hourlyDateRange = dateParams;
        this.googleAnalyticsService.emitEvent("TabularData", "View", "HOURLY - " + this.stationCode );
        break;
      case 'DAILY':
        this.setActiveTableStates('isDailyTableActive');
        this.activeTables.dailyDateRange = dateParams;
        this.googleAnalyticsService.emitEvent("TabularData", "View", "DAILY - " + this.stationCode );
        break;
      case 'MONTHLY':
        this.setActiveTableStates('isMonthlyTableActive');
        this.activeTables.monthlyDateRange = dateParams;
        this.googleAnalyticsService.emitEvent("TabularData", "View", "MONTHLY - " + this.stationCode  );
        break;
      case 'YEARLY':
        this.setActiveTableStates('isYearlyTableActive');
        this.activeTables.yearlyDateRange = dateParams;
        this.googleAnalyticsService.emitEvent("TabularData", "View", "YEARLY - " + this.stationCode  );
        break;
      case 'CHARTS':
        this.setActiveTableStates('isChartsActive');
        this.googleAnalyticsService.emitEvent("TabularData", "View", "CHARTS - " + this.stationCode  );
      default:
        break;
    }

    // If mobile and this event happens we close the context menu
    if (this.displayMobileFilterContextMenu) {
      this.displayMobileFilterContextMenu = false;
    }

    //Raise Table Toggle event
    this.stateService.stateSettings.dashBoard.isTablesActive = this.isTableActive();

  }

  isTableActive() {
    let value = false;
    for (let key in this.activeTables) {
      if (this.activeTables[key]) {
        value = true;
      }
    }

    return value;
  }

  closeTabularData() {
    for (let key in this.activeTables) {
      this.activeTables[key] = false;
    }
  }

  setActiveTableStates(tableStateFlag: string) {
    this.activeTables[tableStateFlag] = !this.activeTables[tableStateFlag];
    let otherTableFlags = this.filterObject(this.activeTables, tableStateFlag); // get all othe flags will be set to false.
    for (let key in otherTableFlags) {
      this.activeTables[key] = false;
    }
  }

  /*toggleFavourite(stationLatest: StationLatest) {
    
    if (stationLatest.isFavourite) {
      //Remove
      this.stateService.removeFavouriteStation(stationLatest);
      this.stationLatest.isFavourite = false;
    } else {
      //Add Favourite , get station object
      this.stateService.addFavouriteStation(stationLatest);
      this.stationLatest.isFavourite = true;
    }
  }*/

  openStationNewWindow() {
    window.open('/station/' + this.stationCode);
    this.googleAnalyticsService.emitEvent("Dashboard", "OpenFromLink", this.stationCode );
    return false;
  }


  onActiveChanged(summary: any) {
    // onActiveChanged

    //summary.summaryType
    //summary.dateRange
    this.toggleTable(summary.summaryType, summary.dateRange);
  }

  toggleMobileFilterContextMenu() {
    this.displayMobileFilterContextMenu = !this.displayMobileFilterContextMenu;
  }

  filterObject(obj, keys) {
    return Object.keys(obj)
      .filter(k => !keys.includes(k))
      .map(k => (<any>Object).assign({}, { [k]: obj[k] }))
      .reduce((res, o) => (<any>Object).assign(res, o), {});
  }



 /*
  * onScroll
  * When the dashboard content is scrolled a flag is set so that the style to the top bar
  * can add a shadow effect to show that it goes under the header
  * bound to the DIV id="dashboard-content" html scroll event.
  */
 onScroll(e) {

  if (e.currentTarget.scrollTop > 0 ) {
    this.isContentScrolling = true;
  } else {
    this.isContentScrolling = false;
  }

 }

/*
 * backToMap
 * Left arrow to navigate back to the map homepage
 */
 backToMap() {
    this.route.navigate(["map"]);
    return false;
  }

   /**
   * Refresh counter completion.
   * This event will trigger a refresh from the API to get the latest information. The metric thats selected will be updated and
   * side panel reset with latest details.
   */
  onRefreshCountdownFinished() {

    this.initData(true);
    // https://github.com/cipchk/ngx-countdown/issues/17
    // Restart the timer countdown to give visual feedback
    const _self = this;

    setTimeout(() =>
    _self.FlipCounter.first.counter.restart()
    );
  }


  /**
   * Helper function to get the number of Minutes passed from a Given
   * UTC date, passed in as a string.
   * @param dateTimeUTC UTC DateTime, formatted in a way to work in all browsers
   */
  public getMinutesSinceDate(dateTimeUTC: string) {
    const lastUpdateDate = new Date(dateTimeUTC);
    const currentDateTime = new Date();
    const diff = Math.abs(lastUpdateDate.getTime() - currentDateTime.getTime());
    const minutes = Math.floor((diff / 1000) / 60);
    return minutes;
  }

  /**
   * Fetches the data from the cache layer and will either get it from the API
   * or the current version cached. To force a refresh set cached = false and then a new request
   * will be made by the data layer.
   * @param cached boolean Return cached data if true otherwsie will init a new API request.
   */
  public initData(cached: boolean = true) {

    this.dataCacheService.getStationModel(this.stationCode).subscribe ( data => { this.stationModel = data; });

    //Get the station latest
    this.dataCacheService.getStationLatestModelsByCode(this.stationCode, cached).subscribe(
      data => {

        this.stationLatestModel = data;

        if (this.stationLatestModel !== undefined) {
          this.stationLastUpdated = this.stationLatestModel.getLastUpdatedDateTime('dddd MMMM Do YYYY, hh:mma');

          // StationColour Indicator based on the duration it was last updated.
          const minutesSinceUpdate = this.getMinutesSinceDate(this.stationLatestModel.DateTimeUTC);
          this.stationIndicatorCode = (minutesSinceUpdate < ONLINE_INDICATOR_THRESHOLD  ) ? 'G' : (minutesSinceUpdate < OUTAGE_INDICATOR_THRESHOLD ) ? 'O' : 'R';


        }

        this.stateService.raiseApplicationReady(); // remove loading screen

      }
    );

    this.dataCacheService.getStationRainfallModelByCode(this.stationCode).subscribe(
      data => {
         this.stationRainfallModel = data;
      }
    );

    let summaryDate = moment().format('YYYY-MM-DD');

    this.dataCacheService.getStationSummary(this.stationCode,'daily', summaryDate, summaryDate, 0, 0, cached).subscribe(
      data => {
        if (data !== undefined && data[0] !== undefined ) {
          this.stationDailySummary = data[0];
        } else {
          this.isStationOffline = true; //flag to indicate station is offline
        }
      }
    );

    // Year to Date part on the dashboard
    this.dataCacheService.getStationSummary(this.stationCode, 'yearly', summaryDate.substring(0, 4), summaryDate.substring(0, 4), 0, 0, cached).subscribe(
      data => { 
        if (data !== undefined && data[0] !== undefined) {
          this.stationYearlySummary = data[0];
        }
      }
    );
  }


}
