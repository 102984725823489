import { Component, OnInit, HostListener, OnDestroy, ViewChild, ElementRef, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { StateService } from '../services/state/state.service';
import { StationModel } from '../models/station.model';

import { DataCache } from '../services/data-cache/data-cache.service';
import { Subscription } from "rxjs";
import { Router } from '@angular/router';

@Component({
  selector: 'app-primary-header',
  templateUrl: './primary-header.component.html',
  styleUrls: ['./primary-header.component.scss']
})
export class PrimaryHeaderComponent implements OnInit, OnDestroy {

  public PreNavigationSubscription: Subscription;

  isUserMenuVisibile: boolean = false;
  stateSettings: any;

  // Store the list of stations
  public weatherStations: StationModel[];
  public filteredWeatherStations: StationModel[];

  // Indication if the search has been populated
  public hasSearchValue: boolean = false;

  // Calculate if the search div is being scrolled
  isContentScrolling: boolean = false;

  // Hookup to the host listener scroll event
  @HostListener('scroll', ['$event'])
  scrollHandler(e) {
    if (e.currentTarget.scrollTop > 0 ) {
      this.isContentScrolling = true;
    } else {
      this.isContentScrolling = false;
    }
  }

  // Buttons for filtering model
  public ownerFilter: string = 'all';

  @ViewChild('findStation', { static: true}) findStationRef: ElementRef;
  @ViewChild('wrapper', { static: true}) wrapperRef: ElementRef;


  constructor(
    private stateService: StateService,
    private dataCacheService: DataCache,
    private router: Router) {
    this.stateSettings = stateService.stateSettings;
  }

  ngOnInit() {
    this.dataCacheService.getStationsAndLatest()
    .subscribe(data => {
     this.weatherStations = data[0];
     this.weatherStations.sort(this.sortObject('Name')); // Sort Object based on Key


     this.filteredWeatherStations = this.groupBy(this.weatherStations, (w) => w.Name.substring(0, 1));
    });

    // Subscribe to the Navigate Page Event from the state service
    this.PreNavigationSubscription = this.stateService.onPreNavigateToPage().subscribe( data => this.onPreNavigateToPage(data));
  }

   sortObject(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
  
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }


  ngOnDestroy() {
    this.PreNavigationSubscription.unsubscribe();
  }


  /**
   * 
   * @param navigation object to hold page being navigated to and station code
   */
  onPreNavigateToPage(navigation: any) {
    this.router.navigate([navigation.page, navigation.stationCode]);

    if (navigation.page === 'map') {
      this.stateService.OnStationSearchMatched(navigation.stationCode);
    }

    // Reset the items and close the search form.
    this.findStationRef.nativeElement.value = '';
    this.hasSearchValue = false;
    this.filteredWeatherStations = this.groupBy(this.weatherStations, (w) => w.Name.substring(0, 1));

    this.removeActiveClass(this.wrapperRef.nativeElement);
  }

  toggleLeftMenu() {
    this.stateService.toggleLeftMenu(true);
  }

  toggleUserContextMenu() {
    // TODO: Login code etc to be done in next revision
    this.isUserMenuVisibile = !this.isUserMenuVisibile;
    return false; // stop link
  }

  maximizeScreen() {
    this.stateSettings.isHeaderVisible = !this.stateSettings.isHeaderVisible;
    return false;
  }


  /*
   * Display side panel of stations to choose. originally the panel showed more data about the stations
   * but was slowly changed to become more of a picking list option for the user.
   */
  displayStationTable() {
    this.stateService.mapSettings.isStationTableActive = true;
    return false;
  }


  /*
   * Update the state to allow the metric summary table to be overlayed over
   * the map to take advantage of full screen.
   */
  setSummaryMetricTableActive(){
    this.stateService.mapSettings.isSummaryMetricTable = true;
    return false;
  }

  addActiveClass(wrap: HTMLElement) {
    wrap.classList.add('active');
  }

  removeActiveClass(wrap: HTMLElement) {
    wrap.classList.remove('active');
    this.setStationFilter('all');
  }


  /**
   * Buttons to quickly filter out by the station owner
   * @param stationOwner string that identifies the station owner
   */
  setStationFilter(stationOwner: string) {
    this.ownerFilter = stationOwner;
    this.filterStations(this.findStationRef.nativeElement.value);
  }

  /**
   * Filter the list of stations shown by the search parameter.
   * @param value The search sting for filtering the items
   */
  filterStations(value) {

    let _self = this;

    if (!value) {
      this.hasSearchValue = false;
      //this.assignCopy(); //when nothing has typed
      this.filteredWeatherStations = this.weatherStations.slice();
      this.filteredWeatherStations = this.filteredWeatherStations.filter( s => _self.ownerFilter === 'all' || s.OwnerShortCode.toLowerCase() === _self.ownerFilter );
      this.filteredWeatherStations = this.groupBy(this.filteredWeatherStations, (w) => w.Name.substring(0, 1));
 

    } else {

      this.hasSearchValue = true;

      this.filteredWeatherStations = this.weatherStations.filter(
        item => (item.Name.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.Code.toLowerCase().startsWith(value.toLowerCase())) && (_self.ownerFilter === 'all' || item.OwnerShortCode.toLowerCase() === _self.ownerFilter )
      );

      this.filteredWeatherStations = this.groupBy(this.filteredWeatherStations, (w) => w.Name.substring(0, 1));

      // reset the scroll position of the list when searching in case its being done after a user
      // has scrolled to the bottom.
      document.getElementById('search-container').scrollTop = 0;
    }
  }


  /**
   * Raise page navigation event that can be listed out for and any state changes
   * can be managed by the service.
   * @param page Name of the page the application will navigate to.
   * @param stationCode optional param of the station code.
   */
  navigateToPage(page: string, stationCode: string = null) {
    this.stateService.raiseNavigationPageSubject({
      'page': page,
      'stationCode': stationCode
    });
  }

  /**
   * Assign the full collections of stations back into the filtered list
   * and grouped accordingly. The input is reset by making reference to the templated
   * findStation
   */
  resetFilteredItems() {
    this.hasSearchValue = false;
    this.filteredWeatherStations = this.groupBy(this.weatherStations, (w) => w.Name.substring(0, 1));
  }


  /**
   * Group a JSON Collection by a certain field.
   * Makes it easier to seperate the weather stations via feature layers.
   * @param xs
   * @param f
   */
  private groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }


}
