<div class="tabular-data-container" [ngClass]="{'active' : active == true}"
     *ngIf="stationModel !== undefined && stationModel !== null">
    <div class="tabular-data-inside-container">

       

            <div class="ui-table-caption ui-widget-header">

                <button type="button" class="close" aria-label="Close" (click)="closeTable('HOURLY')">
                    <span aria-hidden="true"><span class="icon icon-cancel-button-icon"></span></span>
                </button>


                <h2 class="title">Charts</h2>


                <div class="toolbar-wrapper charts" [ngClass]="{'active' : displayFilterMenu == true}">
                    <button (click)="toggleFilterMenu()" class="btn btn-primary table-filter-btn">Date range<span
                            class="icon icon-play"></span></button>


                    <div class="toolbar-wrapper-inside" [ngClass]="{'active' : displayFilterMenu == true}">

                        <div class="filter-options">
                            <div ngbDropdown class="d-inline-block">
                                <button class="btn drop-down-button " ngbDropdownToggle>
                                    {{currentIntervalFilter}}
                                </button>

                                <div ngbDropdownMenu aria-labelledby="interval-filter">
                                    <button (click)="setIntervalFilter('HOURLY')" class="dropdown-item">Hourly</button>
                                    <button (click)="setIntervalFilter('DAILY')" class="dropdown-item">Daily</button>
                                    <button (click)="setIntervalFilter('MONTHLY')" class="dropdown-item">Monthly</button>
                                    <button (click)="setIntervalFilter('YEARLY')" class="dropdown-item">Yearly</button>
                                </div>

                            </div>
                        </div>


                        <div id="date-picker-wrapper">

                            <label>
                                <input placeholder="Date - Time Range" [owlDateTimeTrigger]="dt10" [owlDateTime]="dt10"
                                       [selectMode]="'range'" [(ngModel)]="selectedMoments"
                                       (dateTimeChange)="onDateTimeChange($event)" readonly>
                                <owl-date-time #dt10="owlDateTime" pickerType="{{pickerType}}"
                                               pickerMode="dialog"></owl-date-time>
                                <span class="icon icon-calendar"></span>
                            </label>
                        </div>

                    </div>
                </div>
            </div>



            <div class="tabular-data-buttons">
                <button class="btn btn-primary" (click)="onActiveChanged('MINUTE')" >Minute
                </button>

                <button class="btn btn-primary" (click)="onActiveChanged('HOURLY')">Hourly
                </button>

                <button class="btn btn-primary" (click)="onActiveChanged('DAILY')">Daily
                </button>

                <button class="btn btn-primary" (click)="onActiveChanged('MONTHLY')">Monthly
                </button>

                <button class="btn btn-primary" (click)="onActiveChanged('YEARLY')">Yearly
                </button>

                <button class="btn btn-primary active">Charts
                </button>
            </div>



            <!--<button (click)="submitDateParameters()">GO</button> perfectScrollbar-->
            <div class="chart-container" data-simplebar>





                <div class="row">
                    <div class="col-md-12 col-lg-6 ">
                        <div class="chart-wrapper">
                            <app-temperature-chart></app-temperature-chart>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <div class="chart-wrapper">
                            <app-relative-humidity-chart></app-relative-humidity-chart>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-6 ">
                        <div class="chart-wrapper">
                            <app-evaporation-chart></app-evaporation-chart>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6 ">
                        <div class="chart-wrapper">
                            <app-solar-exposure-chart></app-solar-exposure-chart>
                         
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-6">
                        <div class="chart-wrapper">
                            <app-wind-rose-chart [hidden]="stationModel.hasWindProbes() == false"></app-wind-rose-chart>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <div class="chart-wrapper">
                            <app-wind-speed-chart
                            [hidden]="stationModel.hasWindProbes() == false"></app-wind-speed-chart>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-lg-6 ">
                        <div class="chart-wrapper">
                            
                            <app-rain-fall-chart></app-rain-fall-chart>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <div class="chart-wrapper" [hidden]="currentIntervalFilter != 'HOURLY'">
                           
                            <app-delta-tchart></app-delta-tchart>
                        </div>
                    </div>
                </div>
            </div>
        
    </div>
</div>