export class PrecipitationSummaryMetrics {

  // Period Dates are in UTC
  public FromDateTime: string;
  public ToDateTime: string;

  public Minute: number;
  public Hour: number;
  public Day: number;
  public Month: number;
  public Year: number;
  public EstimatedRainfall: number;

  //Derived members
  public MonthShortCode: string;

  constructor(response: any) {

    if (response !== undefined && response !== null) {
      this.Minute = response.period.minute;
      this.Hour = response.period.hour;
      this.Day = response.period.day;
      this.Month = response.period.month;
      this.MonthShortCode = this.Month.getMonthName(); // Function in date extensions folder
      this.Year = response.period.year;
      this.EstimatedRainfall = response.rainfall;
    }
  }
}