<div class="menuBarItemContainer" [ngClass]="{'active': isActive }">
  <div class="inner-wrapper">
    <div class="close-button" (click)="toggleIsActive()"><span class="icon icon-arrow-down-icon"></span></div>
    <div class="multiselect  btn-group btn-group-toggle">
      <div class="station-group-wrapper" *ngFor="let item of mapSettings.layers.weatherStations; let i = index">
        <label class="btn-primary" ngbButtonLabel>
          <input type="checkbox" ngbButton [(ngModel)]="item.isStationsVisible" 
          (click)="toggleStationLayer(item.groupId)" />{{showStationTypeName(item.groupId)}}
        </label>
        <div class="group" [ngClass]="{'active' : item.isClustered === true}"
        (click)="onGroupToggle(item.groupId)">Group</div>
      </div>
    </div>
  </div>
  <div class="metric-arrow-down"></div>
</div>

<button type="button" class="btn btn-outline-secondary" (click)="toggleIsActive()">
  <div class="icon-container"><span class="icon icon-stations"></span></div>
  <div class="wrapper">
    <div class="dock-title">Stations</div>
    <div class="selected">{{stationSelection}}</div>
    <div class="icon icon-play"></div>
  </div>
</button>