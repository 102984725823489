import * as moment from 'moment';

export class ForecastingViewModel {

  public ForecastDateTime: string;  
  public AirTemperature: number;
  public WindSpeed: number;
  public WindDirection: number;
  public PrecipitationNextHour: number;
  public ForecastDateTimeFormatted: string;
  public ForecastDateTimeOnlyFormatted: string;
  
    constructor(response?: any ) {
      if (response !== null && response !== undefined) {
        if (response.data !== undefined ) {
          this.ForecastDateTime = response.time;
          this.ForecastDateTimeFormatted = moment.utc(response.time).local().format("DD/MM/YYYY");
          this.ForecastDateTimeOnlyFormatted = moment.utc(response.time).local().format("HH:mm:ss");
          this.AirTemperature =  Math.round(response.data.instant.details.air_temperature);
          this.WindSpeed = Math.round(response.data.instant.details.wind_speed);
          this.WindDirection = response.data.instant.details.wind_from_direction;
          this.PrecipitationNextHour = response?.data?.next_1_hours?.details?.precipitation_amount;
          if (this.PrecipitationNextHour == null) {
            this.PrecipitationNextHour = 0;
          }
        }
      }
    }
  }