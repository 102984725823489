import * as moment from 'moment';

export class BoMStationModel {

  public StationName: string;
  public BoMId: string;
  public Owner: string;
  public LastUpdated: string;
  public LastUpdateLocalTime: string;

  public Latitude: number;
  public Longitude: number;

  public ApparentTemperature: number;
  public AirTemperature: number;
  public DewPoint: number;
  public MeanSeaLevelPressure: number;
  public RelativeHumidity: number;
  public WindDirectionDegrees: number;
  public WindDrectionCompass: string;
  public WindSpeedKmh: number;
  public WindSpeedKnots: number;
  public WindGustKmh: number;
  public WindGustSpeedKnots: number;
  public VisibilityKmh: number;
  public RainSince9AM: number;
  public RainPrev24Hrs: number;
  public MaxAirTemp: number;
  public MinAirTemp: number;

  constructor(response: any) {

    if (response !== undefined && response !== null) {
      this.StationName = response.stnaltname;
      this.BoMId = response.bomid;
      this.Owner = 'BoM station';
      this.Latitude = response.lat;
      this.Longitude = response.lon;
      this.LastUpdated = response.timeutc.replace('+00', 'Z').replace(' ', 'T'); // moment(response.timeutc).format('DD-MM-YYYY HH:mm A'); new Date(this.DateTimeUTC.replace('+00','Z').replace(' ', 'T'));
      // TODO: Have API Fixed
      this.LastUpdateLocalTime = moment(this.LastUpdated).format('DD-MM-YYYY hh:mm A');
      this.ApparentTemperature  = response.apptemp;
      this.AirTemperature  = response.airtemp;
      this.DewPoint  = response.dewpoint;
      this.MeanSeaLevelPressure  = response.mslpres;
      this.RelativeHumidity = response.relhum;
      this.WindDirectionDegrees = response.winddirdeg;
      this.WindDrectionCompass = this.degToCompass(response.winddirdeg);
      this.WindSpeedKmh = response.windspdkmh;
      this.WindSpeedKnots = response. windspd;
      this.WindGustKmh = response.gustkmh;
      this.WindGustSpeedKnots = response.gustspd;
      this.VisibilityKmh = response.viskm;
      this.RainSince9AM = response.rain;
      this.RainPrev24Hrs = response.rain24hr;
      this.MaxAirTemp = response.maxairtemp;
      this.MinAirTemp = response.minairtemp;
    }
  }

  private degToCompass(num) {
    const val = Math.floor((num / 22.5) + 0.5);
    const arr = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
    return arr[(val % 16)];
  }
}
