import { StationRainFall } from '../services/weather/models/station-rainfall.model';
import { RainFallMetricsModel } from './rainfall-metrics.model';


export class StationRainfallModel extends StationRainFall {

  public RainFallMetrics: RainFallMetricsModel;

  constructor(rainfall: StationRainFall) {
    super();

    if (rainfall !== undefined) {
      this.Code = rainfall.Code;
      this.Name = rainfall.Name;
      this.Latitude = rainfall.Latitude;
      this.Longitude = rainfall.Longitude;
      if (rainfall.RainFallMetrics !== undefined) {
        this.RainFallMetrics = new RainFallMetricsModel(rainfall.RainFallMetrics);
      }
    }

  }
}