export class APIResponse<T> {
  public metadata = {
    status: 0,
    collection: {
      count: null,
      limit: null,
      offset: null
    }
  };

  collection: [T];

  constructor(metadata, collection) {
    this.metadata = metadata;
    this.collection = collection;
  }

}
