import { Component, OnInit } from '@angular/core';
import { StateService } from '../services/state/state.service';

@Component({
  selector: 'app-terms-conditions-page',
  templateUrl: './terms-conditions-page.component.html',
  styleUrls: ['./terms-conditions-page.component.scss']
})
export class TermsConditionsPageComponent implements OnInit {

  constructor(public stateService: StateService) { }

  ngOnInit() {
    this.stateService.raiseApplicationReady();
  }

}
