import { WindProbe } from '../services/weather/models/wind-probe.model';

import * as moment from 'moment';

export class WindProbeModel extends WindProbe {

  public Height: number;
  public AvgSpeed: number;
  public AvgSpeedKnots: number;
  public AvgDirectionDegrees: number; // Field only available for Hourly and Minutes
  public AvgDirectionCompassPoint: string;
  

  //Max
  public MaxSpeed: number;
  public MaxTime: string;

  public MaxDirectionDegrees: number;
  public MaxCompassPoint: string;

  // Simpler to pass the raw response in and manage mapping in model
  // can easily extract business login into here then where certain fields
  // are not available depending on the interval used.
  constructor(probe: WindProbe) {
    super();

    if (probe !== undefined && probe !== null) {
      this.Height =  probe.Height;
      this.AvgSpeed = probe.AvgSpeed;
      this.AvgSpeedKnots = this.AvgSpeed / 1.825;
      this.AvgDirectionDegrees = probe.AvgDirectionDegrees;
      this.AvgDirectionCompassPoint = probe.AvgDirectionCompassPoint;
      this.MaxSpeed = probe.MaxSpeed;
      this.MaxDirectionDegrees = probe.MaxDirectionDegrees;
      this.MaxCompassPoint = probe.MaxCompassPoint;
      this.MaxTime = probe.MaxTime;
    }

  }

  getMaxDateTimeConditionalString(dateMask) {

    if (this.MaxTime !== null) {
      return  moment(this.MaxTime).format(dateMask);
    }

     if (moment().hours() <= 9 ) { //explicit showing that null here should be to get to this line anyway
      return "available after 9am"; // All other fields should be filled so having this clause for special 9am attributes won't be an issue
    }  

    // Still null after 9am indicating API or database problem so show unkown date.
    return "unknown date";
  }

  getMaxDateTime(dateMask) {

    if (this.MaxTime !== null) {
      return  moment(this.MaxTime).format(dateMask);
    }
    
    return null;
  }

}