export const environment = {
  production: true,
  APIKey: '28FCCD2FAAFEFA6DDC8EAD73.apikey',
  APIURI: 'https://api.agric.wa.gov.au/v2/weather/',
  GoogleTrackingManagerId: 'GTM-K3GHL6K',
  GoogleTagTrackingId: 'G-FB1SGJHB7M',
  //RadarAPIKey: '28FCCD2FAAFEFA6DDC8EAD73.apikey'  test
  //RadarAPIKey: '28FCCD2FAAFEFA6DDC8EAD73.apikey',
  RadarAPIURI: 'https://api.agric.wa.gov.au/',
  SITE_URL: 'https://weather.agric.wa.gov.au',
  RADAR_IMAGE_URL: 'https://api.agric.wa.gov.au/v1/radar/images/',
  GIS2WEB_APIURI: 'https://api.agric.wa.gov.au/v1/gis-to-web/',
  FORECASTING_APIURI: 'https://api.met.no/weatherapi/locationforecast/2.0/compact.json'
};