import { Component, OnInit, HostListener, SimpleChanges, NgModule } from '@angular/core';
import { StateService } from '../services/state/state.service';
import { WeatherService } from '../services/weather/weather.service';
import { LoggingService } from '../services/logging/logging.service';
import { Station } from '../services/weather/models/station.model';
import { StationLatest } from '../services/weather/models/station-latest.model';

import { DataCache } from '../services/data-cache/data-cache.service';
import { NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-station-table',
  templateUrl: './station-table.component.html',
  styleUrls: ['./station-table.component.scss'],
  providers: [LoggingService]
})
export class StationTableComponent implements OnInit {

  public mapSettings;
  public weatherStations: StationLatest[] = [];
  public filteredItems: StationLatest[] = [];
  public hasSearchValue: boolean = false;
  
  public isSearchActive: boolean = false;

  screenHeight: number;
  screenWidth: number;
  tableWrapperHeight: string = "300px";

  @HostListener('window:resize', ['$event'])
    onResize(event?) {
      this.screenHeight = window.innerHeight || screen.height;
      this.screenWidth = window.innerWidth || screen.width;
      this.setTableWrapperHeight();
  }

  constructor(
    private stateService: StateService,
    private weatherService: WeatherService,
    private loggingService: LoggingService,
    private route: Router,
    private dataCacheService: DataCache
  ) { }

  ngOnInit() {
    this.onResize();
    this.mapSettings = this.stateService.mapSettings;

    this.dataCacheService.getStations()
      .subscribe(
      data => { this.weatherStations = data },
      err => { this.onRequestError(err) },
      () => this.onCompletedGetStations());

  }

  setTableWrapperHeight() {
    // determine best fit for table based on height.
    if (this.screenHeight <= 667) {
      this.tableWrapperHeight = "300px";
    }

    if (this.screenHeight <= 740) {
      this.tableWrapperHeight = "479px";
    }

    //otherwise work as pecentage
    if (this.screenHeight > 740) {
      this.tableWrapperHeight = (this.screenHeight * 0.68).toString() + 'px';
    }

  }

  onTabulareDataCloseButtonClicked() {
    this.stateService.mapSettings.isStationTableActive = false;
  }


  assignCopy() {
    this.filteredItems = this.weatherStations.slice();
  }

  filterItem(value) {
    if (!value) {
      this.hasSearchValue = false;
      this.assignCopy(); //when nothing has typed
    } else {

      this.hasSearchValue = true;

      this.filteredItems = this.weatherStations.filter(
        item => item.Name.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
      // reset the scroll position of the list when searching
      document.getElementById('stationPicklist').scrollTop = 0; 
    }
  }

  onCompletedGetStations() {
    this.assignCopy();//when you fetch collection from server.

  }

  onRequestError(error) {
    this.loggingService.logToConsole(error);
  }

  addToFavourites(station) {
   // this.weatherStations.find(s => s.Code === station.Code).isFavourite = true;
   // this.stateService.addFavouriteStation(station);
  }

  getStationsOrdered() {
    return this.weatherStations.sort(this.compare);
  }


  compare(a, b) {
  if (a.Name < b.Name)
    return -1;
  if (a.Name > b.Name)
    return 1;
  return 0;
  }



  navigateToPage(state, station) {
    this.stateService.mapSettings.isStationTableActive = false;
    this.route.navigate(['station', station]);
    this.stateService.panelSettings.isOpen = false;
  }

  ngOnChanges(changes: SimpleChanges) {
  }
}
