import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-mobile-weather-data-btns',
  templateUrl: './mobile-weather-data-btns.component.html',
  styleUrls: ['./mobile-weather-data-btns.component.scss']
})
export class MobileWeatherDataBtnsComponent implements OnInit {


  public isMobileFilterActive: boolean = false;
  
  @Input() active = false;
  @Output() onMenuSelection = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }


  activateMobileFilter() {
    this.isMobileFilterActive = true;
  }

  closeFilter() {
    this.isMobileFilterActive = false;
  }

  toggleTable(tableName: string) {
    this.isMobileFilterActive = false;
    this.onMenuSelection.emit(tableName);
  }

 }

