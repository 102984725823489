<!-- Stations Tabular View -->
<div class="station-table" [ngClass]="{'active' : mapSettings.isStationTableActive == true}">
  <div class="station-table-inside">
    <button type="button" class="close" aria-label="Close" (click)="onTabulareDataCloseButtonClicked()">
      <span aria-hidden="true"><span class="icon  icon-cancel-button-icon"></span></span>
    </button>
    <div class="search-container" [ngClass]="{'active' : isSearchActive == true}">
      <span class="icon icon-search-icon"></span>
      <input #findStation id="findStation" type="text" placeholder="Find weather station" (input)="filterItem(findStation.value)" />
      <span class="icon icon-cancel-button-icon" [hidden]="!hasSearchValue" (click)="findStation.value = ''; assignCopy();"></span>
    </div>


    <div class="toScroll">
      <app-station-favourite-table></app-station-favourite-table>
  
      <div class="all-stations-wrapper">
        <div class="toolbar-wrapper">
          <h2>All weather stations</h2>
          <div class="inside-tools-wrapper">
            <div class="activate-search" (click)="isSearchActive = !isSearchActive" [ngClass]="{'active' : isSearchActive == true}">
              <span class="icon icon-search-icon"></span>
            </div>
          </div>
        </div>
      <div id="stationPicklist" data-simplebar class="table-wrapper" [ngStyle]="{'height' : tableWrapperHeight}" >

        <table class="table">
          <thead>
            <tr>

              <!--<th scope="col">Fav</th>-->
              <th scope="col">Station</th>
            </tr>
          </thead>


          <tbody>
            <ng-template ngFor let-station [ngForOf]="filteredItems" let-i="index">

              <tr [hidden]="station.isFavourite" (click)="navigateToPage('station', station.Code)">
                <!--<th scope="row"><div class="icon icon-favourite-full-icon" (click)="addToFavourites(station)"></div></th>-->
                <td>{{station.Name}} <span>({{station.Code}})</span></td>
              </tr>
            </ng-template>
          </tbody>
       </table>
      </div><!-- stationPicklist -->

    </div> <!-- /toScroll -->
    </div>
   </div> <!--station-table-inside -->
</div> <!-- /station-table -->
<!-- /Stations Tabular View -->